import React, { useMemo } from "react";
import { IReportsGroup } from "../../Report.interface";
import { ReportContext } from "../../../../../../functional/contexts/reportContext";
import { Collapse, CollapsePanel } from "../../../../../uiKit";
import { ReportUserCollapse } from "../reportUserCollapse/ReportUserCollapse";
import { EmptyBlock } from "../../../../layouts/emptyBlock/EmptyBlock";
import { ColorModeKeys } from "../../../../../elements/counterChip/misc/keys";
import { CounterChip } from "../../../../../elements/counterChip/CounterChip";
import type { IssueReportItem2UserDto } from "../../../../../../functional/api";
import { useTranslation } from "react-i18next";

export const ReportsGroup = ({ reports, users, reportsType, selectDate, orgchartFilter }: IReportsGroup) => {
  const { t } = useTranslation();

  const groupedData = useMemo(() => {
    const arr = JSON.parse(JSON.stringify(reports.items));
    // @ts-ignore
    const list = (arr ?? []).reduce((acc, obj) => {
      // @ts-ignore
      if (acc[obj?.userId]) {
        // @ts-ignore
        acc[obj?.userId].value += obj?.value ?? 0;
      } else {
        // @ts-ignore
        acc[obj?.userId] = obj;
      }
      return acc;
    }, {});
    return list
  }, [reports.items]);


  const reportsList = useMemo(() => {
    return orgchartFilter
      ? (reports.items ?? []).filter((user) => user.orgchartId === orgchartFilter)
      :  Object.values(groupedData) as IssueReportItem2UserDto[] ?? [];
  }, [reports.items, orgchartFilter]);

  const collapseHeader = (
    <div className="d-stack-row justify-start align-start spacing-2">
      <p className="mb-0">
        { t(`ui:issue_status.${
          reports.key?.
            replace("new_issues.from_recurring", "new_issues_from_recurring")?.
            replace("default.v1.status.approval", "approval")
        }`) }
      </p>
      {!!reportsList.length && <CounterChip colorMode={ColorModeKeys.primary} count={reportsList.length} />}
    </div>
  );

  return (
    <ReportContext.Provider
      value={{
        reportKey: reports.key,
        users: users,
        timeInterval: selectDate,
      }}
    >
      <Collapse accordion style={{ width: "100%" }}>
        <CollapsePanel
          style={{ width: "100%" }}
          key={reports.id!}
          id={`${reports.id ?? 0}`}
          header={collapseHeader}
        >
          {!!reportsList.length ? (
            <div className="d-stack-column spacing-2">
              {reportsList.map((userDateItem) => (
                  <ReportUserCollapse
                    key={`${userDateItem.userId}-${userDateItem.orgchartId}`}
                    reportsType={reportsType}
                    userDate={userDateItem}
                    user={users?.find((user) => user.id === userDateItem.userId)}
                    orgchartFilter={orgchartFilter}
                  />
                )
              )}
            </div>
          ) : (
            <EmptyBlock />
          )}
        </CollapsePanel>
      </Collapse>
    </ReportContext.Provider>
  );
};

import React from "react";
import { useRootStore } from "../../../../../functional/hooks";
import ruContent from "../../../../../functional/utils/guides-lexical/employee/ru.json";
import enContent from "../../../../../functional/utils/guides-lexical/employee/en.json";
import ukContent from "../../../../../functional/utils/guides-lexical/employee/uk.json";
import { TextEditor as TextEditorLexical } from "../../../textEditor/TextEditor";

export const getContent = (localeKey: string | null): any | null => {
  if (localeKey?.includes("uz")) {
    return JSON.stringify(ruContent);
  }

  switch (localeKey) {
    case "ru":
      return JSON.stringify(ruContent);
    case "en":
      return JSON.stringify(enContent);
    case "uk":
      return JSON.stringify(ukContent);
    default:
      return JSON.stringify(enContent);
  }
};

export const InviteEmployeeGuide = () => {
  const { hintStore } = useRootStore();

  const anchorId = hintStore.getAnchorId;

  return (
    // <TextEditorOld
    //   readOnly={true}
    //   anchorKey={anchorId as string}
    //   id="guide-dasboard"
    //   onChange={() => {}}
    //   value={getContent(appStore.getAppLocale)}
    // />
    <TextEditorLexical
      readOnly
      anchorKey={anchorId as string}
      value={getContent(hintStore.getLang)}
      withoutBackground
    />
  );
};

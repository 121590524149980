import { Form } from "antd";
import React, { createRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IssueDto, StaticFileDto } from "../../../../../functional/api";
import { Alert, Button, Checkbox, Icon, Text, TextArea } from "../../../../uiKit";
import {
  getImagesCount,
  getLexicalTextContentLength,
  initialEditorContent,
  serverInitialEditorContent
} from "../../../../../functional/utils/textEditor";
import { getStringContentType } from "../../../../../functional/helpers/stringFunctions";
import { StringContentType } from "../../../../../functional/enums/stringContentType";
import FileUploader from "../../../../service/fileUploader/FileUploader";
import { TextEditor as TextEditorLexical } from "../../../textEditor/TextEditor";
import { ToolbarType } from "../../../textEditor/types/types";
import { LucidePaperclip } from "lucide-react";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import { actionsKeysConst } from "../../../forms/types/consts";
import { TimePickerMask } from "../../../../uiKit/dataEntry/pickers/timePickerMask/TimePickerMask";
import { TIME_PLAN_QUICK_VALUES } from "../../../../../functional/constants/QuickValues";

interface IIssueRequiredActionsDialogTabAddProof {
  issueData: IssueDto;
  setIsLoading: (value: boolean) => void;
  lastCommentIsProof: boolean;
  proofText: string;
  proofFiles: StaticFileDto[];
  checkboxDisabled: boolean;
  editorRef: React.MutableRefObject<{ isEmptyEditor: () => boolean; resetEditor: () => void } | undefined>;
  showCheckboxToSaveLastComment: boolean;
  onSaveProofFromLastComment: () => void;
  onProofTextChange: (value: string) => void;
  onProofFilesChange: (files: StaticFileDto[]) => void;
  onProofFilesDeleteById: (fileId: number) => void;
  isChecked: boolean;
  onIsCheckedChange: (value: boolean) => void;
  isShowErrorValidateMessage: {
    showCommonError: boolean;
    showTimeFactError: boolean;
  };
  currentTimeFact: string;
  onCurrentTimeFactChange: (value: string) => void;
}

export const IssueRequiredActionsDialogTabAddProof = (props: {
  editorRef: React.MutableRefObject<{
    isEmptyEditor: () => boolean;
    resetEditor: (content?: string) => void
  } | undefined>;
  showCheckboxToSaveLastComment: boolean;
  onSaveProofFromLastComment: () => null | Promise<void>;
  proofText: string;
  currentTimeFact: string;
  isChecked: boolean;
  checkboxDisabled: boolean;
  onCurrentTimeFactChange: (value: string) => void;
  setIsLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void;
  onProofFilesChange: (files: StaticFileDto[]) => void;
  lastCommentIsProof: boolean;
  onIsCheckedChange: (value: boolean, key: actionsKeysConst) => Promise<void | void>;
  onProofTextChange: (text: string) => void;
  issueData: IssueDto;
  proofFiles: StaticFileDto[];
  onProofFilesDeleteById: (fileId: number) => void;
  isShowErrorValidateMessage: { showCommonError: boolean; showTimeFactError: boolean; showDeadlineError: boolean }
}) => {
  const { t } = useTranslation();
  const uploadFilesInputRef = createRef();

  const handleGetProofFormat = () => {
    let format = "";
    props.issueData.proofRequirement?.rules?.forEach((r) => {
      if (r.key === "require.text_min_length") {
        format = t("ui:subheader.issue_complete_dialog_format.text");
      }
      if (r.key === "require.images_min_count") {
        format = t("ui:subheader.issue_complete_dialog_format.image");
      }
      if (r.key === "require.files_min_count") {
        format = t("ui:subheader.issue_complete_dialog_format.file");
      }
    });
    return format;
  };

  const handleGetProofValue = () => {
    let value = "";
    props.issueData.proofRequirement?.rules?.forEach((r) => {
      if (r.key === "require.text_min_length") {
        value = t("ui:subheader.issue_complete_dialog_value.symbols");
      }
      if (r.key === "require.images_min_count") {
        value = t("ui:subheader.issue_complete_dialog_value.count");
      }
      if (r.key === "require.files_min_count") {
        value = t("ui:subheader.issue_complete_dialog_value.count");
      }
    });
    return value;
  };

  // serverInitialEditorContent

  const rendererLexicalProofRequirementText = () => {
    console.log(" props.issueData?.proofRequirement?.text", props.issueData?.proofRequirement?.text);
    return props.issueData?.proofRequirement?.text !== serverInitialEditorContent &&
      props.issueData?.proofRequirement?.text !== initialEditorContent
      ? (
        <TextEditorLexical
          placeholder={t("ui:subheader.text")}
          value={props.issueData?.proofRequirement?.text!}
          readOnly
          isNewStyle
          isTransparent
        />
      )
      : null;
  }

  const m = (
    <div className="d-stack-column">
      {props.issueData?.proofRequirement?.text?.includes('{"root":{"children"')
        ? rendererLexicalProofRequirementText()
       : (
        <Text
          children={
            (props.issueData?.proofRequirement?.text ?? "").length > 0 && props.issueData?.proofRequirement?.text + ". "
          }
        />
      )}
      <Text
        weight="bold"
        children={t("validation:issue_complete_dialog_description", {
          format: handleGetProofFormat(),
          value: handleGetProofValue(),
          number: props.issueData?.proofRequirement?.rules?.find((r) => r.key)?.value ?? 0,
        })}
      />
    </div>
  );

  useEffect(() => {
    if (!props.currentTimeFact.length && props.issueData?.timePlan) {
      props.onCurrentTimeFactChange(props.issueData?.timePlan);
    }
  }, []);

  return (
    <div className="d-stack-column spacing-3">
      <div className="d-stack-row justify-space-between">
        <div style={{ width: "48%" }}>

          <SubheaderText text={t("ui:planning_table.plan")} />
          <TimePickerMask
            size="large"
            disabled
            className="full-width"
            value={props.issueData?.timePlan}
          />
        </div>
        <div style={{ width: "48%" }}>
          <SubheaderText text={t("ui:planning_table.fact")} />
          <TimePickerMask
            size="large"
            className="full-width"
            value={props.currentTimeFact.length ? props.currentTimeFact : null}
            onChange={(value) => props.onCurrentTimeFactChange(value ?? "")}
            quickValues={TIME_PLAN_QUICK_VALUES}
          />
          {props.isShowErrorValidateMessage.showTimeFactError && (
            <Text
              size="12px"
              style={{ color: "var(--color-txt-error)" }}
              children={t("validation:issue_complete_dialog_validate.time_error")}
            />
          )}
        </div>
      </div>
      {/*{(props.issueData?.proofRequirement?.text ?? "").length > 0 && <Alert message={m} />}*/}
      {props.issueData?.proofRequirement !== undefined && <Alert message={m} style={{ whiteSpace: "break-spaces" }} />}
      {/*{props.issueData?.proofRequirement == null && <Alert type="error" message={t("text:task_proof.no_need_proof")} />}*/}
      <Form.Item
        className="my-0"
        extra={
          getLexicalTextContentLength(props.proofText) > 0 &&
          props.issueData?.proofRequirement?.rules?.some((r) => r.key == "require.text_min_length")
            ? /*t("validation:min_char_number", {
                number:
                  props.issueData?.proofRequirement?.rules?.find((r) => r.key == "require.text_min_length")?.value ?? 0,
              }) +
              ". " +*/
              t("common:misc.entered_characters_number", { number: getLexicalTextContentLength(props.proofText) }) +
                " / " +
                props.issueData?.proofRequirement?.rules?.find((r) => r.key == "require.text_min_length")?.value ?? 0
            : undefined
        }
      >
        <div>
          <div className="d-stack-row spacing-2" style={{ overflow: "hidden" }}>
            {/*<div className="flex-grow-1" style={{ overflow: "auto" }}>*/}
            <div className="flex-grow-1" style={{ overflow: "auto" }}>
              {getStringContentType(props.proofText) == StringContentType.text ? (
                <TextArea
                  autoSize
                  autoComplete="off"
                  placeholder={t("ui:subheader.text")}
                  value={props.proofText as unknown as string}
                  onInput={(event: any) => props.onProofTextChange(event.target.value)}
                />
              ) : (
                <TextEditorLexical
                  id="ds-add-proof-editor"
                  ref={props.editorRef}
                  disabled={props.isChecked}
                  variant={props.isShowErrorValidateMessage.showCommonError ? "error" : undefined}
                  placeholder={t("ui:subheader.text")}
                  value={props.proofText}
                  initialValue={props.proofText === initialEditorContent ? initialEditorContent : props.proofText}
                  onChange={props.onProofTextChange}
                  toolbarType={ToolbarType.Hidden}
                />
              )}
            </div>
            <div className="flex-shrink-0">
              <Button
                onClick={() => (uploadFilesInputRef?.current as any)?.click()}
                icon={<Icon component={() => <LucidePaperclip size={18} />} />}
                type="default"
                size={"large"}
                disabled={props.isChecked}
                style={{ height: 43.5, width: 43.5 }}
              />
            </div>
          </div>
          {props.isShowErrorValidateMessage.showCommonError ? (
            <Text
              children={t("validation:issue_complete_dialog_validate.common_error")}
              size="12px"
              style={{ color: "var(--color-txt-error)" }}
              className="mt-1"
            />
          ) : undefined}
        </div>
      </Form.Item>
      <FileUploader
        uploadFilesInputRef={uploadFilesInputRef}
        accept={
          props.issueData?.proofRequirement?.rules?.find((r) => r.key == "require.files_min_count") != null
            ? "*"
            : "image/*"
        }
        hideInfo={!props.proofFiles.length}
        hideButton
        smallSize
        readonly={props.isChecked}
        value={props.proofFiles}
        onUploadStatusChange={props.setIsLoading}
        additionallyCountImg={
          props.issueData?.proofRequirement?.rules?.find((r) => r.key == "require.files_min_count") != null
            ? 0
            : props.proofText
            ? getImagesCount(props.proofText)
            : 0
        }
        // value={props.issueData?.proof?.history?.comment?.attachments?.map((a) => a.file as StaticFileDto) ?? []}
        onUpload={(files: StaticFileDto[]) => props.onProofFilesChange(files)}
        onValueItemDelete={props.onProofFilesDeleteById}
        onAnyItemDelete={props.onProofFilesDeleteById}
        requiredNumber={
          (props.issueData?.proofRequirement?.rules?.find((r) => r.key == "require.files_min_count") != null
            ? props.issueData?.proofRequirement?.rules?.find((r) => r.key == "require.files_min_count")?.value
            : props.issueData?.proofRequirement?.rules?.find((r) => r.key == "require.images_min_count")?.value) ??
          undefined
        }
      />
      {props.showCheckboxToSaveLastComment && (
        <div className="">
          <Checkbox
            // disabled={props.checkboxDisabled}
            // checked={props.lastCommentIsProof}
            checked={props.isChecked}
            // onChange={(event) => {
            //   console.log(event);
            //   event.target.checked ? props.onSaveProofFromLastComment() : props.editorRef.current?.resetEditor();
            // }}
            onChange={(e) => props.onIsCheckedChange(e.target.checked, actionsKeysConst.addProof)}
          >
            {t("ui:label.last_comment_is_proof").toString()}
          </Checkbox>
        </div>
      )}
    </div>
  );
};

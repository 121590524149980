import React, { useCallback, useContext, useEffect, useState } from "react";
import { IssuesArchiveView } from "./IssuesArchiveView";
import { ArchiveSortFiltersKeys, IIssuesArchive } from "./IssuesArchive.interface";
import { useNotifier, useDebounce, usePagingWithController } from "../../../../functional/hooks";
import { useTranslation } from "react-i18next";
import { api } from "../../../../functional/services";
import { BroadcastChannel } from "broadcast-channel";
import { BoardFilterContext } from "../../../../functional/contexts";

export const IssuesArchive = (props: IIssuesArchive) => {
  const { t } = useTranslation();
  const notifier = useNotifier();
  const context = useContext(BoardFilterContext);

  const issueArchiveChannel = new BroadcastChannel("issueArchive");

  const [issueName, setIssueName] = useState<string | undefined>(undefined);
  const [orgchartId, setOrgchartId] = useState<number | undefined>(undefined);
  const [executorId, setExecutorId] = useState<number | undefined>();
  const [senderId, setSenderId] = useState<number | undefined>(undefined);
  const [isActiveTriggerToreload, setIsActiveTriggerToreload] = useState<boolean>(false);
  const [sort, setSort] = useState<ArchiveSortFiltersKeys>(ArchiveSortFiltersKeys.desc)
  const handleChangeSort = useCallback((value: ArchiveSortFiltersKeys) => {
    setSort(value);
    setIsActiveTriggerToreload(true);
  }, []);

  const [isStatusNotDone, setIsStatusNotDone] = useState<boolean>(false);
  const handleChangeStatusNotDone = useCallback((value: boolean) => {
    setIsStatusNotDone(value);
    setIsActiveTriggerToreload(true);
  }, [])

  const handleChangeIssueName = (name: string) => setIssueName(name);
  const handleChangeOrgchartId = (id: number) => {
    setOrgchartId(id);
    setIsActiveTriggerToreload(true);
  };

  const handleChangeExecutorId = (id: number) => {
    setExecutorId(id);
    setIsActiveTriggerToreload(true);
  };

  const handleChangeSenderId = (id: number) => {
    setSenderId(id);
    setIsActiveTriggerToreload(true);
  };

  const handleApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const debounceTrigger = useDebounce(issueName, 500);

  const issues = usePagingWithController(
    api.issueShort,
    {
      boardId: context.boardId ?? null,
      isArchived: true,
      orgchartId,
      search: issueName,
      executorUserId: executorId,
      initiatorUserId: senderId,
      userRelation: context.userRelation,
      orderBy: "dateArchivedAt",
      order: sort,
      BoardStatusKeyIsNot: isStatusNotDone ? 'done' : '',
    },
    { pageSize: 15 },
    undefined,
    handleApiError
    //handlerSuccessRequestEnd
  );

  const resetFilters = () => {
    setExecutorId(undefined);
    setIssueName(undefined);
    setOrgchartId(undefined);
    setSenderId(undefined);
    setIsStatusNotDone(false);
  };

  useEffect(() => {
    if (props.isOpen) {
      resetFilters();
      setIsActiveTriggerToreload(true);
    }
  }, [props.isOpen]);

  useEffect(() => {
    issues.restart(true);
  }, [debounceTrigger]);

  const handleIssueArchiveChannelMessage = (ev: any) => {
    if (ev?.type === "reload") {
      issues.restart(true);
    }
  }

  useEffect(() => {
    issueArchiveChannel?.addEventListener("message", handleIssueArchiveChannelMessage);

    return () => {
      issueArchiveChannel?.removeEventListener("message", handleIssueArchiveChannelMessage);
    };
  }, [issueArchiveChannel]);

  useEffect(() => {
    if (isActiveTriggerToreload) {
      issues.restart(true);
      setIsActiveTriggerToreload(false);
    }
  }, [isActiveTriggerToreload]);

  return (
    <IssuesArchiveView
      name={issueName}
      senderId={senderId}
      executorId={executorId}
      onChangeSenderId={handleChangeSenderId}
      orgchartId={orgchartId}
      onChangeOrgchartId={handleChangeOrgchartId}
      onChangeName={handleChangeIssueName}
      onChangeExecutorId={handleChangeExecutorId}
      isStatusNotDone={isStatusNotDone}
      onChangeStatusNotDone={handleChangeStatusNotDone}
      sort={sort}
      onChangeSort={handleChangeSort}
      issues={issues.items}
      isDone={issues.info.isDone}
      loadNext={issues.loadNext}
      {...props}
    />
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import { RoleDto } from "../../../../functional/api";
import { Button, Dialog, DialogActions } from "../../../uiKit";

export interface IOrgchartFunctionDeleteDialogView {
  allRoles: RoleDto[];
  roleId: number;

  onDelete(): void;

  onClose(): void;
}

function OrgchartDeleteDialogView(props: IOrgchartFunctionDeleteDialogView) {
  const { t } = useTranslation();

  return (
    <Dialog title={t("ui:title.function_delete")} open={true} onClose={() => props.onClose()} closable={false}>
      {t("text:function.delete")}
      <DialogActions>
        <Button onClick={() => props.onClose()} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button theme="error" onClick={() => props.onDelete()} variant="filled">
          {t("ui:button.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OrgchartDeleteDialogView;

import React, { useState } from "react";
import AvatarUserSettingsView from "./AvatarUserSettingsView";
import { IAvatarUserSettings } from "../../../../../functional/types";
import { useAvatarUserSettings } from "../../../../../functional/hooks";

function AvatarUserSettings(props: IAvatarUserSettings) {
  const {
    isUploadDialogOpen,
    handleUploadDialogOpen,
    handleUploadDialogClose,
    handleUploadDialogSuccess,
    handleDeleteClick,
  } = useAvatarUserSettings(props);

  return (
    <AvatarUserSettingsView
      {...props}
      isUploadDialogOpen={isUploadDialogOpen}
      handleUploadDialogOpen={handleUploadDialogOpen}
      handleUploadDialogClose={handleUploadDialogClose}
      handleUploadDialogSuccess={handleUploadDialogSuccess}
      handleDeleteClick={handleDeleteClick}
    />
  );
}

export default AvatarUserSettings;

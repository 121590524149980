import React, { useState } from "react";
import OrgchartCommonDrawerView from "./OrgchartCommonDrawerView";
import { AppTabType } from "../../../../../functional/utils/appLinksAndTabs";
import { useTranslation } from "react-i18next";
import OrgchartCommonDrawerTabRecurringTasks from "../orgchartCommonDrawerTabs/orgchartCommonDrawerTabRecurringTasks/OrgchartCommonDrawerTabRecurringTasks";

export interface IOrgchartCommonDrawer {
  // allRoles: RoleDto[];
  open: boolean;

  onClose(): void;
}

const OrgchartCommonDrawerTabRecurringTasksFunc = (props: any) => {
  return <OrgchartCommonDrawerTabRecurringTasks {...props} />;
};

function OrgchartCommonDrawer(props: IOrgchartCommonDrawer) {
  const { t } = useTranslation();
  const [currentTabId, setCurrentTabId] = useState(0);

  const drawerTabs: AppTabType[] = [
    {
      id: 0,
      name: t("common:tab.orgchart.recurring_tasks"),
      element: OrgchartCommonDrawerTabRecurringTasksFunc,
      // hidden: !(window.location.hostname == "localhost" || window.location.hostname == "beta.tonnus.io"),
    },
    // { id: 2, name: t("common:tab.orgchart.permissions"), element: OrgchartDrawerTabPermissionsFunc },
  ];

  const onTabChange = async (id: number) => {
    setCurrentTabId(id);
    // await functionData.fetch()
  };

  return (
    <OrgchartCommonDrawerView {...props} tabs={drawerTabs} onTabChange={onTabChange} currentTabId={currentTabId} />
  );
}

export default OrgchartCommonDrawer;

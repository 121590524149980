import React from "react";
import { useDateHelpers, useRootStore } from "../../../../../../functional/hooks";
import { ICardRegulationView } from "./CardRegulation.interface";
import clsx from "clsx";
import { Card, Text } from "../../../../../uiKit";
import { useTranslation } from "react-i18next";
import { UserInfo } from "../../../../../elements/userInfo/UserInfo";
import { RegulationIndicator } from "../RegulationIndicator/RegulationIndicator";

export const CardRegulationView = (props: ICardRegulationView) => {
  const { t } = useTranslation();
  const { formatDate } = useDateHelpers();
  const { authStore } = useRootStore();
  const dateTime = formatDate(new Date(props.regulation?.dateCreated ?? '').toString(), {
    formatObject: {
      hour: undefined,
      minute: undefined,
    },
  });

  const titleStyles = clsx("regulation-card-finished__title", {
    "regulation-card-finished__title-fullwidth": props.nameAndDate || !props.regulation?.createdByUser || "",
  });

  const cardStyles = clsx(
    "regulation-card regulation-card-finished",
    { "regulation-card-finished__round": true },
    { "regulation-card-finished__secondary": props.isSecondary }
  );

  return (
    <Card
      clickable
      onClick={props.onClick}
      className={`full-width ${props.isRound ? "" : "br-none"}`}
      variant={props.isRound ? "secondary" : "default"}
      isShadowed={false}
      size={props.isRound ? "small" : "default"}
    >
      <div className="d-stack spacing-3 align-center">
        <Text className="flex-grow-1" weight={500} children={props.regulation.name ?? props.regulation.regulationName} />
        {props.regulation.createdByUser && !props.nameAndDate && (
          <div className="flex-shrink-0" style={{ flexBasis: "20%", overflow: "hidden" }}>
            <UserInfo nameStyle={{ fontWeight: "500" }} avatarSize={25} user={props.regulation.createdByUser} />
          </div>
        )}
        <div className="d-stack spacing-1 justify-center flex-wrap flex-shrink-0" style={{ flexBasis: "30%" }}>
          {props.regulation?.activeContent && (
            <RegulationIndicator name={t("parse:published")} colorSchemeKey="success" />
          )}
          {props.regulation?.draftContent &&
            authStore.getInitialInfo?.identity?.id === props.regulation?.createdByUserId && (
              <RegulationIndicator name={t("parse:at_work")} colorSchemeKey="warning" />
            )}
          {props.toLearnIndicator && <RegulationIndicator name={t("parse:to_learn")} colorSchemeKey="error" />}
        </div>
        <Text className="flex-shrink-0" type="secondary" weight={500} style={{ width: "150px" }} children={dateTime} />
      </div>
    </Card>
  );
};

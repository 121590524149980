import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import "./UserSelectionDialog.scss";
import { IUserSelectionDialog, positionsKeys, PositionsType } from "./UserSelectionDialog.interface";
import { UserSelectionDialogView } from "./UserSelectionDialogView";
import { RoleDto, RolePositionDto } from "../../../../functional/api";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import { useRootStore, useDebounce } from "../../../../functional/hooks";
import {
  IFunctionTreeItem
} from "../regulationAccessControlDialog/regulationFunctionSelectionDialog/functionTreeItem/FunctionTreeItem.interface";
import { RoleSelectorDto } from "../../../../functional/api/models/RoleSelectorDto";
import { SettingUpAccessDialogContext } from "../settingUpAccessDialog/SettingUpAccessDialogContext";
import { RegulationTreeNode } from "../../../../functional/api/models/RegulationTreeDto";
import { IUpdateTreeItemData } from "../../pages/regulation/hooks/IGeneratorTreeData.interface";

export const UserSelectionDialog = (props: IUserSelectionDialog) => {
  const rolesRef = useRef<RoleDto[]>([]);
  const { orgchartStore } = useRootStore();

  const {
    isLoading,
    fetchOrgchartData,
    treeData,
    expandedKeys,
    setExpandedKeys,
    selectedRoles,
    setSelectedRoles,
    updateTreeItemData,
    updateRolePosition,
  } = useContext(SettingUpAccessDialogContext);


  const [searchRoleValue, setSearchRoleValue] = useState<string>("");
  const [currentPositions, setCurrentPositions] = useState<RolePositionDto[] | undefined>(undefined);
  const [rolePositionId, setRolePositionId] = useState<PositionsType>(null);
  const [orgchartId, setOrgchartId] = useState<number | null>(null);


  const debounce = useDebounce(searchRoleValue, 400);

  const findBranches = (tree: any, targetValue: string) => {
    const branches: RegulationTreeNode[] = [];

    const findElementsByName = (array: RegulationTreeNode[], search: string) => {
      array.forEach((node) => {
        if (node.title.toLowerCase().includes(search.toLowerCase())) {
          branches.push({...node, isLeaf: true, children: []});
        }
        if ((node?.children ?? []).length > 0) {
          findElementsByName((node?.children ?? []), search);
        }
      });
    };

    findElementsByName(tree, targetValue);
    return branches;
  }

  const treeDataRoles = useMemo(() => {
    return debounce ? findBranches(treeData, debounce) : treeData;
  }, [debounce, treeData]);

  const onUpdateTreeItemData = useCallback((
    {
      list,
      key,
      isSelected,
      includeNestedRoles,
      isUpdateClaimType,
    }: IUpdateTreeItemData) => {
    return updateTreeItemData({
      list: treeData,
      key,
      isSelected,
      includeNestedRoles,
      isUpdateClaimType,
    });
  }, [treeData]);


  const handleChangeSearchRoleValue = (newValue: string) => setSearchRoleValue(newValue);


  const handleChangeRolePositionId = (event: RadioChangeEvent) => {
    if (Number(event.target.value) === 0) return setRolePositionId(null);
    if (typeof (event.target as HTMLInputElement)?.value === "string")
      return setRolePositionId(event.target.value as PositionsType);
    setRolePositionId(Number((event.target as HTMLInputElement).value) as PositionsType);
  };

  const [activeRole, setActiveRole] = useState<RoleSelectorDto | null>(null);

  const onUpdateRolePosition = useCallback((
    list: RegulationTreeNode[],
    key: React.Key,
    selectedPositions: number[],
    positionType?: positionsKeys | null
  ) => {
    setActiveRole((prev) => {
      return prev ? ({
        ...prev,
        selectedPositions,
        positionType
      }) : null
    });
    return updateRolePosition(list, key, selectedPositions, positionType);
  }, []);

  const handleSelectActiveRole = useCallback((role: RoleSelectorDto | null) => {
    setActiveRole(role);
  }, []);

  const handleChangeOrgchartId = (id: string | number) => setOrgchartId(isNaN(+id) ? null : +id);

  const handleSave = () => {
    props.onAddRoles && props.onAddRoles(selectedRoles);
    props.onClose();
  };

  useEffect(() => {
    if (!props.open) {
      setTimeout(() => {
        setCurrentPositions(undefined);
        // setActiveRoleId(null);
        setSearchRoleValue("");
        setRolePositionId(null);
        rolesRef.current = [];
      }, 150);
    } else {
      const selectedRoles: IFunctionTreeItem[] = (props.selectedRolesList ?? []).map((role) => {
        const {
          roleId: id,
          roleName: title,
          includeNestedRoles,
          orgchartId,
          positions = []
        } = role;
        return {
          id,
          title,
          includeNestedRoles,
          orgchartId,
          selectedPositions: positions.map((position) => position.id!),
          positions,
        }
      });
      setSelectedRoles(selectedRoles);
    }
  }, [props.open]);


  useEffect(() => {
    orgchartStore.getCurrentOrgchartId && setOrgchartId(orgchartStore.getCurrentOrgchartId);
  }, [orgchartStore.getCurrentOrgchartId]);

  useEffect(() => {
    setActiveRole(null);
  }, [orgchartId]);


  useEffect(() => {
    if (orgchartId) {
      const id = props.itemId
      fetchOrgchartData(orgchartId, !!props.isRegular, props.itemId);
    }
  }, [orgchartId]);

  const onExpand = useCallback((expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue);
  }, []);


  return (
    <UserSelectionDialogView
      // rolesIsDone={roles.info.isDone}
      orgchartId={orgchartId}
      onChangeOrgchartId={handleChangeOrgchartId}
      searchRoleValue={searchRoleValue}
      onChangeSearchRoleValue={handleChangeSearchRoleValue}
      // roles={roles.items}
      // rolesAutocomplete={rolesAutocomplete}
      // loadNextRoles={roles.loadNext}
      orgchartsForBlockAllAll={props.orgchartsForBlockAllAll}
      disabledRules={props.disabledRules}
      open={props.open}
      onClose={props.onClose}
      onSelectActiveRole={handleSelectActiveRole}
      onChangeRolePositionId={handleChangeRolePositionId}
      rolePositionId={rolePositionId}
      activeRole={activeRole}
      currentPositions={currentPositions}
      onSave={handleSave}
      isLoading={isLoading}
      treeData={treeDataRoles}
      // onLoadData={handleLoadData}
      expandedKeys={expandedKeys}
      onExpand={onExpand}
      selectedRoles={selectedRoles}
      setSelectedRoles={setSelectedRoles}
      updateTreeItemData={onUpdateTreeItemData}
      updateRolePosition={onUpdateRolePosition}
    />
  );
};
// notifier.show({ message: t("notifier:error.role_no_select"), theme: "error" });

import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MetricReadDto } from "../../../../../../../functional/api";
import DeleteMetricOrDashboardDialog from "../../../../../dialogs/deleteMetricOrDashboardDialog/DeleteMetricOrDashboardDialog";
import { Card, Dropdown, Empty, Icon, IconButton, Text, Tooltip } from "../../../../../../uiKit";
import { FiMoreVertical } from "@react-icons/all-files/fi/FiMoreVertical";
import { dashboardSectionAndRoutesKeys, dashboardSections } from "../../../misc/constants/routesKeys";
import { getDashboardSection } from "../../../misc/helpers/urlFunctions";

interface IEmptyMetric {
  metric: MetricReadDto;
  deleteMetric: (id: number) => void;
}

export const EmptyMetric = (props: IEmptyMetric) => {
  const { uri } = useParams();
  const { t } = useTranslation();
  const [dropdownKey, setDropdownKey] = useState<string>("");
  const [openDeleteMetricDialog, setOpenDeleteMetricDialog] = useState<boolean>(false);

  const handleGetDropdownItems = useCallback(() => {
    return [
      // { key: "settings", text: t("common:menu.metric_settings_dropdown.settings") },
      { key: "delete", text: t("common:menu.metric_settings_dropdown.delete") },
    ];
  }, [t]);

  const handleDropdownKeyChange = useCallback((key: string) => {
    setDropdownKey(key);
  }, []);

  const handleOpenDeleteMetricDialogChange = useCallback((isOpen: boolean) => {
    if (!isOpen) setDropdownKey("");
    setOpenDeleteMetricDialog(isOpen);
  }, []);

  useEffect(() => {
    if (dropdownKey == "delete") {
      setOpenDeleteMetricDialog(true);
    }
  }, [dropdownKey]);

  return (
    <>
      <DeleteMetricOrDashboardDialog
        open={openDeleteMetricDialog}
        onOpen={handleOpenDeleteMetricDialogChange}
        onDelete={() => {
          handleOpenDeleteMetricDialogChange(false);
          props.deleteMetric(props.metric?.id!);
        }}
        title={`${t("ui:title.delete_metric_dialog_title")} "${props.metric?.name}"`}
        description={t("ui:text.delete_metric_dialog_warning")}
      />
      <Card className="metric-to-dashboard" bodyStyle={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div className="metric-to-dashboard__container__header d-flex flex-nowrap align-center flex-grow-0">
          <Tooltip title={props.metric.nameFormatted}>
            <Text className="metric-to-dashboard__container__title">{props.metric.nameFormatted}</Text>
          </Tooltip>
          {dashboardSections.includes(getDashboardSection(uri) as dashboardSectionAndRoutesKeys) ? null : (
            <Dropdown
              items={[
                ...handleGetDropdownItems().map((i) => ({
                  key: i.key,
                  text: i.text,
                  onClick: () => handleDropdownKeyChange(i.key),
                })),
              ]}
              trigger={["click"]}
              overlayClassName="orgchart_switcher-overlay"
            >
              <IconButton
                size="small"
                className="pa-0 ml-auto"
                shape="circle"
                type="link"
                icon={<Icon component={() => <FiMoreVertical />} />}
              />
            </Dropdown>
          )}
        </div>
        <div className="metric-to-dashboard__container__content flex-grow-1 mt-1">
          <Empty description={t("ui:text.empty_metric_users")} />
        </div>
      </Card>
    </>
  );
};

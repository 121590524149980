import React, { useCallback, useEffect, useState } from "react";
import { MetricToDashboardDialogView } from "./MetricToDashboardDialogView";
import { IMetricToDashboardDialog } from "./MetricToDashboardDialog.interface";
import { useApi } from "../../../../functional/hooks";
import { api } from "../../../../functional/services";

export type MetricDataType = {
  id: number | null;
  text: string | null;
};

export const MetricToDashboardDialog = (props: IMetricToDashboardDialog) => {
  const [selectedMetric, setSelectedMetric] = useState<MetricDataType>({
    id: null,
    text: null,
  });
  const [selectedMetricSource2User, setSelectedMetricSource2User] = useState<MetricDataType>({
    id: null,
    text: null,
  });

  const metric = useApi(
    () => api.metric.getById(selectedMetric.id!),
    null,
    () => {},
    {},
    false
  );

  const toggleCloseDialog = useCallback(() => {
    setSelectedMetric({ id: null, text: null });
    setSelectedMetricSource2User({ id: null, text: null });
    props.toggleDialog(false);
  }, [props]);

  const handleSelectedMetricChange = useCallback((metric: MetricDataType) => {
    setSelectedMetric(metric);
  }, []);

  const handleSelectedMetricSource2UserChange = useCallback((s2u: MetricDataType) => {
    setSelectedMetricSource2User(s2u);
  }, []);

  const name = selectedMetricSource2User.text?.split(" - ");

  const handleAddMetricToDashboard = useCallback(() => {
    props.onSuccess({
      ...props.dashboard,
      metrics: [
        ...(props.dashboard?.metrics ?? []),
        {
          metricId: selectedMetric.id!,
          metricSource2UserId: selectedMetricSource2User.id,
          metric: { name: selectedMetric.text },
          metricSource2User: { user2Role: { user: { name: name?.[2] } } },
        },
      ],
    });
  }, [name, props, selectedMetric.id, selectedMetric.text, selectedMetricSource2User.id]);

  useEffect(() => {
    metric.fetch();
  }, [selectedMetric.id]);

  return (
    <MetricToDashboardDialogView
      open={props.open}
      onClose={toggleCloseDialog}
      addMetricsToDashboard={handleAddMetricToDashboard}
      selectedMetric={selectedMetric}
      onSelectedMetricChange={handleSelectedMetricChange}
      onSelectedMetricSource2UserChange={handleSelectedMetricSource2UserChange}
      dashboardId={props.dashboard?.id}
      metric={metric.value ?? undefined}
      dashboard={props.dashboard}
    />
  );
};

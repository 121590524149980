import React from "react";
import "./Card.scss";
import { Card, CardProps, theme } from "antd";
import clsx from "clsx";
import { TCardScrollStrategy, TCardVariant } from "./Card";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../../functional/hooks";

interface ICardView extends CardProps {
  className?: string;
  bodyClassName?: string;
  clickable?: boolean;
  variant?: TCardVariant;
  scrollStrategy?: TCardScrollStrategy;
  isShadowed?: boolean;
  isLoading?: boolean;
  isNoPadding?: boolean;
}

function CardViewObserved({
  className,
  clickable,
  bodyClassName,
  variant,
  scrollStrategy,
  isShadowed,
  isLoading,
  style,
  isNoPadding,
  ...rest
}: ICardView) {
  const { appStore } = useRootStore();
  const { useToken } = theme;
  const { token } = useToken();

  const bCol = appStore.getAppTheme == 1 ? token.colorFillAlter : token.colorBorderSecondary;
  // colorFillAlter colorBorderSecondary colorBgElevated

  return (
    <Card
      className={clsx("uiKit-card", className ?? "", {
        __clickable: clickable,
        __secondary: variant == "secondary",
        __transparent: variant == "transparent",
        "__ss-card-body": scrollStrategy == "cardBody",
        "__no-shadow": (isShadowed != null && !isShadowed) || variant == "transparent",
        "__no-padding": isNoPadding,
      })}
      bordered={false}
      size="small"
      style={{
        ...style,
        // colorBgContainerDisabled, colorSplit
        backgroundColor:
          variant == "secondary" ? "var(--color-srf-secondary )": variant == "transparent" ? "transparent" : style?.backgroundColor,
      }}
      {...rest}
    />
  );
}

export const CardView = observer(CardViewObserved);

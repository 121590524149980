import React from "react";
import { FailedCompletion } from "./FailedCompletion";
import { SuccessfulCompletion } from "./SuccessfulCompletion";
import { useTranslation } from "react-i18next";
import { IPassingRegulationTestView } from "./PassingRegulationTest.interface";
import { Question } from "./Question";
import clsx from "clsx";
import { Button, Dialog, DialogActions, Icon } from "../../../uiKit";
import { useLocation, useNavigate } from "react-router-dom";
import { findId } from "../../../../functional/helpers/pathname";
import { regulationContentTypeKeys } from "../../../../functional/constants/regulation/headerTools";
import { FiArrowRight } from "@react-icons/all-files/fi/FiArrowRight";
import { onValidateAnswered } from "../../../../functional/helpers/passingRegulationTestDialog/helpers";

export const PassingRegulationTestView: React.FC<IPassingRegulationTestView> = (props) => {
  const { t } = useTranslation();

  const location: any = useLocation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const fromSectionId: number | undefined = location.state?.fromSectionId;
  const toLearnRegulationsIds: number[] | undefined = location.state?.noLearntRegulationsIds;
  const allRegulationsIds: number[] | undefined = location.state?.allRegulationsIds;

  const id = findId(pathname, 2);

  const handleGetNextRegulationId = (): number => {
    const nextId = allRegulationsIds!.shift()!;
    return nextId === id ? allRegulationsIds![0] ?? nextId : nextId;
  };

  const handleGerPermitRedirectToNextRegulation = (): boolean => {
    const nextRegulationId = allRegulationsIds && allRegulationsIds[0];
    if (nextRegulationId === id || !allRegulationsIds || !allRegulationsIds.length) return false;
    return !!(toLearnRegulationsIds?.length && props.isSuccessCompletion);
  };

  const handleRedirectToNextRegulation = () => {
    props.onSimplerolleUp();
    const redirectedId = handleGetNextRegulationId();
    // navigate(`/policy/all/${redirectedId}/?contentType=${regulationContentTypeKeys.approved}`, {
    navigate(`/policy/all/${redirectedId}/`, {
      state: {
        fromSectionId: fromSectionId,
        noLearntRegulationsIds: toLearnRegulationsIds?.filter((item) => item !== redirectedId),
        allRegulationsIds: allRegulationsIds,
        fromRegulationId: id,
      },
    });
  };

  const handleRedirectToTopic = () => {
    navigate(`/policy/topics/${fromSectionId}`);
  };

  const dialogStyles = clsx("passing-regulation-test__dialog-wrapper", {
    "passing-regulation-test__dialog-wrapper-fullHeight": !props.isFailedCompletion && !props.isSuccessCompletion,
  });

  const allAnswersIsMarked = onValidateAnswered(props.questions);

  return (
    <>
      <Dialog title={t("ui:title.testing")} open={props.open} bodyStyle={{ overflow: "auto" }} onClose={props.onClose}>
        <div className={dialogStyles + "pb-0 pl-3 pr-3"} style={{ maxHeight: "600px" }}>
          <>
            {props.isSuccessCompletion && <SuccessfulCompletion onCloseWitchReload={props.onCloseWitchReload} />}
            {props.isFailedCompletion && <FailedCompletion />}
            {!props.isFailedCompletion && !props.isSuccessCompletion && (
              <ol className="questions__digital-list">
                {props.questions.map((item) => {
                  const result = props.questionsAfterSend.find((q) => q.id === item.id);
                  return (
                    <li key={item.id}>
                      <Question
                        question={item}
                        questionAfterSend={result}
                        onSelectAnswer={props.onSelectAnswer}
                        onChangeAnswer={props.onChangeAnswer}
                        answerKey={item.answerKey}
                        key={item.id}
                      />
                    </li>
                  );
                })}
              </ol>
            )}
          </>
        </div>
        <DialogActions>
          <Button onClick={props.onClose} variant="text">
            {t("ui:button.back_to_regulation")}
          </Button>
          {fromSectionId && props.isSuccessCompletion && (
            <Button className="stats-icon mr-1" onClick={handleRedirectToTopic} variant="default">
              {t("ui:button.back_to_topic")}
            </Button>
          )}
          {handleGerPermitRedirectToNextRegulation() && (
            <Button
              // TODO: Check this className
              className="button-icon_17 mr-1"
              icon={<Icon component={() => <FiArrowRight />} />}
              onClick={handleRedirectToNextRegulation}
              variant="filled"
            >
              {t("ui:button.next_regulation")}
            </Button>
          )}
          {props.isFailedCompletion && (
            // <Button style={{ marginRight: "8px" }} onClick={props.onTakeInitialState} variant="filled">
            <Button style={{ marginRight: "8px" }} onClick={props.onTryAgain} variant="filled">
              {t("ui:button.try_again")}
            </Button>
          )}
          {!props.isSuccessCompletion && !props.isFailedCompletion && (
            <Button
              disabled={!allAnswersIsMarked}
              onClick={props.onCompleteTest}
              loading={props.isLoading}
              variant="filled"
            >
              {t("ui:button.save_results")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import { useLanguageSwitcher, useRootStore } from "../../../../../functional/hooks";
import { observer } from "mobx-react-lite";
import { Select, SelectOption } from "../../../../uiKit";
import { IssueTypeKeys } from "../../../../../functional/constants/issueTypeKeys";
import { api } from "../../../../../functional/services";
import { IssueCustomFieldDto } from "../../../../../functional/api";
import { ISelect } from "../../../../uiKit/select/Select";
import { ILanguageSwitcher } from "../../../../../functional/types";
import { APP_LANGUAGES_ARRAY } from "../../../../../functional/constants/appLanguagesArray";


function LanguageSwitcher(props: ILanguageSwitcher) {
  const { t } = useTranslation();
  const {
    currentLanguageId,
    handleLanguageChange,
  } = useLanguageSwitcher();

  return (
    <>
      {!props.miniVariant && <SubheaderText text={t("ui:subheader.app_locale")} />}
      <Select
        size={props.miniVariant ? "middle" : "large"}
        style={{ minWidth: props.miniVariant ? "150px" : undefined }}
        value={currentLanguageId}
        fullWidth
        onChange={handleLanguageChange}
        {...props.selectProps}
      >
        {APP_LANGUAGES_ARRAY.map((lng) => (
          <SelectOption key={lng.id} value={lng.id}>
            {lng.name}
          </SelectOption>
        ))}
      </Select>
    </>
  );
}

export default observer(LanguageSwitcher);

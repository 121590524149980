import React, { useContext, useEffect, useRef, useState } from "react";
import { OrgchartFunctionDrawerView } from "./OrgchartFunctionDrawerView";
import { RoleDto } from "../../../../../functional/api";
import { useNotifier, useRootStore } from "../../../../../functional/hooks";
import { OrgchartContext } from "../../../../../functional/contexts/orgchartContext";
import { sectionKeys } from "../../../../../functional/constants/sectionKeys";
import { api } from "../../../../../functional/services";
import { useTranslation } from "react-i18next";
import * as jsonpatch from "fast-json-patch";
import { guideKeys } from "../../../../../functional/types";

export interface IOrgchartFunctionDrawer {
  isOpen: boolean;
  roleId: number;
  roles: RoleDto[];

  onClose: () => void;
}

export type TOrgchartDrawerTabKey = "recurringTasks" | "employees" | "permissions" | "metrics" | "regulations";

export interface IOrgchartDrawerTab {
  tabKey: TOrgchartDrawerTabKey;
  translationKey: string;
  correspondingMenuKey: sectionKeys | null;
  guide?: {
    guideKey: guideKeys;
    anchorId?: string;
  };
}

const orgchartDrawerTabs: IOrgchartDrawerTab[] = [
  {
    tabKey: "recurringTasks",
    translationKey: "common:tab.orgchart.recurring_tasks",
    correspondingMenuKey: sectionKeys.communications,
    guide: {
      guideKey: guideKeys.recurringIssues,
    },
  },
  {
    tabKey: "employees",
    translationKey: "common:tab.orgchart.employee",
    correspondingMenuKey: null,
    guide: {
      guideKey: guideKeys.inviteEmployee,
    },
  },
  {
    tabKey: "permissions",
    translationKey: "common:tab.orgchart.permissions",
    correspondingMenuKey: null,
    guide: {
      guideKey: guideKeys.permissions,
    },
  },
  {
    tabKey: "metrics",
    translationKey: "common:tab.orgchart.metrics",
    correspondingMenuKey: sectionKeys.dashboards,
    guide: {
      guideKey: guideKeys.dashboard,
      anchorId: "1anchor",
    },
  },
  {
    tabKey: "regulations",
    translationKey: "common:tab.orgchart.regulations",
    correspondingMenuKey: sectionKeys.policies,
  },
];

export function OrgchartFunctionDrawer(props: IOrgchartFunctionDrawer) {
  const { t } = useTranslation();
  const notifier = useNotifier();
  const { authStore, orgchartStore } = useRootStore();
  const orgchartContext = useContext(OrgchartContext);
  const [currentTabKey, setCurrentTabKey] = useState<TOrgchartDrawerTabKey | null>(null);
  const [currentRole, setCurrentRole] = useState<RoleDto | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // const reccuringTask = useApi(() =>
  //   api.recurringIssue.getAll({
  //     roleId: (props.roleId as number) ?? undefined,
  //     orgchartId: orgchartStore.getCurrentOrgchartId,
  //   })
  // );

  const getFilteredDrawerTabs = (drawerTabs: IOrgchartDrawerTab[]): IOrgchartDrawerTab[] => {
    const checkAllow = (allowKey: sectionKeys): boolean =>
      !!authStore.getInitialInfo?.menuItems?.find((item) => item.key === allowKey);
    const filteredTabs = drawerTabs?.filter(
      (item) => !item.correspondingMenuKey || checkAllow(item.correspondingMenuKey)
    );
    if (!filteredTabs.some((fT) => fT.tabKey == currentTabKey)) {
      setCurrentTabKey(filteredTabs?.[0]?.tabKey);
    }
    return filteredTabs;
  };

  const axiosController = useRef<AbortController>(new AbortController());

  const role = useRef(props.roleId);
  useEffect(() => {
    role.current = props.roleId
  }, [props.roleId]);

  const handleDrawerOpen = async () => {
    if (props.roleId == null) return;
    const r = await api.role.getById(
      props.roleId,
      { includeClaims: true },
      // axiosController.current?.signal,
    );

    if (r == null) {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error", timeout: 7 });
      return;
    }
    if (role.current === props.roleId) {
      setCurrentRole(r);
    }
  };

  const handleDrawerClose = () => {
    setCurrentRole(undefined);
    axiosController.current?.abort();
    setTimeout(() => {
      setCurrentTabKey(null);
      setCurrentRole(undefined);
    }, 250);
  };

  const handleRoleChange = async (updatedRole: RoleDto, withoutApiCall: boolean = false) => {
    if (withoutApiCall) return void setCurrentRole((prevState) => ({ ...prevState, ...updatedRole }));
    if (jsonpatch.compare({ ...currentRole, ...updatedRole }, { ...currentRole }).length == 0) return;
    const r = await api.role.editPartially(props.roleId, { ...currentRole, ...updatedRole }, { ...currentRole });
    if (r == null) {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error", timeout: 7 });
      return;
    }
    setCurrentRole(r);
    orgchartContext?.refreshState(true);
  };

  const handleCurrentTabChange = (newTabKey: TOrgchartDrawerTabKey) => {
    setCurrentTabKey(newTabKey);
  };

  useEffect(() => {
    if (props.isOpen) {
      handleDrawerOpen();
    } else {
      handleDrawerClose();
    }
  }, [props.isOpen]);

  return (
    <OrgchartFunctionDrawerView
      roles={props.roles}
      isOpen={props.isOpen}
      roleId={props.roleId}
      currentTabKey={currentTabKey ?? getFilteredDrawerTabs(orgchartDrawerTabs)[0]?.tabKey}
      currentRole={currentRole}
      isLoading={isLoading}
      drawerTabs={getFilteredDrawerTabs(orgchartDrawerTabs)}
      onCurrentTabChange={handleCurrentTabChange}
      onRoleChange={handleRoleChange}
      onClose={props.onClose}
      // updater={updater}
      // functionData={functionData.value as RoleDto}
      // refreshData={functionData.fetch}
      // setFunctionData={functionData.set}
    />
  );
}

import React, { useEffect, memo } from "react";
import { Indicator } from "../../../../../elements/indicator";
import { IndicatorIconsTypes } from "../../../../../elements/indicator/Indicator.interface";
import { colorSchemeType } from "../../../../../../functional/utils/colorScheme";
import { Icon, IconButton } from "../../../../../uiKit";
import { FiX } from "@react-icons/all-files/fi/FiX";
import { IssueOptions } from "../issueOptions/IssueOptions";
import { IssueSwitchStatus } from "../../../../../elements/issueSwitchStatus/IssueSwitchStatus";
import { BoardStatusActionDto, IssueDto, IssueIndicatorDto } from "../../../../../../functional/api";
import { IssueActionConsts } from "../../../types/consts";
import { useTranslation } from "react-i18next";

export interface IIssueHeader {
  subId?: string | null;
  status?: string | null;
  title: string;
  indicators?: IssueIndicatorDto[] | null;
  actions?: BoardStatusActionDto[] | null;
  onClose: () => void;
  onOpenCopyIssueDialog: () => void;
  onOpenSendForArchiveDialog: () => void;
  onHandleUnArchive: () => void;
  onOpenWithdrawIssueDialog: () => void;
  onToggleDeleteConfirmDialog: (open: boolean) => void;
  onCreateIssueHistoryByActionKey: (actionKey: IssueActionConsts) => void;
  updater: {
    update: () => Promise<void>;
    updatePartially: () => Promise<void>;
    setInitialState: (initialState: IssueDto) => void;
    applyChanges: (changes: any) => void;
    currentState: IssueDto | null;
    beforeState: IssueDto | null;
  };
}

export const IssueHeader = memo((props: IIssueHeader) => {
  const { t } = useTranslation();
  return (
    <div className="d-stack-row align-center justify-space-between mb-4">
      <div className="d-stack-row align-center flex-wrap">
        {props.title && <h3 className="ma-0 mr-3">{props.title}</h3>}
        {props.subId && (
          <p className="ma-0 mr-6" style={{ color: "var(--color-txt-secondary)" }}>
            #{props.subId}
          </p>
        )}
        <IssueSwitchStatus
          issueData={props.updater.currentState}
          indicators={props.indicators}
          actions={props.actions}
          onCreateIssueHistoryByActionKey={props.onCreateIssueHistoryByActionKey}
        />
        <div className="d-stack-row align-center justify-start spacing-2 ml-2">
          {props.indicators?.map((indicator) => (
            <React.Fragment key={indicator.id}>
              <Indicator
                isFaint
                isMiddleSize
                name={t(`ui:issue_status.${indicator.key!}`)}
                key={indicator.id!}
                // TODO: Add <Icon component={() => icon} /> component
                icon={indicator.icon as IndicatorIconsTypes}
                colorSchemeKey={indicator.colorSchemeKey as colorSchemeType}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="d-stack-row align-center">
        <IssueOptions
          actions={props.actions}
          onOpenCopyIssueDialog={props.onOpenCopyIssueDialog}
          onOpenWithdrawIssueDialog={props.onOpenWithdrawIssueDialog}
          toggleDeleteConfirm={props.onToggleDeleteConfirmDialog}
          onOpenSendForArchiveDialog={props.onOpenSendForArchiveDialog}
          onHandleUnArchive={props.onHandleUnArchive}
        />
        <IconButton
          style={{ background: "none" }}
          size="large"
          onClick={props.onClose}
          icon={<Icon component={() => <FiX size={22} />} />}
        />
      </div>
    </div>
  );
});

import React, { useEffect, useRef, useState } from "react";
import TaskRecurringCardView from "./TaskRecurringCardView";
import { RecurringIssueDto, RoleDto } from "../../../../../functional/api";
import { api } from "../../../../../functional/services";
import { removeDuplicatesFromArrayByKey } from "../../../../../functional/helpers/arrayFunctions";

export interface ITaskRecurringCard {
  data: RecurringIssueDto;

  hideButtons?: boolean;
  onPauseClick: () => void;
  onWatchHistoryClick: () => void;
  onEditClick: (data: RecurringIssueDto) => void;
  onDeleteClick: () => void;
}

function TaskRecurringCard(props: ITaskRecurringCard) {
  // const allRoles = useApi(() => api.role.autocomplete({ pageSize: 1000 }));
  // const [allRoles, setAllRoles] = useState<RoleDto[]>([]);
  // const allRolesCopy = useRef<RoleDto[]>([]);
  // const setAllRolesSync = (v: RoleDto[]) => {
  //   allRolesCopy.current = v;
  //   setAllRoles(v);
  // };
  const [isPaused, setIsPaused] = useState(props.data.isPaused ?? false);
  const isPausedCopy = useRef(props.data.isPaused ?? false);
  const setIsPausedSync = (v: boolean) => {
    isPausedCopy.current = v;
    setIsPaused(v);
  };

  const handlePauseClick = async () => {
    const r = await api.recurringIssue.editPartially(
      props.data.id as number,
      {
        ...props.data,
        isPaused: !isPausedCopy.current,
      },
      {
        ...props.data,
        isPaused: isPausedCopy.current,
      }
    );
    if (r != null) {
      setIsPausedSync(!isPausedCopy.current ?? true);
    }
  };

  const handleEditClick = () => {
    props.onEditClick({ ...props.data, isPaused: isPausedCopy.current });
  };

  // const loadRolesData = async (roleIds: number[]) => {
  //   const r = await api.role.getAll({ ids: roleIds });
  //   if (r == null || r.items == null) return;
  //   console.log("removeDuplicatesFromArrayByKey([...allRolesCopy.current, ...r.items], \"id\")", removeDuplicatesFromArrayByKey([...allRolesCopy.current, ...r.items], "id"));
  //   setAllRolesSync(removeDuplicatesFromArrayByKey([...allRolesCopy.current, ...r.items], "id"));
  // };

  // useEffect(() => {
  //   initializeRolesData();
  // }, []);

  // const initializeRolesData = async () => {
  //   console.log("initializeRolesData props.data", props.data);
  //   let rolesIds = (props.data.executorConfigs?.map((c) => c.roleId) ?? []).filter((c) => c != null);
  //   rolesIds = [...rolesIds, props.data.senderRoleId];
  //   rolesIds = [...rolesIds.filter((x) => x != null)];
  //   rolesIds = [...new Set(rolesIds)];
  //   await loadRolesData(rolesIds as number[]);
  //   // for await (const id of rolesIds) {
  //   //   await loadRoleData(id as number);
  //   // }
  // };

  return (
    <TaskRecurringCardView
      data={{ ...props.data, isPaused: isPaused }}
      hideButtons={props.hideButtons}
      onPauseClick={handlePauseClick}
      onEditClick={handleEditClick}
      onWatchHistoryClick={props.onWatchHistoryClick}
      onDeleteClick={props.onDeleteClick}
      // allRoles={allRoles ?? []}
    />
  );
}

export default TaskRecurringCard;

import { useTranslation } from "react-i18next";
import { useRootStore } from "../../../../../functional/hooks";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import { Select } from "../../../../uiKit";
import React from "react";
import { observer } from "mobx-react-lite";

interface INotificationsVisibilitySwitcherProps {
  //
}

const NotificationsVisibilitySwitcherObserved = (props: INotificationsVisibilitySwitcherProps) => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();

  const stateList = [
    { id: 0, text: t("parse:np_do_not_show"), value: "off" },
    { id: 1, text: t("parse:np_compact_display"), value: "tooltipOnly" },
    { id: 2, text: t("parse:np_show"), value: "on" },
  ];

  return (
    <>
      <SubheaderText text={t("parse:np_notifications_center")} />
      <Select
        value={appStore.getNotificationsVisibilitySettingsState.notificationsCenter}
        onChange={(value) =>
          appStore.setNotificationsVisibilitySettingsState({
            ...appStore.getNotificationsVisibilitySettingsState,
            notificationsCenter: value,
          })
        }
        fullWidth
        items={stateList}
      />
    </>
  );
};

export const NotificationsVisibilitySwitcher = observer(NotificationsVisibilitySwitcherObserved);

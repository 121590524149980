import React, { useEffect } from "react";
import { OrgchartDrawerTabRegulationsView } from "./OrgchartDrawerTabRegulationsView";
import { usePagingWithController } from "../../../../../../functional/hooks";
import { RegulationDto } from "../../../../../../functional/api";
import { api } from "../../../../../../functional/services";
import { RegulationFilter } from "../../../../../../functional/api/filters/regulationFilter";

interface IOrgchartDrawerTabRegulationsProps {
  roleId: number;
}

export const OrgchartDrawerTabRegulations = (props: IOrgchartDrawerTabRegulationsProps) => {
  const regulation = usePagingWithController<RegulationDto, RegulationFilter>(
    api.regulation,
    {
      roleId: props.roleId,
      hasActiveContent: true,
    },
    { pageSize: 10 }
  );


  const section = usePagingWithController<RegulationDto, RegulationFilter>(
    // @ts-ignore
    api.section,
    {
      roleId: props.roleId,
    },
    { pageSize: 10 }
  );

  useEffect(() => {
    regulation.reset();
    regulation.restart();
    section.reset();
    section.restart();
  }, []);


  return (
    <OrgchartDrawerTabRegulationsView
      section={{
        // @ts-ignore
        items: section.items,
        info: section.info,
      }}
      regulation={{
        items: regulation.items,
        info: regulation.info,
      }}
      onLoadNextRegulation={regulation.loadNext}
      onLoadNextSection={section.loadNext}
    />
  );
};

import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import { useRootStore } from "../../../../../functional/hooks";
import { Select } from "../../../../uiKit";
import Visibility from "visibilityjs";

function AudioSettingsSwitcher() {
  const { t } = useTranslation();
  const { appStore } = useRootStore();

  const stateList = [
    { id: 0, text: t("parse:ns_off"), value: "off" },
    { id: 1, text: t("parse:ns_visible_only"), value: "inactiveOnly" },
    { id: 2, text: t("parse:ns_on"), value: "on" },
  ].filter((i) => (Visibility.isSupported() ? i : i.id != 1));

  return (
    <>
      <SubheaderText text={t("parse:notifications_sound")} />
      <Select
        disabled={appStore.getNotificationsVisibilitySettingsState.notificationsCenter == "off"}
        value={
          appStore.getNotificationsVisibilitySettingsState.notificationsCenter == "off"
            ? "off"
            : appStore.getAudioSettingsState.notifications
        }
        onChange={(value) =>
          appStore.setAudioSettingsState({ ...appStore.getAudioSettingsState, notifications: value })
        }
        fullWidth
        items={stateList}
      />
    </>
  );
}

export default observer(AudioSettingsSwitcher);

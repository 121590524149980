import React, { useContext, useEffect, useMemo, useState, memo, useCallback } from "react";
import OrgchartFunctionCardHubbardView from "./OrgchartFunctionCardHubbardView";
import { MenuItemBaseDto, OrgchartItemDto, RoleDto } from "../../../../../functional/api";
import { useTranslation } from "react-i18next";
import { OrgchartContext } from "../../../../../functional/contexts/orgchartContext";
import { api } from "../../../../../functional/services";
import { useNotifier } from "../../../../../functional/hooks";
import { OrgchartTemplateTypeEnum } from "../../../../../functional/api/models/OrgchartTemplateTypeDto";

export type TOrgchartFunctionCardHubbardMenuItem = {
  id: number;
  title: string;
  action(event: any, id: number): void;
  icon: React.ReactNode | null;
  hidden?: boolean;
  disabled: boolean;
};

export interface IOrgchartFunctionCardHubbard {
  roles: RoleDto[];
  zoom: number;
  blockData: OrgchartItemDto | null;
  blockRole: RoleDto | null;
  params: IOrgchartFunctionCardHubbardParams;
  orgchartDrawType: OrgchartTemplateTypeEnum;
  visibilityFilters?: {
    result: boolean;
    employee: boolean;
    description: boolean;
  };
  isHiddenPrintBlock?: boolean;
  printBlockId?: number | null;
  handleOrgchartBlockPrint?(id: number): void;
}

export interface IOrgchartFunctionCardHubbardParams {
  isRootRole: boolean;
  isVertical: boolean;
  isRootRoleChild: boolean;
  isLastChild: boolean;
  hasParent: boolean;
  blockId: number | null | undefined;
  parentParams?: IOrgchartFunctionCardHubbardParams;
}

const OrgchartFunctionCardHubbard = memo((props: IOrgchartFunctionCardHubbard) => {
  const notifier = useNotifier();
  const { t } = useTranslation();
  const orgchartContext = useContext(OrgchartContext);
  const getRoleDataById = (roleId: number): RoleDto | null => props.roles?.find((r) => r.id == roleId) ?? null;
  // Add event listener

  const hasBlockWideChildren = (roleId: number): boolean => {
    const role = getRoleDataById(roleId);
    const roleChildren = props.roles.filter((r) => r.parentId == roleId);
    if (role?.childrenAreHorizontal && roleChildren.length > 1) return true;
    return roleChildren.some((rC) => hasBlockWideChildren(rC.id as number));
  };

  const checkIsBlockWide = () => {
    return hasBlockWideChildren(props.blockRole?.id as number);
    // return (document.getElementById("orgchartRoleBlock" + props.blockRole?.id)?.offsetWidth ?? 200) > 400;
  };

  const [menuFromServer, setMenuFromServer] = useState<MenuItemBaseDto[] | null>(null);
  const cardMenu = useMemo(() => menuFromServer ?? [], [menuFromServer])

  const [isBlockWide, setIsBlockWide] = useState<boolean>(checkIsBlockWide());
  const [isCardMenuOpen, setIsCardMenuOpen] = useState<boolean>(false);
  const [isCardMenuLoading, setIsCardMenuLoading] = useState<boolean>(false);

  const handleCardMenuOpen = useCallback(async (event: any) => {
    event.stopPropagation && event.stopPropagation();
    event.preventDefault && event.preventDefault();
    setIsCardMenuOpen(true);
    if (menuFromServer == null) {
      await handleCardMenuLoad();
    }
  }, [menuFromServer, props.blockData?.roleId]);

  useEffect(() => {
    setIsBlockWide(checkIsBlockWide());
  }, [props.roles]);

  const handleCardMenuClose = useCallback((afterActionClick?: boolean) => {
    setIsCardMenuOpen(false);
    setIsCardMenuLoading(false);
    setTimeout(
      () => {
        setMenuFromServer(null);
      },
      400
      // afterActionClick ? 1000 : 500
    );
  }, []);

  const handleCardMenuLoad = useCallback(async () => {
    if (props.blockData?.roleId == null || menuFromServer != null) {
      return;
    }
    setIsCardMenuLoading(true);
    const r = await api.role.calculated(props.blockData?.roleId);
    setIsCardMenuLoading(false);
    if (r == null) {
      notifier.show(t("notifier:error.something_wrong"));
      return;
    }
    const actionsList = r.actions ?? [];
    actionsList.push({
      id: 125,
      key: 'print',
    })
    setMenuFromServer(actionsList);
  }, [menuFromServer, props.blockData?.roleId]);

  const handleMenuItemClick = useCallback((actionKey: string, roleId: number) => {
    handleCardMenuClose(true);
    switch (actionKey) {
      case "add_role":
        orgchartContext?.updateState({
          ...orgchartContext,
          blockId: props.blockData?.roleId as number,
          openedDialog: "add",
        });
        // handleCardMenuClose();
        break;
      case "edit":
        orgchartContext?.updateState({
          ...orgchartContext,
          blockId: props.blockData?.roleId as number,
          openedDialog: "edit",
        });
        // handleCardMenuClose();
        break;
      case "manage":
        orgchartContext?.updateState({
          ...orgchartContext,
          blockId: props.blockData?.roleId as number,
          openedDialog: "openFunction",
        });
        // handleCardMenuClose();
        break;
      case "sort":
        orgchartContext?.updateState({
          ...orgchartContext,
          blockId: props.blockData?.roleId as number,
          openedDialog: "sort",
        });
        // handleCardMenuClose();
        break;
      case "transfer":
        orgchartContext?.updateState({
          ...orgchartContext,
          blockId: props.blockData?.roleId as number,
          openedDialog: "move",
        });
        break;
      case "delete":
        orgchartContext?.updateState({
          ...orgchartContext,
          blockId: props.blockData?.roleId as number,
          openedDialog: "delete",
        });
        // handleCardMenuClose();
        break;
      case "print":
        if (props.handleOrgchartBlockPrint) {
          props.handleOrgchartBlockPrint(roleId);
        }
        break;
    }
  }, [props.blockData?.roleId, props.handleOrgchartBlockPrint, orgchartContext]);

  const handleCardClick = useCallback(() => {
    orgchartContext?.updateState({
      ...orgchartContext,
      blockId: props.blockData?.roleId as number,
      openedDialog: "openFunction",
    });
  }, [props.blockData?.roleId]);

  const resetOrgchartState = () => {
    orgchartContext?.resetState();
  };

  const isHiddenPrintBlock = useMemo(() => {
    if (props.printBlockId === props.blockRole?.id) {
      return false
    }
    if (props.isHiddenPrintBlock === undefined) {
      const { id, parentId } = props.blockRole ?? {};
      return props.printBlockId
        ? !(props.printBlockId === id || props.printBlockId === parentId)
        : false
    }
    return props.isHiddenPrintBlock

  }, [props.isHiddenPrintBlock, props.printBlockId]);


  return (
    <>
    <OrgchartFunctionCardHubbardView
      cardMenu={cardMenu}
      roles={props.roles}
      zoom={props.zoom}
      blockRole={props.blockRole}
      isHiddenPrintBlock={isHiddenPrintBlock}
      printBlockId={props.printBlockId}
      handleOrgchartBlockPrint={props.handleOrgchartBlockPrint}
      blockData={props.blockData}
      isCardMenuOpen={isCardMenuOpen}
      isCardMenuLoading={isCardMenuLoading}
      handleCardClick={handleCardClick}
      handleMenuItemClick={handleMenuItemClick}
      handleCardMenuOpen={handleCardMenuOpen}
      handleCardMenuLoad={handleCardMenuLoad}
      handleCardMenuClose={handleCardMenuClose}
      params={props.params}
      orgchartDrawType={props.orgchartDrawType}
      isBlockWide={isBlockWide}
    />
    </>
  );
});

export default OrgchartFunctionCardHubbard;

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IRegulationContent } from "./Pages.interface";
import { initialEditorContent } from "../../../../../functional/utils/textEditor";
import { RegulationHistory } from "../components/RegulationHistory/RegulationHistory";
import { UserShortDto } from "../../../../../functional/api";
import { allowsRegulationKeys, progressUsersKeys } from "../../../../../functional/constants/regulation/consts";
import { useTranslation } from "react-i18next";
import { ListUsersDialog } from "../../../dialogs/listUsersDialog/ListUsersDialog";
import { defineAllow } from "../../../../../functional/helpers/helpers";
import { ProgressUsers } from "../components/ProgressUsers/ProgressUsers";
import { RedirectedRegulationButtons } from "../components/RedirectedRegulationButtons/RedirectedRegulationButtons";
import { RegulationViewOptions } from "../components/RegulationViewOptions/RegulationViewOptions";
import { RegulationContentHeader } from "../components/RegulationContentHeader/RegulationContentHeader";
import { CreateTestsForm } from "../../../forms/createTestsForm/CreateTestsForm";
import { RegulationSkeleton } from "../components/skeletons/regulation/RegulationSkeleton";
import { Card } from "../../../../uiKit";
import { TextEditor as TextEditorLexical } from "../../../textEditor/TextEditor";
import {
  RegulationEditorToolbarAction
} from "../components/RegulationEditorToolbarAction/RegulationEditorToolbarAction";

import "./RegulationContent.scss";

export const RegulationContent = (props: IRegulationContent) => {
  const { t } = useTranslation();

  const [isOpenUserProgressDialog, setIsOpenUserProgressDialog] = useState<boolean>(false);
  const [usersProgressType, setUsersProgressType] = useState<progressUsersKeys | undefined>(undefined);

  const handleOpenUserProgressDialog = (progressType: progressUsersKeys) => {
    setIsOpenUserProgressDialog(true);
    setUsersProgressType(progressType);
  };
  const handleCloseUserProgressDialog = () => {
    setIsOpenUserProgressDialog(false);
    setUsersProgressType(undefined);
  };

  const usersProgress = useMemo(() => {
    return usersProgressType === progressUsersKeys.whoStudied
      ? props.usersWhoStudied
      : props.usersToStudy;
  }, [props.usersWhoStudied, props.usersToStudy, usersProgressType]);

  const isDone = useMemo(() => {
    return usersProgressType === progressUsersKeys.whoStudied
      ? props.usersWhoStudiedIsDone
      : props.usersToStudyIsDone
  }, [
    props.usersWhoStudiedIsDone,
    props.usersToStudyIsDone,
    usersProgressType,
  ]);

  const handleGetTitleForProgressDialog = (): string => {
    if (usersProgressType === progressUsersKeys.toStudy) return t("ui:title.no_learnt_regulation");
    else if (usersProgressType === progressUsersKeys.whoStudied) return t("ui:title.learnt_regulation");
    else return "";
  };

  const editorRef = useRef<{ resetEditor: () => void }>();

  const handleGetRegulationContent = useMemo((): string => {
    const currentContent = props.currentContent?.content ?? initialEditorContent;
    return defineAllow(allowsRegulationKeys.reject, props.allowedActions) ||
      defineAllow(allowsRegulationKeys.publish, props.allowedActions)
      ? props.regulation?.draftContent?.content ?? currentContent
      : currentContent;
  }, [props.currentContent?.content, props.regulation?.draftContent?.content, props.allowedActions]);

  useEffect(() => {
    if (!props.isEditable) {
      editorRef.current?.resetEditor();
    }
  }, [props.isEditable, handleGetRegulationContent]);

  const [isShowTest, setIsShowTest] = useState<boolean>(false);
  const onOpenTest = useCallback(() => {
    setIsShowTest(true);
  }, []);

  useEffect(() => {
    if (props.isEditable) {
      setIsShowTest(props.editableTest.length > 0);
    } else {
      setIsShowTest(false);
    }
  }, [props.isEditable, props.editableTest]);

  const loadNext = useCallback(() => {
    return usersProgressType === progressUsersKeys.toStudy
      ? props.usersToStudyLoadNext()
      : props.usersWhoStudiedLoadNext()
  }, [usersProgressType]);

  return (
    <>
      {isOpenUserProgressDialog && (
        <ListUsersDialog
          open={isOpenUserProgressDialog}
          title={handleGetTitleForProgressDialog()}
          users={usersProgress}
          onClose={handleCloseUserProgressDialog}
          loadNext={loadNext}
          isDone={isDone}
        />
      )}
      {/*<CenterPageContentLayout maxWidth={1000}>*/}
      <>
        <Card className="regulation-content-page__content" isShadowed={false}>
          {props.isLoading ? (
            <RegulationSkeleton />
          ) : (
            <>
              <RegulationContentHeader
                draftTitle={props.draftTitle}
                currentTitle={props.regulation?.name ?? ""}
                isEditable={props.isEditable}
                allowedActions={props.allowedActions}
                dateCreated={props.regulation?.dateCreated!}
                onChangeTitle={props.onChangeTitle}
                createdByUser={props.regulation?.createdByUser}
                onOpenRequestChangeAccessTypeDialog={props.onOpenRequestChangeAccessTypeDialog}
              />
              {(props.currentContent || props.draftContent) && (
                <div className="mt-2">
                  {props.isEditable ? (
                    <div>
                      <TextEditorLexical
                        id={"regulation-draft-editor" + props.regulation?.id}
                        variant={"new-style"}
                        value={props.regulation?.draftContent?.content ?? props.currentContent?.content ?? ""}
                        initialValue={props.regulation?.draftContent?.content ?? props.currentContent?.content ?? ""}
                        onChange={props.onChangeDraftContent}
                        isShowEditorTitle={true}
                        titleValue={props.draftTitle ?? ""}
                        onChangeTitle={props.onChangeTitle}
                        toolbar={(
                          <RegulationEditorToolbarAction
                            handleClickOpenTest={onOpenTest}
                          />
                        )}
                      />
                      {isShowTest && (
                        <div className="create-regulation-form-tests-form-wrap">
                          <CreateTestsForm
                            styles={{ marginTop: "8px" }}
                            regulationContentId={(props.isDraft ? props.draftContent : props.currentContent)?.id ?? 0}
                            defaultQuestions={props.editableTest}
                            ref={props.testRef}
                            editableMode
                            onChangeHasChanges={props.onSetHasDraftTestChanges}
                            noImmediatelyCreateQuestion
                            onClose={() => {}}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <TextEditorLexical
                      ref={editorRef}
                      readOnly
                      value={handleGetRegulationContent}
                      initialValue={handleGetRegulationContent}
                      placeholder={" "}
                    />
                  )}
                </div>
              )}
              <div className="  regulation-content-page-wrap">
                <RedirectedRegulationButtons />
                <RegulationViewOptions
                  isLoadingStudyBtn={props.isLoadingStudyBtn}
                  onStudyRegulation={props.onStudyRegulation}
                  hasActiveContent={!!props.regulation?.activeContent}
                  isEditable={props.isEditable}
                  allowedActions={props.allowedActions}
                  countQuestions={props.countQuestions}
                  testIsPassed={props.testIsPassed}
                  onClickPassTest={props.onOpenTestPassDialog}
                />
                {((props.regulation &&
                  props.usersWhoStudied.length > 0) ||
                  (props.regulation && props.usersToStudy.length > 0)) && (
                  <ProgressUsers
                    onOpenProgressUserDialog={handleOpenUserProgressDialog}
                    learntUsers={props.usersWhoStudied}
                    learntUsersTotalItems={props.usersWhoStudiedTotalItems}
                    noLearntUsers={props.usersToStudy}
                    noLearntUsersTotalItems={props.usersToStudyTotalItems}
                  />
                )}
              </div>
            </>
          )}

          {/* <>
              <RegulationContentHeader
                draftTitle={props.draftTitle}
                currentTitle={props.regulation?.name ?? ""}
                isEditable={props.isEditable}
                dateCreated={props.regulation?.dateCreated!}
                onChangeTitle={props.onChangeTitle}
                createdByUser={props.regulation?.createdByUser}
              />
              {(props.currentContent || props.draftContent) && (
                <div className="mt-2">
                  {props.isEditable ? (
                    <div>
                      <TextEditor
                        id="draft-editor"
                        onChange={props.onChangeDraftContent}
                        value={stringToContent(
                          props.regulation?.draftContent?.content ?? props.currentContent?.content ?? ""
                        )}
                      />
                      {props.isEditable && (
                        <CreateTestsForm
                          styles={{ marginTop: "8px" }}
                          regulationContentId={(props.isDraft ? props.draftContent : props.currentContent)?.id ?? 0}
                          defaultQuestions={props.editableTest}
                          ref={props.testRef}
                          editableMode
                          onChangeHasChanges={props.onSetHasDraftTestChanges}
                          noImmediatelyCreateQuestion
                          onClose={() => {}}
                        />
                      )}
                    </div>
                  ) : (
                    <TextEditor
                      readOnly
                      id="readonly-editor"
                      onChange={() => {}}
                      value={stringToContent(handleGetRegulationContent())}
                    />
                  )}
                </div>
              )}
              {((props.regulation &&
                props.regulation?.usersWhoStudied &&
                props.regulation?.usersWhoStudied?.length > 0) ||
                (props.regulation && props.regulation?.usersToStudy && props.regulation?.usersToStudy.length > 0)) && (
                <ProgressUsers
                  onOpenProgressUserDialog={handleOpenUserProgressDialog}
                  learntUsers={props.regulation?.usersWhoStudied?.map((item) => item.user!) ?? []}
                  noLearntUsers={props.regulation?.usersToStudy ?? []}
                />
              )}
              <RedirectedRegulationButtons />
              <RegulationViewOptions
                isLoadingStudyBtn={props.isLoadingStudyBtn}
                onStudyRegulation={props.onStudyRegulation}
                hasActiveContent={!!props.regulation?.activeContent}
                isEditable={props.isEditable}
                allowedActions={props.allowedActions}
                countQuestions={props.countQuestions}
                testIsPassed={props.testIsPassed}
                onClickPassTest={props.onOpenTestPassDialog}
              />
            </>*/}
        </Card>
        {props.regulation && (props.isDraft ? props.draftContent : props.currentContent)?.id && (
          <RegulationHistory
            regulationId={props.regulation?.id ?? 0}
            regulationContentId={props.currentContent?.id ?? 0}
          />
        )}
      </>
      {/*</CenterPageContentLayout>*/}
    </>
  );
};

import React from "react";
import CompanyLeaveDialogView from "./CompanyLeaveDialogView";
import { User2CompanyDto } from "../../../../functional/api";
import { api } from "../../../../functional/services";
import { useNotifier, useRootStore } from "../../../../functional/hooks";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";
import Interceptors from "../../../../functional/plugins/interceptors";
import { rootStore } from "../../../../functional/stores/rootStore";

export interface ICompanyLeaveDialog {
  open: boolean;
  companyData: User2CompanyDto;
  userId?: number;
  handleRefresh(): void;
  onLeave(): void;
  onClose(): void;
}

function CompanyLeaveDialog(props: ICompanyLeaveDialog) {
  const { authStore } = useRootStore();
  const notifier = useNotifier();
  const userId = props?.userId ?? authStore.getInitialInfo?.identity?.id;
  const { t } = useTranslation();

  const handleClose = () => {
    props.onClose();
  };

  const handleLeave = async () => {
    const r = await api.user.leaveCompany(userId as number, props.companyData.companyId as number);
    notifier.showSuccessError(
      t("notifier:success.company_leave", {
        companyName:
          (props.companyData.company?.name ?? "").trim().length > 0
            ? props.companyData.company?.name
            : props.companyData.company?.nameFallback,
      }),
      t("notifier:error.company_leave"),
      !!r
    );
    if (r) {
      const newCompaniesList = (toJS(authStore.getInitialInfo?.identity?.companies) ?? []).filter(
        (c) => c.companyId != props.companyData.companyId
      );
      const newCompanyId = newCompaniesList.length > 0 ? newCompaniesList[0].companyId : null;
      authStore.setCurrentCompanyId(newCompanyId ?? null);
      Interceptors.setup(rootStore, { companyId: newCompanyId ?? undefined });
      // await authStore.refreshInitialInfo();
      await authStore.authorizeWithRefreshToken();
      props.onLeave();
      props.onClose();
    } else {
    }
  };

  return (
    <CompanyLeaveDialogView
      companyData={props.companyData}
      open={props.open}
      onClose={handleClose}
      onLeave={handleLeave}
    />
  );
}

export default CompanyLeaveDialog;

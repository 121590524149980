import React, { useContext, useEffect, useState } from "react";
import { ReportContext } from "../../../../../../functional/contexts/reportContext";
import { usePagingWithController } from "../../../../../../functional/hooks";
import { api } from "../../../../../../functional/services";
import { UserInfo } from "../../../../../elements/userInfo/UserInfo";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";
import { IReportUserCollapse, reportsTypesTypes } from "../../Report.interface";
import { ReportIssueCard } from "../reportIssueCard/ReportIssueCard";
import { Collapse, CollapsePanel, Spin } from "../../../../../uiKit";
import { ColorModeKeys } from "../../../../../elements/counterChip/misc/keys";
import { CounterChip } from "../../../../../elements/counterChip/CounterChip";

export const ReportUserCollapse = ({ user, userDate, reportsType, orgchartFilter }: IReportUserCollapse) => {
  const { reportKey, timeInterval } = useContext(ReportContext);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const reqViolationsOptions = {
    withViolationUserId: userDate?.userId,
    withViolationKey: reportKey,
    pageSize: 10,
    customFilterDateFrom: timeInterval?.from,
    customFilterDateTo: timeInterval?.to,
  };
  const reqCustomRuleOptions = {
    executorUserId: userDate?.userId,
    customFilterKey: reportKey,
    pageSize: 10,
    customFilterDateFrom: timeInterval?.from,
    customFilterDateTo: timeInterval?.to,
  };
  const reqOptions = reportsType === reportsTypesTypes.violations ? reqViolationsOptions : reqCustomRuleOptions;
  const issuesTest = usePagingWithController(
    api.issueShort,
    { orgchartId: orgchartFilter ? userDate?.orgchartId ?? undefined : undefined },
    reqOptions,
    undefined
  );


  const onFetchIssues = (key: string | string[]) => {
    setIsOpen(!!key.length)
  }
  useEffect(() => {
    if (isOpen) {
      issuesTest.reset();
      issuesTest.restart();
    }
  }, [timeInterval, orgchartFilter, isOpen]);

  const collapseHeader = (
    <div className="d-stack-row justify-start align-start spacing-2">
      <UserInfo
        user={user}
        isShowIsFired
      />
      {!!userDate?.value && <CounterChip colorMode={ColorModeKeys.primary} count={userDate?.value} />}
    </div>
  );


  return (
    <Collapse onChange={onFetchIssues} accordion style={{ width: "100%" }}>
      <CollapsePanel key={user?.id ?? 0} id={`${user?.id ?? 0}`} header={collapseHeader}>
        {issuesTest.info.isLoading ? (
          <div className="d-flex align-center justify-center">
            <Spin spinning={true} />
          </div>
        ) : (
          issuesTest.items?.map((issue) => (
            <ReportIssueCard reportsType={reportsType} key={issue.id} issueShort={issue} />
          ))
        )}
        {issuesTest.items?.length > 0 && (
          <ScrollTrigger
            disabled={issuesTest.items.length >= userDate?.value!}
            onIntersection={issuesTest.loadNext}
            marginTop={issuesTest.items?.length > 0}
            hidden={issuesTest.items.length >= userDate?.value!}
          />
        )}
      </CollapsePanel>
    </Collapse>
  );
};

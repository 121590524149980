import React, { useCallback, useEffect, useState, useMemo } from "react";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { Form } from "antd";
import { InputPassword, Dialog, DialogActions, Button, Popover } from "../../../uiKit";
import { useTranslation } from "react-i18next";
import { CheckIcon2, CloseIcon } from "../../../../functional/constants/icon";
import './ChangePasswordDialog.scss';
import { api } from "../../../../functional/services";
import { useRootStore } from "../../../../functional/hooks";
import { ReactionDropdown } from "../../../elements/emojiPicker/components/generalDropdown/ReactionDropdown";
import { AddReactionButton } from "../../../elements/emojiPicker/components/AddReactionButton";
import { Link, useNavigate } from "react-router-dom";
import { AuthenticationResultDto, RefreshTokenDto } from "../../../../functional/api";

type ChangePasswordType = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

type ChangePasswordDialogType = {
  close: () => void;
  showConfirmDialog: () => void;
}

export const ChangePasswordDialog = (props: ChangePasswordDialogType) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { authStore } = useRootStore();

  const userEmail = useMemo(() => {
    return authStore?.getInitialInfo?.identity?.email ?? ''
  }, [authStore?.getInitialInfo?.identity?.email]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSendValidationError, setIsSendValidationError] = useState<boolean>(false);

  const [isShowPopoverError, setIsShowPopoverError] = useState<boolean>(false);
  const handleClosePopover = () => {
    setIsShowPopoverError(false);
  }

  const handleSubmit = () => {
    form.submit();
  };

  const checkValidation = () => {
    const validationStatus = !!validation.isValid
    setIsSendValidationError(!validationStatus);
    return validationStatus;
  };

  const handleSubmitForm = async () => {
    const validationStatus = checkValidation();
    if (validationStatus) {
      const { newPassword, oldPassword } = changePasswordData;
      setIsLoading(true);
      const resp = await api.auth.passwordUpdate({ newPassword, oldPassword });
      setIsLoading(false);
      if (resp) {
        const { tokenAccess, refreshToken,  initialInfo } = resp;
        authStore.setRefreshToken(refreshToken!);
        authStore.setAccessToken(tokenAccess!);
        authStore.setInitialInfo(initialInfo!);

        props.showConfirmDialog();
        props.close()
      } else {
        form.setFields([
          {
            name: "oldPassword",
            errors: [""],
          },
        ]);
        setIsShowPopoverError(true);
      }
    } else {
      form.setFields([
        {
          name: "newPassword",
          errors: [''],
        },
      ]);
    }

  };

  const handleClickResetPassword = async () => {
    navigate("/restore-password");
    await authStore.unauthorize();

  }

  const handleClose = () => {
    props.close();
  };

  const [changePasswordData, setChangePasswordData] = useState<ChangePasswordType>({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const handleChange = ({ value, key }: any) => {
    setChangePasswordData((prev: any) => ({
      ...prev,
      [key]: value,
    }))
  };

  const validation = useMemo(() => {
    const { newPassword, oldPassword, confirmPassword } = changePasswordData;
    const lengthRule = newPassword && newPassword.trim().length > 7;
    const lowerCaseRule = newPassword && newPassword !== newPassword.toLowerCase();
    const specialCharacterRule = newPassword && /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(newPassword);
    const passwordsMustNotSame = newPassword && newPassword !== oldPassword;
    const isValidOldPasswordLength = oldPassword.trim().length;
    const isValidConfirmPasswordLength = confirmPassword.trim().length;
    const isValid = lengthRule && lowerCaseRule && specialCharacterRule && passwordsMustNotSame
      && isValidOldPasswordLength && isValidConfirmPasswordLength;
    return {
      lengthRule,
      lowerCaseRule,
      specialCharacterRule,
      passwordsMustNotSame,
      isValid,
    }
  }, [changePasswordData.newPassword, changePasswordData.oldPassword, changePasswordData.confirmPassword]);


  return (
    <Dialog
      width={600}
      className="change-password-dialog"
      title={t("ui:title.change_password")}
      open={true}
      onClose={handleClose}
      closable={false}

    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={handleSubmitForm}
      >

        <div className="form-row">
          <SubheaderText
            text={t("ui:subheader.change_password.info_text", {user: userEmail})}
            noBottomOffset
          />
        </div>
        <div className="form-row">
          {/*<div className="form-row-popover-container">*/}
          {/*  <Popover*/}
          {/*    overlayClassName="reaction-overlay"*/}
          {/*    content={*/}
          {/*      <div className="change-password-error-popover">*/}
          {/*        <div className="change-password-error-popover__title">*/}
          {/*          { t("validation:invalid_current_password") }*/}
          {/*        </div>*/}
          {/*        <div className="change-password-error-popover__text">*/}
          {/*          { t('ui:change_password.popover_error.title')}*/}
          {/*          <span onClick={handleClickResetPassword}>*/}
          {/*            { t('ui:change_password.popover_error.link')}*/}
          {/*          </span>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    }*/}
          {/*    arrowContent={undefined}*/}
          {/*    arrow={{ pointAtCenter: false }}*/}
          {/*    trigger={["click"]}*/}
          {/*    placement="right"*/}
          {/*    open={isShowPopoverError}*/}
          {/*  >*/}
          {/*  </Popover>*/}
          {/*</div>*/}
          <SubheaderText text={t("ui:subheader.change_password.old_password")}/>
          <Form.Item
            name="oldPassword"
            // rules={[
            //   { required: true, message: t("validation:field_required") },
            // ]}
            initialValue={changePasswordData.oldPassword}
          >
            <InputPassword
              value={changePasswordData.oldPassword}
              autoComplete="off"
              placeholder={t("ui:subheader.change_password.placeholder")}
              onChange={(e) => handleChange({ value: e.target.value, key: "oldPassword" })}
              onFocus={handleClosePopover}
            />
            <Popover
              overlayClassName="reaction-overlay"
              content={
                <div className="change-password-error-popover">
                  <div className="change-password-error-popover__title">
                    {t("validation:invalid_current_password")}
                  </div>
                  <div className="change-password-error-popover__text">
                    {t("ui:change_password.popover_error.title")}
                    <span onClick={handleClickResetPassword}>
                      {t("ui:change_password.popover_error.link")}
                    </span>
                  </div>
                </div>
              }
              arrowContent={undefined}
              arrow={{ pointAtCenter: false }}
              trigger={["click"]}
              placement="right"
              open={isShowPopoverError}
            >
            </Popover>
          </Form.Item>
        </div>
        <div className="form-row">
          <SubheaderText text={t("ui:subheader.change_password.new_password")} />
          <Form.Item
            name="newPassword"
            // rules={[
            //   { required: true, message: t("validation:field_required") },
            // ]}
            initialValue={changePasswordData.newPassword}
          >
            <InputPassword
              value={changePasswordData.newPassword}
              autoComplete="off"
              placeholder={t("ui:subheader.change_password.placeholder")}
              onChange={(e) => handleChange({ value: e.target.value, key: "newPassword" })}
              onBlur={checkValidation}
            />
          </Form.Item>
          {/*{ validation.isValid ? (*/}
          <div className="validation-info">
            <div className={`validation-info-item ${validation.lengthRule ? "validation-info-item_active" : ""}${
              isSendValidationError && !validation.lengthRule ? "validation-info-item_error" : ""}`}>
              {isSendValidationError && !validation.lengthRule ? (
                <CloseIcon/>
              ) : (
                <CheckIcon2 />
              )}
              {t("ui:change_password.first_validation_rule")}
            </div>
            <div className={`validation-info-item ${validation.lowerCaseRule ? "validation-info-item_active" : ""}${
              isSendValidationError && !validation.lowerCaseRule ? "validation-info-item_error" : ""}`}>
              {isSendValidationError && !validation.lowerCaseRule ? (
                <CloseIcon/>
              ) : (
                <CheckIcon2 />
              )}
              {t("ui:change_password.second_validation_rule")}
            </div>
            <div
              className={`validation-info-item ${validation.specialCharacterRule ? "validation-info-item_active" : ""}${
                isSendValidationError && !validation.specialCharacterRule ? "validation-info-item_error" : ""}`}>
              {isSendValidationError && !validation.specialCharacterRule ? (
                <CloseIcon/>
              ) : (
                <CheckIcon2 />
              )}
              {t("ui:change_password.third_validation_rule")}
            </div>
            <div
              className={`validation-info-item ${validation.passwordsMustNotSame ? "validation-info-item_active" : ""}${
                isSendValidationError && !validation.passwordsMustNotSame ? "validation-info-item_error" : ""}`}>
              {isSendValidationError && !validation.passwordsMustNotSame ? (
                <CloseIcon/>
              ) : (
                <CheckIcon2 />
              )}
              {t("ui:change_password.fourth_validation_rule")}
            </div>
          </div>
          {/*) : null }*/}
        </div>
        <div className="form-row">
          <SubheaderText text={t("ui:subheader.change_password.confirm_password")} />
          <Form.Item
            name="confirmPassword"
            rules={[
              // { required: true, message: t("validation:field_required") },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t("validation:passwords_new_should_match")));
                }
              })
            ]}
            initialValue={changePasswordData.confirmPassword}
          >
            <InputPassword
              value={changePasswordData.confirmPassword}
              autoComplete="off"
              placeholder={t("ui:subheader.change_password.placeholder")}
              onChange={(e) => handleChange({ value: e.target.value, key: 'confirmPassword' })}
            />
          </Form.Item>
        </div>
      </Form>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="text"
        >
          {t("ui:button.cancel")}
        </Button>
        <Button
          loading={isLoading}
          disabled={!validation.isValid}
          onClick={handleSubmit}
          type="primary"
          variant="text"
        >
          {t("ui:button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

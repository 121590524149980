import React from "react";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { User2CompanyDto, User2RoleDto } from "../../../../functional/api";
import "./CompaniesManageForm.scss";
import CompanyAddDialog from "../../dialogs/companyAddDialog/CompanyAddDialog";
import CompanyLeaveDialog from "../../dialogs/companyLeaveDialog/CompanyLeaveDialog";
import { Avatar, Button, Card, Text } from "../../../uiKit";
import { If } from "../../../service/conditionStatement";

export interface ICompaniesManageFormView {
  companies: User2CompanyDto[];
  isAddCompanyDialogOpen: boolean;
  isLeaveCompanyDialogOpen: boolean;
  currentCompanyId: number | null;
  leaveCompanyData: User2CompanyDto;

  setCompanyToLeaveData(data: User2RoleDto): void;

  handleLeaveCompanyDialogOpen(): void;

  handleLeaveCompanyDialogClose(): void;

  handleAddCompanyDialogOpen(): void;

  handleAddCompanyDialogClose(): void;

  handleRefresh(): void;

  handleReauthorize(): void;
}

function CompaniesManageFormView(props: ICompaniesManageFormView) {
  const { t } = useTranslation();

  return (
    <>
      <CompanyAddDialog
        open={props.isAddCompanyDialogOpen}
        onClose={props.handleAddCompanyDialogClose}
        onAdd={props.handleRefresh}
      />
      <CompanyLeaveDialog
        open={props.isLeaveCompanyDialogOpen}
        companyData={props.leaveCompanyData}
        handleRefresh={props.handleRefresh}
        onLeave={props.handleReauthorize}
        onClose={props.handleLeaveCompanyDialogClose}
      />
      <div className="d-flex flex-column" style={{ height: "100%" }}>
        <div>
          <SubheaderText text={t("ui:subheader.my_companies")} />
        </div>
        <div className="flex-grow-1 d-stack-column spacing-2" style={{ overflow: "auto" }}>
          {props.companies
            ?.sort(function (a, b) {
              return new Intl.Collator(["en", "ru", "uk"], {
                numeric: false,
                ignorePunctuation: true,
              }).compare(
                ((a.company?.name ?? "").trim().length > 0 ? a.company?.name : a.company?.nameFallback) ?? "",
                ((b.company?.name ?? "").trim().length > 0 ? b.company?.name : b.company?.nameFallback) ?? ""
              );
            })
            ?.map((u2c, index) => (
              <Card
                key={u2c.id}
                variant="secondary"
                className={"companies-manage-card"}
              >
                <div className="d-flex flex-nowrap align-center">
                  <Avatar
                    size={36}
                    color={u2c.company?.color}
                    src={u2c.company?.image?.url}
                    text={(u2c.company?.name ?? "").trim().length > 0 ? u2c.company?.name : u2c.company?.nameFallback}
                  />
                  <div className="flex-grow-1 d-stack-column mx-3" style={{ overflow: "hidden" }}>
                    <Text
                      style={{ fontWeight: "500", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                      children={
                        (u2c.company?.name ?? "").trim().length > 0 ? u2c.company?.name : u2c.company?.nameFallback
                      }
                    />
                    <If condition={props.currentCompanyId == u2c.companyId}>
                      <Text style={{ opacity: 0.7 }} children={t("ui:text.current_company")} />
                    </If>
                  </div>
                  <Button
                    className="flex-shrink-0"
                    onClick={() => {
                      props.setCompanyToLeaveData(u2c);
                      props.handleLeaveCompanyDialogOpen();
                    }}
                    variant="text"
                    theme="error"
                  >
                    {t("ui:button.leave_company")}
                  </Button>
                </div>
              </Card>
            ))}
        </div>
        <Button className="mt-3" onClick={() => props.handleAddCompanyDialogOpen()} variant="filled">
          {t("ui:button.add_company")}
        </Button>
      </div>
    </>
  );
}

export default CompaniesManageFormView;

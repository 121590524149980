import { ModalProps } from "antd";
import React, { ReactNode, useEffect, useState } from "react";
import { DialogView } from "./DialogView";
import clsx from "clsx";
import { IDialog } from "../../../functional/types";
import { useDialog } from "../../../functional/hooks";

export function Dialog(props: IDialog) {
  const { footer } = useDialog(props);

  return <DialogView open={props.open} onCancel={props.onClose} footer={footer} {...props} />;
}

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";
import {resources} from "./resources";

i18n
  .use(LanguageDetector)
  // .use(i18nextPlugin)
  .use(initReactI18next)
  .init({
    interpolation: {
      escapeValue: false,
    },
    // lng: "ru",
    supportedLngs: ["en", "ru", "uk", "zh", "es", "uz-latn", "uz-cyrl", "uz"],
    // debug: false,
    // debug: window.location?.hostname == "localhost",
    cleanCode: true,
    lowerCaseLng: true,
    nonExplicitSupportedLngs: true,
    // load: ["en", "ru", "uk", "zh"],
    // preload: ["en", "ru", "uk", "zh"],
    preload: true,
    pluralSeparator: "-",
    // load: "languageOnly",
    resources,
    // parseMissingKeyHandler: (key) => {
    //   console.warn(`react-i18next key "${key}" not found.`);
    //   return key;
    // },
  });

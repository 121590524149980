import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PageContentLoader from "../../../service/pageContentLoader/PageContentLoader";
import { PositionSelectionBox } from "./PositionSelectionBox";
import { RoleSelectionBox } from "./RoleSelectionBox";
import { IUserSelectionDialogView, positionsKeys } from "./UserSelectionDialog.interface";
import { Button, Dialog, DialogActions, Divider } from "../../../uiKit";
import { useRootStore } from "../../../../functional/hooks";

export const UserSelectionDialogView: React.FC<IUserSelectionDialogView> = (props) => {
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const [disabledFromDown, setDisabledFromDown] = useState<boolean>(false);

  const allAllIsDisabled = () =>
    props.orgchartsForBlockAllAll?.find((orgchartId) => orgchartId === props.orgchartId)
      ? props.activeRoleId === null
      : false;

  const handleCheckPositionType = () =>
    props.rolePositionId === positionsKeys.allManagers || props.rolePositionId === positionsKeys.allPerformers;

  const isSupportOldUi = authStore.getCurrentCompanyUiType == 1;
  // const isSupportOldUi = true;

  // const handleCheckPositionType = () => props.rolePositionId === positionsKeys.allManagers;

  return (
    <Dialog
      open={props.open}
      width={620}
      title={t("ui:subheader.role_select")}
      scrollStrategy="dialogBody"
      onClose={props.onClose}
      bodyStyle={{
        display: "flex",
      }}
      closable={false}
    >
      {/*<divider  />*/}
      <div className="user-selection-dialog__content">
        {!props.isLoading ? (
          <div className="d-flex align-stretch" style={{ width: "100%", height: "100%" }}>
            <RoleSelectionBox
              isSupportOldUi={isSupportOldUi}
              orgchartId={props.orgchartId}
              onChangeOrgchartId={props.onChangeOrgchartId}
              rolesIsDone={props.rolesIsDone}
              labels={
                isSupportOldUi
                  ? props.roles.map((item) => ({ id: item.id!, name: item.name! }))
                  : props.rolesAutocomplete.map((item) => ({ id: item.id!, name: item.text! }))
              }
              activeLabelId={props.activeRoleId}
              onSelectActiveRole={props.onSelectActiveRole}
              searchRoleValue={props.searchRoleValue}
              loadNextRoles={props.loadNextRoles}
              onChangeSearchRoleValue={props.onChangeSearchRoleValue}
            />
            {isSupportOldUi && <Divider isVertical className="mx-2" />}
            {isSupportOldUi && (
              <PositionSelectionBox
                orgchartId={props.orgchartId}
                disabledRules={props.disabledRules}
                rolePositionId={props.rolePositionId}
                roleId={props.activeRoleId}
                setDisabledFromDown={setDisabledFromDown}
                disabledAllAll={allAllIsDisabled()}
                onChangeRolePositionId={props.onChangeRolePositionId}
                positions={props.currentPositions}
              />
            )}
          </div>
        ) : (
          <PageContentLoader />
        )}
      </div>
      {/*<Divider />*/}
      <DialogActions>
        <Button disabled={props.isLoading} onClick={props.onClose} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button
          loading={props.isLoading}
          disabled={(allAllIsDisabled() && !handleCheckPositionType()) || disabledFromDown}
          onClick={props.onSave}
          variant="filled"
        >
          {t("ui:button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

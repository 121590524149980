import React from "react";
import { IGlobalSearchView } from "./GlobalSearch.interface";
import { useTranslation } from "react-i18next";
import { Empty, Icon, Input, Popover, Spin } from "../../../../../uiKit";
import { FiSearch } from "react-icons/fi";
import { CardIssue } from "../../../../pages/communications/components/cardIssue/CardIssue";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";

export const GlobalSearchView = (props: IGlobalSearchView) => {
  const { t } = useTranslation();

  return (
    <Popover
      overlayStyle={{ padding: 0, maxWidth: "420px", width: "100%" }}
      overlayInnerStyle={{ maxHeight: "50vh", overflowY: "auto" }}
      className="global-search__popover"
      open={props.isOpen}
      showArrow={false}
      placement="bottom"
      align={{ offset: [0, 9] }}
      content={
        <Spin spinning={props.isLoading} size="large">
          {props.searchedItems && props.searchedItems?.length > 0 && props.searchIssueText.trim().length > 0 ? (
            <>
              {props.searchedItems?.map((item) => (
                <CardIssue key={item.id} noDrag issue={item} outlined />
              ))}
              <ScrollTrigger
                disabled={props.isDone}
                onIntersection={props.loadNext}
                marginTop={props.searchedItems.length > 0}
                hidden={props.isDone}
              />
            </>
          ) : (
            props.searchIssueText.trim().length > 0 && <Empty className="my-4" />
          )}
        </Spin>
      }
      children={
        <Input
          style={{ width: "420px" }}
          className={`global-search__input ${props.isFocus ? "__is-focused" : ""}`}
          placeholder={t("ui:placeholder.search_here")}
          prefix={<Icon component={() => <FiSearch size={24} />} />}
          value={props.searchIssueText}
          onInput={(e: any) => props.onChangeSearchText(e.target.value as string)}
          onFocus={props.onToggleFocus(true)}
          onBlur={props.onBlur}
          autoComplete="off"
          onBlurCapture={props.onBlur}
          bordered={false}
        />
      }
    />
  );
};

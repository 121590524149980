import React from "react";
import { observer } from "mobx-react-lite";
import { useImageViewer } from "../../../functional/hooks";
import ImageViewerView from "./ImageViewerView";
import { IImageViewer } from "../../../functional/types";

function ImageViewer(props: IImageViewer) {
  const {
    images,
    currentImageIndex,
    handlePrevCLick,
    handleNextCLick,
    handleThumbnailCLick,
    handleClose,
  } = useImageViewer(props);

  return (
    <ImageViewerView
      images={images}
      viewerProps={props.viewerProps}
      currentImageIndex={currentImageIndex}
      onClickPrev={handlePrevCLick}
      onClickNext={handleNextCLick}
      onClickThumbnail={handleThumbnailCLick}
      onClose={handleClose}
    />
  );
}

export default observer(ImageViewer);

import React, { memo } from "react";
import { IAnswer } from "../CreateTestsForm.interface";
import { useTranslation } from "react-i18next";
import {  Checkbox, TextArea } from "../../../../uiKit";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CloseIcon } from "../../../../../functional/constants/icon";
import "./Answer.scss";

export const Answer: React.FC<IAnswer> = memo((props) => {
  const { t } = useTranslation();

  const handleChangeChecked = (event: CheckboxChangeEvent) => {
    props.onChangeChecked(event.target.checked, props.id);
  };

  const handleClickDelete = () => {
    props.onDelete(props.id)
  };

  const handleChangeAnswer = (event: React.FormEvent<HTMLTextAreaElement>) => {
    props.onChangeName(event.currentTarget.value, props.id)
  };

  return (
    <div className="answer-row">
      <div className="question-answer-checkbox">
        <Checkbox
          checked={props.checked}
          isNewDesign={true}
          onChange={handleChangeChecked}
        />
      </div>
      <TextArea
        placeholder={t("ui:placeholder.come_up_answer")}
        value={props.name}
        onInput={handleChangeAnswer}
        autoSize={{ minRows: 1, maxRows: 5 }}
        isHiddenBorder={true}
      />
      <div
        className="question-answer-delete-button"
        onClick={handleClickDelete}
      >
        <CloseIcon size={14} />
      </div>

    </div>
  );
});

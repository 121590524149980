import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import "./OrgchartDrawerTabEmployee.scss";
import { RoleDto, RolePositionDto, User2RoleDto } from "../../../../../../functional/api";
import { OrgchartContext } from "../../../../../../functional/contexts/orgchartContext";
import { useClaims, useRootStore } from "../../../../../../functional/hooks";
import { permissionKeys } from "../../../../../../functional/utils/permissions";
import FunctionPositionCard from "../../functionPositionCard/FunctionPositionCard";
import { Button, Dialog, DialogActions, Input, Spin } from "../../../../../uiKit";
import SubheaderText from "../../../../../elements/subheaderText/SubheaderText";
import { observer } from "mobx-react-lite";

export interface IOrgchartDrawerTabEmployeeView {
  functionData: RoleDto;
  isLoading: boolean;
  isPositionCreateDialogOpen: boolean;
  positionToCreateName: string;

  handleUserAddOnPosition: (positionId: number, userId: number) => void;
  setPositionToCreateName: (val: string) => void;
  handleUserDeleteFromPosition: (positionId: number, userId: number) => void;
  handlePositionDataUpdate: (data: RolePositionDto) => void;
  handlePositionCreateClick: () => void;
  handlePositionCreate: () => void;
  onPositionCreateDialogClose: () => void;
  handlePositionDelete: (positionId: number) => void;
}

const PositionCreateDialog = (props: IOrgchartDrawerTabEmployeeView) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={props.isPositionCreateDialogOpen}
      onClose={props.onPositionCreateDialogClose}
      title={t("parse:position_create")}
      closable={false}
    >
      <SubheaderText text={t("ui:subheader.task_recurring_name")} />
      <Input
        value={props.positionToCreateName}
        onInput={(e) => props.setPositionToCreateName(e.currentTarget.value ?? "")}
        placeholder={t("ui:placeholder.recurring_task_name")}
      />
      <DialogActions>
        <>
          <Button onClick={props.onPositionCreateDialogClose} variant="text">
            {t("ui:button.cancel")}
          </Button>
          <Button
            disabled={(props.positionToCreateName ?? "").trim().length == 0}
            onClick={props.handlePositionCreate}
            variant="filled"
            loading={props.isLoading}
          >
            {t("ui:button.create")}
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};

function OrgchartDrawerTabEmployeeView(props: IOrgchartDrawerTabEmployeeView) {
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const claims = useClaims();
  const orgchartContext = useContext(OrgchartContext);

  return (
    <Spin spinning={props.functionData == null} className="orgchart-function-drawer__tabs__loader">
      <div className="function-positions-card__wrapper" style={{ width: "50%", minWidth: "400px" }}>
        <PositionCreateDialog {...props} />
        {props.functionData?.positions
          ?.sort((a, b) => (a.parentPositionId != null ? 1 : -1))
          ?.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
          ?.filter((position) => (authStore.getCurrentCompanyUiType == 2 ? position.parentPositionId == null : true))
          ?.map((position: RolePositionDto, index: number) => (
            <div
              className={`pl-${position.parentPositionId == null ? 0 : 2} mt-${index > 0 ? 2 : 0}`}
              key={position.id}
            >
              <FunctionPositionCard
                data={position}
                isLoading={props.isLoading}
                functionData={props.functionData}
                handleUserAddOnPosition={props.handleUserAddOnPosition}
                handleUserDeleteFromPosition={props.handleUserDeleteFromPosition}
                handlePositionDataUpdate={props.handlePositionDataUpdate}
                handlePositionDelete={props.handlePositionDelete}
                readOnly={!claims.has(permissionKeys.orgchart.role.usersEdit)}
              />
            </div>
          ))}
        {claims.has(permissionKeys.orgchart.role.usersEdit) && authStore.getCurrentCompanyUiType != 2 && (
          <div className="pl-2 mt-2">
            <Button
              disabled={
                props.isLoading ||
                (props.functionData.positions ?? [])?.some(
                  (p: RolePositionDto) =>
                    (p.name ?? "").length == 0 &&
                    (props.functionData.users ?? []).filter((u2r: User2RoleDto) => u2r.positionId == p.id).length == 0
                )
              }
              onClick={() => props.handlePositionCreateClick()}
              variant="filled"
            >
              {t("parse:add_employee")}
            </Button>
          </div>
        )}
      </div>
    </Spin>
  );
}

export default observer(OrgchartDrawerTabEmployeeView);

import React from "react";
import { useTranslation } from "react-i18next";
import YouTube from "react-youtube";
import "./VideoDialog.scss";
import { Button, Dialog, DialogActions, Spin } from "../../../uiKit";
import { IVideoDialogView } from "../../../../functional/types";

function VideoDialogView(props: IVideoDialogView) {
  const { t } = useTranslation();

  const resetPlayerState = () => {
    document
      .getElementById("yt-video-component")
      // @ts-ignore
      ?.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', "*");
    const data = { event: "command", func: "seekTo", args: [0, true] };
    document
      .getElementById("yt-video-component")
      // @ts-ignore
      ?.contentWindow.postMessage(JSON.stringify(data), "*");
  };

  return (
    <Dialog
      width={"unset"}
      // width={1100}
      zIndex={10000}
      closable={false}
      title={!props.removeTitle ? t("ui:title.watch_to_start") : undefined}
      open={(props.videoId ?? "").trim().length > 0}
      onClose={props.handleLaterClick}
    >
      {props.videoId ? (
        <YouTube
          videoId={props.videoId}
          opts={{
            width: "100%",
            height: "100%",
            playerVars: {
              start: 0,
              end: 0,
              iv_load_policy: 3,
              rel: 0,
              autoplay: props.autoPlay === true ? 1 : 0,
              enablejsapi: 1,
            },
          }}
          onEnd={() => {
            props.onAllowToSaveProgress();
            props.autoPlay && resetPlayerState();
            // playerVars: { start: 0, end: 0, iv_load_policy: 3, rel: 0, autoplay: 0, enablejsapi: 1 },
          }}
          containerClassName="video_dialog__container"
          id="yt-video-component"
          className="video_dialog__iframe"
        />
      ) : (
        <Spin />
      )}
      <DialogActions>
        {props.isPassed ? (
          <Button onClick={props.handleLaterClick} variant="text">
            {t("ui:button.ok")}
          </Button>
        ) : (
          <>
            <Button onClick={props.handleLaterClick} variant="text">
              {t("ui:button.later")}
            </Button>
            <Button disabled={!props.abilityToSave} onClick={props.onSaveProgress} variant="filled">
              {t("ui:button.studied_and_understood")}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default VideoDialogView;

import React, { memo, useCallback, useMemo } from "react";
import { ICardIssueView } from "./CardIssue.interface";
import { Indicator } from "../../../../../elements/indicator";
import { IndicatorIconsTypes } from "../../../../../elements/indicator/Indicator.interface";
import { colorSchemeType } from "../../../../../../functional/utils/colorScheme";
import { Card, Icon, IconButton, Tag, Text, Tooltip } from "../../../../../uiKit";
import { UserInfo } from "../../../../../elements/userInfo/UserInfo";
import { AiOutlineArrowRight } from "@react-icons/all-files/ai/AiOutlineArrowRight";
import { useDateHelpers, useRootStore } from "../../../../../../functional/hooks";
import "./CardIssue.scss";
import { truncateString } from "../../../../../../functional/helpers/stringFunctions";
import { removeDuplicatesFromArrayByKey } from "../../../../../../functional/helpers/arrayFunctions";
import { OrgchartCard } from "../../../../../elements/orgchartCard/OrgchartCard";
import clsx from "clsx";
import { LucideClock } from "lucide-react";
import { FlowTypeColorSchemeKey } from "../../constants/keys";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import { CalendarTime } from "../../../../../../functional/constants/icon";
import { CardIssueTag } from "../cardIssueTag/CardIssueTag";

export const CardIssueView = memo((props: ICardIssueView) => {

  const { t } = useTranslation();

  // const { board } = useContext(BoardFilterContext);
  const { authStore } = useRootStore();
  const { formatDate, formatTimeSpan } = useDateHelpers();

  const isCurrentUserInitiator = useMemo(
    () => props.issue.initiatorUserId === authStore.getInitialInfo?.identity?.id,
    [authStore.getInitialInfo?.identity?.id, props.issue.initiatorUserId]
  );

  const handleGetTimePlanValue = useCallback(() => {
    if (
      isCurrentUserInitiator &&
      props.issue.timePlanForApproval &&
      !props.issue.timePlanForApproval.includes("00:00:00")
    ) {
      return formatTimeSpan(props.issue.timePlanForApproval, { returnAs: "split" });
    }
    if (!isCurrentUserInitiator && props.issue.timePlan && !props.issue.timePlan.includes("00:00:00")) {
      return formatTimeSpan(props.issue.timePlan, { returnAs: "split" });
    }
    if (
      isCurrentUserInitiator &&
      !props.issue.timePlanForApproval &&
      props.issue.timePlan &&
      !props.issue.timePlan.includes("00:00:00")
    ) {
      return formatTimeSpan(props.issue.timePlan, { returnAs: "split" });
    }
  }, [formatTimeSpan, isCurrentUserInitiator, props.issue.timePlan, props.issue.timePlanForApproval]);

  // const currentPriority = useMemo(
  //   () => board?.priorities?.find((p) => p.id === props.issue.priority),
  //   [board?.priorities, props.issue.priority]
  // );

  const getHeadStyle = useCallback(() => {
    if (!props.issue.calculated?.border?.colorSchemeKey) {
      return undefined
    }
    const color = props.issue.calculated?.border?.colorSchemeKey === FlowTypeColorSchemeKey.Background
     ? "var(--color-txt-primary)"
     : "var(--color-txt-alwayswhite)";

    return {
        border: "none",
        padding: "2px 2px 2px 4px",
        fontWeight: "normal",
        color,
        minHeight: "30px",
      }
  }, [props.issue]);

  // const issueCard = useRef<HTMLDivElement | null>(null);
  // const [isShowContent, setIsShowContent] = useState(true);
  // const isShowContent = useRef<boolean>(true);

  //
  // useEffect(() => {
  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         // console.log('Элемент видим на экране', entry.intersectionRatio);
  //         if (issueCard.current) {
  //           issueCard.current.style.height = "";
  //           setIsShowContent(true);
  //           // isShowContent.current = true;
  //         }
  //       } else {
  //         if (issueCard.current) {
  //           issueCard.current.style.height = `${entry.boundingClientRect.height}px`;
  //           setIsShowContent(false);
  //           // isShowContent.current = false;
  //         }
  //       }
  //     });
  //   });
  //   console.log("issueCard.current", issueCard.current);
  //   if (issueCard.current) {
  //     observer.observe(issueCard.current);
  //   }
  // }, []);

  const cardDeadlineColor = useMemo(() => {
    if (props.issue.dateDeadline && props.issue?.status?.baseStatusKey !== 'done') {
      const today = dayjs(props.issue.dateDeadline);
      const diff = today.diff(new Date(), 'hour', true);
      if (diff < 0) {
        return "var(--color-txt-error)"
      } else if (diff <= 48) {
        return "var(--color-txt-warning)"
      }
    }
    return "var(--color-txt-secondary"
  }, [props.issue.dateDeadline]);

  const handleClickChangeDeadline = useCallback((e) => {
    e.stopPropagation();
    props.onClickChangeDeadline && props.onClickChangeDeadline();
  }, []);

  const isShowTooltip = useMemo(() => {
    return !isCurrentUserInitiator && props.issue.isStrictDeadline;
  }, [props.issue.isStrictDeadline, isCurrentUserInitiator]);

  const handleClickAddIssue = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    props.onAddIssue?.(props.issue.id!);
  };


  return (
    <Card
      onClick={props.onClick}
      hoverable
      bordered
      title={props.issue.calculated?.border?.key
        ? t(`ui:issue_border.${props.issue.calculated.border.key}`)
        : ""
      }
      headStyle={getHeadStyle()}
      bodyStyle={
        !!props.issue.calculated?.border?.colorSchemeKey
          ? {
              backgroundColor: "var(--color-srf-primary)",
              borderRadius: "var(--border-radius-sm)",
            }
          : {
          }
      }
      size="small"
      className={clsx("todo-list__card", "pa-1", "__border", {
        __warning: props.issue.calculated?.border?.colorSchemeKey === FlowTypeColorSchemeKey.Warning,
        __primary: props.issue.calculated?.border?.colorSchemeKey === FlowTypeColorSchemeKey.Primary,
        __success: props.issue.calculated?.border?.colorSchemeKey === FlowTypeColorSchemeKey.Success,
        __background: props.issue.calculated?.border?.colorSchemeKey === FlowTypeColorSchemeKey.Background,
      })}
    >
      <div
        className="issue-card"
        // ref={issueCard}
      >
          <div className="issue-card__content">
            <div className="d-stack-row justify-start align-start spacing-0">
              <div className="d-stack-column justify-start align-start spacing-1 flex-grow-1">
                <div className="issue-card__header d-stack-row align-center justify-center">

                  {props.issue.priority && (
                    <Tag
                      className="d-flex align-center justify-center"
                      bordered={false}
                      style={{
                        backgroundColor: `var(--color-priority${props.issue.priority}-bg)`,
                        color: `var(--color-priority${props.issue.priority}-text)`,
                        width: 24,
                        height: 24,
                        paddingInline: "4px",
                        textAlign: "center",
                      }}
                    >
                      {props.issue.priority}
                    </Tag>
                  )}
                  {handleGetTimePlanValue() ? (
                    <Tag
                      className="d-flex align-center justify-center"
                      style={{
                        height: 24,
                        padding: 4,
                        backgroundColor: "var(--color-srf-secondary)",
                        border: "none",
                        color: "var(--color-txt-primary)",
                      }}
                      icon={<LucideClock size={18} className="mr-1" color="var(--color-icn-default)" />}
                      bordered={false}
                    >
                      <Text size="12px" children={handleGetTimePlanValue() ?? ""} />
                    </Tag>
                  ) : null}
                  {props?.issue?.calculated?.indicators && props?.issue?.calculated?.indicators.length > 0 && (
                    <div className="d-stack-row justify-start align-center flex-wrap">
                      {removeDuplicatesFromArrayByKey(props?.issue?.calculated?.indicators, ["colorSchemeKey", "icon"]).map(
                        (indicator) => (
                          <div key={indicator.id!}>
                            <Indicator
                              name={t(`ui:issue_status.${indicator.key!}`)}
                              key={indicator.id!}
                              isSimplified
                              isFaint
                              // TODO: Add <Icon component={() => icon} /> component
                              icon={indicator.icon as IndicatorIconsTypes}
                              colorSchemeKey={indicator.colorSchemeKey as colorSchemeType}
                            />
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>

              </div>
              <div className="d-flex align-center">
                <Text className="todo-list__card-identifier">{`#${props.issue.subId}`}</Text>
                {props.onAddIssue && (
                  <IconButton
                    className="todo-list-plus ml-1"
                    onClick={handleClickAddIssue}
                  >
                    <FiPlus />
                  </IconButton>
                )}
              </div>
            </div>
            <div className="todo-list__card-task mb-3 mt-2">
              <p className="todo-list__card-name mb-0">{truncateString(props.issue.name ?? "", 96, "end", true)}</p>
            </div>
            <div
              className={"todo-list__card-footer d-stack-row align-center spacing-1 justify-space-between"}
            >
              <div className="d-stack-row align-center justify-start spacing-3 mt-2">
                <UserInfo avatarSize={24} nameDisplayMode="none" user={props.issue.initiatorUser!} />
                <AiOutlineArrowRight fontSize={22} color="var(--color-icn-default)" />
                <UserInfo avatarSize={24} nameDisplayMode="none" user={props.issue.executorUser!} />
              </div>
              {isShowTooltip ? (
                <Tooltip
                  placement="right"
                  className="card-issue-deadline-tooltip"
                  title={() => (
                    <div className="card-issue-deadline-tooltip-container">
                      <div className="card-issue-deadline-tooltip-container__title">
                        {t("ui:card_issue_deadline_tooltip.title")}
                      </div>
                      <div className="card-issue-deadline-tooltip-container__text">
                        {t("ui:card_issue_deadline_tooltip.text")}
                      </div>
                    </div>
                  )}
                  arrow={true}
                  trigger={"hover"}
                >
                  <Text
                    className="todo-list__card-deadline mt-2 mr-2"
                    style={{
                      color: cardDeadlineColor,
                    }}
                  >
                    {props.issue.dateDeadline && formatDate(props.issue.dateDeadline, { formatObject: { month: "short" } })}
                  </Text>
                </Tooltip>
              ) : (
                <Text
                  className="todo-list__card-deadline mt-2 mr-2"
                  style={{
                    color: cardDeadlineColor,
                  }}
                  onClick={handleClickChangeDeadline}
                >
                  {props.issue.dateDeadline
                    ? formatDate(props.issue.dateDeadline, { formatObject: { month: "short" } })
                    : (
                        <Tooltip title={t("ui:tooltip.set_deadline")}>

                          <Icon
                            className="todo-list__card-deadline-icon"
                            component={() => <CalendarTime/>}
                          />
                        </Tooltip>
                    )
                  }
                </Text>
              )}
            </div>
          </div>

          {(!!props.issue.tags?.length || !props.showOrgchartCard) && (
            <div className="issue-card__footer">
              {!props.showOrgchartCard && (
                <div className="d-stack-column spacing-2 card-issue-orgchart">
                  <div className="d-flex justify-end">
                    <OrgchartCard
                      boldName
                      avatarSize={24}
                      fontSize={12}
                      color={"var(--color-txt-secondary)"}
                      orgchart={{ name: props.issue.orgchartName, image: props.issue.orgchartImage }}
                      isReversedCardItems
                    />
                  </div>
                </div>
              )}
              {!!props.issue.tags?.length && (
                <div className="card-issue-tags-list">
                  {props.issue.tags.map((tag) => (
                    <CardIssueTag
                      key={tag.id}
                      tag={tag}
                    />
                  ))}
                </div>
              )}
            </div>
          )}
      </div>
    </Card>
  );
});

import React from "react";
import "./Button.scss";
import { Button } from "antd";
import { ButtonProps } from "antd/lib/button/button";
import clsx from "clsx";

interface IButtonView extends ButtonProps {
  theme?: "error" | "success" | "warning" | "info";
  variant?: "filled" | "outlined" | "text" | "link" | "default";
  allowTextWrap?: boolean;
  isActiveFilter?: boolean;
}

const getButtonType = (type: "filled" | "outlined" | "text" | "link" | "default" | undefined) => {
  switch (type) {
    case "filled":
      return "primary";
    case "outlined":
      return "primary";
    case "text":
      return "text";
    case "link":
      return "link";
    case "default":
      return undefined;
    default:
      return "primary";
  }
};

export function ButtonView({ theme, variant, className, allowTextWrap, isActiveFilter, ...rest }: IButtonView) {
  // const btnStyles = clsx("button_main btn-icon-mr btn-align", className)
  return (
    <Button
      // size="large"
      className={clsx("uiKit-button", className ?? "", `uiKit-button_${getButtonType(variant)}`,{
        _success: theme == "success",
        _warning: theme == "warning",
        "__text-wrap": !!allowTextWrap,
        "_active-filter": isActiveFilter,
      })}
      ghost={variant == "outlined"}
      danger={theme == "error"}
      type={getButtonType(variant)}
      {...rest}
    />
  );
}

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Icon } from "../../../../uiKit";
import { useTranslation } from "react-i18next";
import { GoogleIcon } from "../../../../../functional/constants/icon";
import { GoogleAuthDisconnect } from "./GoogleAuthDisconnect";
import { api } from "../../../../../functional/services";
import { useNotifier, useRootStore } from "../../../../../functional/hooks";
import { observer } from "mobx-react-lite";
import { SelectedGoogleCalendarDialog } from "./SelectedGoogleCalendarDialog/SelectedGoogleCalendarDialog";
import { IGoogleCalendar } from "./GoogleCalendar.interface";
import { useLocation } from "react-router-dom";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";

interface IGoogleCalendarAuthProps {
  isCalendarSettingsModal?: boolean;
  onCloseModal?: () => void;
  isHiddenDisconnectButton?: boolean;
  isSmallSwitch?: boolean;
  isHiddenShowEventsSwitch?: boolean;
}

export const GoogleAuthConnect = observer((
  {
    isCalendarSettingsModal,
    onCloseModal,
    isHiddenDisconnectButton,
    isSmallSwitch,
    isHiddenShowEventsSwitch
  }: IGoogleCalendarAuthProps
) => {
  const { authStore } = useRootStore();
  const notifier = useNotifier();

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const googleCalendarAccount = useMemo(() => {
    return authStore.getGoogleCalendarAccount ?? {
      hasToken: false,
      connectedToReadCalendar: false,
      connectedToWriteCalendar: false,
    } ;
  }, [authStore.getGoogleCalendarAccount]);

  const isShowGoogleEvents = useMemo(() => {
    return googleCalendarAccount.connectedToReadCalendar ?? false;
  }, [googleCalendarAccount]);

  const isConnectGoogleCalendar = useMemo(() => {
    return googleCalendarAccount?.hasToken ?? false
  }, [googleCalendarAccount]);

  const [isShowSelectedGoogleCalendarDialog, setIsShowSelectedGoogleCalendarDialog] = useState<boolean>(false);

  const openSelectedGoogleCalendarDialog = () => {
    setIsShowSelectedGoogleCalendarDialog(true);
  }

  const closeSelectedGoogleCalendarDialog = () => {
    setIsShowSelectedGoogleCalendarDialog(false);
  }

  const [googleCalendarList, setGoogleCalendarList] = useState<IGoogleCalendar[]>([]);

  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const handleSelectGoogleCalendar = async (id: string, isModal?: boolean) => {
    if (isModal) {
      setIsButtonLoading(true);
    }
    const resp = await api.googleCalendar.setCalendarId(id);
    if (isModal) {
      setIsButtonLoading(false);
    }
    if (resp === null) {
      handleError();
    } else {
      authStore.setGoogleCalendarAccount({
        ...googleCalendarAccount,
        hasToken: true,
        connectedToReadCalendar: true,
      });
      if (isModal) {
        closeSelectedGoogleCalendarDialog();
      }
      if (onCloseModal) {
        onCloseModal();
      }
    }
  }

  const fetchGoogleCalendars = async () => {
    const resp = await api.googleCalendar.getCalendarIno();
    if (resp) {
      const validForSelectionCalendars = resp.filter((calendar: IGoogleCalendar) => calendar.isValidForSelection);
      setGoogleCalendarList(validForSelectionCalendars);
    }
  };

  const onConnectReadingGoogleCalendar = (list?: IGoogleCalendar[]) => {
    const calendarList = list || googleCalendarList;
    console.log("calendarList", calendarList);
    if (!calendarList) {
      if (onCloseModal) {
        onCloseModal();
        setIsLoading(false);
      }
      return;
    }
    if (calendarList.length > 1) {
      openSelectedGoogleCalendarDialog();
    } else {
      const [defaultCalendar] = calendarList;
      handleSelectGoogleCalendar(defaultCalendar.id)
    }
  }

  const onToggleShowGoogleCalendarEvents = (status: boolean) => {
    if (status) {
      onConnectReadingGoogleCalendar();
    }
  }


  const onDisconnectReadingGoogleCalendar = async () => {
    const resp = await api.googleCalendar.disconnectReading();
    if (resp === null) {
      handleError();
    } else {
      authStore.setGoogleCalendarAccount({
        ...googleCalendarAccount,
        connectedToReadCalendar: false,
      });
    }
    return resp !== null;
  }

  const handleError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });


  useEffect(() => {
    console.log("useEffect []");
    fetchGoogleCalendars();
  }, []);

  const handleCloseGoogleAuthModal = async () => {
    console.log("handleCloseGoogleAuthModal");
    await authStore.fetchGoogleCalendarAccount();
    const resp = await api.googleCalendar.getCalendarIno();
    console.log("resp", resp);
    if (resp) {
      const calendarList = resp.filter((calendar: IGoogleCalendar) => calendar.isValidForSelection);
      console.log("calendarList", calendarList);
      setGoogleCalendarList(calendarList);
      onConnectReadingGoogleCalendar(calendarList);
    }
    if (!onCloseModal) {
      setIsLoading(false);
    }

  };

  const [authLink, setAuthLink] = useState<string | null>(null);

  const getAuthLink = async () => {
    const link = await api.googleCalendar.getInoUrl();
    setAuthLink(link);
    return link;
  }

  const handleClickAuth = async () => {
    setIsLoading(true);

    if (authLink) {
      window.open(authLink, "_blank");
      // @ts-ignore
      window.handleCloseGoogleAuthModal = handleCloseGoogleAuthModal;
    } else {
      const link = await getAuthLink();
      if (link) {
        window.open(link, "_blank");
        // @ts-ignore
        window.handleCloseGoogleAuthModal = handleCloseGoogleAuthModal;
      }


    }
  };

  useEffect(() => {
    getAuthLink();
  }, []);


  const { state } = useLocation();

  const googleCalendarButton = useRef<HTMLDivElement>(null);
  useEffect(() => {
    window.history.replaceState({}, '');
    if (state?.googleCalendarAuth) {
      googleCalendarButton.current?.scrollIntoView({
        behavior: "smooth",
      })
    }

  }, []);

  return (
    <div
      ref={googleCalendarButton}
      style={{ display: "flex", flexDirection: "column" }}
    >
      {isShowSelectedGoogleCalendarDialog && (
        <SelectedGoogleCalendarDialog
          closeModal={closeSelectedGoogleCalendarDialog}
          calendarList={googleCalendarList}
          isLoading={isButtonLoading}
          onSubmit={handleSelectGoogleCalendar}
        />
      )}
      {
        isConnectGoogleCalendar ? (
        <GoogleAuthDisconnect
          onToggle={onToggleShowGoogleCalendarEvents}
          onDisconnectCalendar={onDisconnectReadingGoogleCalendar}
          isShowGoogleEvents={isShowGoogleEvents}
          isHiddenDisconnectButton={isHiddenDisconnectButton}
          isSmallSwitch={isSmallSwitch}
          isHiddenShowEventsSwitch={isHiddenShowEventsSwitch}
        />
      ) : (
          <div className="google-auth-info">
            {isCalendarSettingsModal && (
              <div className="google-auth-info__title">
                <SubheaderText noBottomOffset text={t("ui:subheader.google_account.title")} />
              </div>
            )}
            <Button
              variant={"default"}
              onClick={handleClickAuth}
              loading={isLoading}
              style={{
                height: 36,
                width: isCalendarSettingsModal ? "auto" : "100%",
              }}
            >
              {t("ui:button.google_add_account")}
              <Icon
                style={{
                  fontSize: 16,
                }}
                children={
                  <GoogleIcon size={16} />
                }
              />
            </Button>
          </div>
        )
      }
    </div>
  );
});

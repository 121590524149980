import React, { useEffect, useState } from "react";
import { ModuleRegulationRestudyAccessView } from "./ModuleRegulationRestudyAccessView";
import { api } from "../../../../../functional/services";
import { UserShortDto } from "../../../../../functional/api";

interface IModuleRegulationRestudyAccessProps {
  //
}

export const ModuleRegulationRestudyAccess = (props: IModuleRegulationRestudyAccessProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [usersWhoCantResendToRestudy, setUsersWhoCantResendToRestudy] = useState<UserShortDto[]>([]);

  const loadUsersWhoCanResendToRestudy = async () => {
    setIsLoading(true);
    const r = await api.company.getUsersWithPermission("resend_regulation");
    if (r == null) {
      setIsLoading(false);
      return;
    }
    let _usersWhoCantResendToRestudy: UserShortDto[] = [];
    for await (const userId of r.userIds ?? []) {
      const u = await api.userShort.getById(userId);
      if (u != null) {
        _usersWhoCantResendToRestudy = [..._usersWhoCantResendToRestudy, u];
      }
    }
    setUsersWhoCantResendToRestudy(_usersWhoCantResendToRestudy);
    setIsLoading(false);
  };

  const handleUserAdd = async (userId: number | null) => {
    if (userId == null) return;
    setIsLoading(true);
    const r = await api.company.setUsersWithPermission({
      userIds: [
        ...new Set([...usersWhoCantResendToRestudy.map((u) => u.id), userId].filter((id) => id != null)),
      ] as number[],
      permission: "resend_regulation",
    });
    if (r != null) {
      setIsAddDialogOpen(false);
    }
    await loadUsersWhoCanResendToRestudy();
    setIsLoading(false);
  };

  const handleUserDelete = async (userId: number) => {
    setIsLoading(true);
    const r = await api.company.setUsersWithPermission({
      userIds: [
        ...new Set([...usersWhoCantResendToRestudy.map((u) => u.id)].filter((id) => id != null && id != userId)),
      ] as number[],
      permission: "resend_regulation",
    });
    await loadUsersWhoCanResendToRestudy();
    setIsLoading(false);
  };

  useEffect(() => {
    loadUsersWhoCanResendToRestudy();
  }, []);

  return (
    <ModuleRegulationRestudyAccessView
      isLoading={isLoading}
      isAddDialogOpen={isAddDialogOpen}
      usersWhoCantResendToRestudy={usersWhoCantResendToRestudy}
      setIsAddDialogOpen={setIsAddDialogOpen}
      onUserAdd={handleUserAdd}
      onUserDelete={handleUserDelete}
    />
  );
};

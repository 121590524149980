import React, { ChangeEvent, memo, MouseEvent, useCallback, useRef, useState } from "react";
import { Button, IconButton, Input, Popover } from "../../../../uiKit";
import { LexicalEditor } from "lexical";
import { useTranslation } from "react-i18next";
import { INSERT_IMAGE_COMMAND } from "../../plugins/ImagesPlugin/ImagesPlugin";
import { FileInput } from "../FileInput";
import { NodeType } from "../../types/types";
import { api } from "../../../../../functional/services";
import { LucideCheck, LucideImage } from "lucide-react";
import { ImageIcon } from "../../../../../functional/constants/icon";

interface ImagePopupProps {
  editor: LexicalEditor;
  isEditable: boolean;
  isLoadingChange: (value: boolean) => void;
  isNewStyle?: boolean;
}

export const ImagePopup = memo((props: ImagePopupProps) => {
  const [imageUrl, setImageUrl] = useState<string>("");
  const [isOpenImagePopup, setIsOpenImagePopup] = useState<boolean>(false);
  const { t } = useTranslation();

  const fileUploadRef = useRef<any>(null);

  const remoteImage = useCallback(
    async (files: FileList) => {
      for (const file of files) {
        const [mime] = file.type.split("/");
        if (mime === NodeType.Image) {
          props.isLoadingChange(true);
          const formData = new FormData();
          formData.append("files", file);
          const r = await api.staticFile.upload(formData, NodeType.Image);
          if (r === null) {
            props.isLoadingChange(false);
            return;
          } else {
            props.editor.dispatchCommand(INSERT_IMAGE_COMMAND, { src: r[0].url as string, altText: "" });
            props.isLoadingChange(false);
          }
        }
      }
    },
    [props]
  );

  const handleUploadRefClick = useCallback((event: MouseEvent) => {
    event.preventDefault();
    fileUploadRef.current.click();
  }, []);

  const handleUploadImage = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      await remoteImage(event.target.files as FileList);
      fileUploadRef.current.value = "";
    },
    [remoteImage]
  );

  const handleUrlChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setImageUrl(event.target.value);
  }, []);

  const handleClick = useCallback(() => {
    props.editor.dispatchCommand(INSERT_IMAGE_COMMAND, { src: imageUrl, altText: "" });
    setIsOpenImagePopup(false);
    setImageUrl("");
  }, [imageUrl, props.editor]);

  const handleOpenChange = useCallback(() => {
    if (isOpenImagePopup) {
      setImageUrl("");
    }
    setIsOpenImagePopup(!isOpenImagePopup);
  }, [isOpenImagePopup]);

  return (
    <Popover
      placement="bottom"
      trigger="click"
      open={isOpenImagePopup}
      onOpenChange={handleOpenChange}
      content={
        <div className="d-stack-column spacing-2">
          <div className="d-stack-row align-center justify-center">
            <Input
              size="middle"
              // status={imageUrlValidation(imageUrl) || !imageUrl ? "" : "error"}
              // status={imageUrl ? "" : "error"}
              value={imageUrl}
              onChange={handleUrlChange}
              placeholder={t("ui:placeholder.text_editor_url")}
            />
            <IconButton
              className="ml-3"
              icon={<LucideCheck size={18} />}
              onClick={handleClick}
              // disabled={!imageUrlValidation(imageUrl) || !imageUrl}
              disabled={!imageUrl}
            />
          </div>
          <Button onClick={(e: any) => handleUploadRefClick(e)}>{t("ui:button.upload_from_pc")}</Button>
        </div>
      }
    >
      <IconButton
        onClick={() => setIsOpenImagePopup(true)}
        title={t("common:text_editor_toolbar.image")}
        icon={props.isNewStyle ? <ImageIcon/> : <LucideImage size={18} />}
      />
      <FileInput ref={fileUploadRef} label="Image Upload" onChange={handleUploadImage} accept="image/*" />
    </Popover>
  );
});

import React from "react";
import "./UserSelectionChip.scss";
import { Tag } from "../../uiKit";
import { OrgchartCard } from "../orgchartCard/OrgchartCard";
import { OrgchartDto } from "../../../functional/api";

interface IUserSelectionChipView {
  id: number;
  text1?: string;
  divider?: string;
  text2?: string;
  isAll: boolean;
  orgchart?: OrgchartDto | null;
  style?: React.CSSProperties;
  onRemove: (id: number) => void;
}

export const UserSelectionChipView = (props: IUserSelectionChipView) => {
  const handleGetOrgchartAvatar = (o?: OrgchartDto | null): JSX.Element | null => {
    if (!o) return null;
    return <OrgchartCard orgchart={o} isAvatarOnly showTooltip />;
  };

  return (
    // TODO: Fix via CSS
    <Tag
      closable
      key={props.id}
      className="badge-being-deleted d-stack-row align-center spacing-2"
      onClose={() => props.onRemove(props.id)}
      color="var(--color-layout-fill-base)"
      style={props.style}
    >
      {handleGetOrgchartAvatar(props.orgchart)}
      <span
        className="badge-being-deleted__text"
        children={[
          props.text1 != null && props.text1.trim().length > 0 ? props.text1.trim() : "",
          !props.isAll && props.text2 != null && props.text2.trim().length > 0 ? props.text2.trim() : "",
        ]
          .filter((item) => item.length > 0)
          .join(props.divider ?? " – ")}
      />
      {/*{props.text1 != null && props.text1.length > 0 ? truncateString(props.text1, 30) : null}*/}
    </Tag>
  );
};

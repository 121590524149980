import React from "react";
import { Icon, Text, Tooltip } from "../../uiKit";
import { LuHourglass } from "react-icons/lu";
import { TimeCount } from "../timeCount/TimeCount";
import { useTranslation } from "react-i18next";
import { IEndAccessTimeCount } from "./EndAccessTimeCount.interface";

export const EndAccessTimeCount = (props: IEndAccessTimeCount) => {
  const { t } = useTranslation();

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const targetTime = new Date(props.endAccessTime).getTime();
    const difference = targetTime - now;
    if (difference < 0) {
     return {
       days: 0,
       hours: 0,
       minutes: 0,
     }
    }
    return {
        days: Math.max(0, Math.floor(difference / (1000 * 60 * 60 * 24))),
        hours: Math.max(0, Math.floor((difference / (1000 * 60 * 60)) % 24)),
        minutes: Math.max(0, Math.floor((difference / (1000 * 60)) % 60)),
      };
  }

  const tooltipText = () => {
    const { days, hours, minutes } = calculateTimeLeft();
    return (
      <div>
        <div>{t("ui:end_access_time_count.title")}</div>
        <div>{`${days}${t("parse:date_helper.day")} ${hours}${t("parse:date_helper.hour")} ${minutes}${t("parse:date_helper.minute")}`}</div>
      </div>)
  }

  return (
    <div className="end-access-time mb-4">
      {props.isCollapsed
        ? (
          <div
            className="d-flex justify-center align-items-center"
            style={{
              fontSize: 16,
              height: 36,
            }}
          >
            <Tooltip
              title={tooltipText()}
              placement="right"
            >
              <Icon>
                <LuHourglass color={"var(--color-text-second)"}/>
              </Icon>
            </Tooltip>
          </div>
        ) : (
        <>
          <div className="end-access-time__header d-flex justify-center align-center mb-2">
            <Icon className={"mr-1"}>
              <LuHourglass color={"var(--color-text-second)"}/>
            </Icon>
            <Text
              style={{
                fontSize: 12,
                lineHeight: "14px",
                whiteSpace: "nowrap"
              }}
            >
              { t("ui:end_access_time_count.title") }
            </Text>
          </div>
          <TimeCount
            endDate={props.endAccessTime!}
            isHideSecond
          />
        </>
    ) }
    </div>
  )
}

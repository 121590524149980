import React, { memo } from "react";
import { Select, SelectProps } from "antd";
import "./Select.scss";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Icon } from "../icon/Icon";
import { BsFilter } from "@react-icons/all-files/bs/BsFilter";
import { ArrowDownIcon } from "../../../functional/constants/icon";
import { ISelectItem, ISelectView } from "../../../functional/types/Select.Interface";

function generateOptions(items: ISelectItem[]) {
  // const { devStore } = useRootStore();

  return items.map((option, index) => (
    <Select.Option
      value={option.value}
      key={option.id ?? option.value}
      disabled={option.disabled}
      title={option.text}
      {...option.additionalProps}
    >
      <div className="d-flex align-center full-width">
        {option.icon != null && (
          <Icon style={{ flexShrink: 0 }} className="mr-1" component={() => option.icon as React.ReactElement} />
        )}
        <span style={{ overflow: "hidden", textOverflow: "ellipsis" }} children={option.text} />
      </div>
    </Select.Option>
  ));
}

export const SelectView= memo((
  {
    items,
    className,
    readOnly,
    variant,
    fullWidth,
    isSelectedDisabled,
    isNewDesign,
    isNoBorder,
    suffixIcon,
    isBigSize,
    ...rest
  }: ISelectView) => {
  const { t } = useTranslation();

  return (
    <Select
      placeholder={t("ui:placeholder.click_to_select")}
      className={clsx("uiKit-select", className ?? "", {
        "no-interact": readOnly,
        __filled: variant == "filled",
        "full-width": fullWidth,
        "uiKit-select_disabled": isSelectedDisabled,
        "uiKit-select_new-design": isNewDesign,
        "uiKit-select_no-border": isNoBorder,
        "uiKit-select_big": isBigSize,
      })}
      size="large"
      optionFilterProp="children"
      suffixIcon={isNewDesign ? suffixIcon ?? (<ArrowDownIcon size={24} />) : suffixIcon}
      filterOption={(input, option) => {
        return option?.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      {...rest}
      children={items ? generateOptions(items) : rest.children}
    />
  );
});

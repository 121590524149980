import React, { memo, useMemo } from "react";
import { IssueActivityText } from "../issueActivityText/issueActivityText";
import { IIssueActivityTextRow } from "../../../../../../../../functional/types/IssueActivityTextRow.interface";
import { Icon } from "../../../../../../../uiKit";
import { ChevronRight } from "lucide-react";
import "./IssueActivityTextRow.scss";
import { useTranslation } from "react-i18next";
import { emptyText, initialEditorContent, serverInitialEditorContent } from "../../../../../../../../functional/utils/textEditor";

export const IssueActivityTextRow = memo((props: IIssueActivityTextRow) => {
  const { t } = useTranslation();
  console.log("props.prevValue", props.prevValue);
  console.log("initialEditorContent", initialEditorContent);
  console.log("props.value", props.value === serverInitialEditorContent);
  const isEmptyPrevText = useMemo(() => {
    return !props.prevValue ||
      props.prevValue === initialEditorContent ||
      props.prevValue === serverInitialEditorContent ||
      props.prevValue === emptyText
      ;
  },[props.prevValue]);

  const isEmptyValueText = useMemo(() => {
    return !props.value ||
      props.value === initialEditorContent ||
      props.value === serverInitialEditorContent ||
      props.value === emptyText
  },[props.value]);

  const valueText = useMemo(() => {
    const value = props.value && props.isTime && props.isInlineText
      ?  props.value.split(":").slice(0, 2).join(":")
      : props.value
    return isEmptyValueText ? t("parse:no") : value;
  }, [isEmptyValueText, props.value, props.isTime, props.isInlineText]);

  const prevText = useMemo(() => {
    const value = props.prevValue && props.isTime && props.isInlineText
      ?  props.prevValue.split(":").slice(0, 2).join(":")
      : props.prevValue
    return isEmptyPrevText ? t("parse:no") : value;
  }, [isEmptyPrevText, props.prevValue, props.isTime, props.isInlineText]);


  const prevTextBackground = useMemo(() => {
    return props.prevValue ? "var(--color-calendar-default1)" : undefined
  }, [props.prevValue]);

  const textBackground = useMemo(() => {
    if (isEmptyValueText) {
      return undefined;
    }
    return props.prevValue ? "var(--color-calendar-primary-weaker1)" : "var(--color-calendar-default1)"
  }, [props.prevValue]);

  return (
    <div className={"issue-activity-text-row"}>
      <IssueActivityText
        background={prevTextBackground}
        text={prevText}
        isInlineText={!isEmptyPrevText ? props.isInlineText : true}
        color={isEmptyPrevText ? "var(--color-primary-weaker-second)" : undefined}
      />
      <Icon
        component={() => <ChevronRight size={16}/>}
      />
      <IssueActivityText
        background={textBackground}
        text={valueText}
        isInlineText={props.isInlineText}
        color={isEmptyValueText ? "var(--color-primary-weaker-second)" : undefined}
      />
    </div>
 )
})

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi, useDateHelpers, useNotifier, useRootStore } from "../../../functional/hooks";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import { IReportPage } from "./ReportPage.interface";
import { api } from "../../../functional/services";
import { IssueReportDto } from "../../../functional/api";
import { ReportsCommunication } from "../../modules/pages/report/pages/reportsCommunication/ReportsCommunication";
import { reportsTypesTypes } from "../../modules/pages/report/Report.interface";
import AdminDriverPeriodSelectDialog from "../../modules/dialogs/adminDriverPeriodSelectDialog/AdminDriverPeriodSelectDialog";
import { isEqualDate } from "../../../functional/helpers/dateСomparison";
import { FiCalendar } from "@react-icons/all-files/fi/FiCalendar";
import { Avatar, Button, Divider, Icon, Select, SelectOption, TabPane, Tabs, Tag } from "../../uiKit";
import { useSearchParams } from "react-router-dom";
import { theme } from "antd";
import { DotsLoader } from "../../uiKit/dotsLoader/DotsLoader";

function ReportCommunicationPage({ byDriver }: IReportPage) {
  const { t } = useTranslation();
  const notifier = useNotifier();
  const dateHelpers = useDateHelpers();
  const interval = 1000 * 60 * 60 * 24;
  let startOfDay = Math.floor(Date.now() / interval) * interval;
  let endOfDay = startOfDay + interval - 1;

  const defaultRange = {
    from: new Date(startOfDay).toISOString(),
    to: new Date(endOfDay).toISOString(),
  };

  const [date, setDate] = useState<{ from: string; to: string }>(defaultRange);
  const [selectDate, setSelectDate] = useState<{ from: string; to: string }>(defaultRange);
  const [dateButtonText, setDateButtonText] = useState<string>("");
  const [tabKey, setTabKey] = useState<string>("1");
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const { orgchartStore } = useRootStore();

  const [searchParams, setSearchParams] = useSearchParams();

  function parseQueryFilters() {
    const orgchartId = searchParams.get("orgchartId") ?? null;
    return {
      orgcharts: orgchartId ? +orgchartId : null,
    }
  }
  const [orgchartFilter, setOrgchartFilter] = useState<number | null>(parseQueryFilters().orgcharts ?? null);


  useEffect(() => {
    if (orgchartFilter) {
      searchParams.set("orgchartId", orgchartFilter.toString())
    } else {
      searchParams.delete("orgchartId");
    }
    setSearchParams(searchParams);
  }, [orgchartFilter]);


  const { useToken } = theme;
  const { token } = useToken();


  const handleApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
  const reportCustomRule = useApi(
    () =>
      api.report.customRule({
        dateFrom: date.from,
        dateTo: date.to,
      }),
    handleApiError
  );

  const reportViolation = useApi(() => api.report.violation({
    dateFrom: date.from,
    dateTo: date.to,
  }), handleApiError);

  const getActualDateISO = (value: string): string => {
    // костыль собачий, переношу конец дня не несколько часов назад, потому что без этого тупой mui пикер перекидывает на некст дату -_-
    return new Date(Math.floor(new Date(value).setMinutes(1) / interval) * interval + interval - 1).toISOString();
  };

  const handleSelectTimeRange = (value: { from: string; to: string }) => {
    // mui picker ставит выбранный день на начало дня, строчкой ниже я выбранную дату переношу на конец дня
    value.to = getActualDateISO(value.to);
    setDate(value);
  };

  const onFetchReports = () => {
    onCloseDialog();
    const promises: Promise<IssueReportDto | null>[] = [reportCustomRule.fetch(), reportViolation.fetch()];
    Promise.all(promises).then(() => {
      setSelectDate(date);
    });

    // reportCustomRule.fetch();
    // reportViolation.fetch();
  };

  const onOpenDialog = () => {
    setOpenDialog(true);
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const fromYear = new Date(selectDate.from).getFullYear();
    const toYear = new Date(selectDate.from).getFullYear();
    // TODO: Check calculations
    // Если выбран один день
    // @ts-ignore
    if (isEqualDate(new Date(selectDate.from), new Date(new Date(selectDate.to) - 19000000), false, false)) {
      setDateButtonText(
        dateHelpers.formatDate(selectDate.from, {
          formatObject: {
            month: "short",
            hour: undefined,
            minute: undefined,
          },
        }) as string
      );
    } else {
      setDateButtonText(
        dateHelpers.formatDate(selectDate.from, {
          showYearWhenIsCurrent: fromYear != currentYear || toYear != currentYear,
          formatObject: {
            hour: undefined,
            minute: undefined,
            month: "short",
          },
        }) +
          " - " +
          dateHelpers.formatDate(
            new Date(Math.floor(new Date(selectDate.to).setMinutes(1) / interval) * interval).toISOString(),
            {
              showYearWhenIsCurrent: fromYear != currentYear || toYear != currentYear,
              formatObject: {
                hour: undefined,
                minute: undefined,
                month: "short",
              },
            }
          )
      );
    }
  }, [selectDate]);

  const isLoadingByTabe =
    tabKey == "1"
      ? reportCustomRule.isLoading || !reportCustomRule?.value
      : reportViolation.isLoading || !reportViolation.value;

  return (
    <>
      <AdminDriverPeriodSelectDialog
        value={{
          from: date.from,
          // @ts-ignore
          to: new Date(new Date(date.to) - 19000000).toISOString(),
        }}
        open={openDialog}
        onClose={onCloseDialog}
        onChange={handleSelectTimeRange}
        onSave={onFetchReports}
        disableFuture
      />
      <PageHeader title={t("common:page_title.report_communication")}>
        <Select
          style={{ minWidth: "220px" }}
          value={orgchartFilter}
          onChange={(value) => setOrgchartFilter(value)}
          className="orgchart-select-employee"
          autoFocus={false}
          bordered={false}
          showSearch={false}
          showArrow={true}
          maxTagCount={4}
          maxTagTextLength={12}
          dropdownRender={(menu) => (
            <>
              <Button onClick={() => setOrgchartFilter(null)} variant="text" className="full-width br-none">
                {t("parse:all")}
              </Button>
              <Divider className="mb-1" />
              {menu}
            </>
          )}
          tagRender={(p) => {
            const { label, value } = p;
            return (
              <Tag
                style={{
                  borderRadius: "24px",
                  paddingLeft: "0",
                  border: `1px solid ${token.colorFill}`,
                  backgroundColor: token.colorFillAlter,
                }}
              >
                {label}
              </Tag>
            );
          }}
        >
          {orgchartStore.getOrgchartsList.map((o) => (
            <SelectOption key={o.id} value={o.id as number}>
              <span className="mr-2">
                <Avatar
                  size="small"
                  color={o.colorHex}
                  text={o.name}
                  src={o.image?.url}
                  isNoFlex
                />
              </span>
              <span children={o.name} />
            </SelectOption>
          ))}
        </Select>
      </PageHeader>
      <PageContent isFilled>
        <Tabs
          destroyInactiveTabPane
          tabBarExtraContent={{
            right: (
              <Button
                icon={<Icon component={() => <FiCalendar />} />}
                disabled={isLoadingByTabe}
                onClick={onOpenDialog}
                variant="filled"
                className="mr-2 mb-2"
              >
                {dateButtonText}
              </Button>
            ),
          }}
          activeKey={tabKey}
          onChange={(tk) => setTabKey(tk)}
        >
          <TabPane
            destroyInactiveTabPane
            tab={
              <div className="px-2">
                { t("ui:subheader.by_statuses") }
              </div>
            }
            key="1"
          >
            <div style={{ minHeight: 330 }}>
              {reportCustomRule.value?.items && reportCustomRule.value?.users && (
                <ReportsCommunication
                  selectDate={selectDate}
                  reportsType={reportsTypesTypes.customRule}
                  reports={reportCustomRule.value?.items}
                  users={reportCustomRule.value.users}
                  orgchartFilter={orgchartFilter}
                />
              )}
            </div>
          </TabPane>
          <TabPane destroyInactiveTabPane tab={<div className="px-2">{t("ui:subheader.by_violations")}</div>} key="2">
            <div style={{ minHeight: 330 }}>
              {reportViolation.value?.items && reportViolation?.value && (
                <>
                  <ReportsCommunication
                    selectDate={selectDate}
                    reportsType={reportsTypesTypes.violations}
                    reports={reportViolation.value?.items.filter((item) => item.key != "hm3")}
                    users={reportViolation?.value.users}
                    orgchartFilter={orgchartFilter}
                  />
                  <h2
                    style={{
                      lineHeight: "1",
                      margin: "8px 0px",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    {t("ui:subheader.by_activity")}
                  </h2>
                  <ReportsCommunication
                    selectDate={selectDate}
                    reportsType={reportsTypesTypes.violations}
                    reports={reportViolation.value?.items.filter((item) => item.key == "hm3")}
                    users={reportViolation?.value.users}
                    orgchartFilter={orgchartFilter}
                  />
                </>
              )}
            </div>
          </TabPane>
        </Tabs>
        <div style={{ position: "relative" }}>{isLoadingByTabe && (
          <DotsLoader
            text={t("text:report_loading")}
          />
        )}</div>
      </PageContent>
    </>
  );
}

export default ReportCommunicationPage;

import React, { memo, useCallback, useMemo } from "react";
import { Button, Dialog, DialogActions, InputNumber, Tooltip } from "../../../uiKit";
import { useTranslation } from "react-i18next";
import { truncateString } from "../../../../functional/helpers/stringFunctions";
import { TDashboardChartDataItem } from "../../pages/dashboards/dashboardChart/DashboardChart";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";

interface IUpdateMetricDialogView {
  open: boolean;
  onToggleDialogVisibility: (value: boolean) => void;
  onUpdateClick: () => void;
  onChangeCurrentMetricData: (value: TDashboardChartDataItem | null) => void;
  value: TDashboardChartDataItem | null;
  metricData: {
    name: string | null;
    date: string | null;
    isQuotaAvailable: boolean;
  };
}

export const UpdateMetricDialogView = memo((props: IUpdateMetricDialogView) => {
  const { t } = useTranslation();

  const handleGetTitle = useMemo(() => {
    return (
      truncateString(props.metricData.name ?? "", 25, "end") +
      (props.metricData.date != null ? " - " + props.metricData.date : "")
    );
  }, [props.metricData.date, props.metricData.name]);

  const handleClose = useCallback(() => {
    props.onToggleDialogVisibility(false);
  }, [props]);

  const handleUpdate = useCallback(() => {
    props.onUpdateClick();
  }, [props]);

  const handleMetricValueChange = useCallback(
    (
      v: ValueType | null,
      l: {
        id: number;
        name: string;
        color?: string | undefined;
        isReadOnly?: boolean | undefined;
        value: number | null;
      }
    ) => {
      props.onChangeCurrentMetricData(
        props.value != null
          ? {
              ...props.value,
              charts: {
                ...props.value.charts,
                line: [
                  ...props.value.charts.line.map((c) =>
                    c.id == l.id
                      ? {
                          ...c,
                          value: v == null ? null : Number(v.toString().substring(0, 15)),
                        }
                      : c
                  ),
                ],
              },
            }
          : null
      );
    },
    [props]
  );

  const handleMetricQuotaChange = useCallback(
    (v: string) => {
      props.onChangeCurrentMetricData(
        props.value != null
          ? {
              ...props.value,
              charts: {
                ...props.value?.charts,
                quota: v.length == 0 ? null : Number(v),
              },
            }
          : null
      );
    },
    [props]
  );

  return (
    <Dialog title={handleGetTitle} width={500} open={props.open} destroyOnClose onClose={handleClose} closable={false}>
      {props.value != null && (
        <div className="d-stack-column spacing-4">
          {props.value?.charts.line.map((l) => (
            <div key={l.id}>
              <Tooltip title={l.name ?? l.id ?? ""} placement="topLeft">
                <div>
                  <SubheaderText text={<span>{`${t("parse:value")} (${l.name ?? l.id})`}</span>} />
                </div>
              </Tooltip>
              <InputNumber
                className="full-width"
                placeholder={t("parse:enter_value")}
                value={l.value ?? undefined}
                max={999999999999999}
                readOnly={l.isReadOnly}
                onChange={(v) => handleMetricValueChange(v, l)}
              />
            </div>
          ))}
          {props.metricData.isQuotaAvailable && (
            <div>
              <SubheaderText text={t("parse:quantitative_plan")} />
              <InputNumber
                className="full-width"
                placeholder={t("parse:enter_value")}
                value={props.value?.charts.quota ?? undefined}
                max={999999999999999}
                onInput={handleMetricQuotaChange}
              />
            </div>
          )}
        </div>
      )}
      <DialogActions>
        <Button onClick={handleClose} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button onClick={handleUpdate} variant="filled">
          {t("ui:button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

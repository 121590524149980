import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $isTextNode,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import {
  $findMatchingParent,
  $getNearestBlockElementAncestorOrThrow,
  $getNearestNodeOfType,
  mergeRegister,
} from "@lexical/utils";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { $isListNode, ListNode } from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isHeadingNode, $isQuoteNode } from "@lexical/rich-text";
import { $getSelectionStyleValueForProperty, $patchStyleText } from "@lexical/selection";
import * as React from "react";
import { memo, useCallback, useEffect, useState } from "react";
import { getSelectedNode } from "../../utils/getSelectedNode";
import { AlignFormatType, BlockFormatType, ListFormatType } from "../../../../../functional/types/FormatSelect.interface";
import { ToolbarPluginView } from "./ToolbarPluginView";
import { INSERT_ANCHOR_COMMAND } from "../AnchorPlugin/AnchorPlugin";
import { useNotifier } from "../../../../../functional/hooks";
import { useTranslation } from "react-i18next";
import { TextFormatType } from "../../types/types";
import { InsertTableDialog } from "../../components/TableDialog";
import { IToolbarPlugin } from "./ToolbarPlugin.interface";
import { CommunicationsDynamicToolbar } from "./CommunicationsDynamicToolbar/CommunicationsDynamicToolbar";
import { INSERT_EMOJI_COMMAND } from "../EmojisPlugin/EmojiPlugin";
import { $isDecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode";
import { InsertVideoDialog } from "../../ui/InsertVideoDialog/InsertVideoDialog";

export const ToolbarPlugin = memo((props: IToolbarPlugin) => {
  const [editor] = useLexicalComposerContext();
  const notifier = useNotifier();
  const { t } = useTranslation();
  const [activeEditor, setActiveEditor] = useState<LexicalEditor>(editor);
  const [blockType, setBlockType] = useState<BlockFormatType>(BlockFormatType.PARAGRAPH);
  const [alignType, setAlignType] = useState<AlignFormatType>(AlignFormatType.LEFT);
  const [listType, setListType] = useState<ListFormatType | null>(null);
  // const [fontSize, setFontSize] = useState<string>("15px");
  const [fontColor, setFontColor] = useState<string>("#000");
  const [bgColor, setBgColor] = useState<string>("#fff");
  // const [fontFamily, setFontFamily] = useState<string>("Arial");
  const [isLink, setIsLink] = useState<boolean>(false);
  const [isTable, setIsTable] = useState<boolean>(false);
  const [isBold, setIsBold] = useState<boolean>(false);
  const [isItalic, setIsItalic] = useState<boolean>(false);
  const [isUnderline, setIsUnderline] = useState<boolean>(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isSubscript, setIsSubscript] = useState(false);
  const [isSuperscript, setIsSuperscript] = useState(false);
  const [isCode, setIsCode] = useState<boolean>(false);
  const [canUndo, setCanUndo] = useState<boolean>(false);
  const [canRedo, setCanRedo] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState(() => editor.isEditable());
  const [linkUrl, setLinkUrl] = useState<string>("");
  const [isOpenLinkPopup, setIsOpenLinkPopup] = useState<boolean>(false);
  const [isActiveLinkButton, setIsActiveLinkButton] = useState<boolean>(false);
  const [isOpenTableDialog, setIsOpenTableDialog] = useState<boolean>(false);
  const [isOpenVideoDialog, setIsOpenVideoDialog] = useState<boolean>(false);
  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat(TextFormatType.BOLD));
      setIsItalic(selection.hasFormat(TextFormatType.ITALIC));
      setIsUnderline(selection.hasFormat(TextFormatType.UNDERLINE));
      setIsStrikethrough(selection.hasFormat(TextFormatType.STRIKETHROUGH));
      setIsSubscript(selection.hasFormat(TextFormatType.SUBSCRIPT));
      setIsSuperscript(selection.hasFormat(TextFormatType.SUPERSCRIPT));
      setIsCode(selection.hasFormat(TextFormatType.CODE));
      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();

      if (node.getParents().find((v) => v.__type === "table")) {
        setIsTable(true);
      } else {
        setIsTable(false);
      }
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
        $isLinkNode(parent) ? setLinkUrl(parent.getURL()) : setLinkUrl(node.getURL());
      } else {
        setIsLink(false);
        setLinkUrl("");
      }
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
          const type = parentList ? parentList.getListType() : element.getListType();
          if (Object.values(BlockFormatType).includes(type as BlockFormatType)) {
            setBlockType(type as BlockFormatType);
          }
          if (Object.values(ListFormatType).includes(type as ListFormatType)) {
            setListType(type as ListFormatType);
          } else {
            setListType(null);
          }
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          if (Object.values(BlockFormatType).includes(type as BlockFormatType)) {
            setBlockType(type as BlockFormatType);
          }
          if (Object.values(ListFormatType).includes(type as ListFormatType)) {
            setListType(type as ListFormatType);
          } else {
            setListType(null);
          }
          // if ($isCodeNode(element)) {
          //   const language = element.getLanguage() as keyof typeof CODE_LANGUAGE_MAP;
          //   // setCodeLanguage(language ? CODE_LANGUAGE_MAP[language] || language : "");
          //   return;
          // }
        }
      }
      const alignByNum: { [key: number]: AlignFormatType } = {
        0: AlignFormatType.LEFT,
        1: AlignFormatType.LEFT,
        2: AlignFormatType.CENTER,
        3: AlignFormatType.RIGHT,
        4: AlignFormatType.JUSTIFY,
      };

      const align = element.getFormat();

      if (align in alignByNum) {
        setAlignType(alignByNum[align]);
      }

      // Handle buttons
      // setFontSize($getSelectionStyleValueForProperty(selection, "font-size", "15px"));
      setIsActiveLinkButton(node.__text && node.__text.trim().length);
      setFontColor($getSelectionStyleValueForProperty(selection, "color", "#000"));
      setBgColor($getSelectionStyleValueForProperty(selection, "background-color", "#fff"));
      // setFontFamily($getSelectionStyleValueForProperty(selection, "font-family", "Arial"));
    }
  }, [activeEditor]);

  const applyStyleText = useCallback(
    (styles: Record<string, string>) => {
      activeEditor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, styles);
        }
      });
    },
    [activeEditor]
  );

  const onFontColorSelect = useCallback(
    (value: string) => {
      applyStyleText({ color: value });
    },
    [applyStyleText]
  );

  const onBgColorSelect = useCallback(
    (value: string) => {
      applyStyleText({ "background-color": value });
    },
    [applyStyleText]
  );

  const insertLink = useCallback(() => {
    if (!isLink) {
      setIsOpenLinkPopup(true);
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  const handleCreateAnchor = useCallback(
    (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      const anchorId = window.prompt(t("ui:placeholder.create_anchor"));
      if (!anchorId?.length || !anchorId?.match(/^[0-9a-zA-Z]+$/)) {
        return notifier.show({
          message: t("notifier:error.anchor_validation"),
          theme: "error",
        });
      }
      if (anchorId?.length && anchorId?.match(/^[0-9a-zA-Z]+$/)) {
        activeEditor.dispatchCommand(INSERT_ANCHOR_COMMAND, anchorId);
      }
    },
    [activeEditor, notifier, t]
  );

  const handleAddEmoji = useCallback(
    (emoji: string) => {
      activeEditor.dispatchCommand(INSERT_EMOJI_COMMAND, emoji);
    },
    [activeEditor]
  );

  const handleLinkUrlChange = useCallback((url: string) => {
    setLinkUrl(url);
  }, []);

  const handleIsOpenLinkPopupChange = useCallback((value: boolean) => {
    setIsOpenLinkPopup(value);
  }, []);

  const handleIsOpenTableDialogChange = useCallback((value: boolean) => {
    setIsOpenTableDialog(value);
  }, []);

  const handleOpenVideoDialog = useCallback(() => {
    setIsOpenVideoDialog(true);
  }, []);

  const handleCloseVideoDialog = useCallback(() => {
    setIsOpenVideoDialog(false);
  }, []);

  const handleClearFormatting = useCallback(() => {
    activeEditor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const anchor = selection.anchor;
        const focus = selection.focus;
        const nodes = selection.getNodes();

        if (anchor.key === focus.key && anchor.offset === focus.offset) {
          return;
        }

        nodes.forEach((node, idx) => {
          // We split the first and last node by the selection
          // So that we don't format unselected text inside those nodes
          if ($isTextNode(node)) {
            if (idx === 0 && anchor.offset !== 0) {
              node = node.splitText(anchor.offset)[1] || node;
            }
            if (idx === nodes.length - 1) {
              node = node.splitText(focus.offset)[0] || node;
            }

            if (node.__style !== "") {
              node.setStyle("");
            }
            if (node.__format !== 0) {
              node.setFormat(0);
              $getNearestBlockElementAncestorOrThrow(node).setFormat("");
            }
          } else if ($isHeadingNode(node) || $isQuoteNode(node)) {
            node.replace($createParagraphNode(), true);
          } else if ($isDecoratorBlockNode(node)) {
            node.setFormat("");
          }
        });
      }
    });
  }, [activeEditor]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener((editable) => {
        setIsEditable(editable);
      }),
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      activeEditor.registerCommand<boolean>(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      activeEditor.registerCommand<boolean>(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [activeEditor, editor, updateToolbar]);

  return (
    <>
      <InsertTableDialog
        activeEditor={activeEditor}
        onClose={() => handleIsOpenTableDialogChange(false)}
        open={isOpenTableDialog}
      />
      <InsertVideoDialog
        editor={activeEditor}
        isLoadingChange={props.isLoadingChange}
        onOpen={handleOpenVideoDialog}
        onClose={handleCloseVideoDialog}
        isOpen={isOpenVideoDialog}
      />
      {props.isSimplifiedToolbar ? (
        <CommunicationsDynamicToolbar
          isSimplifiedToolbar={props.isSimplifiedToolbar}
          alignType={alignType}
          listType={listType}
          insertLink={insertLink}
          isBold={isBold}
          activeLinkButton={isActiveLinkButton}
          isEditable={isEditable}
          blockType={blockType}
          activeEditor={activeEditor}
          isLink={isLink}
          fontColor={fontColor}
          isItalic={isItalic}
          isUnderline={isUnderline}
          linkUrl={linkUrl}
          onFontColorSelect={onFontColorSelect}
          openLinkPopup={isOpenLinkPopup}
          setLinkUrl={handleLinkUrlChange}
          setOpenLinkPopup={handleIsOpenLinkPopupChange}
          className={props.className}
          issueId={props.issueId}
        />
      ) : (
        <ToolbarPluginView
          onChangeEmoji={handleAddEmoji}
          className={props.className}
          activeEditor={activeEditor}
          isLink={isLink}
          editor={editor}
          alignType={alignType}
          activeLinkButton={isActiveLinkButton}
          isEditable={isEditable}
          blockType={blockType}
          listType={listType}
          bgColor={bgColor}
          onBgColorSelect={onBgColorSelect}
          canRedo={canRedo}
          canUndo={canUndo}
          fontColor={fontColor}
          insertLink={insertLink}
          isBold={isBold}
          isCode={isCode}
          isItalic={isItalic}
          isUnderline={isUnderline}
          isSuperscript={isSuperscript}
          isSubscript={isSubscript}
          isTable={isTable}
          isStrikethrough={isStrikethrough}
          linkUrl={linkUrl}
          onFontColorSelect={onFontColorSelect}
          openLinkPopup={isOpenLinkPopup}
          setLinkUrl={handleLinkUrlChange}
          setOpenLinkPopup={handleIsOpenLinkPopupChange}
          setIsOpenTableDialog={handleIsOpenTableDialogChange}
          onCreateAnchor={handleCreateAnchor}
          onClearFormatting={handleClearFormatting}
          isLoadingChange={props.isLoadingChange}
          onOpenVideoDialog={handleOpenVideoDialog}
          isNewStyle={props.isNewStyle}
          toolbar={props.toolbar}
          issueId={props.issueId}
        />
      )}
    </>
  );
});

import React, { useEffect, useState } from "react";
import EmployeeInviteListDialogView from "./EmployeeInviteListDialogView";
import { useApi, useNotifier } from "../../../../functional/hooks";
import { api } from "../../../../functional/services";
import { CompanyInviteDto } from "../../../../functional/api";
import { useTranslation } from "react-i18next";

function EmployeeInviteListDialog(props: any) {
  const notifier = useNotifier();
  const { t } = useTranslation();
  const [invitesList, setInvitesList] = useState<CompanyInviteDto[]>([]);
  const allInvites = useApi(
    // api.company.getById(authStore.getInitialInfo?.identity?.currentCompanyId as number),
    () => api.companyInvite.getAll({ pageSize: 1000 }),
    () => {},
    (x) => setInvitesList(x?.items ?? [])
  );

  useEffect(() => {
    if (props.open) {
      allInvites.fetch();
    }
  }, [props.open]);

  const handleCodeCopy = async (code: string) => {
    await navigator.clipboard
      .writeText(code)
      .then(() => notifier.show({ message: t("notifier:success.copy"), theme: "success" }))
      .catch(() => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" }));
  };

  const handleLinkCopy = async (link: string) => {
    await navigator.clipboard
      .writeText(link)
      .then(() => notifier.show({ message: t("notifier:success.copy"), theme: "success" }))
      .catch(() => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" }));
  };

  const handleDelete = async (id: number) => {
    const r = await api.companyInvite.del(id);
    notifier.showSuccessError(t("notifier:success.invite_delete"), t("notifier:error.invite_delete"), r != null);
    if (r) {
      await allInvites.fetch();
    }
  };

  return (
    <EmployeeInviteListDialogView
      allInvites={invitesList}
      {...props}
      handleCodeCopy={handleCodeCopy}
      handleLinkCopy={handleLinkCopy}
      handleDelete={handleDelete}
    />
  );
}

export default EmployeeInviteListDialog;

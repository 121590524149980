import React, { useCallback } from "react";
import { PermissionDto, RoleDto } from "../../../../../../functional/api";
import "./OrgchartDrawerTabPermissions.scss";
import { useClaims, useRootStore } from "../../../../../../functional/hooks";
import { getPermissionsTooltips } from "../../../../../../functional/utils/tooltip";
import { LocalesKeys } from "../../../../../../functional/constants/localesKeys";
import { Card, Spin, Switch, Text, Tooltip } from "../../../../../uiKit";
import { toJS } from "mobx";
import { OpeningGuideIcon } from "../../../../../elements/openingGuideIcon/OpeningGuideIcon";
import { permissionKeys } from "../../../../../../functional/utils/permissions";
import { FiHelpCircle } from "react-icons/fi";
import { observer } from "mobx-react-lite";
import { guideKeys } from "../../../../../../functional/types";
import { useTranslation } from "react-i18next";

export interface IOrgchartDrawerTabPermissionsView {
  currentRole: RoleDto;
  isLoading: boolean;

  onSwitchChange: (value: boolean, id: number, type: string) => void;
}

interface IPermissionSwitchesGroup {
  currentRole: RoleDto;
  permission: PermissionDto;
  disabled: boolean;
  parentProps: IOrgchartDrawerTabPermissionsView;
  locale: LocalesKeys;
  nestingLevel: number;
  onGetAnchorId: (hintKey: string) => string | undefined;
  isLoading: boolean;

  onSwitchChange: (value: boolean, id: number, type: string) => void;
}

const arrIdsToShowHintIcon: { key: string; anchorId: string }[] = [
  {
    key: "orgchart",
    anchorId: "1anchor",
  },
  { key: "recurring_issue", anchorId: "2anchor" },
  { key: "user", anchorId: "3anchor" },
  // {
  //   key: "user.invite",
  //   anchorId: 4,
  // },
  { key: "company", anchorId: "4anchor" },
  { key: "report", anchorId: "5anchor" },
  { key: "user_schedule", anchorId: "6anchor" },
];

const PermissionSwitchesGroup = (props: IPermissionSwitchesGroup) => {
  const localePermissions = getPermissionsTooltips(props.locale);
  const { t } = useTranslation();

  const getTitle = useCallback((key: string) => {
    if (!key) {
      return "test"
    }
    const keyArr = key.split(".");
    return  keyArr.length > 1
      ? t(`ui:permissions.hint_key.${key}`)
      : t(`ui:permissions.hint_key.${key}.title`)
  }, []);

  return (props.permission.children ?? []).length == 0 ? (
    <div className="d-stack justify-start align-center spacing-2">
      <Switch
        label={
          <div className="d-stack spacing-2 align-center">
            <span children={t(`ui:permissions.${props.permission.key}`)} />
            <div className="d-flex align-center">
              {localePermissions?.find((item) => item.key === props.permission.key) && (
                <Tooltip
                  title={localePermissions.find((item) => item.key === props.permission.key)!.text}
                  placement="bottom"
                >
                  <FiHelpCircle className="guide-icon" />
                </Tooltip>
              )}
            </div>
          </div>
        }
        // size="small"
        loading={props.isLoading}
        key={props.permission.id}
        disabled={props.disabled}
        checked={props.currentRole.claims?.some((x) => x.type == props.permission.key) ?? false}
        onChange={(value) => props.onSwitchChange(value, props.permission.id as number, props.permission.key as string)}
      />
    </div>
  ) : (
    <div className={`ml-${4 * props.nestingLevel}`}>
      <div className="d-stack justify-start align-center spacing-2 mb-2">
        {console.log("props.permission", props.permission)}
        <Text
          size="16px"
          weight="bold"
          children={
            getTitle(props.permission.hintKey ?? props.permission.key ?? "")
          }
        />
        {arrIdsToShowHintIcon?.find((item) => item.key === props.permission.hintKey) && (
          <OpeningGuideIcon
            contentKey={guideKeys.permissions}
            anchorId={props.onGetAnchorId(props.permission.hintKey!)}
          />
        )}
      </div>
      <div className="d-stack-column spacing-3">
        {props.permission.children?.map((child, index) => (
          <PermissionSwitchesGroup {...props} permission={child} nestingLevel={props.nestingLevel + 1} key={child.id} />
        ))}
      </div>
    </div>
  );
};

function OrgchartDrawerTabPermissionsViewObserved(props: IOrgchartDrawerTabPermissionsView) {
  const claims = useClaims();
  const { appStore, helperStore } = useRootStore();

  const handleGetAnchorId = (hintKey: string): string | undefined => {
    return arrIdsToShowHintIcon.find((item) => item.key === hintKey)?.anchorId;
  };

  return (
    <Spin spinning={helperStore.getPermissions.length == 0} className="orgchart-function-drawer__tabs__loader">
      <div className="d-stack-column spacing-3">
        {helperStore.getPermissions.map((permission: PermissionDto) => (
          <Card variant="secondary" isShadowed={false} key={permission.id}>
            <PermissionSwitchesGroup
              permission={toJS(permission)}
              disabled={!claims.has(permissionKeys.orgchart.role.permissionsEdit)}
              nestingLevel={0}
              parentProps={props}
              isLoading={props.isLoading}
              locale={appStore.getAppLocale as LocalesKeys}
              currentRole={props.currentRole}
              onGetAnchorId={handleGetAnchorId}
              onSwitchChange={props.onSwitchChange}
            />
          </Card>
        ))}
      </div>
    </Spin>
  );
}

export const OrgchartDrawerTabPermissionsView = observer(OrgchartDrawerTabPermissionsViewObserved);

import React, { ChangeEvent, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, Input } from "../../../uiKit";
import { IDuplicateCompanyDialog } from "./DuplicateCompanyDialog.interface";
import { Form } from "antd";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";

export const DuplicateCompanyDialog = memo((props: IDuplicateCompanyDialog) => {

  const { t } = useTranslation();

  const [form] = Form.useForm();
  const handleSubmit = () => form.submit();

  const [ownerEmail, setOwnerEmail] = useState<string>("");
  const handleChangeOwnerEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setOwnerEmail(event.target.value);
  }

  const handleDuplicateCompany = () => {
    props.onAccept(ownerEmail);
  }


  return (
    <Dialog
      width={600}
      title={t("parse:duplicate_company")}
      open={props.open}
      onClose={props.onClose}
      closable={false}
    >
      <Form
        form={form}
        name="CreateCompanyGroupForm"
        onFinish={handleDuplicateCompany}
      >
        <SubheaderText text={t("ui:subheader.email")} />
        <Form.Item
          className="form-item__mb3"
          // label={t("ui:label.name")}
          name="groupName"
          initialValue={ownerEmail}
          rules={[{ required: true, message: t("validation:field_required") }]}
        >
          <Input
            autoComplete="off"
            placeholder={t("ui:placeholder.email")}
            value={ownerEmail}
            onChange={handleChangeOwnerEmail}
          />
        </Form.Item>
      </Form>
      <DialogActions>
        <Button
          onClick={props.onClose}
          variant={"text"}
        >
          {t("ui:button.cancel")}
        </Button>
        <Button
          disabled={props.isLoading}
          loading={props.isLoading}
          onClick={handleSubmit}
          variant="filled"
        >
          {t("ui:button.duplicate")}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import dayjs from "dayjs";
import { Button, Card, Icon, IconButton, Select, Text } from "../../../../../uiKit";
import { LucideChevronLeft, LucideChevronRight, LucidePanelRight } from "lucide-react";
import { useDateHelpers, useRootStore } from "../../../../../../functional/hooks";
import { SideBarIcon } from "../../../../../../functional/constants/icon";
import { useTranslation } from "react-i18next";
import "./CommunicationHeaderContentCalendarPlanning.scss";
import clsx from "clsx";
import { CalendarPlanningCalendarViewType } from "../../../../../../functional/types";

interface ICommunicationHeaderContentCalendarPlanningProps {
  //
}

const CommunicationHeaderContentCalendarPlanningObserved = (
  props: ICommunicationHeaderContentCalendarPlanningProps
) => {
  const { communicationCalendarPlanningStore, authStore } = useRootStore();

  const { formatDate } = useDateHelpers();

  const rangeSwitchText: string = useMemo(() => {
    const viewType = communicationCalendarPlanningStore.getCalendarViewType;
    const dateFrom = communicationCalendarPlanningStore.getDateFrom;

    if (viewType == CalendarPlanningCalendarViewType.DAY) {
      return formatDate(dateFrom, {
        formatObject: {
          day: "numeric",
          month: "long",
          hour: undefined,
          minute: undefined,
          timeZone: authStore.getInitialInfo?.identity?.timeZoneId ?? undefined,
        },
      });
    }
    if (viewType == CalendarPlanningCalendarViewType.WEEK) {
      return [
        formatDate(dateFrom, {
          formatObject: {
            day: "numeric",
            month: "long",
            hour: undefined,
            minute: undefined,
            timeZone: authStore.getInitialInfo?.identity?.timeZoneId ?? undefined,
          },
        }),
        formatDate(dayjs(dateFrom).add(6, "day").toDate(), {
          formatObject: {
            day: "numeric",
            month: "long",
            hour: undefined,
            minute: undefined,
            timeZone: authStore.getInitialInfo?.identity?.timeZoneId ?? undefined,
          },
        }),
      ].join(" - ");
    }
    if (viewType == CalendarPlanningCalendarViewType.REPORTING_WEEK) {
      return [
        formatDate(dateFrom, {
          formatObject: {
            day: "numeric",
            month: "long",
            hour: undefined,
            minute: undefined,
            timeZone: authStore.getInitialInfo?.identity?.timeZoneId ?? undefined,
          },
        }),
        formatDate(dayjs(dateFrom).add(6, "day").toDate(), {
          formatObject: {
            day: "numeric",
            month: "long",
            hour: undefined,
            minute: undefined,
            timeZone: authStore.getInitialInfo?.identity?.timeZoneId ?? undefined,
          },
        }),
      ].join(" - ");
    }
    if (viewType == CalendarPlanningCalendarViewType.MONTH) {
      return formatDate(dateFrom, {
        formatObject: {
          hour: undefined,
          minute: undefined,
          month: "long",
          day: undefined,
          timeZone: authStore.getInitialInfo?.identity?.timeZoneId ?? undefined,
        },
      });
    }
    if (viewType == CalendarPlanningCalendarViewType.YEAR) {
      return formatDate(dateFrom, {
        formatObject: {
          hour: undefined,
          minute: undefined,
          year: "numeric",
          day: undefined,
          month: undefined,
          timeZone: authStore.getInitialInfo?.identity?.timeZoneId ?? undefined,
        },
      });
    }
    return "";
  }, [
    communicationCalendarPlanningStore.getCalendarViewType,
    communicationCalendarPlanningStore.getDateFrom,
    communicationCalendarPlanningStore.getDateTo,
    authStore.getInitialInfo?.identity?.timeZoneId,
  ]);

  const { t } = useTranslation();

  return (
    <div className="communication-header-content__calendar-planning__wrapper d-flex align-center justify-space-between flex-grow-1">
      <div className="d-stack spacing-2 align-center">
        {/*{!communicationCalendarPlanningStore.getIsSidebarOpen && (*/}
        {/*  <div>*/}
        {/*    <IconButton*/}
        {/*      className="flex-shrink-0"*/}
        {/*      onClick={() => communicationCalendarPlanningStore.setIsSidebarOpen(true)}*/}
        {/*      style={{ marginRight: "2px" }}*/}
        {/*      children={<Icon component={() => <SideBarIcon size={20} />} />}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*)}*/}
        <div>
          <IconButton
            className={clsx("flex-shrink-0 toggle-sidebar-open", {
              "toggle-sidebar-open_close": !communicationCalendarPlanningStore.getIsSidebarOpen
            })}
            onClick={() => communicationCalendarPlanningStore.setIsSidebarOpen(!communicationCalendarPlanningStore.getIsSidebarOpen)}
            style={{ marginRight: "2px" }}
            isShowBorder={true}
            children={<Icon component={() => <SideBarIcon size={14} />} />}
          />
        </div>
        <div className="d-stack spacing-1 pl-2 pr-4">
          <Text weight="bold" style={{ textTransform: "capitalize" }}>
            {dayjs().tz().format("MMMM")}
          </Text>
          <Text>{dayjs().tz().format("YYYY")}</Text>
        </div>
        <Card
          className="br-sm"
          isShadowed={false}
          variant="secondary"
          bodyStyle={{ padding: 0 }}
        >
          <Button
            variant="link"
            style={{ fontSize: "12px" }}
            children={t('ui:calendar.today')}
            onClick={communicationCalendarPlanningStore.getOnGoToToday}
          />
        </Card>
        <Card className="br-sm" isShadowed={false} variant="secondary" bodyStyle={{ padding: 0 }}>
          <Select
            size="middle"
            value={communicationCalendarPlanningStore.getCalendarViewType}
            options={[
              {
                value: CalendarPlanningCalendarViewType.DAY,
                label: t(`ui:metric_switch.${CalendarPlanningCalendarViewType.DAY}`)
              },
              {
                value: CalendarPlanningCalendarViewType.WEEK,
                label: t(`ui:metric_switch.${CalendarPlanningCalendarViewType.WEEK}`)
              },
              {
                value: CalendarPlanningCalendarViewType.REPORTING_WEEK,
                label: t(`ui:metric_switch.${CalendarPlanningCalendarViewType.REPORTING_WEEK}`)
              },
              {
                value: CalendarPlanningCalendarViewType.MONTH,
                label: t(`ui:metric_switch.${CalendarPlanningCalendarViewType.MONTH}`)
              },
            ]}
            dropdownStyle={{ minWidth: "max-content" }}
            onChange={(value: CalendarPlanningCalendarViewType) =>
              communicationCalendarPlanningStore.setCalendarViewType(value)
            }
          />
        </Card>
        <Card className="br-sm" isShadowed={false} variant="secondary" bodyStyle={{ padding: 0 }}>
          <Button
            variant="link"
            icon={<Icon component={() => <LucideChevronLeft color="var(--color-text-weakest)" size={14} />} />}
            onClick={communicationCalendarPlanningStore.getOnGoToPrevious}
          />
          <Text className="no-select" size="12px" style={{ verticalAlign: "text-top" }} children={rangeSwitchText} />
          <Button
            variant="link"
            icon={<Icon component={() => <LucideChevronRight color="var(--color-text-weakest)" size={14} />} />}
            onClick={communicationCalendarPlanningStore.getOnGoToNext}
          />
        </Card>
      </div>
    </div>
  );
};

export const CommunicationHeaderContentCalendarPlanning = observer(CommunicationHeaderContentCalendarPlanningObserved);

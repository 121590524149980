import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ICardIssue } from "./CardIssue.interface";
import { IssueQueryKeys } from "../../../../../../functional/constants/issueTypeKeys";
import { DraggableProvider } from "./DraggableProvider";
import { CardIssueView } from "./CardIssueView";
import { IssueChangeDeadlineDialog } from "../IssueChangeDeadlineDialog/IssueChangeDeadlineDialog";
import { BoardFilterContext } from "../../../../../../functional/contexts";

export const CardIssue = memo((props: ICardIssue) => {
  const navigate = useNavigate();
  const { board } = useContext(BoardFilterContext);

  const handleOpenIssue = useCallback(async () => {
    navigate({
      search: `?${IssueQueryKeys.issueId}=${props?.issue?.id}`,
    });
    // if (props?.issue?.id != null) {
    //   await api.notification.setIsRead({ issueId: props?.issue?.id, setRead: true });
    // }
  }, [props?.issue?.id]); //


  const [isShowDialog, setIsShowDialog] = useState(false);


  const handleOpenChangeDeadlineDialog = useCallback(() => {
    setIsShowDialog(true);
  }, []);


  const handleCloseChangeDeadlineDialog = useCallback(() => {
    setIsShowDialog(false);
  }, []);

  const filling = (
    <CardIssueView
      issue={props.issue}
      onClick={handleOpenIssue}
      onClickChangeDeadline={handleOpenChangeDeadlineDialog}
      outlined={props.outlined}
      showOrgchartCard={props.isGroupingByOrgcharts ?? false}
      onAddIssue={props.onAddIssue}
    />
  );


  return (
    <>
      {isShowDialog && (
        <IssueChangeDeadlineDialog
          dateDeadline={props.issue.dateDeadline!}
          onClose={handleCloseChangeDeadlineDialog}
          issueId={props.issue.id!}
        />
      )}
      {
        props.noDrag ? (
          filling
        ) : (
          <DraggableProvider draggableId={props.issue.id} draggableIndex={props.index ?? 0} children={filling} />
        )
      }
    </>
  )
});

import React, { useCallback, useRef, useState } from "react";
import { Avatar, Button, Dialog, DialogActions, Icon, Input } from "../../../uiKit";
import { useTranslation } from "react-i18next";
import { OrgchartDto } from "../../../../functional/api";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { CirclePicker } from "react-color";
import AvatarUploadForm from "../../pages/settings/avatarUploadForm/AvatarUploadForm";
import { theme } from "antd";
import { useRootStore } from "../../../../functional/hooks";
import { AiOutlinePaperClip } from "@react-icons/all-files/ai/AiOutlinePaperClip";
import { CloseIcon } from "../../../../functional/constants/icon";
import "./OrgchartCreateEditDialog.scss";
import { orgchartHexToVariables, orgchartVariablesToHex } from "../../../../functional/utils/hexToVariables";

interface IOrgchartCreateEditDialogView {
  open: boolean;
  state: OrgchartDto;
  isEditMode: boolean;
  isLoading: boolean;
  colors: string[];
  isUploadJson: boolean;

  setState: (value: OrgchartDto) => void;
  onCreate: () => void;
  onSave: () => void;
  onClose: () => void;
}

export function OrgchartCreateEditDialogView(props: IOrgchartCreateEditDialogView) {
  const { orgchartStore } = useRootStore();
  const { t } = useTranslation();
  const { useToken } = theme;
  const { token } = useToken();
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState<boolean>(false);
  const uploadInputRef = useRef<HTMLInputElement>(null);

  const handleChangeFile = useCallback((e) => {
    const file = e.target.files[0];
    props.setState({ ...props.state, file });
  }, [props.state]);

  const handleClearFile = useCallback(() => {
    props.setState({ ...props.state, file: undefined });
  }, [props.state])

  return (
    <Dialog
      title={props.isEditMode ? t("parse:edit_orgchart") : t("parse:create_orgchart")}
      open={props.open}
      onClose={props.onClose}
      closable={false}
    >
      <div className="d-stack-column spacing-4">
        <AvatarUploadForm
          onClose={() => setIsUploadDialogOpen(false)}
          onUpload={(imageId, imageUrl) => {
            setIsUploadDialogOpen(false);
            props.setState({ ...props.state, imageId: imageId, image: { url: imageUrl } });
          }}
          open={isUploadDialogOpen}
          imageUrl={props.state.image?.url ?? undefined}
          text={props.state.name}
          color={props.state.colorHex ?? undefined}
        />
        <div className="d-flex align-center justify-center" style={{ width: "100%" }}>
          <Avatar
            color={props.state.colorHex ?? undefined}
            src={props.state.image?.url ?? undefined}
            size={100}
            text={props.state.name}
          />
          <div className="d-flex flex-column ml-4">
            <Button variant="text" onClick={() => setIsUploadDialogOpen(true)}>
              {t("ui:button.edit")}
            </Button>
            <Button
              onClick={() =>
                props.setState({ ...props.state, imageId: null, image: { ...props.state.image, url: null } })
              }
              disabled={props.state.imageId == null}
              variant="text"
              theme="error"
            >
              {t("ui:button.delete")}
            </Button>
          </div>
        </div>
        <div className="full-width">
          <SubheaderText text={t("ui:subheader.name")} />
          <Input
            value={props.state.name ?? ""}
            placeholder={t("ui:placeholder.issue_name")}
            disabled={props.isLoading}
            onInput={(e) => props.setState({ ...props.state, name: e.currentTarget.value })}
          />
        </div>
        <div className="full-width">
          <SubheaderText text={t("ui:subheader.description")} />
          <Input
            value={props.state.description ?? ""}
            placeholder={t("ui:placeholder.issue_description")}
            disabled={props.isLoading}
            onInput={(e) => props.setState({ ...props.state, description: e.currentTarget.value })}
          />
        </div>
        {props.isEditMode && (
          <div className="full-width">
            <SubheaderText text={t("parse:result_name")} />
            <Input
              value={props.state.resultName ?? ""}
              placeholder={t("ui:placeholder.recurring_task_result")}
              disabled={props.isLoading}
              onInput={(e) => props.setState({ ...props.state, resultName: e.currentTarget.value })}
            />
          </div>
        )}
        {/*<div className="full-width">*/}
        {/*  <SubheaderText text={t("parse:company_result")} />*/}
        {/*  <Input*/}
        {/*    value={props.state.valuableFinalProduct ?? ""}*/}
        {/*    placeholder={t("ui:placeholder.recurring_task_result")}*/}
        {/*    disabled={props.isLoading}*/}
        {/*    onInput={(e) => props.setState({ ...props.state, valuableFinalProduct: e.currentTarget.value })}*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="full-width">
          <SubheaderText text={t("parse:color")} />
          <div
            className="br-md"
            style={{
              background: token.colorBgContainer,
              border: `1px solid ${token.colorBorder}`,
              padding: "7px 11px"
            }}
          >
            <CirclePicker
              width="100%"
              circleSpacing={8}
              circleSize={24}
              // className="orgchart-function-card_color-picker"
              color={props.state?.colorHex ? orgchartHexToVariables(props.state?.colorHex) : undefined}
              colors={props.colors}
              onChangeComplete={(color, e) => props.setState({ ...props.state, colorHex: orgchartVariablesToHex(e.target.title), })}
            />
          </div>
        </div>
        {props.isUploadJson && (
          <div className="full-width">
            { props.state.file?.name ? (
              <div className={"orgchart-template-info"}>
                { props.state.file?.name  }
                <div
                  className="orgchart-template-info__icon"
                  onClick={handleClearFile}
                >
                  <CloseIcon/>
                </div>
              </div>
            ) : (
              <>
              <input
                style={{ display: "none" }}
                type="file"
                onChange={handleChangeFile}
                accept="application/JSON"
                ref={uploadInputRef}
              />
              <Button
                /*@ts-ignore*/
                onClick={() => uploadInputRef?.current?.click()}
                icon={<Icon component={() => <AiOutlinePaperClip />} />}
                style={{ fontSize: "14px" }}
                size="small"
                type="text"
              >
              {t("ui:button.attach")} JSON
              </Button>
              </>
            )}


          </div>
        )}
      </div>
      <DialogActions>
        <Button onClick={props.onClose} variant="outlined" children={t("ui:button.cancel")} />
        <Button
          onClick={props.isEditMode ? props.onSave : props.onCreate}
          disabled={props.state.name?.length == 0}
          loading={props.isLoading}
          children={props.isEditMode ? t("ui:button.apply") : t("ui:button.create")}
        />
      </DialogActions>
    </Dialog>
  );
}

import React from "react";
// Connect Inter Font
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "./styles/global.scss";
import { observer } from "mobx-react-lite";
// Import Google Analytics v4
// import GA4React from "ga-4-react";
// Import Carrot Quest Localizations
import { useApp } from "./functional/hooks";

import "./functional/services/dayjs";
// import AntD locales
// Import all required components
import { App as AppWrapper, ConfigProvider } from "antd";
import { Layout } from "./components/uiKit";
import { ConnectionChecker } from "./components/service/connectionChecker/ConnectionChecker";
import { AppPreloader } from "./components/service/appPreloader/AppPreloader";
import AppRouter from "./components/service/appRouter/AppRouter";
import ImageViewer from "./components/service/imageViewer/ImageViewer";
import FileViewer from "./components/service/fileViewer/FileViewer";
import { RootStoreProvider } from "./functional/contexts/storeContext";
import { PlaceholderTechnicalWork } from "./components/uiKit/placeholderTechnicalWork/PlaceholderTechnicalWork";
// Import DayJS locales
require("dayjs/locale/ru");
require("dayjs/locale/uk");
require("dayjs/locale/en");
require("dayjs/locale/zh-tw");

declare global {
  interface Window {
    carrotquest: any;
  }
}

function AppObserved() {
  const { antDLocale, themeAlgorithm, isDarkTheme } = useApp();

  // const { appStore, authStore } = useRootStore();
  // const { getUserShortFullName } = useUserNameFormat();
  // const { defaultAlgorithm, darkAlgorithm } = theme;
  //
  // const identityFallback = useRef<UserDto | null>(null);
  // const isCarrotQuestInitialized = useRef<boolean>(false);

  // const appSetLocaleDayjsAntd = (localeCode: string) => {
  //   // dayjs.tz.setDefault("America/New_York");
  //   dayjs.locale(localeCode == "zh" ? "zh-tw" : localeCode);
  //   if (localeCode == "ru") antDLocale.current = ru_RU;
  //   if (localeCode == "en") antDLocale.current = en_US;
  //   if (localeCode == "uk") antDLocale.current = uk_UA;
  //   if (localeCode == "zh") antDLocale.current = zh_TW;
  //   if (localeCode == "es") antDLocale.current = es_ES;
  //   if (localeCode == "es") antDLocale.current = es_ES;
  //   if (localeCode.includes("uz")) antDLocale.current = ru_RU;
  //
  //   document.body.setAttribute("lang", localeCode);
  //   dayjsSetStartOfWeek(localeCode);
  // };
  //
  // const dayjsSetStartOfWeek = (localeCode?: string) => {
  //   const weekStart = authStore.getInitialInfo?.identity?.companies?.find(
  //     (u2c) => u2c.companyId == authStore.getCurrentCompanyId
  //   )?.company?.weekStart;
  //   weekStart != null &&
  //     dayjs.updateLocale((localeCode == "zh" ? "zh-tw" : localeCode) ?? dayjs.locale(), {
  //       weekStart: weekStart,
  //     });
  // };
  //
  // const carrotQuestSetLocale = (localeCode: string, isInitialized: boolean) => {
  //   if (!isInitialized) return;
  //   window.carrotquest && window.carrotquest.removeChat && window.carrotquest.removeChat();
  //   const _locFile =
  //     localeCode == "ru" ? carrotQuestLocaleRu : localeCode == "uk" ? carrotQuestLocaleUk : carrotQuestLocaleEn;
  //   const _locFileAdditional =
  //     localeCode == "ru"
  //       ? carrotAdditionalSettingsRu
  //       : localeCode == "uk"
  //       ? carrotAdditionalSettingsUk
  //       : carrotAdditionalSettingsEn;
  //
  //   window.carrotquest.i18n = _locFile;
  //   window.carrotquest.data.app.settings = {
  //     ...window.carrotquest.data.app.settings,
  //     ..._locFileAdditional,
  //   };
  // };

  // const carrotQuestInit = () => {
  //   const identity = toJS(authStore.getInitialInfo)?.identity;
  //   if (identity != null && identity != identityFallback.current) {
  //     const userName = getUserShortFullName(
  //       identity?.lastName ?? "",
  //       identity?.firstName ?? "",
  //       identity?.middleName ?? ""
  //     ) ;
  //     const userEmail = identity.email ?? "";
  //     const userPhoneNumber = identity.phoneNumber ?? "";
  //     const currentCompanyName =
  //       ((identity.companies?.find((ic) => ic.companyId == identity.currentCompanyId)?.company?.name ?? "").trim()
  //         .length > 0
  //         ? identity.companies?.find((ic) => ic.companyId == identity.currentCompanyId)?.company?.name ?? ""
  //         : identity.companies?.find((ic) => ic.companyId == identity.currentCompanyId)?.company?.nameFallback) ?? "";
  //
  //     window.carrotquest &&
  //       window.carrotquest.identify({
  //         $name: userName + " (" + currentCompanyName + ")",
  //         $email: userEmail,
  //         $phone: userPhoneNumber,
  //       });
  //
  //     identityFallback.current = identity;
  //   }
  // };
  //
  // useEffect(() => {
  //   if (appStore.getAppLocale == null || appStore.getAppLocale.trim().length == 0) return;
  //   appSetLocaleDayjsAntd(appStore.getAppLocale.trim());
  //   carrotQuestSetLocale(appStore.getAppLocale.trim(), isCarrotQuestInitialized.current);
  // }, [appStore.getAppLocale]);
  //
  // useEffect(() => {
  //   dayjs.tz.setDefault(authStore.getInitialInfo?.identity?.timeZoneId ?? undefined);
  // }, [authStore.getInitialInfo?.identity?.timeZoneId]);
  //
  // useEffect(() => {
  //   carrotQuestInit();
  //   dayjsSetStartOfWeek();
  // }, [authStore.getInitialInfo]);
  //
  // useEffect(() => {
  //   console.log("window.opener?.handleCloseGoogleAuthModal", window.opener?.handleCloseGoogleAuthModal);
  //   if (window.opener?.handleCloseGoogleAuthModal) {
  //     window.opener.handleCloseGoogleAuthModal();
  //     window.close();
  //   }
  //   // @ts-ignore
  //   window.carrotquest.onReady(() => {
  //     isCarrotQuestInitialized.current = true;
  //     appStore.getAppLocale != null &&
  //       appStore.getAppLocale.trim().length > 0 &&
  //       carrotQuestSetLocale(appStore.getAppLocale.trim(), isCarrotQuestInitialized.current);
  //   });
  //
  // }, []);

  // Initialize Google Analytics
  // try {
  //   setTimeout((_) => {
  //     const ga4react = new GA4React("G-P9WJMQHY48");
  //     ga4react.initialize().catch((err) => console.error(err));
  //   }, 500);
  // } catch (err) {
  //   console.error(err);
  // }
  //
  // const [passwordTestingServer, setPasswordTestingServer] = useLocalStorage<string>("passwordTestingServer","")
  //
  // const passwordVerificationTestingServer = () => {
  //   const password = prompt("Введите пароль", "");
  //   if (password !== '13leet37') {
  //     passwordVerificationTestingServer();
  //   } else {
  //     setPasswordTestingServer(password);
  //   }
  // }
  //
  //
  // useEffect(() => {
  //   const isDev =
  //     window.location?.hostname === "dev.bbooster.io" ||
  //     window.location.hostname === "staging.bbooster.io";
  //   if (!passwordTestingServer && isDev) {
  //     passwordVerificationTestingServer();
  //   }
  // }, []);

  const isShowPlaceholderTechnicalWork = false;
  return isShowPlaceholderTechnicalWork ? (
        <PlaceholderTechnicalWork/>
      ) : (
        <ConfigProvider
          locale={antDLocale.current}
          theme={{
            algorithm: themeAlgorithm,
            token: {
              colorPrimary: isDarkTheme ? "#788EFF" : "#5672FF",
              colorInfo: isDarkTheme ? "#788EFF" : "#5672FF",
              colorError: isDarkTheme ? "#F04438" : "#F04438",
              colorSuccess: isDarkTheme ? "#12B76A" : "#12B76A",
              colorWarning: isDarkTheme ? "#EAAA08" : "#EAAA08",
              colorTextSecondary: isDarkTheme ? "#98A2B3" : "#98A2B3",
              colorIcon: isDarkTheme ? "#98A2B3" : "#98A2B3",
              colorText: isDarkTheme ? "#FFFFFF" : "#222A38",
              colorTextBase: isDarkTheme ? "#FFFFFF" : "#222A38",
              colorTextPlaceholder: isDarkTheme ? "#98A2B3" : "#98A2B3",
              colorBgContainer: isDarkTheme ? "#222A38" : "#FFFFFF",
              colorBorder: isDarkTheme ? "#475467" : "#E4E7EC",
              fontFamily:
                "-apple-system, BlinkMacSystemFont, Inter, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
            },
          }}
        >
          <AppWrapper>
            <RootStoreProvider>
              <Layout id="appLayout" className="app-layout__wrapper">
                <ConnectionChecker />
                <ImageViewer />
                <FileViewer />
                <AppPreloader />
                <AppRouter />
                {/*<div id="notification__wrapper">*/}
                {/*  <div id="notification__inner" />*/}
                {/*</div>*/}
              </Layout>
            </RootStoreProvider>
          </AppWrapper>
        </ConfigProvider>
      )

}

export const App = observer(AppObserved);

import React, { memo } from "react";
import { Avatar, Text } from "../../../uiKit";
import "./PrintMetricDialog.scss";
import { IPrintMetricDialog } from "./PrintMetricDialog.interface";
import { DashboardChart } from "../../pages/dashboards/dashboardChart/DashboardChart";

import { PrintMetricTable } from "../../../elements/printMetricTable/PrintMetricTable";
import { Portal } from "react-portal";
import { useRootStore } from "../../../../functional/hooks";
import { useUserNameFormat } from "../../../../functional/hooks/useUserNameFormat";

export const PrintMetricDialog = memo((props: IPrintMetricDialog) => {
  const { authStore } = useRootStore();
  const currentCompany = authStore.getCurrentCompany;

  const { getFullName, getAvatarText } = useUserNameFormat();
  const users = props.printData.metricFillerUsers ?? [];


  return (
    <Portal>
      <div className="print-metric">
        <div className="print-metric-wrapper">
          <div className="print-metric-company">
            <Avatar
              size={20}
              className="print-metric-company__avatar"
              type="company"
              text={currentCompany?.name ?? currentCompany?.nameFallback}
              color={currentCompany?.color}
              src={currentCompany?.image?.url ?? undefined}
            />
            <Text
              className="print-metric-company__name"
              children={currentCompany?.name}
            />
          </div>
          <div className="print-metric-wrap">
            <div className="print-metric__role mb-1">
              <Text
                size={"24px"}
                weight={700}
              >
                {props.printData.metricFillerRole?.name ?? props.metric.rolePosition?.name ?? ""}
              </Text>
            </div>
            <div className="print-metric__title mb-4">
              <Text
                size={"16px"}
                weight={600}
              >
                {props.metric.name}
              </Text>
            </div>
            <div className="print-metric-user">
              {users.length === 1 && (
                <Avatar
                  size={75}
                  className="print-metric-user__avatar"
                  type="company"
                  text={getAvatarText(users[0]?.lastName ?? "", users[0]?.firstName ?? "") ?? users[0]?.nameFallback}
                  color={users[0]?.color}
                  src={users[0]?.avatar?.image?.url ?? undefined}
                />
              )}

              <div className="print-metric-user-wrap">
                <Text
                  className="print-metric-user__role"
                  children={props.printData.metricFillerRolePosition?.name ?? ""}
                />
                <div className="print-metric-user__name">
                  {users.map((user, i) => (
                    <Text
                      key={user.id}
                      style={{
                        fontSize: 15,
                        lineHeight: "21px",
                      }}
                      children={
                        `${getFullName(user?.lastName ?? "", user?.firstName ?? "", user?.middleName ?? "")}${
                          users.length > 1 && i < users.length - 1 ? ", " : ""
                        }`
                      }
                    />
                  ))}
                </div>

              </div>
            </div>
            <div className="print-metric-char">
              <DashboardChart
                data={props.chartData}
                metricData={props.metric}
                yDividers={props.yDividers}
                metricId={props.metric.id as number}
                metricSource2UserId={props.metricSource2UserId}
                isReversed={props.metric?.isReversed}
                isReadOnly={
                  props.metricSource2UserId != null
                    ? props.metric?.graphs?.find((c) => c.metricSource2UserId == props.metricSource2UserId)
                    ?.canBeEdited != true
                    : props.metric.graphs?.some((c) => c.canBeEdited == false)
                  // : props.metric.graphs?.every((c) => c.canBeEdited == false)
                }
                viewSettings={props.chartSettings}
                isPrint
              />
            </div>
            {!!props.metric.description?.length && (
              <div className="print-metric__description mt-6">
                <Text
                  style={{
                    fontSize: 12,
                    lineHeight: 1.4,
                  }}
                >
                  {props.metric.description}
                </Text>
              </div>
            )}
          </div>
          {props.printData.tableData.map((item, i) => (
            <PrintMetricTable
              key={item.metricSource2UserId}
              metric={props.metric}
              index={i}
              metricSource2UserId={item.metricSource2UserId}
              data={props.printData.tableData}
            />
          ))}
        </div>
      </div>
    </Portal>
)
})

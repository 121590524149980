import React, { useEffect, useRef, useState } from "react";
import { ITimeCount, ITimeLeft } from "./TimeCount.interface";
import { TimeCountItem } from "../timeCountItem/TimeCountItem";
import { Text } from "../../uiKit";
import { useTranslation } from "react-i18next";


export const TimeCount = (props: ITimeCount) => {
  const { t } = useTranslation();
  const interval = useRef<NodeJS.Timeout | undefined>();

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const targetTime = new Date(props.endDate).getTime();
    const difference = targetTime - now;
    if (difference < 0) {
      clearInterval(interval.current);
    }
    setTimeLeft({
      days: Math.max(0, Math.floor(difference / (1000 * 60 * 60 * 24))),
      hours: Math.max(0, Math.floor((difference / (1000 * 60 * 60)) % 24)),
      minutes: Math.max(0, Math.floor((difference / (1000 * 60)) % 60)),
      seconds: Math.max(0, Math.floor((difference / 1000) % 60)),
    });
  }

  const [timeLeft, setTimeLeft] = useState<ITimeLeft>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    interval.current = setInterval(() => {
      calculateTimeLeft();
    }, props.isHideSecond ? 60000 : 1000);

    return () => clearInterval(interval.current);
  }, [props.endDate]);

  useEffect(() => {
    calculateTimeLeft()
  }, []);

  function pluralize(count: number, singular: string, few: string, many: string) {
    if (count % 10 === 1 && count % 100 !== 11) return singular;
    if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) return few;
    return many;
  }

  const { days, hours, minutes, seconds } = timeLeft;

  return (
    <div className="time-count d-flex spacing-2 justify-center">
      <TimeCountItem
        value={days}
        label={pluralize(days, t("ui:end_access_time_count.day"), t("ui:end_access_time_count.day1"), t("ui:end_access_time_count.day2"))}
      />
      <Text>
        :
      </Text>
      <TimeCountItem
        value={hours}
        label={pluralize(hours, t("ui:end_access_time_count.hours"), t("ui:end_access_time_count.hours1"), t("ui:end_access_time_count.hours2"))}
      />
      <Text>
        :
      </Text>
      <TimeCountItem
        value={minutes}
        label={pluralize(minutes, t("ui:end_access_time_count.minute"), t("ui:end_access_time_count.minute1"), t("ui:end_access_time_count.minute2"))}
      />
      {!props.isHideSecond && (
      <>
        <Text>
          :
        </Text>
        <TimeCountItem
          value={seconds}
          label={pluralize(minutes, t("ui:end_access_time_count.second"), t("ui:end_access_time_count.second1"), t("ui:end_access_time_count.second2"))}
        />
      </>
      )}

    </div>
  );
};






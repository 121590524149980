import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { DRAG_DROP_PASTE } from "@lexical/rich-text";
import { mediaFileReader } from "@lexical/utils";
import { COMMAND_PRIORITY_LOW } from "lexical";
import { useEffect } from "react";

import { INSERT_IMAGE_COMMAND } from "../ImagesPlugin/ImagesPlugin";
import { NodeType } from "../../types/types";
import { api } from "../../../../../functional/services";

const ACCEPTABLE_IMAGE_TYPES = ["image/", "image/heic", "image/heif", "image/gif", "image/webp"];

interface IDragDropPaste {
  isLoadingChange: (value: boolean) => void;
}

export default function DragDropPaste(props: IDragDropPaste): null {
  const [editor] = useLexicalComposerContext();
  // useEffect(() => {
  //   return editor.registerCommand(
  //     DRAG_DROP_PASTE,
  //     (files) => {
  //       (async () => {
  //         const filesResult = await mediaFileReader(
  //           files,
  //           [ACCEPTABLE_IMAGE_TYPES].flatMap((x) => x)
  //         );
  //         for (const { file, result } of filesResult) {
  //           if (isMimeType(file, ACCEPTABLE_IMAGE_TYPES)) {
  //             editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
  //               altText: file.name,
  //               src: result,
  //             });
  //           }
  //         }
  //       })();
  //       return true;
  //     },
  //     COMMAND_PRIORITY_LOW
  //   );
  // }, [editor]);
  useEffect(() => {
    return editor.registerCommand(
      DRAG_DROP_PASTE,
      (files) => {
        (async () => {
          const filesResult = await mediaFileReader(
            files,
            [ACCEPTABLE_IMAGE_TYPES].flatMap((x) => x)
          );
          for (const { file, result } of filesResult) {
            const [mime] = file.type.split("/");
            if (mime === NodeType.Image) {
              props.isLoadingChange(true);
              const formData = new FormData();
              formData.append("files", file);
              const r = await api.staticFile.upload(formData, NodeType.Image);
              if (r === null) {
                props.isLoadingChange(false);
                return;
              } else {
                editor.dispatchCommand(INSERT_IMAGE_COMMAND, { src: r[0].url as string, altText: file.name });
                props.isLoadingChange(false);
              }
            }
          }
        })();
        return true;
      },
      COMMAND_PRIORITY_LOW
    );
  }, [editor, props]);
  return null;
}

import "./ScheduleCell.scss";
import { theme } from "antd";
import { addAlphaToHex } from "../../../../../functional/helpers/colorFunctions";
import { useMemo } from "react";
import { createSelectable, TSelectableItemProps } from "react-selectable-fast";
import clsx from "clsx";
import dayjs, { Dayjs } from "dayjs";
import { User2RoleDto, UserScheduleDto } from "../../../../../functional/api";
import { NumericRange } from "../../../../../functional/types/customTypes";
import { useRootStore } from "../../../../../functional/hooks";
import { observer } from "mobx-react-lite";
import { getObjectFromTimeSpan } from "../../../../../functional/helpers/dateFunctions";
import { useTranslation } from "react-i18next";

interface IScheduleCellProps {
  index: number;
  date: Dayjs;
  user2role: User2RoleDto;
  defaultPeriodSchedule: (UserScheduleDto | undefined)[];
  isDisabled?: boolean;
  useUserTimeZone: boolean;
}

const ScheduleCellSelectable = observer((props: IScheduleCellProps & TSelectableItemProps) => {
  const { t } = useTranslation();
  const { useToken } = theme;
  const { token } = useToken();
  const { authStore } = useRootStore();

  const getTimeZonedTimeSpan = (timeSpan: string | null | undefined): string => {
    if (timeSpan == null || timeSpan.trim().length == 0) return "";
    if (!props.useUserTimeZone) return timeSpan.slice(0, 5);
    // const userTimeZone = authStore.getInitialInfo?.identity?.timeZoneId?.trim() || null;
    const userTimeZone = props.user2role.user?.timeZoneId?.trim() || null;
    const companyTimeZone = authStore.getCurrentCompany?.timeZoneId?.trim() || null;
    if (userTimeZone == null || companyTimeZone == null) return timeSpan.slice(0, 5);
    const obj = getObjectFromTimeSpan(timeSpan);
    return dayjs.tz({ hours: obj.hours, minutes: obj.minutes }, companyTimeZone).tz(userTimeZone).format("HH:mm");
  };

  const userSchedule: UserScheduleDto | undefined = useMemo(
    () =>
      props.user2role.schedules?.find((s) =>
        props.date.isBetween(dayjs.utc(s.dateFrom).startOf("day"), dayjs.utc(s.dateTo).endOf("day"), "date", "[]")
      ),
    [props.user2role, props.date]
  );

  const isCardAvailable: boolean = useMemo(() => {
    if (props.user2role.dateFrom != null && props.user2role.dateTo != null) {
      return props.date.isBetween(
        dayjs.utc(props.user2role.dateFrom).startOf("day"),
        dayjs.utc(props.user2role.dateTo).endOf("day"),
        "date",
        "[]"
      );
    }
    return props.user2role.dateFrom != null
      ? props.date.isSameOrAfter(dayjs.utc(props.user2role.dateFrom).startOf("day"))
      : false;
  }, [props.user2role, props.date]);

  const cardType: NumericRange<0, 4> = useMemo(() => {
    if (!isCardAvailable) return 0;
    if (userSchedule != null) return (userSchedule.type ?? 0) as NumericRange<0, 4>;
    return (props.defaultPeriodSchedule[props.index]?.type ?? 0) as NumericRange<0, 4>;
  }, [props.defaultPeriodSchedule, props.index, userSchedule, isCardAvailable]);

  const cardText: string = useMemo(() => {
    if (!isCardAvailable) return "";
    if (userSchedule != null) {
      if (userSchedule.type == 1) {
        return getTimeZonedTimeSpan(userSchedule?.timeFrom) + "\n" + getTimeZonedTimeSpan(userSchedule?.timeTo);
      }
      return t(`ui:user_schedule.type${userSchedule.type}`);
    }
    const schedule = props.defaultPeriodSchedule[props.index];
    if (schedule?.type == 1) {
      return (
        getTimeZonedTimeSpan(schedule?.timeFrom) +
        "\n" +
        getTimeZonedTimeSpan(schedule?.timeTo)
      );
    }
    console.log("props.defaultPeriodSchedule[props.index]", schedule);
    return schedule ? t(`ui:user_schedule.type${schedule.type}`) : "";

  }, [props.defaultPeriodSchedule, props.index, userSchedule, isCardAvailable]);

  const cardColor = useMemo(() => {
    const colorWithAlpha = (hex: string) => addAlphaToHex(hex, 0.25);
    if ((cardText ?? "").trim().length == 0) return colorWithAlpha(token.colorBgContainerDisabled);
    switch (cardType as NumericRange<0, 4>) {
      case 1:
        return colorWithAlpha(token.colorSuccess);
      case 2:
        return colorWithAlpha(token.colorWarning);
      case 3:
        return colorWithAlpha(token.colorWarning);
      case 4:
        return colorWithAlpha(token.colorError);
      default:
        return colorWithAlpha(token.colorBgContainerDisabled);
    }
  }, [cardType, cardText]);

  const cardClassName = useMemo(() => {
    if ((cardText ?? "").trim().length == 0) return "schedule-cell__wrapper_default";
    switch (cardType as NumericRange<0, 4>) {
      case 1:
        return "schedule-cell__wrapper_workday";
      case 2:
        return "schedule-cell__wrapper_weekend";
      case 3:
        return "schedule-cell__wrapper_vacation";
      case 4:
        return "schedule-cell__wrapper_sickday";
      default:
        return "schedule-cell__wrapper_default";
    }
  }, [cardType, cardText])

  return (
    <div
      ref={props.selectableRef}
      className={clsx(`schedule-cell__wrapper ${cardClassName}`, {
        __disabled: props.isDisabled || !isCardAvailable,
        __active: props.isSelecting,
        __selected: props.isSelected,
      })}
    >
      <span children={cardText} />
    </div>
  );
});

export const ScheduleCell = createSelectable(ScheduleCellSelectable);

import React from "react";
import { useRootStore } from "../../../functional/hooks";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import LanguageSwitcher from "../../modules/pages/settings/languageSwitcher/LanguageSwitcher";
import DrawerStateSwitcher from "../../modules/pages/settings/drawerStateSwitcher/DrawerStateSwitcher";
import AudioSettingsSwitcher from "../../modules/pages/settings/audioSettingsSwitcher/AudioSettingsSwitcher";
import { ThemeSwitcher } from "../../modules/pages/settings/themeSwitcher/ThemeSwitcher";
import { NotificationsVisibilitySwitcher } from "../../modules/pages/settings/notificationsVisibilitySwitcher/NotificationsVisibilitySwitcher";

function SettingsPage() {
  const { t } = useTranslation();
  const { authStore } = useRootStore();

  return (
    <>
      <PageHeader title={t("common:page_title.settings_common")} />
      <PageContent
        tabs={authStore.getInitialInfo?.settingsTabs}
        useTranslationInTabs
        isFilled
      >
        <div className="d-stack-column spacing-3" style={{ maxWidth: "444px", width: "100%" }}>
          <LanguageSwitcher />
          <ThemeSwitcher />
          <DrawerStateSwitcher />
          <AudioSettingsSwitcher />
          <NotificationsVisibilitySwitcher />
        </div>
      </PageContent>
    </>
  );
}

export default observer(SettingsPage);

import React, { useMemo } from "react";
import { Tag } from "../tags/tag/Tag";
import { ITagLabel, TagLabelColor } from "../../../../functional/types/TagLabel.interface";
import "./TagLabel.scss";

export const TagLabel = ({ text, color, icon }: ITagLabel) => {

  const tagStyle = useMemo(() => {
    switch (color) {
      case TagLabelColor.primary:
        return {
          color: "var(--color-tag-txt-blue)",
          borderColor: "var(--color-tag-brdr-blue)",
          background: "var(--color-tag-bg-blue)",
        }
      case TagLabelColor.warning:
        return {
          color: "var(--color-tag-txt-orange)",
          borderColor: "var(--color-tag-brdr-orange)",
          background: "var(--color-tag-bg-orange)",
        }
      case TagLabelColor.success:
        return {
          color: "var(--color-tag-txt-green)",
          borderColor: "var(--color-tag-brdr-green)",
          background: "var(--color-tag-bg-green)",
        }
      case TagLabelColor.error:
        return {
          color: "var(--color-tag-txt-red)",
          borderColor: "var(--color-tag-brdr-red)",
          background: "var(--color-tag-bg-red)",
        }
      default:
        return {
          color: "var(--color-tag-txt-blue)",
          borderColor: "var(--color-tag-brdr-blue)",
          background: "var(--color-tag-bg-blue)",
        }
    }
  }, [color])

  return (
    <Tag
      className="tag-label"
      style={tagStyle}
    >
      {icon}
      { text }
    </Tag>
  )
};

import React from "react";
import "./ControlButtons.scss";
import { IControlButtons } from "./ControlButtons.interface";
import { Icon, IconButton } from "../../../../../uiKit";
import { FiSettings } from "@react-icons/all-files/fi/FiSettings";
import { ColumnsSelect } from "../ColumnsSelect/ColumnsSelect";
import { FiPrinter } from "@react-icons/all-files/fi/FiPrinter";

export const ControlButtons = (props: IControlButtons) => {
  return (
    <div className="d-stack spacing-3">
      <ColumnsSelect
        value={props.dashboardColumnsAmount}
        onChange={props.onDashboardColumnsAmountChange}
        variant="filled"
        size="middle"
      />
      {props.dashboard && (
        <div className="control-buttons">
          <IconButton
            onClick={props.onPrint}
            icon={<Icon component={() => <FiPrinter />} />}
          />
          <IconButton
            onClick={() => props.onOpenCreateEditDashboardDialogChange(true, true)}
            icon={<Icon component={() => <FiSettings />} />}
          />
        </div>
      )}
    </div>
  );
};

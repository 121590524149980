import React, { useCallback, useState } from "react";
import { Button, Icon } from "../../../../uiKit";
import { useTranslation } from "react-i18next";
import { useNotifier } from "../../../../../functional/hooks";
import { api } from "../../../../../functional/services";
import { LucideSend } from "lucide-react";

export const TelegramBotFeature = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const notifier = useNotifier();

  const handleGetTelegramLink = useCallback(async () => {
    setIsLoading(true);
    const r = await api.user.getTelegramLink();
    setIsLoading(false);
    if (r !== null && (r ?? "").trim().length > 0) {
      window.open(r, "_blank", "noreferrer");
      return;
    }
    notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
  }, [notifier, t]);

  return (
    <Button
      onClick={handleGetTelegramLink}
      loading={isLoading}
      style={{
        height: 36
      }}
    >
      {t("ui:button.telegram_bot_settings")}
      <Icon children={<LucideSend size={15} />} />
    </Button>
  );
};

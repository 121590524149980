import React, { useState } from "react";
import AvatarCompanySettingsView from "./AvatarCompanySettingsView";
import { IAvatarCompanySettings } from "../../../../../functional/types";
import { useAvatarCompanySettings } from "../../../../../functional/hooks";

function AvatarCompanySettings(props: IAvatarCompanySettings) {
  const {
    isUploadDialogOpen,
    handleUploadDialogOpen,
    handleUploadDialogClose,
    handleUploadDialogSuccess,
    handleDeleteClick,
  } = useAvatarCompanySettings(props);

  return (
    <AvatarCompanySettingsView
      {...props}
      isUploadDialogOpen={isUploadDialogOpen}
      handleUploadDialogOpen={handleUploadDialogOpen}
      handleUploadDialogClose={handleUploadDialogClose}
      handleUploadDialogSuccess={handleUploadDialogSuccess}
      handleDeleteClick={handleDeleteClick}
    />
  );
}

export default AvatarCompanySettings;

import "./NotificationCenterPopover.scss";
import { useTranslation } from "react-i18next";
import { Badge, Switch, TabPane, Tabs, Text } from "../../../uiKit";
import { NotificationCenterTab } from "../notificationCenterTab/NotificationCenterTab";
import { INotificationCenterPopoverProps, TNotificationCenterTabKey } from "../../../../functional/types";
import { notificationCenterTabs } from "../../../../functional/constants";

export const NotificationCenterPopover = (props: INotificationCenterPopoverProps) => {
  const { t } = useTranslation();

  return (
    <div className="notification-center-popover__wrapper">
      <Tabs
        defaultActiveKey={props.currentTab}
        activeKey={props.currentTab}
        tabBarExtraContent={
          <div className="d-stack spacing-2 align-center">
            <Text className="no-select" children={t("parse:unread_only")} />
            <Switch
              checked={props.isUnreadOnly}
              onChange={(isChecked) => props.onUnreadOnlyChange(isChecked)}
              loading={Object.values(props.notificationData).some((v) => v.isLoading)}
            />
          </div>
        }
        tabBarStyle={{ marginBottom: 0, paddingRight: "4px" }}
        onChange={(key) => props.onCurrentTabChange(key as TNotificationCenterTabKey)}
      >
        {notificationCenterTabs.map((tab) => (
          <TabPane
            disabled={Object.values(props.notificationData).some((v) => v.isLoading)}
            key={tab.key}
            tab={
              <div className="d-stack spacing-2 align-center px-4">
                <span className="no-select" style={{ textTransform: "none" }} children={t(tab.name)} />
                <Badge
                  style={{
                    background:
                      props.currentTab == tab.key ? "var(--color-srf-accent)" : "var(--color-icn-default)",
                    color: props.currentTab == tab.key ? "var(--color-txt-alwayswhite)" : "var(--color-txt-inverse)",
                  }}
                  overflowCount={99}
                  count={props.unreadMessagesCount[tab.key]}
                  // count={getNotificationsCountByKey(nT.key as TNotificationsCenterTabs)}
                />
              </div>
            }
            children={
              <NotificationCenterTab
                notificationData={props.notificationData[tab.key]}
                tabKey={tab.key}
                unreadMessagesCount={props.unreadMessagesCount[tab.key]}
                notificationsWithInvertedReadStatusIds={props.notificationsWithInvertedReadStatusIds}
                loadingNotificationsIds={props.loadingNotificationsIds}
                isMarkAllAsReadLoading={props.isMarkAllAsReadLoading}
                onNotificationClick={props.onNotificationClick}
                onMarkAllAsReadClick={() => props.onMarkAllAsReadClick(tab.key)}
                onMarkAsReadUnreadClick={props.onMarkAsReadUnreadClick}
                onLoadMore={() => props.onLoadMore(tab.key)}
              />
            }
          />
        ))}
      </Tabs>
    </div>
  );
};

import React, {
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { MetricCreateEditDialogView } from "./MetricCreateEditDialogView";
import { useApi, useNotifier, useRootStore } from "../../../../functional/hooks";
import { api } from "../../../../functional/services";
import { useTranslation } from "react-i18next";
import { IMetricCreateEditDialog } from "./MetricCreateEditDialog.interface";
import { MetricDto } from "../../../../functional/api";
import { OrgchartContext } from "../../../../functional/contexts/orgchartContext";

export const MetricCreateEditDialog = memo(
  forwardRef((props: IMetricCreateEditDialog, ref) => {
    const { authStore } = useRootStore();
    const notifier = useNotifier();
    const { t } = useTranslation();
    const roleId: number | null = useContext(OrgchartContext)?.blockId ?? props.metrics[0]?.rolePositionId ?? null;

    const companyId = authStore.getInitialInfo?.identity?.currentCompanyId;
    const companyUiType = authStore.getCurrentCompanyUiType;
    const [defaultRolePositionId, setDefaultRolePositionId] = useState<number | null>(null);
    const [metric, setMetric] = useState<MetricDto | undefined>(undefined);
    const [isDoubleMetric, setIsDoubleMetric] = useState<boolean>(false);

    const companyData = useApi(
      () => api.company.getById(companyId as number),
      () => {},
      () => {}
    );

    const handleAscendingSortIntervals = useCallback(() => {
      if (companyData.value?.intervals) {
        return companyData.value?.intervals
          .map((i) => ({
            ...i,
            sortValue: i?.day! + i?.week! * 100 + i?.month! * 1000 + i?.year! * 10000,
          }))
          .sort((a, b) => a.sortValue - b.sortValue);
      }
      return undefined;
    }, [companyData.value?.intervals]);

    const initialInterval = useMemo(
      () => handleAscendingSortIntervals()?.find((i) => i.week != 0),
      [handleAscendingSortIntervals]
    );

    const mainMetric = useMemo(() => props.metrics?.find((metric) => metric.isMain), [props.metrics]);

    // const setRolePositionId = (r: RoleDto) => {
    //   se({ ...metric, rolePositionId: r!.positions?.find((x) => x.parentPositionId == null)?.id ?? null });
    // };

    const role = useApi(
      () => api.role.getById(roleId!),
      null,
      (x) => setDefaultRolePositionId(x!.positions?.find((x) => x.parentPositionId == null)?.id ?? null)
    );

    const handleIsDoubleMetricChange = useCallback(
      (isDouble: boolean) => {
        if (!isDouble) {
          setMetric({
            ...metric,
            sources: [...(metric?.sources?.filter((s) => s.order != 1) ?? [])],
          });
        }
        if (
          isDouble &&
          !metric?.sources?.find((s) => s.order == 1) &&
          !metric?.sources?.find((s) => s.order == 0)?.existingSourceId
        ) {
          handleChangeMetricData({
            ...metric,
            sources: [
              ...(metric?.sources?.map((s) => (s.order == 0 ? { ...s, isCreateForEachFiller: false } : s)) ?? []),
              {
                order: 1,
                fillerRolePositionId: metric?.sources?.[0]?.fillerRolePositionId,
                companyIntervalId: metric?.sources?.[0]?.companyIntervalId,
                existingSourceId: null,
              },
            ],
          });
        }
        setIsDoubleMetric(isDouble);
      },
      [metric]
    );

    const toggleCloseDialog = useCallback(() => {
      setMetric(undefined);
      setIsDoubleMetric(false);
      props.toggleDialog(false);
      props.toggleEditMode?.(false);
    }, [props]);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleCreateMetric = useCallback(async () => {
      if (metric?.isMain && mainMetric && metric.id != mainMetric.id) {
        setIsLoading(true);
        await api.metric.edit(mainMetric.id!, { ...mainMetric, isMain: false });
        const res = await api.metric.create(metric);
        setIsLoading(false);
        if (res) {
          notifier.show({
            message: t("notifier:success.metric_create"),
            theme: "success",
          });
          props.onSuccess?.();
          toggleCloseDialog();
          return;
        } else {
          notifier.show({
            message: t("notifier:error.something_wrong"),
            theme: "error",
          });
          return;
        }
      }
      if (metric) {
        setIsLoading(true);
        const r = await api.metric.create(metric);
        setIsLoading(false);
        if (r) {
          notifier.show({
            message: t("notifier:success.metric_create"),
            theme: "success",
          });
          props.onSuccess?.();
          toggleCloseDialog();
        } else {
          notifier.show({
            message: t("notifier:error.something_wrong"),
            theme: "error",
          });
        }
      }
    }, [mainMetric, metric, notifier, props, t, toggleCloseDialog]);

    const handleEditMetric = useCallback(async () => {
      if (metric?.isMain && mainMetric && metric.id != mainMetric.id) {
        setIsLoading(true);
        await api.metric.edit(mainMetric.id!, { ...mainMetric, isMain: false });
        setIsLoading(false);
        const res = props.metricId && (await api.metric.edit(props.metricId, metric));
        if (res) {
          notifier.show({
            message: t("notifier:success.metric_update"),
            theme: "success",
          });
          props.onSuccess?.();
          toggleCloseDialog();
          props.toggleEditMode?.(false);
          return;
        } else {
          notifier.show({
            message: t("notifier:error.something_wrong"),
            theme: "error",
          });
          props.toggleEditMode?.(false);
          return;
        }
      }
      if (metric) {
        setIsLoading(true);
        const r = props.metricId && (await api.metric.edit(props.metricId, metric));
        setIsLoading(false);
        if (r) {
          notifier.show({
            message: t("notifier:success.metric_update"),
            theme: "success",
          });
          props.onSuccess?.();
          toggleCloseDialog();
          props.toggleEditMode?.(false);
        } else {
          notifier.show({
            message: t("notifier:error.something_wrong"),
            theme: "error",
          });
        }
      }
      props.toggleEditMode?.(false);
    }, [mainMetric, metric, notifier, props, t, toggleCloseDialog]);

    // useImperativeHandle(
    //   ref,
    //   () => ({
    //     reset: () => {
    //       setMetric({
    //         rolePositionId: defaultRolePositionId,
    //         sources: [
    //           {
    //             companyInterval: initialInterval,
    //             order: 0,
    //             existingSourceId: null,
    //             companyIntervalId: initialInterval?.id,
    //             fillerRolePositionId: defaultRolePositionId,
    //           },
    //         ],
    //       });
    //     },
    //   }),
    //   [defaultRolePositionId, initialInterval]
    // );

    // const reset = useCallback(() => {
    //   console.log(123);
    //   setMetric({
    //     rolePositionId: defaultRolePositionId,
    //     sources: [
    //       {
    //         companyInterval: initialInterval,
    //         order: 0,
    //         existingSourceId: null,
    //         companyIntervalId: initialInterval?.id,
    //         fillerRolePositionId: defaultRolePositionId,
    //       },
    //     ],
    //   });
    // }, [[defaultRolePositionId, initialInterval]]);
    //
    // useEffect(() => {
    //   reset()
    // }, []);

    const handleChangeMetricData = (m: MetricDto) => setMetric(m);

    // useEffect(() => {
    //   setMetric((prev) => ({
    //     ...prev,
    //     sources: [{
    //       companyInterval: initialInterval,
    //       companyIntervalId: initialInterval?.id,
    //       order: 0,
    //       existingSourceId: null
    //     }]
    //   }));
    // }, [initialInterval]);

    useEffect(() => {
      if (!props.editMode) {
        setMetric((prev) => ({
          ...prev,
          sources: [{
            companyInterval: initialInterval,
            companyIntervalId: initialInterval?.id,
            order: 0,
            fillerRolePositionId: defaultRolePositionId,
            existingSourceId: null
          }],
          rolePositionId: defaultRolePositionId
        }));
      }
    }, [defaultRolePositionId, initialInterval]);


    useEffect(() => {
      if (props.metricId && props.metricId != 0 && props.editMode) {
        api.metric.getById(props.metricId).then((r) => {
          if (r) {
            setMetric(r);
          }
        });
      }
    }, [props.metricId, props.editMode]);

    return (
      <MetricCreateEditDialogView
        role={role.value ?? undefined}
        open={props.open}
        metric={metric}
        editMode={props.editMode}
        toggleDialog={toggleCloseDialog}
        onCreate={handleCreateMetric}
        onEdit={handleEditMetric}
        onChangeMetric={handleChangeMetricData}
        intervals={handleAscendingSortIntervals()}
        isDoubleMetric={isDoubleMetric}
        onIsDoubleMetricChange={handleIsDoubleMetricChange}
        mainMetric={mainMetric}
        roleId={roleId}
        companyUiType={companyUiType}
        isLoading={isLoading}
      />
    );
  })
);

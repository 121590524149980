import React, { useCallback, useEffect, useRef, useState, memo, useMemo } from "react";
import { UserCard } from "../../../../../elements/userCard/UserCard";
import { TextEditor as TextEditorLexical } from "../../../../textEditor/TextEditor";
import { ToolbarType } from "../../../../textEditor/types/types";
import { initialEditorContent } from "../../../../../../functional/utils/textEditor";
import { Button } from "../../../../../uiKit";
import { api } from "../../../../../../functional/services";
import { useTranslation } from "react-i18next";
import { useNotifier } from "../../../../../../functional/hooks";
import { IRegulationHistorySendBlock } from "../../../../../../functional/types/RegulationHistory.interface";
import { HistorySendBlock } from "../../../../../uiKit/History/HistorySendBlock";

export const RegulationHistorySendBlock = (props: IRegulationHistorySendBlock) => {
  const handleCreateRegulationHistory = useCallback(async (text?: string) => {
    const resp = await api.regulationHistory.create({
      regulationContentId: props.regulationContentId,
      comment: {
        content: text,
      },
    });
    if (resp) {
      props.regulationHistory.restart(true);
    }
    return !!resp;
  }, [props.regulationId, props.regulationContentId]);

  return (
    <HistorySendBlock
      itemId={props.regulationId}
      user={props.user}
      isLoading={props.regulationHistory?.info?.isLoading}
      onCreateHistory={handleCreateRegulationHistory}
    />
  )
}

import React, { createRef, memo, useCallback, useRef, useState } from "react";
import { UserCard } from "../../elements/userCard/UserCard";
import { TextEditor as TextEditorLexical } from "../../modules/textEditor/TextEditor";
import { ToolbarType } from "../../modules/textEditor/types/types";
import { initialEditorContent } from "../../../functional/utils/textEditor";
import { Button, Icon } from "../index";
import { useTranslation } from "react-i18next";
import { useNotifier } from "../../../functional/hooks";
import FileUploader from "../../service/fileUploader/FileUploader";
import { AiOutlinePaperClip } from "@react-icons/all-files/ai/AiOutlinePaperClip";
import "./HistoryComments.scss";
import { StaticFileDto } from "../../../functional/api";
import clsx from "clsx";

export const HistorySendBlock = memo((props: any) => {
  const { t } = useTranslation();
  const notifier = useNotifier();

  const uploadFilesInputRef = createRef<HTMLInputElement>();

  const editorOptionsRef = useRef<{
    resetEditor: (content?: string) => void;
    isEmptyEditor: () => boolean
    hiddenToolbar: () => void;
    setIsActivated: (value: boolean) => void;
  }>();

  const [isSendingLoading, setIsSendingLoading] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<StaticFileDto[]>([]);
  const attachmentsCopy = useRef<StaticFileDto[]>([]);

  const handleChangeAttachments = useCallback(
    (files: StaticFileDto[]) => {
      setAttachments(files);
      attachmentsCopy.current = files;
    },[]);

  const handleDeleteAttachmentById = useCallback((fileId: number) => {
    if (fileId === null) return;
    handleChangeAttachments((attachments ?? []).filter((x) => x.id !== fileId));
  }, [attachments]);


  const [historyContent, setHistoryContent] = useState<string>(initialEditorContent);
  const handleChangeCommentContent = useCallback((content: string) => {
    setHistoryContent(content);
  }, []);

  const [isFocus, setIsFocus] = useState<boolean>(false);
  const handleOnFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  const handleClickCancel = useCallback(() => {
    setHistoryContent('');
    editorOptionsRef.current?.resetEditor();
    editorOptionsRef.current?.hiddenToolbar();
    editorOptionsRef.current?.setIsActivated(false);
    localStorage.removeItem(`history-editor-send-${props.itemId}`);
    setIsFocus(false);
  }, [props.itemId]);

  const handleCreateHistory = useCallback(async (text?: string) => {
    if (editorOptionsRef.current?.isEmptyEditor()) {
      return;
    }
    setIsSendingLoading(true);
    const attachmentsList = props.isShowUpload ? [
      ...attachmentsCopy.current.map((files, index) => {
        return {
          fileId: files.id,
          order: index,
        };
      }),
    ] : undefined;
    const status = await props.onCreateHistory(text ?? historyContent, attachmentsList);
    if (status) {
      editorOptionsRef.current?.resetEditor();
      setHistoryContent(initialEditorContent);
      handleChangeAttachments([]);
      localStorage.removeItem(`history-editor-send-${props.itemId}`);
    } else {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" })
    }
    setIsSendingLoading(false)
  }, [props.itemId, historyContent, attachmentsCopy]);

  const handleClickUploadButton = () => {
    uploadFilesInputRef.current?.click();
  }



  return (
    <div className="history-comment">
      <div className={clsx("history-comment-row", {
        "history-comment-row_full": !props.user,
      })}>
        {!!props.user && (
          <div className="history-comment-row__user">
            <UserCard
              userInfo={props.user}
              avatarSize={32}
              hiddenUserInfo
            />
          </div>
        )}
        <div className="history-comment-wrap">
          <TextEditorLexical
            id={`history-editor-send-${props.itemId}`}
            variant="new-style"
            ref={editorOptionsRef}
            value={historyContent}
            toolbarType={ToolbarType.Dynamic}
            initialValue={initialEditorContent}
            onChange={handleChangeCommentContent}
            onFocus={handleOnFocus}
            placeholder={t("ui:placeholder.write_comment")}
            isSimplifiedToolbar={props.isSimplifiedToolbar}
            isShowBorder={props.isShowBorder}
          />
          {isFocus && (
            <div className="history-comment-footer">
              {props.isShowUpload && (
                <Button
                  className="history-upload-button"
                  onClick={handleClickUploadButton}
                  icon={<Icon component={() => <AiOutlinePaperClip />} />}
                  style={{ fontSize: "14px", fontWeight: "14px" }}
                  size="small"
                  type="text"
                >
                  {t("ui:button.attach")}
                </Button>
              )}
              <Button
                onClick={handleClickCancel}
                className="button-icon_17"
                variant="default"
              >
                {t("ui:button.cancel")}
              </Button>
              <Button
                onClick={() => handleCreateHistory()}
                disabled={editorOptionsRef.current?.isEmptyEditor()}
                loading={isSendingLoading || props.isLoading}
                className="button-icon_17"
                variant="filled"
              >
                {t("ui:button.send")}
              </Button>
            </div>
          )}
          {props.isShowUpload && (
            <FileUploader
              maxSizeMb={40}
              smallSize
              relatedInputId="comment-area-ctrlv-planning"
              value={attachments}
              onUpload={handleChangeAttachments}
              uploadFilesInputRef={uploadFilesInputRef}
              filesContainerMaxHeight="35vh"
              onAnyItemDelete={handleDeleteAttachmentById}
              onUploadStatusChange={props.setIsFileLoaderUploadingSync}
              hideInfo
            />
          )}

        </div>
      </div>
    </div>
  )
});

import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, Input, Text } from "../../../../uiKit";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import { TagIcon } from "../../../../../functional/constants/icon";
import "./CreateTagDialog.scss";
import { ColorPicker } from "../../../../uiKit/colorPicker/ColorPicker";
import { ICreateTagDialog } from "./CreateTagDialog.interface";
import { Form } from "antd";
import { api } from "../../../../../functional/services";
import { useNotifier } from "../../../../../functional/hooks";
import { TAG_WITH_THIS_NAME_ALREADY_EXISTS } from "../../../../../functional/constants/ApiErrorCode";

export const CreateTagDialog = (props: ICreateTagDialog) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [tagColor, setTagColor] = useState<string>("gray");
  const handleChangeColor = useCallback((color: string) => {
    setTagColor(color);
  }, []);

  const [tagTitle, setTagTitle] = useState<string>("");
  const handleInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTagTitle(e.target.value);
  }, []);

  const notifier = useNotifier();
  const handleApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const [isShowError, setIsShowError] = useState<boolean>(false);
  const clearError = useCallback(() => {
    setIsShowError(false);
  }, []);

  const onError = (err: any) => {
    const {data: { apiError } } =  err.response;
    if (apiError === TAG_WITH_THIS_NAME_ALREADY_EXISTS) {
      setIsShowError(true);
    } else {
      handleApiError();
    }
  }

  const handleCreateTag = useCallback(async () => {
    setIsLoading(true);
    const status = await api.tags.createTag({
      name: tagTitle,
      color: tagColor,
    }, onError);

    setIsLoading(false);
    if (status) {
      notifier.show({
        message: t("notifier:success.tag_create", { tag: tagTitle}),
        theme: "success",
      });
      props.resetData();
      props.onClose();
    } else {
      // handleApiError();
    }

  }, [tagColor, tagTitle]);

  const onFinish = () => {
    console.log("onFinish");
  }


  return (
    <Dialog
      closable={false}
      title={t("ui:create_tag_dialog.title")}
      onClose={props.onClose}
      open={true}
    >
      <Form
        requiredMark={false}
        layout="vertical"
        form={form}
        className="create-tag"
        onFinish={onFinish}
      >
        <Form.Item
          className="create-tag-row"
          name="tagName"
        >
          <SubheaderText text={t("ui:create_tag_dialog.label_name")} />
          <Input
            placeholder={t("ui:create_tag_dialog.label_placeholder")}
            onFocus={clearError}
            prefix={
              <TagIcon
                color="var(--color-text-second)"
                size={20}
              />
            }
            maxLength={30}
            value={tagTitle}
            onInput={handleInput}
            status={isShowError ? "error" : ""}
            // onBlur={props.updater.updatePartially}
          />
          { isShowError && (
            <Text
              className={"form-item-error"}
              size="12px"
              style={{ color: "var(--color-txt-error)" }}
              children={t("validation:tag_name")}
            />
          )}
        </Form.Item>
        <Form.Item
          className="create-tag-row"
          name="color"
        >
          <SubheaderText text={t("ui:create_tag_dialog.color")} />
          <ColorPicker
            activeColor={tagColor}
            onChangeColor={handleChangeColor}
            isRow
            isNoBorder
            isOpen
          />
        </Form.Item>
      </Form>
      <DialogActions>
        <Button
          onClick={props.onClose}
          variant="default"
        >
          {t("ui:button.cancel")}
        </Button>
        <Button
          onClick={handleCreateTag}
          variant="filled"
          loading={isLoading}
          disabled={!tagTitle}
        >
          { t("ui:button.create") }
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import React, { useEffect, useRef } from "react";
import ScrollTriggerView from "./ScrollTriggerView";
import { IScrollTrigger } from "../../../functional/types";
import { useScrollTrigger } from "../../../functional/hooks";

function ScrollTrigger(props: IScrollTrigger) {
  const { triggerRef, handleClick } = useScrollTrigger(props);

  return (
    <ScrollTriggerView
      fullWidth={props.fullWidth}
      triggerRef={triggerRef}
      className={props.className}
      marginTop={props.marginTop}
      disabled={props.disabled}
      hidden={props.hidden}
      style={props.style}
      debugMode={props.debugMode}
      onClick={handleClick}
    />
  );
}

export default ScrollTrigger;

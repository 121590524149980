import React from "react";
import { observer } from "mobx-react-lite";
import { useAuth } from "../../../../functional/hooks";
import LoginFormView from "./LoginFormView";

function LoginForm() {
  const {
    isLoading,
    handleLogin,
    handleClickGoToSignup,
  } = useAuth();

  return (
    <LoginFormView
      isLoading={isLoading}
      handleLogin={handleLogin}
      onClickGoToSignup={handleClickGoToSignup}
    />
  )
}

export default observer(LoginForm);

import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { DataNode } from "antd/es/tree";
import { useNotifier, useRootStore, useRegulationTreeData } from "../../../../../../functional/hooks";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalAuthorizedContext } from "../../../../../../functional/contexts/globalAuthorizedContext";
import { RegulationSidebarView } from "./RegulationSidebarView";
import { api } from "../../../../../../functional/services";
import { UserShortDto } from "../../../../../../functional/api";
import {
  AccessControlDialogKeys,
  TemplateDialogContentTypeKeys,
} from "../../../../dialogs/regulationAccessControlDialog/consts/consts";
import { RegulationAccessControlDialog } from "../../../../dialogs/regulationAccessControlDialog/RegulationAccessControlDialog";
import { RegulationCreationOrSelectionTemplateDialog } from "../../../../dialogs/regulationAccessControlDialog/regulationCreationOrSelectionTemplateDialog/RegulationCreationOrSelectionTemplateDialog";
import { CustomConfirmDialog } from "../../../../dialogs/customConfirmDialog/СustomConfirmDialog";
import { RegulationTreeNode } from "../../../../../../functional/api/models/RegulationTreeDto";
import "./RegulationSidebar.scss";
import { AddRegulationToSectionDto, NavigationMenuItemDto } from "../../../../../../functional/api/models/NavigationMenuDto";
import { defineAllow } from "../../../../../../functional/helpers/helpers";
import { allowsRegulationKeys } from "../../../../../../functional/constants/regulation/consts";

export interface IRegulationSidebarProps {
  isSidebarOpen: boolean;
  sidebarSize: { width: number };
  onSidebarOpenChange: () => void;
  onSidebarSizeChange: ({ width }: { width: number }) => void;
  onSidebarResizing: (isResizing: boolean) => void;
}

type AccessControlDialogStateType = {
  isOpenAccessControlDialog: boolean;
  isOpenFunctionSelectionDialog: boolean;
  isOpenCreationOrSelectionTemplateDialog: boolean;
  isOpenDeletingSectionDialog: boolean;
  contentType: TemplateDialogContentTypeKeys;
  id: number;
  isRegulation?: boolean;
  navigationItemId?: number;
  parentName?: string;
  createdByUser?: UserShortDto;
  parentId?: number;
  hasItems?: boolean;
};

export const RegulationSidebar = (props: IRegulationSidebarProps) => {
  const globalAuthorizedContext = useContext(GlobalAuthorizedContext);

  const {
    selectedKeys,
    treeData,
    isLoading,
    loadData,
    getSelectedKeysFromUri,
    setSelectedKeys,
    handleLoadData,
    fetchNavigationItemData,
    setTreeData,
    findElementById,
    generateTreeData,
    generateTreeDataItem,
    loadedKeys,
  } = useRegulationTreeData();

  const notifier = useNotifier();

  const navigate = useNavigate();
  const location = useLocation();
  const prevLocation = useRef<string>("");
  const { t } = useTranslation();
  const {regulationStore } = useRootStore();

  // const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [treeData, setTreeData] = useState<RegulationTreeNode[]>([]);
  // const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const [accessControlDialogsState, setAccessControlDialogsState] = useState<AccessControlDialogStateType>({
    isOpenAccessControlDialog: false,
    isOpenFunctionSelectionDialog: false,
    isOpenCreationOrSelectionTemplateDialog: false,
    isOpenDeletingSectionDialog: false,
    contentType: TemplateDialogContentTypeKeys.Selection,
    id: -1,
    isRegulation: false,
    navigationItemId: 0,
    parentName: '',
    createdByUser: undefined,
    hasItems: false,
  });

  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  // const loadData = async (isSoftLoad?: boolean) => {
  //   !isSoftLoad && setIsLoading(true);
  //   const list = await api.navigationMenu.getTree();
  //   if (list === null || !list.items) {
  //     notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
  //     setIsLoading(false);
  //     return;
  //   }
  //   generateTreeData(list.items);
  //   await getSelectedKeysFromUri();
  //   setIsLoading(false);
  // };

  const updateTreeData = (list: RegulationTreeNode[], key: React.Key, children: RegulationTreeNode[]): RegulationTreeNode[] => {
    return list.map((node) => {
      if (node.key === key) {
        return {
          ...node,
          children,
        };
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        };
      }
      return node;
    });
  };

  const generationKeys = (id: number, parentKey?: string) => {
    return parentKey ? `${parentKey}-s${id}` : `s${id}`;
  }

  const generationMenuItem = (child: NavigationMenuItemDto, navItem: RegulationTreeNode) => {
    const type = !!child.regulationId ? 'r' : 's';
    const title = !!child.regulationId ? child.regulation?.name : child.name ?? `${child.id}`;
    // console.log("sidebar generationMenuItem", child);
    const childMenuItem = generateTreeDataItem(
      {
        ...child,
        title,
        isLeaf: !!child.regulationId,
        key: `${navItem}-${type}${child.id}`,
      },
      child,
      // @ts-ignore
      navItem,
    );
    return childMenuItem;
  }

  const update = (list: RegulationTreeNode[], keys: string[], child: NavigationMenuItemDto): RegulationTreeNode[] => {
    const newList = list.map((navItem) => {
      const [navItemKey] = keys;
      if (navItem.key === navItemKey) {

        if (keys.length > 1) {
          keys.shift();
          const childrenList = navItem.children ? update(navItem.children, keys, child) : [];
          return {
            ...navItem,
            children: childrenList,
          }
        } else {
          const childMenuItem = generationMenuItem(child, navItem);
          const childrenList = navItem.children ?? [];
          const childrenIndex = childrenList.findIndex((child) => child.id === childMenuItem.id);
          if (childrenIndex !== -1) {
            childrenList.splice(childrenIndex, 1, childMenuItem);
          } else {
            childrenList.unshift(childMenuItem);
          }
          return {
            ...navItem,
            children: childrenList,
          };
        }
      }
      return navItem;
    });
    return newList;
  };

  const updateNavigationItemTreeData = (item: NavigationMenuItemDto, keys: string[]) => {
    setTreeData((prev) => {
      const newList = prev.map((navItem) => {
        // console.log("navItemKey", navItem);
        const [navItemKey] = keys;
        if (navItem.key === navItemKey) {
          if (keys.length > 1) {
            keys.shift();
            const childrenList = navItem.children ? update(navItem.children, keys, item) : [];
            return {
              ...navItem,
              children: childrenList,
            }
          } else {
            const childMenuItem = generationMenuItem(item, navItem);
            const childrenList = navItem.children ?? [];
            const childrenIndex = childrenList.findIndex((child) => child.id === childMenuItem.id);
            if (childrenIndex !== -1) {
              childrenList.splice(childrenIndex, 1, childMenuItem);
            } else {
              childrenList.unshift(childMenuItem);
            }
            return {
              ...navItem,
              children: childrenList,
            };
          }
        }
        return navItem;
      });
      return newList;
    });
  }

  const reloadDataForced = async (id?: number, isRegulation?: boolean, parentId?: number) => {
    if (id) {
      const list = isRegulation
        ? await api.navigationMenu.getTree(id, isRegulation)
        : await api.navigationMenu.getItemById(id);
      const navigationItemsList = isRegulation ? (list?.items ?? []) : [list];

      navigationItemsList.forEach(( navigationItem) => {
        const item =  navigationItem as NavigationMenuItemDto;
        const { breadcrumbs = [] } = item ?? {};
        const keys: string[] = [];
        // const lastNavigationItem = breadcrumbs.pop();
        if (breadcrumbs.length > 1) {
          breadcrumbs.pop();
          breadcrumbs.forEach(({ id }, i) => {
            keys.push(generationKeys(id, keys[i - 1]));
          });
          updateNavigationItemTreeData(item, keys);
        } else {
          setTreeData((prev) => {
            return prev.map((navItem) => {
              if (navItem.id === item?.id) {

                return generateTreeDataItem({
                    ...item,
                    hasItems: !!item.items?.length,
                    title: item.name ?? `${item.id}`,
                    key: "",
                    isRoot: true,
                    isLeaf: navItem.isLeaf ?? false,
                  },
                  item);
              }
              return navItem;
            });
          });
        }
      });
    } else {
      loadData(true);
    }
  };

  const getInfoFromUri = useCallback(() => {
    const { pathname } = location;
    let id, type;
    const pathParts = pathname.split("/");

    if (pathParts.includes("policy")) {
      if (pathParts[2] === "all") {
        type = "r";
        id = parseInt(pathParts[3], 10);
      } else if (pathParts[2] === "topics") {
        type = "s";
        id = parseInt(pathParts[3], 10);
      }
    }
    return { type, id };
  }, [location]);


  useEffect(() => {
    if (prevLocation.current && location.pathname !== prevLocation.current) {
      getSelectedKeysFromUri(true);
    }
  }, [location.pathname]);

  const handleExpand = useCallback((keys: string[], isChangeRoute?: boolean) => {
    setExpandedKeys((prev) => {
      const uniqClosedItem = keys.filter((item) => isChangeRoute ? prev : !prev.includes(item));
      const uniqOpenItem = prev.filter((item) => !keys.includes(item));
      return [...uniqOpenItem.concat(uniqClosedItem)];
    })
  }, []);

  const setSectionIsEditable = (isEditable: boolean) => regulationStore.setSectionIsEditable(isEditable);
  const setRegulationIsEditable = (isEditable: boolean) => regulationStore.setRegulationIsEditable(isEditable);

  const handleSelect = useCallback((id: number, parentId: number, isRegulation: boolean, key: React.Key) => {
    const regulationUri = parentId && parentId !== -1 ? `topics/${parentId}/policy/${id}` : `all/${id}`;
    const uri = isRegulation ? regulationUri : `topics/${id}`;
    const route = `/policy/${uri}`;
    prevLocation.current = route;
    setSectionIsEditable(false);
    setRegulationIsEditable(false);
    // setSelectedItems(key);
    const selectedKey = key as string;
    setSelectedKeys([selectedKey]);
    navigate(route);
  }, [location]);

  const handleAccessControlDialogOpen = useCallback(
    (
        key: AccessControlDialogKeys,
        // hasItems,
        id?: number,
        isRegulation?: boolean,
        navigationItemId?: number,
        hasItems?: boolean,
        parentName?: string,
        createdByUser?: UserShortDto,
        parentId?: number,
    ) => {
      setAccessControlDialogsState((prevState) => ({
        ...prevState,
        [key]: true,
        id: id ?? -1,
        isRegulation,
        navigationItemId,
        hasItems,
        parentName,
        createdByUser,
        parentId,
      }));
    }, []);

  const handleAccessControlDialogClose = useCallback((key: AccessControlDialogKeys) => {
    setAccessControlDialogsState((prevState) => ({
      ...prevState,
      [key]: false,
      id: -1,
      isRegulation: false,
      navigationItemId: 0,
      parentName: '',
      hasItems: false,
    }));
  }, []);

  const onOpenAccessControlDialog = useCallback((navigationItemId: number, createdByUser?: UserShortDto) => {
    // console.log("arg", arg);
    handleAccessControlDialogOpen(
      AccessControlDialogKeys.isOpenAccessControlDialog,
      undefined,
      undefined,
      navigationItemId,
      undefined,
      undefined,
      createdByUser,
    );
  }, []);
  const onOpenDeletingTopicDialog =
    useCallback((id: number, isRegulation: boolean, navigationItemId: number, hasItems?: boolean, parentName?: string, parentId?: number) => {
      handleAccessControlDialogOpen(
        AccessControlDialogKeys.isOpenDeletingSectionDialog,
        id,
        isRegulation,
        navigationItemId,
        hasItems ?? false,
        parentName,
        undefined,
        parentId,
      );
    }, []);

  // const handleSetContentType = useCallback((contentType: TemplateDialogContentTypeKeys) => {
  //   setAccessControlDialogsState((prevState) => ({ ...prevState, contentType }));
  // }, []);

  useEffect(() => {
    loadData();
    globalAuthorizedContext?.setState({
      ...globalAuthorizedContext,
      regulation: {
        ...globalAuthorizedContext?.regulation,
        reloadSidebarData: reloadDataForced,
      },
    });
  }, []);

  useEffect(() => {

    globalAuthorizedContext?.setState({
      ...globalAuthorizedContext,
      regulation: {
        ...globalAuthorizedContext?.regulation,
        reloadSidebarData: reloadDataForced,
      },
    });


  }, [treeData]);

  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const handlerApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const handleDeleteNavigationItem = async () => {
    const {
      id, isRegulation, navigationItemId, parentName, parentId
    } = accessControlDialogsState;
    setIsConfirmLoading(true);
    const resp = navigationItemId
      ? await api.navigationMenu.deleteItem(navigationItemId)
      : await api.regulation.del(id!);
    setIsConfirmLoading(false);
    if (resp !== null) {
      if (isRegulation) {
        const notifierText = parentName
          ? "notifier:success.regulations_moved_non_section"
          : "notifier:success.delete_regulation";
        notifier.show({ message: t(notifierText), theme: "success" });
      } else {
        notifier.show({ message: t("notifier:success.delete_section"), theme: "success" });
      }
      console.log("parentId", parentId);
      handleAccessControlDialogClose(AccessControlDialogKeys.isOpenDeletingSectionDialog);
      const navigationMenuItemId = id === navigationItemId ? 0 : id;
      reloadDataForced(isRegulation
        ? parentId ?? navigationMenuItemId
        : navigationMenuItemId,
        parentId ? false : isRegulation,
      );
    } else {
      handlerApiError();
    }
  };

  // @ts-ignore
  const onDrop = useCallback(async (info) => {
    console.log("info", info);
    const {
      key: dragKey,
      navigationItem: {
        id: dragNodeId,
        regulationId,
        actions,
      },
      parentActions,
    } = info.dragNode;

    console.log("parenActions", parentActions);
    if (!defineAllow(allowsRegulationKeys.edit, parentActions ?? actions ?? [])) {
      return;
    }
    // const dropKey = info.node.key;
    // const isLeaf = info.node.isLeaf;
    const { navigationItem, key: dropKey, isLeaf, parentActions: dropParenActions = [] } = info.node;
    const { parentId, id, actions: dropActions = [] } = navigationItem;

    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]); // the drop position relative to the drop node, inside 0, top -1, bottom 1
    const isInside = dropPosition === 0 && !isLeaf;

    const newParentId = isInside ? id : parentId ?? null;

    if ((regulationId && newParentId) && !defineAllow(allowsRegulationKeys.edit, isInside ? dropActions : dropParenActions)) {
      return;
    }

    if (!newParentId && regulationId) {
      if (dragNodeId) {
        await api.navigationMenu.deleteItem(dragNodeId);
        await reloadDataForced();
      }
      return;
    }

    const insertAfterItemId = dropPosition === -1 || isInside ? undefined : id;
    const moveData = dragNodeId ? {
      itemIds: [dragNodeId],
      newParentId,
      insertAfterItemId,
    } : {
      id: newParentId,
      regulationId,
      insertAfterItemId,
    }

    const loop = (
      // @ts-ignore
      data: TreeDataNode[],
      key: React.Key,
      // @ts-ignore
      callback: (node: TreeDataNode, i: number, data: TreeDataNode[]) => void,
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children!, key, callback);
        }
      }
    };
    const data = [...treeData];

    // Find dragObject
    // @ts-ignore
    let dragObj: TreeDataNode;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    dragObj.style = { fontWeight: !newParentId && !isLeaf ? "bold" : "normal"};
    if (!info.dropToGap && !isLeaf) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
        item.children.unshift(dragObj);
      });
    } else {
      // @ts-ignore
      let ar: TreeDataNode[] = [];
      let i: number;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        // Drop on the top of the drop node
        ar.splice(i!, 0, dragObj!);
      } else {
        // Drop on the bottom of the drop node
        ar.splice(i! + 1, 0, dragObj!);
      }
    }
    setTreeData(data);
    if (dragNodeId) {
      await api.navigationMenu.move(moveData);
    } else {
      const resp = await api.navigationMenu.addRegulationToSection(moveData as AddRegulationToSectionDto);
      console.log("resp", resp);
    }
  }, [treeData]);

  const handleCreateTopic = useCallback(() => {
    regulationStore.setParentId(-1);
    navigate('/policy/create-topic');
  }, []);

  const confirmDialogSubTitle = useMemo(() => {
    if (accessControlDialogsState.isRegulation) {
      if (accessControlDialogsState.parentName) {
        return  t("ui:confirm_dialog_remove_from_section.text", { sectionTitle: accessControlDialogsState.parentName ?? ""});
      }
      return  t("ui:text.delete_regulation");
    }
    return accessControlDialogsState.hasItems ? t("ui:text.delete_not_empty_topic") : t("ui:text.delete_topic");
  }, [accessControlDialogsState.isRegulation, accessControlDialogsState.parentName, accessControlDialogsState.hasItems]);

  const confirmDialogTitle = useMemo(() => {
    if (accessControlDialogsState.isRegulation) {
      return t("ui:confirm_dialog_remove_from_section.title")
    }
    return accessControlDialogsState.hasItems ? t("ui:title.delete_not_empty") : t("ui:title.confirm_deletion")
  }, [accessControlDialogsState.isRegulation, accessControlDialogsState.hasItems]);

  return (
    <>
      {accessControlDialogsState.isOpenAccessControlDialog && (
        <RegulationAccessControlDialog
          isOpen={accessControlDialogsState.isOpenAccessControlDialog}
          onClose={() => handleAccessControlDialogClose(AccessControlDialogKeys.isOpenAccessControlDialog)}
          sectionId={accessControlDialogsState.navigationItemId}
          createdByUser={accessControlDialogsState.createdByUser}
        />
      )}

      {accessControlDialogsState.isOpenCreationOrSelectionTemplateDialog && (
        <RegulationCreationOrSelectionTemplateDialog
          isOpen={accessControlDialogsState.isOpenCreationOrSelectionTemplateDialog}
          onClose={() =>
            handleAccessControlDialogClose(AccessControlDialogKeys.isOpenCreationOrSelectionTemplateDialog)
          }
          contentType={accessControlDialogsState.contentType}
        />
      )}
      {accessControlDialogsState.isOpenDeletingSectionDialog && (
        <CustomConfirmDialog
          open={accessControlDialogsState.isOpenDeletingSectionDialog}
          onClose={() => handleAccessControlDialogClose(AccessControlDialogKeys.isOpenDeletingSectionDialog)}
          isHiddenCloseButton={accessControlDialogsState.hasItems}
          onConfirm={() => accessControlDialogsState.hasItems
            ? handleAccessControlDialogClose(AccessControlDialogKeys.isOpenDeletingSectionDialog)
            : handleDeleteNavigationItem()
          }
          title={confirmDialogTitle}
          subTitle={confirmDialogSubTitle}
          loading={isConfirmLoading}
          buttonText={{
            confirm: accessControlDialogsState.hasItems ?  t("ui:button.close") : undefined,
          }}
        />
      )}
      <RegulationSidebarView
        isLoading={isLoading}
        selectedKeys={selectedKeys}
        // expandedKeys={[...new Set([...autoExpandedKeys, ...manualExpandedKeys])]}
        expandedKeys={expandedKeys}
        items={treeData}
        loadedKeys={loadedKeys}
        onExpand={handleExpand}
        onSelect={handleSelect}
        onLoadData={handleLoadData}
        onOpenAccessControlDialog={onOpenAccessControlDialog}
        onOpenDeletingTopicDialog={onOpenDeletingTopicDialog}
        onDrop={onDrop}
        onCreateTopic={handleCreateTopic}
        {...props}
      />
    </>
  );
};

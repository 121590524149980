import React, { ChangeEvent, memo, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, Input } from "../../../uiKit";
import { IDashboardCreateEditDialogView } from "./DashboardCreateEditDialog.interface";
import { Form } from "antd";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import ExecutorSelect from "../../../elements/executorSelect/ExecutorSelect";
import { UserSelectionRule } from "../../../../functional/api/types/userSelectionRule";
import { MetricToDashboardDialog } from "../metricToDashboardDialog/MetricToDashboardDialog";
import { MetricToDashboardDialogCard } from "../../pages/dashboards/components/MetricToDashboardDialogCard/MetricToDashboardDialogCard";
import { ColumnsSelect } from "../../pages/dashboards/components/ColumnsSelect/ColumnsSelect";
import { closestCorners, DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";

export const DashboardCreateEditDialogView = memo((props: IDashboardCreateEditDialogView) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const sensors = useSensors(
    useSensor(PointerSensor)
    // useSensor(KeyboardSensor, {
    //   coordinateGetter: sortableKeyboardCoordinates,
    // })
  );

  const formItemRules = useMemo(
    () => [
      {
        required: true,
        message: t("validation:field_required"),
      },
    ],
    [t]
  );

  const handleSubmit = useCallback(() => form.submit(), [form]);

  const handleCreate = useCallback(() => props.createDashboard(), [props]);

  const handleEdit = useCallback(() => props.editDashboard(), [props]);

  const handleClose = useCallback(() => props.onClose(), [props]);

  const handleFinish = useCallback(() => {
    props.editMode ? handleEdit() : handleCreate();
  }, [handleCreate, handleEdit, props.editMode]);

  const handleGetDashboardDialogTitle = useCallback(() => {
    return props.editMode ? t("ui:title.edit_dashboard") : t("ui:title.create_dashboard");
  }, [props.editMode, t]);

  const handleOpenDeleteDashboardDialog = useCallback(() => props.onOpenDeleteDashboardDialogChange(true), [props]);

  const handleOpenMetricToDashboardDialog = useCallback(() => props.onOpenMetricToDashboardDialog(true), [props]);

  const handleDashboardNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      props.onChangeDashboard({
        ...props.dashboard,
        name: event.currentTarget.value,
      });
    },
    [props]
  );

  const handleDashboardDescriptionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      props.onChangeDashboard({ ...props.dashboard, description: event.currentTarget.value });
    },
    [props]
  );

  useEffect(() => {
    form.setFieldsValue({
      name: props.dashboard?.name,
    });
  }, [form, props.dashboard]);

  return (
    <>
      <MetricToDashboardDialog
        open={props.openMetricToDashboardDialog}
        toggleDialog={props.onOpenMetricToDashboardDialog}
        onSuccess={props.onChangeDashboard}
        dashboard={props.dashboard}
      />
      <Dialog
        destroyOnClose={true}
        title={handleGetDashboardDialogTitle()}
        open={props.open}
        onClose={handleClose}
        closable={false}
        // scrollStrategy="dialogBody"
      >
        <Form form={form} onFinish={handleFinish} autoComplete="off">
          <SubheaderText text={t("ui:subheader.dashboard_name")} />
          <Form.Item name="name" rules={formItemRules}>
            <Input
              value={props.dashboard?.name ?? ""}
              onChange={handleDashboardNameChange}
              maxLength={30}
              placeholder={t("ui:placeholder.issue_name")}
            />
          </Form.Item>
          <SubheaderText text={t("ui:subheader.issue_description")} />
          <Form.Item rules={formItemRules}>
            <Input
              value={props.dashboard?.description ?? ""}
              onChange={handleDashboardDescriptionChange}
              maxLength={30}
              placeholder={t("ui:placeholder.issue_description")}
            />
          </Form.Item>
          <SubheaderText text={t("ui:subheader.dashboard_column_count")} />
          <Form.Item rules={formItemRules}>
            <ColumnsSelect
              value={props.dashboard?.columnCount}
              onChange={(value) => props.onChangeDashboard({ ...props.dashboard, columnCount: value })}
              size="large"
            />
          </Form.Item>
          <SubheaderText text={t("ui:subheader.select_metrics")} />
          {/*<Form.Item>*/}
          {/*<AutocompleteAsync*/}
          {/*  type="metric"*/}
          {/*  mode="multiple"*/}
          {/*  // value={props.dashboard.metricSource2Users}*/}
          {/*  onChange={*/}
          {/*    (metrics: MetricSource2UserDto[]) => {}*/}
          {/*    // props.onChangeDashboard({ ...props.dashboard, metricSource2Users: metrics })*/}
          {/*  }*/}
          {/*/>*/}
          <div className="br-md metric-to-dashboard-dialog-card__wrapper">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCorners}
              modifiers={[restrictToVerticalAxis, restrictToParentElement]}
              onDragEnd={props.handleMetricDragEnd}
            >
              {/*<React.Fragment key={m.metricId! * index}>*/}
              {/*  {index > 0 ? <Divider style={{ opacity: "0.5" }} /> : null}*/}
              {/*@ts-ignore - id*/}
              <SortableContext
                items={(props.dashboard?.metrics ?? []).map((m) => m.order) as number[]}
                strategy={verticalListSortingStrategy}
              >
                {props.dashboard?.metrics
                  // ?.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
                  // ?.sort((a, b) => a.metric?.name?.localeCompare(b.metric?.name ?? "") as number)
                  ?.map((m) => (
                    <MetricToDashboardDialogCard
                      key={m.metricId! + "/" + m.order}
                      metric={m}
                      metrics={props.metrics ?? []}
                      deleteMetric={props.onDeleteMetric}
                    />
                  ))}
              </SortableContext>
            </DndContext>
          </div>
          <Button
            variant="default"
            className={`${props.dashboard?.metrics?.length ? "mt-2 mb-2" : "mb-2"}`}
            onClick={handleOpenMetricToDashboardDialog}
          >
            {t("ui:button.add")}
          </Button>
          {/*</Form.Item>*/}
          <Form.Item>
            {props.dashboard?.userSelectionRules && (
              <ExecutorSelect
                isWhoInNaming
                value={props.dashboard?.userSelectionRules as (UserSelectionRule & { idx?: number })[]}
                onChange={(role) =>
                  props.onChangeDashboard({
                    ...props.dashboard,
                    userSelectionRules: role,
                  })
                }
              />
            )}
          </Form.Item>
        </Form>
        <DialogActions>
          {props.editMode ? (
            <Button onClick={handleOpenDeleteDashboardDialog} variant="default">
              {t("ui:button.delete_dashboard")}
            </Button>
          ) : (
            <Button onClick={handleClose} variant="default">
              {t("ui:button.cancel")}
            </Button>
          )}
          <Button
            onClick={handleSubmit}
            variant="filled"
            loading={props.isLoading}
          >
            {t("ui:button.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

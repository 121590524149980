import React from "react";
import { useRootStore } from "../functional/hooks";
import { observer } from "mobx-react-lite";
import { TopAppBar } from "../components/modules/layouts/topAppBar/TopAppBar";
import LeftAppDrawer from "../components/modules/layouts/leftAppDrawer/LeftAppDrawer";
import { Alert, Layout, LayoutContent } from "../components/uiKit";
import CompanySettingsOnFirstLoginDialog from "../components/modules/dialogs/companySettingsOnFirstLoginDialog/CompanySettingsOnFirstLoginDialog";
import EmployeeSettingsOnFirstLoginDialog from "../components/modules/dialogs/employeeSettingsOnFirstLoginDialog/EmployeeSettingsOnFirstLoginDialog";
import VideoDialog from "../components/modules/dialogs/videoDialog/VideoDialog";
import { IssueUpdateWrapper } from "../components/service/issueUpdateWraper/IssueUpdateWrapper";
import { CommonDialogWrapperByQP } from "../components/service/commonDialogWrapperByQP/CommonDialogWrapperByQP";
import { useTranslation } from "react-i18next";
import { GlobalAuthorizedContextProvider } from "../functional/contexts/globalAuthorizedContext";
import { GuideDrawer } from "../components/modules/drawers/guide/GuideDrawer";
import { ShowUpdateNoteDialog } from "../components/modules/dialogs/showUpdateNoteDialog/ShowUpdateNoteDialog";
import { TimezoneNotificationDialog } from "../components/modules/dialogs/timezoneNotificationDialog/TimezoneNotificationDialog";
import { IAuthorizedLayout } from "../functional/types";
import { useAuthorized } from "../functional/hooks/Authorized/useAuthorized";
import { EndAccessTimeDialog } from "../components/modules/dialogs/endAccessTimeDialog/EndAccessTimeDialog";

function AuthorizedLayout(props: IAuthorizedLayout) {
  const { authStore, appStore } = useRootStore();
  const { t } = useTranslation();

  const {
    isShowBetaAlert,
    isShowEndAccessTimeDialog,
    currentCompany,
    currentUser,
    isResident,
    isResidentEndAccessTime,
    clientName,
    handleCloseEndAccessTimeDialog,
  } = useAuthorized(props);

  return (
    <GlobalAuthorizedContextProvider>
      <TopAppBar />
      <CommonDialogWrapperByQP />
      <IssueUpdateWrapper />
      <CompanySettingsOnFirstLoginDialog />
      <EmployeeSettingsOnFirstLoginDialog />
      <GuideDrawer />
      <ShowUpdateNoteDialog />
      <TimezoneNotificationDialog />
      {authStore.isAuthorized && (
        <>
          {isShowEndAccessTimeDialog && (
            <EndAccessTimeDialog
              endAccessTime={currentCompany?.endAccessTime!}
              userOwnerEmail={currentCompany?.userOwner?.email ?? ""}
              isResident={isResident}
              isResidentEndAccessTime={isResidentEndAccessTime}
              clientName={clientName}
              onClose={handleCloseEndAccessTimeDialog}
            />
          )}

          <VideoDialog
            trainingKey="ed.b.s"
            name="profile"
            onWatchedClickObject={{ profile: true }}
            hidden={
              currentUser?.name != null &&
              currentUser?.name.trim()?.length > 0
            }
          />
          <VideoDialog
            trainingKey="ed.b.o"
            name="overview"
            onWatchedClickObject={{ overview: true }}
            hidden={
              currentUser?.name != null &&
              currentUser?.name.trim()?.length > 0
            }
          />
          <VideoDialog
            trainingKey="ed.b.i"
            name="start"
            onWatchedClickObject={{ start: true }}
            hidden={
              currentUser?.name != null &&
              currentUser?.name.trim()?.length > 0
            }
          />
        </>
      )}
      <Layout className={"authorized-layout"} style={{ marginTop: "56px" }}>
        <LeftAppDrawer
          pageKey={props.pageKey}
        />
        <LayoutContent
          className={`app-content__wrapper  app-content__wrapper_${props.pageKey}`}
        >
          {isShowBetaAlert && (
              <Alert type="warning" className="py-1 px-2" message={t("parse:section_under_construction")} />
            )}
          {appStore.getCanDrawMainLayout ? props.children : <div />}
        </LayoutContent>
      </Layout>
      {/*<LayoutFooter className="px-4 py-2" style={{ background: "var(--color-primary-weak)" }}>*/}
      {/*  <div className="d-flex justify-space-between align-center ml-auto mr-auto" style={{ maxWidth: "768px" }}>*/}
      {/*    /!*  <div className="flex-grow-1" />*!/*/}
      {/*    /!*</div>*!/*/}
      {/*    /!*<div>*!/*/}
      {/*    /!*<Text children="We have an App!" />*!/*/}
      {/*    <Button*/}
      {/*      onClick={() => window.open("intent://my.tonnus.io/#Intent;scheme=https;package=io.tonnus;end")}*/}
      {/*      children={"Open the App"}*/}
      {/*    />*/}
      {/*    <NotificationsCenter />*/}
      {/*  </div>*/}
      {/*</LayoutFooter>*/}
    </GlobalAuthorizedContextProvider>
  );
}

export default observer(AuthorizedLayout);

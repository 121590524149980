import React from "react";
import { Icon, IconButton } from "../../../../../uiKit";
import { IArchiveButtonView } from "./ArchiveButton.interface";
import { IoArchiveOutline } from "@react-icons/all-files/io5/IoArchiveOutline";

export const ArchiveButtonView = (props: IArchiveButtonView) => {
  return (
    <IconButton
      onClick={props.onClick}
      children={<Icon component={() => <IoArchiveOutline color={"var(--color-icn-accent)"} />} />}
    />
    // <Button
    //   className="filter-communications-button"
    //   onClick={props.onClick}
    //   variant="default"
    //   icon={<IoArchiveOutline style={{ margin: "0 auto" }} size="20px" className="filter-communications-icon" />}
    // />
  );
};

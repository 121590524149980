import React, { useRef, useState } from "react";
import TaskRecurringCreateEditForm from "../../forms/taskRecurringCreateEditForm/TaskRecurringCreateEditForm";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions } from "../../../uiKit";
import { IRecurringIssueCreateEditDialog } from "../../../../functional/types";
import { useRecurringIssueCreateEditDialog } from "../../../../functional/hooks";

function RecurringIssueCreateEditDialogView(props: IRecurringIssueCreateEditDialog) {
  const { t } = useTranslation();
  const {
    createEditFormRef,
    onFormLoadingChange,
    isLoading,
  } = useRecurringIssueCreateEditDialog();

  return (
    <Dialog
      width={1200}
      open={props.open}
      onClose={() => props.onClose()}
      destroyOnClose
      scrollStrategy="dialogBody"
      title={props.data ? t("parse:editing_routine") : t("parse:creating_routine")}
      closable={false}
    >
      {props.open && (
        <TaskRecurringCreateEditForm
          ref={createEditFormRef}
          onFormLoadingChange={onFormLoadingChange}
          hideSaveButton
          {...props}
        />
      )}
      <DialogActions>
        <Button
          onClick={() => {
            localStorage.removeItem(
              props.data
                ? "recurring-issue-form-description-editor" + props.data.id
                : "recurring-issue-form-description-editor"
            );
            localStorage.removeItem(
              `issue-form-proof_description-${props.data?.id ?? -1}`
            );
            props.onClose();
          }}
          variant="text"
        >
          {t("ui:button.cancel")}
        </Button>
        <Button loading={isLoading} onClick={() => createEditFormRef.current?.saveTask()} variant="filled">
          {t("ui:button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RecurringIssueCreateEditDialogView;

import React, { memo, useMemo } from "react";
import { IssueActivityText } from "../issueActivityText/issueActivityText";
import { IIssueActivityStatusRow } from "../../../../../../../../functional/types/IssueActivityStatusRow.interface";
import { Icon } from "../../../../../../../uiKit";
import { ChevronRight } from "lucide-react";
import "./IssueActivityStatusRow.scss";
import { useTranslation } from "react-i18next";


export const IssueActivityStatusRow = memo((props: IIssueActivityStatusRow) => {
  const { t } = useTranslation();

  const prevStatusText = props.previousBoardStatus ? t(`ui:issue_activity.issue_status.${props.previousBoardStatus.key}`) : "";
  const statusText = t(`ui:issue_activity.issue_status.${props.boardStatus.key}`);


  const prevTextBackground = useMemo(() => {
    return props.previousBoardStatus ? "var(--color-calendar-default1)" : undefined
  }, [props.previousBoardStatus]);

  const textBackground = useMemo(() => {
    return props.previousBoardStatus ? "var(--color-calendar-primary-weaker1)" : "var(--color-calendar-default1)"
  }, [props.previousBoardStatus]);

  return (
    <div className={"issue-activity-text-row"}>
      {!!prevStatusText && (
        <>
          <IssueActivityText
            background={prevTextBackground}
            text={prevStatusText}
            isInlineText
          />
          <Icon
            component={() => <ChevronRight size={16}/>}
          />
        </>
      )}
      <IssueActivityText
        background={textBackground}
        text={statusText}
        isInlineText
      />
    </div>
 )
})

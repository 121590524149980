import React, { createRef, memo, useEffect, useMemo, useState } from "react";
import { Tag } from "../../dataDisplay/tags/tag/Tag";
import { ColorPicker } from "../../colorPicker/ColorPicker";
import "./SelectTagsOption.scss";
import clsx from "clsx";
import { createPortal } from "react-dom";
import { useTagsColor } from "../../../../functional/hooks/useTagColor";

export const SelectTagsOption = memo((props: any) => {
  const {
    borderColor,
    background,
    color,
  } = useTagsColor(props.tag.color ?? "gray");

   const handleRemoveTag = () => {
    props.onRemoveTag(props.tag);
  };

  const handleChangeColor = (color: string) => {
    props.onChangeColor({...props.tag, color});
  };

  const [colorPickerPosition, setColorPickerPosition] = useState({
    top: 0,
    left: 0,
  });
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const handleOpenChange = (value: boolean) => {
    if (value) {
      const { top = 0, left = 0 } = tagAction.current?.getBoundingClientRect() ?? {};
      setColorPickerPosition({
        top: top + 24,
        left,
      });
    }
    setIsOpenDropdown(value);
  };

  const [editTag, setEditTag] = useState<any>(null);

  const handleRenameTag = (e: any) => {
    setEditTag(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();

    const { key } = e;
    switch (key) {
      case "Enter":
        props.updateTag({
          ...props.tag,
          value: editTag,
        });
        setEditTag(null);
        props.onChangeRenameTag("");
        break;
      case "Escape":
        setEditTag(null);
        props.onChangeRenameTag("");
        break;
    }
  }

  useEffect(() => {
    if (props.renameTag === props.tag.value) {
      setEditTag(props.tag.value);
    }
  }, [props.renameTag]);

  const isRenameTag = useMemo(() => {
    return props.renameTag === props.tag.value;
  }, [props.renameTag, props.tag.value]);

  const tagAction = createRef<HTMLDivElement>();

  return (
    <>
      {props.showColorPicker === props.tag.value &&
        createPortal(
          <div
            style={{
            position: "fixed",
            top: `${colorPickerPosition.top}px`,
            left: `${colorPickerPosition.left}px`,
            zIndex: 10000,
          }}
          >
          <ColorPicker
            activeColor={props.tag?.color}
            onChangeColor={handleChangeColor}
          />
        </div>
       ,document.body)}
    <div
      className={clsx("selected-tag", {
        "selected-tag_active": isOpenDropdown || props.showColorPicker === props.tag.value
      })}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <Tag
        closable
        className="d-flex align-center justify-center"
        style={{
          height: "24px",
          borderColor,
          background,
          color,
        }}
        bordered={false}
        onClose={handleRemoveTag}
      >
        <span
          className={"selected-tag__value"}
        >
          { props.tag.label }
        </span>
      </Tag>
    </div>
      </>
  )
});

import React, { memo, useCallback, useContext, useEffect, useRef, useState } from "react";
import { IListIssuesView } from "../../../../../../functional/types/ListIssues.interface";
import { Droppable } from "react-beautiful-dnd";
import clsx from "clsx";
import { CounterChip } from "../../../../../elements/counterChip/CounterChip";
import { ColorModeKeys } from "../../../../../elements/counterChip/misc/keys";
import { DragPreloader } from "./components/dragPreloader/DragPreloader";
import { NoDataBlock } from "./components/noDataBlock/NoDataBlock";
import { Text } from "../../../../../uiKit";
import { CardIssue } from "../cardIssue/CardIssue";
import { useRootStore } from "../../../../../../functional/hooks";
import { observer } from "mobx-react-lite";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";
import { BoardFilterContext, CollapseContext } from "../../../../../../functional/contexts";
import { useTranslation } from "react-i18next";

export const ListIssuesView = memo((props: IListIssuesView) => {
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const { fromColumnId, possibilityStatuses } = useContext(CollapseContext);
  const { isGroupingByOrgcharts, zoom } = useContext(BoardFilterContext);

  const isEmpty = !props.isLoading && !props.issues.length;

  const isDisabledMove = possibilityStatuses
    ? fromColumnId === props.column?.id || ![...possibilityStatuses]?.includes(props.status?.id ?? 0)
    : true;
  const columnStyles = clsx("todo-list", "px-2");

  return (
    <div className={columnStyles}>
      <div className="d-stack-column justify-center align-center" style={{ padding: "0px 16px" }}>
        <div className="d-flex d-stack-row align-center mb-2 mt-2" style={{ width: "100%" }}>
          <Text style={{ fontWeight: 800 }}>
            { t(`ui:issue_status.${props.column?.key}`) }
          </Text>
          {!!props.issues.length && props.column?.order !== 5 && (
            <CounterChip className="ml-2" colorMode={ColorModeKeys.primary} count={props.totalItems} />
          )}
        </div>
      </div>
      <Droppable
        key={props.status?.id}
        isDropDisabled={possibilityStatuses && isDisabledMove}
        droppableId={props.status?.id!.toString() ?? ""}

      >
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{zoom: 1}}
          >
            {isEmpty && <NoDataBlock />}
            {!!fromColumnId && fromColumnId !== props.status?.id && (
              <DragPreloader
                isLoading={!!possibilityStatuses}
                isCanBeMove={!isDisabledMove}
                boardStatus={props.column?.name}
              />
            )}

            {props.issues?.map((issue, index) => (
              <div
                key={issue.id}
                style={{
                  zoom: props.draggingIssueId === issue.id ? 1 / (zoom / 100) : 1
                }}
              >
                <CardIssue
                  noDrag={issue.executorUserId !== authStore.getInitialInfo?.identity?.id}
                  index={index}
                  key={issue.id}
                  issue={issue}
                  isGroupingByOrgcharts={isGroupingByOrgcharts}

                />
              </div>
            ))}
            {!props.isDone && props.issues?.length ? (
              <ScrollTrigger
                onIntersection={props.loadNext}
                marginTop={false}
              />
            ) : null}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
});

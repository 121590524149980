import React, { useCallback } from "react";
import { Button, Dialog, DialogActions } from "../../../uiKit";
import { useTranslation } from "react-i18next";
import { UserCard } from "../../../elements/userCard/UserCard";
import "./RequestChangeAccessTypeDialog.scss";
import { useNotifier } from "../../../../functional/hooks";
import { INoAccessResource } from "./RequestChangeAccessTypeDialog.interface";

export const RequestChangeAccessTypeDialog = (props: INoAccessResource) => {
  const { t } = useTranslation();
  const notifier = useNotifier();

  const handleClickCopyLink = useCallback(async () => {
    await navigator.clipboard
      .writeText(props.item.link)
      .then(() => notifier.show({ message: t("notifier:success.copy"), theme: "success" }))
      .catch(() => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" }));
  }, [props.item.link]);

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      bodyStyle={{ paddingBottom: 4, maxWidth: 645 }}
      title={t("ui:request_change_access_type_dialog.title", {type: props.accessType})}
      isNewDesign
      className={"request-change-access-type-dialog"}
    >
      <div className="request-change-access-type">
        <div className="request-change-access-type__text">
          { props.item.isSection
            ? t("ui:request_change_access_type_dialog.section_text")
            : t("ui:request_change_access_type_dialog.text")
          }
        </div>
        <div className="request-change-access-type-content">
          <div className="request-change-access-type-content-wrap">
            <UserCard
              userInfo={props.item.user}
              boldName
              avatarSize={20}
              // additionalInfo={`${props.dateSented}`}
              isNewStyle={true}
              nameSize={12}
            />
            <div className="request-change-access-type-content__title">
              { props.item.title }
            </div>
          </div>
          <div className="request-change-access-type-content__button">
            <Button
              onClick={handleClickCopyLink}
              variant="link"
            >
              { t("ui:request_change_access_type_dialog.copy_link") }
            </Button>
          </div>
        </div>

      </div>
    </Dialog>
  );
}

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi, useDateHelpers } from "../../../functional/hooks";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import { api } from "../../../functional/services";
import { ReportsDriver } from "../../modules/pages/report/pages/reportsDriver/ReportsDriver";
import AdminDriverPeriodSelectDialog from "../../modules/dialogs/adminDriverPeriodSelectDialog/AdminDriverPeriodSelectDialog";
import { BsCalendar } from "@react-icons/all-files/bs/BsCalendar";
import { isEqualDate } from "../../../functional/helpers/dateСomparison";
import { Button, Icon } from "../../uiKit";

export const ReportDriverPage = () => {
  const { t } = useTranslation();
  const dateHelpers = useDateHelpers();

  const interval = 1000 * 60 * 60 * 24; // 24 hours in milliseconds
  let startOfDay = Math.floor(Date.now() / interval) * interval;
  let endOfDay = startOfDay + interval - 1;

  const defaultRange = {
    from: new Date(startOfDay).toISOString(),
    to: new Date(endOfDay).toISOString(),
  };

  const [date, setDate] = useState<{ from: string; to: string }>(defaultRange);
  const [selectDate, setSelectDate] = useState<{ from: string; to: string }>(defaultRange);
  const [dateButtonText, setDateButtonText] = useState<string>("");

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const reportDriver = useApi(() => api.report.driver({ dateFrom: date.from, dateTo: date.to }));

  const onFetchReports = () => {
    setSelectDate(date);
    reportDriver.fetch();
    setOpenDialog(false);
  };

  const onOpenDialog = () => {
    setOpenDialog(true);
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const getActualDateISO = (value: string): string => {
    // костыль собачий, переношу конец дня не несколько часов назад, потому что без этого тупой mui пикер перекидывает на некст дату -_-
    const test = new Date(Math.floor(new Date(value).setMinutes(1) / interval) * interval + interval - 1).toISOString();
    return test;
  };

  const handleSelectTimeRange = (value: { from: string; to: string }) => {
    // mui picker ставит выбранный день на начало дня, строчкой ниже я выбранную дату переношу на конец дня
    value.to = getActualDateISO(value.to);
    setDate(value);
  };

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const fromYear = new Date(selectDate.from).getFullYear();
    const toYear = new Date(selectDate.from).getFullYear();
    // TODO: Check calculations
    // Если выбран один день
    // @ts-ignore
    if (isEqualDate(new Date(selectDate.from), new Date(new Date(selectDate.to) - 19000000), false, false)) {
      setDateButtonText(
        dateHelpers.formatDate(selectDate.from, {
          formatObject: {
            month: "short",
            hour: undefined,
            minute: undefined,
          },
        })
      );
    } else {
      setDateButtonText(
        dateHelpers.formatDate(selectDate.from, {
          showYearWhenIsCurrent: fromYear != currentYear || toYear != currentYear,
          formatObject: {
            hour: undefined,
            minute: undefined,
            month: "short",
          },
        }) +
          " - " +
          dateHelpers.formatDate(
            new Date(Math.floor(new Date(selectDate.to).setMinutes(1) / interval) * interval).toISOString(),
            {
              showYearWhenIsCurrent: fromYear != currentYear || toYear != currentYear,
              formatObject: {
                hour: undefined,
                minute: undefined,
                month: "short",
              },
            }
          )
      );
    }
  }, [selectDate]);

  return (
    <>
      <PageHeader title={t("common:page_title.report_driver")} />
      {/*tabs={AppTabsByPage["report"]}*/}
      <PageContent isLoading={reportDriver.isLoading} isFilled>
        <AdminDriverPeriodSelectDialog
          value={{
            from: date.from,
            // @ts-ignore
            to: new Date(new Date(date.to) - 19000000).toISOString(),
          }}
          open={openDialog}
          onClose={onCloseDialog}
          onChange={handleSelectTimeRange}
          onSave={onFetchReports}
          disableFuture
        />
        <div style={{ marginBottom: "16px", marginTop: "0px" }}>
          <Button icon={<Icon component={() => <BsCalendar />} />} onClick={onOpenDialog} variant="filled">
            {dateButtonText}
          </Button>
        </div>
        {reportDriver.value && <ReportsDriver data={reportDriver.value} />}
      </PageContent>
    </>
  );
};

export default ReportDriverPage;

import React, { useEffect, useState } from "react";
import OrgchartCommonDrawerTabRecurringTasksView from "./OrgchartCommonDrawerTabRecurringTasksView";
import { api } from "../../../../../../functional/services";
import { RecurringIssueDto } from "../../../../../../functional/api";
import { useRootStore, usePagingWithController, useDebounce } from "../../../../../../functional/hooks";
import { observer } from "mobx-react-lite";

interface IOrgchartCommonDrawerTabRecurringTasks {
  //
}

export type TIssuesFilters = {
  name: string;
  roleId: number | null;
  isPaused: boolean | null;
  executorConfigType: string;
  executorPositionType: number | null;
};

function OrgchartCommonDrawerTabRecurringTasks(props: IOrgchartCommonDrawerTabRecurringTasks) {
  const { orgchartStore } = useRootStore();
  const [issuesFilters, setIssueFilters] = useState<TIssuesFilters>({
    name: "",
    roleId: null,
    isPaused: null,
    executorConfigType: "",
    executorPositionType: null,
  });

  const [isDeleteIssueDialogOpen, setIsDeleteIssueDialogOpen] = useState<boolean>(false);
  const [isIssueHistoryDialogOpen, setIsIssueHistoryDialogOpen] = useState<boolean>(false);
  const [isIssueCreateEditDialogOpen, setIsIssueCreateEditDialogOpen] = useState<boolean>(false);
  const [issueToManageId, setIssueToManageId] = useState<number | null>(null);
  const [issueToManageData, setIssueToManageData] = useState<RecurringIssueDto | null>(null);

  const [sortSettings, setSortSettings] = useState<{ by: string; order: string }>({
    by: "date_created",
    order: "desc",
  });

  const recurringTasks = usePagingWithController(
    api.recurringIssue,
    {
      name: issuesFilters.name,
      roleId: issuesFilters.roleId,
      orgchartId: orgchartStore.getCurrentOrgchartId,
      executorConfigType: issuesFilters.executorConfigType.length > 0 ? issuesFilters.executorConfigType : null,
      isPaused: issuesFilters.isPaused /* || null*/,
      executorConfigRolePositionType: issuesFilters.executorPositionType,
    },
    { pageSize: 20, orderBy: sortSettings.by, order: sortSettings.order }
  );

  const debouncedFilters = useDebounce(issuesFilters, 500);

  useEffect(() => {
    recurringTasks.reset();
    recurringTasks.restart();
  }, [debouncedFilters, sortSettings]);


  const handleHistoryWatchClick = async (issueId: number) => {
    setIssueToManageId(issueId);
    setIsIssueHistoryDialogOpen(true);
  };

  const handleEditClick = async (issue: RecurringIssueDto) => {
    setIssueToManageData({ ...issue });
    setIsIssueCreateEditDialogOpen(true);
  };

  const handlePauseClick = async (issueId: number) => {
    // await recurringTasks.reset();
    // await recurringTasks.restart();
  };

  const handleDeleteClick = async (issueId: number) => {
    setIssueToManageId(issueId);
    setIsDeleteIssueDialogOpen(true);
  };

  const handleIssueDelete = async () => {
    const r = await api.recurringIssue.del(issueToManageId as number);
    if (r != null) {
      setIsDeleteIssueDialogOpen(false);
      await recurringTasks.reset();
      await recurringTasks.restart();
    }
  };

  return (
    <OrgchartCommonDrawerTabRecurringTasksView
      tasks={recurringTasks}
      issueFilters={issuesFilters}
      setIssueFilters={setIssueFilters}
      issueToManageId={issueToManageId}
      issueToManageData={issueToManageData}
      isIssueHistoryDialogOpen={isIssueHistoryDialogOpen}
      isDeleteIssueDialogOpen={isDeleteIssueDialogOpen}
      isIssueCreateEditDialogOpen={isIssueCreateEditDialogOpen}
      setIsDeleteIssueDialogOpen={setIsDeleteIssueDialogOpen}
      setIsIssueHistoryDialogOpen={setIsIssueHistoryDialogOpen}
      setIsIssueCreateEditDialogOpen={setIsIssueCreateEditDialogOpen}
      setIssueToManageData={setIssueToManageData}
      onCardWatchHistoryClick={handleHistoryWatchClick}
      onCardEditClick={handleEditClick}
      onCardDeleteClick={handleDeleteClick}
      handleIssueDelete={handleIssueDelete}
      onCardPauseClick={handlePauseClick}
      sortSettings={sortSettings}
      setSortSettings={setSortSettings}
    />
  );
}

export default observer(OrgchartCommonDrawerTabRecurringTasks);

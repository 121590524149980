import React, { ChangeEvent, memo, MouseEvent, useCallback, useRef, useState } from "react";
import { Button, Dialog, DialogActions, IconButton, Input } from "../../../../uiKit";
import { useTranslation } from "react-i18next";
import { api } from "../../../../../functional/services";
import { LexicalEditor } from "lexical";
import { INSERT_VIDEO_COMMAND } from "../../plugins/VideoPlugin/VideoPlugin";
import { NodeType } from "../../types/types";
import { INSERT_YOUTUBE_COMMAND } from "../../plugins/YouTubePlugin/YouTubePlugin";
import { parseUrl, youtubeUrlValidation } from "../../utils/urlValidation";
import { FileInput } from "../FileInput";
import { LucideUpload } from "lucide-react";

interface VideoDialogProps {
  editor: LexicalEditor;
  isLoadingChange: (value: boolean) => void;
  onOpen: () => void;
  onClose: () => void;
  isOpen: boolean;
}

export const InsertVideoDialog = memo((props: VideoDialogProps) => {
  const [videoUrl, setVideoUrl] = useState<string>("");
  const { t } = useTranslation();

  const fileUploadRef = useRef<any>(null);

  const handleCloseDialog = useCallback(() => {
    setVideoUrl("");
    props.onClose();
  }, [props]);

  const remoteVideo = useCallback(
    async (files: FileList) => {
      for (const file of files) {
        const [mime] = file.type.split("/");
        if (mime === NodeType.Video) {
          props.isLoadingChange(true);
          const formData = new FormData();
          formData.append("files", file);
          const r = await api.staticFile.upload(formData, NodeType.File);
          if (r === null) {
            props.isLoadingChange(false);
            return;
          } else {
            props.editor.dispatchCommand(INSERT_VIDEO_COMMAND, r?.[0]?.url!);
            props.isLoadingChange(false);
          }
        }
      }
    },
    [props]
  );

  const handleUploadRefClick = useCallback((event: MouseEvent) => {
    event.preventDefault();
    fileUploadRef.current.click();
  }, []);

  const handleUploadVideo = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      handleCloseDialog();
      await remoteVideo(event.target.files as FileList);
      fileUploadRef.current.value = "";
    },
    [handleCloseDialog, remoteVideo]
  );

  const handleUrlChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setVideoUrl(event.target.value);
  }, []);

  const handleAddClick = useCallback(() => {
    videoUrl && props.editor.dispatchCommand(INSERT_YOUTUBE_COMMAND, parseUrl(videoUrl)?.id!);
    handleCloseDialog();
  }, [handleCloseDialog, props.editor, videoUrl]);

  return (
    <>
      <Dialog
        closable={false}
        open={props.isOpen}
        onClose={handleCloseDialog}
        width={300}
        title={t("ui:title.insert_video")}
        destroyOnClose
      >
        <div className="d-stack-row spacing-2">
          <Input
            size="middle"
            status={youtubeUrlValidation(videoUrl) || !videoUrl ? "" : "error"}
            value={videoUrl}
            onChange={handleUrlChange}
            placeholder={t("ui:placeholder.text_editor_url")}
          />
          <IconButton
            title={t("ui:button.upload_from_pc")}
            type={"primary"}
            icon={<LucideUpload size={18} />}
            onClick={(e: any) => handleUploadRefClick(e)}
          />
        </div>
        <DialogActions>
          <Button
            type="primary"
            onClick={handleAddClick}
            variant="filled"
            disabled={!youtubeUrlValidation(videoUrl) || !videoUrl}
          >
            {t("ui:button.add")}
          </Button>
          <Button onClick={handleCloseDialog} variant="default">
            {t("ui:button.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
      <FileInput ref={fileUploadRef} label="Video Upload" onChange={handleUploadVideo} accept="video/*" />
    </>
  );
});

import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import "./CommentCard.scss";
import { UserCard } from "../userCard/UserCard";
import FileUploader from "../../service/fileUploader/FileUploader";
import { Reactions } from "../emojiPicker/Reactions";
import { TextEditor as TextEditorLexical } from "../../modules/textEditor/TextEditor";
import { initialEditorContent } from "../../../functional/utils/textEditor";
import { Button } from "../../uiKit";
import { useTranslation } from "react-i18next";
import { DotIcon } from "../../../functional/constants/icon";
import { DeleteCommentDialog } from "../../modules/dialogs/deleteCommentDialog/DeleteCommentDialog";
import { ICommentCardView } from "../../../functional/types/CommentCard.interface";
import { PlanCommentAttachmentDto, StaticFileDto } from "../../../functional/api";
import clsx from "clsx";


export const CommentCardView = (props: ICommentCardView) => {
  const { t } = useTranslation();

  const [comment, setComment] = useState<string | undefined>(undefined);
  const editorRef = useRef<{ resetEditor: () => void; isEmptyEditor: () => boolean }>();

  const onChangeComment = useCallback((value) => {
    setComment(value);
  }, []);

  const handleEditClick = () => {
    if (props.handleEditClick) {
      props.handleEditClick(props.historyId);
      setComment(props.commentContent);
    }
  };

  const handleClickCancel = () => {
    if (props.handleEditClick) {
      props.handleEditClick(-1);
    }
    setComment('');
    editorRef.current?.resetEditor();
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [commentAttachmentsList, setCommentAttachmentsList] =
    useState<StaticFileDto[]>(props.commentAttachments ? props.commentAttachments.map((item: PlanCommentAttachmentDto) => item.file!) : [])


  const handleDeleteAttachmentById = useCallback((fileId: number) => {
    if (fileId == null) return;
    setCommentAttachmentsList((prev) => prev.filter(({id}) => id != fileId));
  }, []);

  const handleClickSave = async () => {
    // props.handleSaveClick(props.historyId, comment);
    if (props.item && props.handleSaveClick) {
      const newData = {
        ...props.item,
        attachments: commentAttachmentsList,
        comment: {
          ...props.item.comment,
          content: comment,
        }
      };
      console.log("newData", newData);
      setIsLoading(true);
      const editStatus = await props.handleSaveClick(props.historyId, newData);
      setIsLoading(false);
      console.log("editStatus", editStatus);
      if (editStatus) {
        setComment(props.commentContent);
      }
    }
  };

  const [isOpenDeleteCommentDialog, setIsOpenDeleteCommentDialog] = useState<boolean>(false);
  const handleClickRemove = () => {
    setIsOpenDeleteCommentDialog(true);
  }

  const handleCloseDeleteCommentDialog = () => {
    setIsOpenDeleteCommentDialog(false);
  }

  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const handleDeleteComment = async () => {
    if (props.handleRemoveComment) {
      setIsDeleteLoading(true);
      const removeStatus = await props.handleRemoveComment(props.historyId);
      if (removeStatus) {
        handleCloseDeleteCommentDialog();
      }
      setIsDeleteLoading(false);
    }
  };


  return (
    <>
      {isOpenDeleteCommentDialog && (
        <DeleteCommentDialog
          onClose={handleCloseDeleteCommentDialog}
          open={isOpenDeleteCommentDialog}
          onDelete={handleDeleteComment}
          loading={isDeleteLoading}
          title={t("ui:title.confirm_delete_comment")}
          description={t("ui:text.confirm_delete_comment")}
        />
      )}
      <div className="comment-card" key={props.historyId}>
        {!props.isHiddenUser && (
          <div className="comment-card__header">
            {props.creator && (
              <UserCard
                userInfo={props.creator}
                boldName
                avatarSize={32}
                additionalInfo={`${props.dateSented}`}
                isNewStyle={props.isNewStyle}
                nameSize={12}
              />
            )}
            {!props.isNewStyle && (
              <Reactions
                historyId={props.historyId}
                reactions={props.reactions}
                strategy={props.strategy}
              />
            )}
          </div>
        )}
        <div className={clsx("comment-card__content", {
            "comment-card__content_new-style": props.isNewStyle,
            "comment-card__content_no-padding": props.isHiddenUser
          }
          )}
        >
          {!!props.commentContent?.length && (
            <TextEditorLexical
              ref={editorRef}
              readOnly={props.editCommentId !== props.historyId}
              value={props.editCommentId === props.historyId ? comment : props.commentContent}
              variant={props.isNewStyle ? 'new-style' : undefined}
              initialValue={props.commentContent ?? initialEditorContent}
              isNewStyle={props.isNewStyle}
              onChange={onChangeComment}
              isSimplifiedToolbar={props.isSimplifiedToolbar}
              isShowBorder={props.isShowBorder}

            />
          )}

          { props.editCommentId === props.historyId && (
            <div className="comment-card-content-footer">
              <Button
                onClick={handleClickCancel}
                className="button-icon_17"
                variant="default"
              >
                {t("ui:button.cancel")}
              </Button>
              <Button
                onClick={handleClickSave}
                className="button-icon_17"
                variant="filled"
                loading={isLoading}
                disabled={editorRef.current?.isEmptyEditor()}
              >
                {t("ui:button.save")}
              </Button>
            </div>
          )}
        </div>
        {props.isNewStyle && (
          <div className={clsx("comment-card-footer", {
            "comment-card-footer_no-padding": props.isHiddenUser,
          })}>
            {props.isEditing && props.editCommentId !== props.historyId && (
              <div className="comment-card-footer-action">
                <Button
                  className="px-0"
                  onClick={handleEditClick}
                  variant={"link"}
                  children={t("ui:button.edit")}
                  disabled={!!props.editCommentId && props.editCommentId !== -1 && props.editCommentId !== props.historyId}
                />
                <DotIcon />
                <Button
                  className="px-0"
                  onClick={handleClickRemove}
                  variant={"link"}
                  children={t("ui:button.delete")}
                  disabled={!!props.editCommentId && props.editCommentId !== -1 && props.editCommentId !== props.historyId}
                />
                {props.editedInfo && (
                  <div className={"comment-card-footer-action__edited"}>
                    { props.editedInfo }
                  </div>
                )}

              </div>
            )}
            {!props.isHiddenReactions && (
              <div className="comment-card__reaction">
                <Reactions
                  isNewStyle={true}
                  historyId={props.historyId}
                  reactions={props.reactions}
                  strategy={props.strategy}
                  isBorder={true}
                  isShowFullEmoji
                />
              </div>
            )}
          </div>
        )}
        {props.commentAttachments && (
          <div className={clsx("comment-card__attachments", {
            "comment-card__attachments_no-padding": props.isHiddenUser
          })}>
            <FileUploader
              noDragAndDrop
              value={commentAttachmentsList}
              hideInfo
              smallSize
              noDelete={props.editCommentId !== props.historyId}
              onValueItemDelete={handleDeleteAttachmentById}
            />
          </div>
        )}
      </div>
    </>
  );
};

import { UpdateNoteContentDto } from "../../../../../functional/api";
import { initialEditorContent } from "../../../../../functional/utils/textEditor";
import { LocalesKeys } from "../../../../../functional/constants/localesKeys";

export interface IInitialUpdateNotes {
  ru: UpdateNoteContentDto;
  en: UpdateNoteContentDto;
  uk: UpdateNoteContentDto;
  zh: UpdateNoteContentDto;
  // es: UpdateNoteContentDto;
  // uz: UpdateNoteContentDto;
}

export const initialNotes: IInitialUpdateNotes = {
  en: {
    name: "",
    content: initialEditorContent,
    cultureKey: LocalesKeys.en,
  },
  ru: {
    name: "",
    content: initialEditorContent,
    cultureKey: LocalesKeys.ru,
  },
  uk: {
    name: "",
    content: initialEditorContent,
    cultureKey: LocalesKeys.uk,
  },
  zh: {
    name: "",
    content: initialEditorContent,
    cultureKey: LocalesKeys.zh,
  },
  // es: {
  //   name: "",
  //   content: initialEditorContent,
  //   cultureKey: LocalesKeys.es,
  // },
  // uz: {
  //   name: "",
  //   content: initialEditorContent,
  //   cultureKey: LocalesKeys.uz,
  // },
};

export enum validationErrorKeys {
  noName = "NO_NAME",
  noContent = "NO_CONTENT",
  noAllNotes = "NO_ALL_CONTENTS",
}

export enum publishedSelectKeys {
  published = "PUBLISHED",
  notPublished = "NOT_PUBLISHED",
}

import React, { useCallback } from "react";
import {
  Dialog,
} from "../../../uiKit";
import { useTranslation } from "react-i18next";

import "./CalendarGoogleEventBlock.scss";
import { ICalendarGoogleEvent } from "./CalendarGoogleEventBlock.interface";
import {
  ACCEPTED, DECLINED,
  GOOGLE_CALENDAR_EVENT_ROWS,
  TENTATIVE
} from "../../../../functional/constants/googleCalendarEvent";
import dayjs, { ManipulateType } from "dayjs";
import { IReminders } from "../../../../functional/api/models/GoogleCalendarEventDto";
import {
  AcceptedIcon,
  DeclinedIcon,
  GoogleCalendarIcon,
  ReminderIcon,
  TentativeIcon,
  } from "../../../../functional/constants/icon";

export const CalendarGoogleEventBlockView = ({ event, onClose }: ICalendarGoogleEvent) => {
  const { t } = useTranslation();

  const getRemainderTextValue = (value: number, unit: ManipulateType) => {
    const date = dayjs().subtract(value, unit);
    // @ts-ignore
    return dayjs().from(date, true);
  }

  const getRemainderValue = (item: IReminders) => {
    const { minutes } = item;
    const duration = dayjs.duration(minutes, 'minutes');
    const days = duration.asDays();
    const hours = duration.asHours();
    const weeks = duration.asWeeks();
    if (weeks && Number.isInteger(weeks)) {
      return getRemainderTextValue(weeks, 'weeks');
    }
    if (days && Number.isInteger(days)) {
      return getRemainderTextValue(days, 'days');
    }
    if (hours && Number.isInteger(hours)) {
      return getRemainderTextValue(hours, 'hours');
    }
    return getRemainderTextValue(minutes, 'minutes');
  };

  const getEventContent = useCallback((key) => {
    // @ts-ignore
    return event[key] ?? "";
  }, [event]);

  const getStatusIcon = useCallback((status: string | undefined) => {
    switch (status) {
      case ACCEPTED:
        return (<AcceptedIcon color="var(--color-success-base)"/>);
      case DECLINED:
        return (<DeclinedIcon color="var(--color-error-weaker)"/>);
      case TENTATIVE:
        return (<TentativeIcon color="var(--color-tentative)"/>);
      default:
        return (<TentativeIcon color="var(--color-text-second)"/>);
    }
  }, []);

  return (
    <Dialog
      title={t("ui:title.google_event")}
      open={true}
      onClose={onClose}
    >
      <div className="google-calendar-event">
        <div className="google-calendar-event-row google-calendar-event-row_first">
          <div className="google-calendar-event__title">
            {event.name}
          </div>
        </div>

        {GOOGLE_CALENDAR_EVENT_ROWS.map((row) => (
          !!getEventContent(row.key) && (
            <div
              className="google-calendar-event-row"
              key={row.key}
            >
              <div className="google-calendar-event-info">
                <div className="google-calendar-event-info__icon">
                  {row.icon}
                </div>
                <div
                  className="google-calendar-event-info__content"
                  dangerouslySetInnerHTML={{
                    __html: getEventContent(row.key)
                  }}
                />
              </div>
            </div>
          )))}
        {(event.reminders ?? []).map((reminder) => (
          <div
            className="google-calendar-event-row"
            key={reminder.minutes}
          >
            <div className="google-calendar-event-info">
              <div className="google-calendar-event-info__icon">
                <ReminderIcon />
              </div>
              <div className="google-calendar-event-info__content">
                {t("ui:text.google_calendar_reminder", { time: getRemainderValue(reminder) })}
              </div>
            </div>
          </div>
        ))}
        {event.guests.length > 0 && (
          <div
            className="google-calendar-event-row"
          >
            <div className="google-calendar-event-guests">
              {event.guests.map((guest) => (

                <div
                  className="google-calendar-event-info"
                  key={guest.email}
                >
                  <div className="google-calendar-event-info__icon">
                    { getStatusIcon(guest.responseStatus) }
                  </div>

                  <div className="google-calendar-event-info__content">
                    {guest.email}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="google-calendar-event-row">
          <div className="google-calendar-event-info">
            <div className="google-calendar-event-info__icon">
              <GoogleCalendarIcon size={20} />
            </div>

            <div className="google-calendar-event-info__content">
              <a
                href={event.htmlLink}
                target="_blank"
              >
                {event.htmlLink}
              </a>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
};

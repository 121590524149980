import React, { useCallback } from "react";
import "./Indicator.scss";
import { IIndicator } from "./Indicator.interface";
import { IndicatorIconsKeys } from "../../modules/pages/communications/constants/keys";
import { Icon, Tag, Tooltip } from "../../uiKit";
import { LucideCalendar, LucideCheck, LucideClock, LucideMail } from "lucide-react";
import { colorScheme } from "../../../functional/utils/colorScheme";
import { BsExclamationDiamond } from "react-icons/bs";

export const Indicator = (props: IIndicator): JSX.Element | null => {

  const handleGetIcon = useCallback(() => {
    switch (props.icon) {
      case IndicatorIconsKeys.CheckMark:
        return <Icon component={() => <LucideCheck size={18} />} />;
      case IndicatorIconsKeys.Message:
        return <Icon component={() => <LucideMail size={18} />} />;
      case IndicatorIconsKeys.Warning:
        return <Icon component={() => <BsExclamationDiamond size={18} />} />;
      case IndicatorIconsKeys.Clock:
        return <Icon component={() => <LucideClock size={18} />} />;
      case IndicatorIconsKeys.Calendar:
        return <Icon component={() => <LucideCalendar size={18} />} />;
      default:
        return null;
    }
  }, [props.icon]);

  const handleGetColor = useCallback(() => {
    switch (props.colorSchemeKey) {
      case colorScheme.Success:
        return "success";
      case colorScheme.Error:
        return "error";
      case colorScheme.Warning:
        return "warning";
      case colorScheme.Primary:
        return "processing";
      case colorScheme.Default:
        return "default";
      default:
        return undefined;
    }
  }, [props.colorSchemeKey]);

  return (
    <>
      {props.isSimplified && props.icon ? (
        <Tooltip title={props.name}>
          <Tag
            className="simplified-indicator d-flex align-center justify-center mr-1"
            style={{ width: 25, height: 25 }}
            icon={handleGetIcon()}
            color={handleGetColor()}
          />
        </Tooltip>
      ) : (
        <Tag className="d-flex align-center" color={handleGetColor()} style={{ height: 32, fontSize: 14 }}>
          {props.name}
        </Tag>
      )}
    </>
  );
};

import React from "react";
import { Divider } from "antd";
import { useTranslation } from "react-i18next";
import { IRegulationHistoryHeader } from "../../../../../../functional/types/RegulationHistory.interface";
import { Select, Text } from "../../../../../uiKit";
import { ISelectItem } from "../../../../../../functional/types/Select.Interface";
import { historySortKeys } from "../../../../../../functional/constants/regulation/consts";

const RegulationHistoryHeaderMemo: React.FC<IRegulationHistoryHeader> = (props) => {
  const { t } = useTranslation();

  const selectOptions: ISelectItem[] = [
    {
      value: historySortKeys.onesFirst,
      text: t("common:misc.sort_items.ones_first"),
    },
    {
      value: historySortKeys.oldOnes,
      text: t("common:misc.sort_items.old_ones"),
    },
  ];

  return (
      <div className="d-flex justify-end align-center">
        {/*<Text weight={500} size={"16px"} children={t("parse:history_log")} />*/}
        <Select
          value={props.sortValue}
          onChange={props.onChangeSortValue}
          items={selectOptions}
          size={"middle"}
        />
      </div>
  );
};

export const RegulationHistoryHeader = React.memo(RegulationHistoryHeaderMemo);

import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { Button, DatePicker, Dialog, DialogActions, Input, InputNumber, Select, Switch } from "../../../uiKit";
import { TARIFF_LIST } from "../../../../functional/constants/TariffList";
import { CompanyForAdminDto } from "../../../../functional/api";
import { User2CompanyAccessLevel } from "../../../../functional/api/models/CompanyUserLimitDto";
import { CompanyUsersAccessLevel } from "../../forms/companyEditForm/companyUsersAccessLevel/CompanyUsersAccessLevel";
import { ISelectItem } from "../../../../functional/types/Select.Interface";
import { CompanyFeatureDto } from "../../../../functional/api/models/CompanyForAdminDto";
import dayjs from "dayjs";

interface ICreateEditCompanyDialogView {
  open: boolean;
  isLoading: boolean;
  companyId?: number | null;
  companyModuleTypeArray: CompanyFeatureDto[];
  companyModuleTypeArrayActive: number[];
  settingsState: CompanyForAdminDto;

  setSettingsState: (state: CompanyForAdminDto) => void;
  handleModuleTypeChange: (key: string, value: boolean) => void;
  onSave: () => void;
  onCreate: () => void;
  onClose: () => void;
}

function CreateEditCompanyDialogView(props: ICreateEditCompanyDialogView) {
  const { t } = useTranslation();

  const tariffList: ISelectItem[] = TARIFF_LIST.map((item) => ({
    ...item,
     text: t(item.text!),
  }))

  const handleChangeAccessLevel = useCallback((accessLevel: User2CompanyAccessLevel, limit: number | null) => {
    const indexUpdateAccessLevel = (props.settingsState.userLimits ?? [])
      .findIndex((item) => item.accessLevel === accessLevel);
    if (indexUpdateAccessLevel !== -1) {
      const updateAccessLevel = props.settingsState.userLimits![indexUpdateAccessLevel];
      const userLimits = [...props.settingsState.userLimits!];
      userLimits.splice(indexUpdateAccessLevel, 1, {
        ...updateAccessLevel,
        limit
      });
      props.setSettingsState({
        ...props.settingsState,
        userLimits,
      });
    }
  }, [props.settingsState])

  return (
    <Dialog
      title={t("parse:company_settings")}
      open={props.open}
      onClose={props.onClose}
      scrollStrategy="dialogBody"
      closable={false}
    >
      <div className="d-stack-column spacing-3">
        <div>
          <SubheaderText text={t("parse:external_id")} />
          <Input
            placeholder={t("parse:external_id")}
            value={props.settingsState.externalId ?? ""}
            onInput={(event) =>
              props.setSettingsState({ ...props.settingsState, externalId: event.currentTarget.value })
            }
          />
        </div>
        <div>
          <SubheaderText text={t("ui:subheader.contact_person")} />
          <Input
            placeholder={t("ui:subheader.contact_person")}
            value={props.settingsState.contactName ?? ""}
            onInput={(event) =>
              props.setSettingsState({ ...props.settingsState, contactName: event.currentTarget.value })
            }
          />
        </div>
        <div>
          <SubheaderText text={t("ui:subheader.contact_phone_number")} />
          <Input
            placeholder={t("ui:placeholder.contact_phone_number")}
            value={props.settingsState.contactPhoneNumber ?? ""}
            onInput={(event) =>
              props.setSettingsState({ ...props.settingsState, contactPhoneNumber: event.currentTarget.value })
            }
          />
        </div>
        <div>
          <SubheaderText text={t("parse:ui_type")} />
          <Select
            disabled={!props.companyId}
            className="full-width"
            value={props.settingsState.uiType}
            onChange={(value) => props.setSettingsState({ ...props.settingsState, uiType: value })}
            items={[
              { id: 1, text: t("parse:ui_type_old"), value: 1 },
              { id: 2, text: t("parse:ui_type_new"), value: 2 }
            ]}
          />
        </div>
        <div>
          <SubheaderText text={t("parse:tariff")} />
          <Select
            className="full-width"
            value={props.settingsState.tariffCode}
            onChange={(value) => props.setSettingsState({ ...props.settingsState, tariffCode: value })}
            items={tariffList}
          />
        </div>
        {props.settingsState.userLimits!
          .filter(({ accessLevel }) => accessLevel !== User2CompanyAccessLevel.Blocked)
          .map((item) => (
            <CompanyUsersAccessLevel
              key={item.accessLevel}
              item={item}
              onChange={handleChangeAccessLevel}
            />
          ))
        }
        <div>
          <SubheaderText text={t("parse:end_access_time")} />
          <DatePicker
            className="full-width"
            placeholder={t("ui:placeholder.time_block.selectDate")}
            allowClear={true}
            value={props.settingsState.endAccessTime ? dayjs(props.settingsState.endAccessTime ) : null}
            onChange={(value) => {
              const endAccessTime = value ? value.toString() : null;
              props.setSettingsState({
                ...props.settingsState,
                endAccessTime,
              });
            }}
            size="large"
            showTime={{ format: "HH:mm" }}
          />
        </div>
        <div className="d-stack-column spacing-2">
          <Switch
            onChange={(value) => props.setSettingsState({
              ...props.settingsState,
              isPaid: value,
              isPartiallyPaid: false
            })}
            checked={!!props.settingsState.isPaid}
            label={t("ui:label.paid_company")}
          />
          <Switch
            onChange={(value) => props.setSettingsState({
              ...props.settingsState,
              isPartiallyPaid: value,
              isPaid: false
            })}
            checked={!!props.settingsState.isPartiallyPaid}
            label={t("ui:label.partially_paid_company")}
          />
          <Switch
            onChange={(value) => props.setSettingsState({ ...props.settingsState, isTest: value })}
            checked={!!props.settingsState.isTest}
            label={t("ui:label.test_company")}
          />
          <Switch
            onChange={(value) => props.setSettingsState({ ...props.settingsState, isInactive: value })}
            checked={!!props.settingsState.isInactive}
            label={t("ui:label.inactive")}
          />
        </div>
        <div className="d-stack-column spacing-2">
          <SubheaderText text={t("parse:available_modules")} />
          {props.companyModuleTypeArray
            // TODO: Remove later
            // .sort((a, b) => (a?.key ?? 0) - (b?.key ?? 0))
            .map((mt) => (
              <Switch
                key={mt.key}
                disabled={
                  props.isLoading
                  // ((mt.key == 2 || mt.key == 7 || mt.key == 10) &&
                  //   !props.companyModuleTypeArrayActive.some((ta) => ta == mt.key))
                }
                onChange={(value) => props.handleModuleTypeChange(mt.key, value)}
                checked={mt.isEnable}
                // onChange={(value) => props.handleModuleTypeChange(mt.key as number, value)}
                // checked={props.companyModuleTypeArrayActive.some((ta) => ta == mt.key)}
                label={t(`ui:modules.${mt.key ?? ""}`)}
              />
            ))}
        </div>
      </div>
      <DialogActions>
        <Button disabled={props.isLoading} variant="text" onClick={props.onClose}>
          {t("ui:button.cancel")}
        </Button>
        <Button
          loading={props.isLoading}
          variant="filled"
          onClick={() => (props.companyId != null ? props.onSave() : props.onCreate())}
        >
          {props.companyId != null ? t("ui:button.save") : t("ui:button.create")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateEditCompanyDialogView;

import React, { memo } from "react";
import { ColumnType } from "antd/lib/table";
import { Icon, IconButton, InputNumber, Table } from "../../../../../uiKit";
import { UserInfo } from "../../../../../elements/userInfo/UserInfo";
import { FiX } from "@react-icons/all-files/fi/FiX";
import { useTranslation } from "react-i18next";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";
import { IMetricDataContent } from "./MetricDataContent.interface";
import clsx from "clsx";

export const MetricDataContent = memo((props: IMetricDataContent) => {
  const { t } = useTranslation();
  const tableColumns: ColumnType<any>[] = [
    {
      title: t("parse:metrics_table.date"),
      dataIndex: "date",
      key: "date",
      width: "15%",
      align: "center"
    },
    {
      title: props.isPrint ? t("parse:metrics_table.quota_print") : t("parse:metrics_table.quota"),
      dataIndex: "quota",
      key: "quota",
      width: props.isPrint ? 95 : 150,
      render: (value, record, index) => (
        <InputNumber
          disabled={props.metric?.renderType == 1}
          size="large"
          className="full-width"
          isNewStyle
          value={
            props.changedData
              ?.find((d) => d.metricSource2UserId == props.currentMetricSource2UserId)
              ?.tableData?.find((tD) => tD.date == record.date)?.quota === undefined
              ? value
              : props.changedData
                ?.find((d) => d.metricSource2UserId == props.currentMetricSource2UserId)
                ?.tableData?.find((tD) => tD.date == record.date)?.quota
          }
          onChange={(v) => props.onDataChange(record, v, "quota")}
          placeholder={t("parse:enter_value")}
        />
      ),
      // width: "15%",
    },
    {
      title: t("parse:metrics_table.value"),
      dataIndex: "value",
      key: "value",
      width: props.isPrint ? 95 : undefined,
      render: (value, record, index) => {
        if (record.date == "01.10.2023") {
          // console.log(
          //   value,
          //   record,
          //   props.changedData?.find((d) => d.metricSource2UserId == props.currentMetricSource2UserId)
          // );
        }
        return (
          <InputNumber
            size="large"
            className="full-width"
            isNewStyle
            value={
              props.changedData
                ?.find((d) => d.metricSource2UserId == props.currentMetricSource2UserId)
                ?.tableData?.find((tD) => tD.date == record.date)?.value === undefined
                ? value
                : props.changedData
                  ?.find((d) => d.metricSource2UserId == props.currentMetricSource2UserId)
                  ?.tableData?.find((tD) => tD.date == record.date)?.value
            }
            onChange={(v) => props.onDataChange(record, v, "value")}
            placeholder={`${t("parse:enter_value")} 111`}
          />
        );
      },
      // width: "15%",
    },
    {
      title: t("parse:metrics_table.filler"),
      dataIndex: "filler",
      key: "filler",
      width: props.isPrint ? 140 : "25%",
      render: (value, record, index) =>
        value == null ? null : (
          <div className={clsx("d-flex align-center justify-center", {"justify-center": props.isPrint})}>
            <UserInfo
              user={value}
            />
          </div>
        ),
    },
    {
      title: t("parse:metrics_table.fill_date"),
      dataIndex: "fill_date",
      key: "fill_date",
      width: props.isPrint ? 97 : 150,
      align: "center",
    },
  ];
  if (!props.isPrint) {
    tableColumns.push({
      title: "",
      dataIndex: "",
      key: "action_delete",
      render: (value, record, index) => (
        <IconButton
          onClick={() => props.onDataChange(record, null, "reset")}
          className={"metrics-table-delete-button"}
        >
          <Icon component={() => <FiX />} />
        </IconButton>
      ),
      width: "1%",
    })
  }

  return (
    <>
      <Table
        loading={props.loadingState.isLoading}
        scroll={{ x: "100%" }}
        size="small"
        tableLayout="auto"
        pagination={false}
        sticky
        bordered={false}
        isNewStyle
        columns={tableColumns}
        dataSource={
          props.data.find((d) => d.metricSource2UserId == props.currentMetricSource2UserId)?.tableData ?? []
        }
      />
      {/*{JSON.stringify(props.data.find((d) => d.graphId == props.currentGraphId)?.tableData ?? [])}*/}
      {!props.isPrint && (
        <ScrollTrigger onIntersection={props.onLoadMore} marginTop={true} disabled={props.loadingState.isLoading} />
      )}
    </>
  )
})

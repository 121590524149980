import React from "react";
import FileViewerView from "./FileViewerView";
import { useFileViewer, useRootStore } from "../../../functional/hooks";
import { observer } from "mobx-react-lite";
import { IFileViewer } from "../../../functional/types";


function FileViewer(props: IFileViewer) {
  const {
    files,
    currentFileIndex,
    handlePrevCLick,
    handleNextCLick,
    handleThumbnailCLick,
    handleClose,
  } = useFileViewer(props);

  return (
    <FileViewerView
      files={files}
      currentFileIndex={currentFileIndex}
      onClickPrev={handlePrevCLick}
      onClickNext={handleNextCLick}
      onClickThumbnail={handleThumbnailCLick}
      onClose={handleClose}
    />
  );
}

export default observer(FileViewer);

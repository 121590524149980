import "./OrgchartFunctionDrawerHeader.scss";
import {
  Button,
  Icon,
  Input,
  Popover,
  Segmented,
  Shape,
  Spin,
  Switch,
  TabPane,
  Tabs,
  Text,
  TextArea,
  Tooltip,
} from "../../../../uiKit";
import { useTranslation } from "react-i18next";
import { LucideAlignHorizontalJustifyStart, LucideAlignVerticalJustifyStart, LucideChevronDown } from "lucide-react";
import { RoleDto } from "../../../../../functional/api";
import { createRef, memo, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useClaims, useRootStore } from "../../../../../functional/hooks";
import { If } from "../../../../service/conditionStatement";
import { permissionKeys } from "../../../../../functional/utils/permissions";
import { CirclePicker } from "react-color";
import { IOrgchartDrawerTab, TOrgchartDrawerTabKey } from "../orgchartFunctionDrawer/OrgchartFunctionDrawer";
import { OpeningGuideIcon } from "../../../../elements/openingGuideIcon/OpeningGuideIcon";
import { InputRef } from "antd";
import isHotkey from "is-hotkey";
import clsx from "clsx";
import { LinkItUrl } from "react-linkify-it";
import { ORGCHART_DEFAULT_TYPE } from "../../../../../functional/constants/orgchartTypes";
import { orgchartHexToVariables, orgchartVariablesToHex } from "../../../../../functional/utils/hexToVariables";

interface IOrgchartFunctionDrawerHeaderProps {
  role: RoleDto | undefined;
  currentTabKey: TOrgchartDrawerTabKey | null;
  drawerTabs: IOrgchartDrawerTab[];

  onCurrentTabChange: (newTabKey: TOrgchartDrawerTabKey) => void;
  onRoleChange: (role: RoleDto) => void;
}

const OrgchartFunctionDrawerTab = memo((tab: IOrgchartDrawerTab) => {
  const { t } = useTranslation();

  return (
    <div className="d-stack-row spacing-2 align-center">
      <span className="no-select" children={t(tab.translationKey)} />
      {tab.guide != null && tab.guide?.guideKey != null && (
        <OpeningGuideIcon contentKey={tab.guide?.guideKey} anchorId={tab.guide?.anchorId} />
      )}
    </div>
  );
});

const OrgchartFunctionDrawerHeaderObserved = (props: IOrgchartFunctionDrawerHeaderProps) => {
  const { t } = useTranslation();
  const { authStore, helperStore, orgchartStore } = useRootStore();
  const claims = useClaims();
  const isSaveHotKey = isHotkey("mod+enter");

  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState<boolean>(false);
  const [isDescriptionEditMode, setIsDescriptionEditMode] = useState<boolean>(false);

  const resultInputRef = createRef<InputRef>();
  const descriptionInputRef = createRef<InputRef>();

  const [localRole, setLocalRole] = useState<RoleDto>({
    name: props.role?.name ?? "",
    applyColorToChildren: props.role?.applyColorToChildren ?? true,
    childrenAreHorizontal: props.role?.childrenAreHorizontal ?? true,
    awaitedResult: props.role?.awaitedResult ?? "",
    colorHex: props.role?.colorHex ?? undefined,
    description: props.role?.description ?? "",
  });

  const isChildrenAreHorizontalSwitchDisabled = useMemo(
    () => {
      const type = orgchartStore.getCurrentOrgchart?.type ?? authStore.getCurrentCompany?.orgchartType;
      return type !== ORGCHART_DEFAULT_TYPE && props.role?.parentId == null
    },
    [props.role, authStore.getCurrentCompany, orgchartStore.getCurrentOrgchart]
  );

  const isExpandButtonDisabled: boolean = useMemo(() => {
    return false;
  }, [localRole.description]);

  const handleIsDescriptionExpandedChange = (isExpanded: boolean) => {
    setIsDescriptionExpanded(isExpanded);
    // if (isExpanded) return;
    // const textArea = document.getElementById("orgchartFunctionDrawerHeaderDescriptionInput");
    // if (textArea == null) return;
    // setTimeout(() => {
    //   textArea.scrollTo({ top: 0 });
    // }, 0);
  };

  useEffect(() => {
    setLocalRole({
      name: props.role?.name ?? "",
      applyColorToChildren: localRole.applyColorToChildren ?? true,
      childrenAreHorizontal: props.role?.childrenAreHorizontal ?? true,
      awaitedResult: props.role?.awaitedResult ?? "",
      colorHex: props.role?.colorHex ?? undefined,
      description: props.role?.description ?? "",
    });
  }, [
    props.role?.name,
    props.role?.applyColorToChildren,
    props.role?.childrenAreHorizontal,
    props.role?.awaitedResult,
    props.role?.colorHex,
    props.role?.description,
  ]);

  useEffect(() => {
    if (isDescriptionEditMode) {
      setIsDescriptionExpanded(true);
      descriptionInputRef.current?.focus();
    }
  }, [isDescriptionEditMode]);

  return (
    <div className="orgchart-function-drawer-header__wrapper">
      <Spin spinning={props.role == null}>
        <div className="d-stack-column spacing-2">
          <div className="d-stack align-center spacing-3">
            <Popover
              open={
                !claims.has(permissionKeys.orgchart.role.edit) || helperStore.getColors.length == 0 ? false : undefined
              }
              destroyTooltipOnHide={true}
              placement="bottomLeft"
              align={{ offset: [-8, 12] }}
              content={
                <div className="d-stack-column spacing-2">
                  <CirclePicker
                    circleSpacing={8}
                    circleSize={24}
                    color={localRole?.colorHex ? orgchartHexToVariables(localRole.colorHex) : undefined}
                    colors={helperStore.getColors}
                    styles={{
                      default: {
                        card: {
                          flexWrap: "nowrap",
                          width: "100%",
                        },
                      },
                    }}
                    onChangeComplete={(color, e) => {
                      props.onRoleChange({
                        colorHex: orgchartVariablesToHex(e.target.title),
                        applyColorToChildren: localRole.applyColorToChildren,
                      })
                    }
                    }
                  />
                  <Switch
                    label={t("parse:change_subfunctions_colors")}
                    onChange={(value) => setLocalRole((prevState) => ({ ...prevState, applyColorToChildren: value }))}
                    checked={!!localRole.applyColorToChildren}
                  />
                </div>
              }
              children={
                <Shape
                  type="circle"
                  size={20}
                  backgroundColor={props.role?.colorHex ?? undefined}
                  style={{ border: "1px solid var(--color-primary-base)" }}
                />
              }
            />
            <If condition={claims.has(permissionKeys.orgchart.role.edit)}>
              <Tooltip title={t("ui:text.child_functions_direction") as string}>
                <Segmented
                  readOnly={!claims.has(permissionKeys.orgchart.role.edit)}
                  size="small"
                  value={localRole?.childrenAreHorizontal ? "h" : "v"}
                  disabled={isChildrenAreHorizontalSwitchDisabled}
                  onChange={(value) => {
                    props.onRoleChange({ childrenAreHorizontal: value == "h" });
                  }}
                  options={[
                    { label: <LucideAlignVerticalJustifyStart size={14} />, value: "v" },
                    { label: <LucideAlignHorizontalJustifyStart size={14} />, value: "h" },
                  ]}
                />
              </Tooltip>
            </If>
            <div className="flex-grow-1">
              <Input
                readOnly={!claims.has(permissionKeys.orgchart.role.edit)}
                value={localRole.name ?? undefined}
                bordered={false}
                size="small"
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  padding: 0,
                }}
                onBlur={() =>
                  localRole.name?.trim().length == 0
                    ? setLocalRole((prevState) => ({
                        ...prevState,
                        name: props.role?.name ?? undefined,
                      }))
                    : props.onRoleChange({ name: localRole.name?.trim() })
                }
                onInput={(event) =>
                  setLocalRole((prevState) => ({
                    ...prevState,
                    name: (event.target as HTMLInputElement).value,
                  }))
                }
              />
            </div>
          </div>
          <div className="d-stack spacing-2 mt-2">
            <Text
              className="no-select flex-shrink-0"
              type="secondary"
              children={`${orgchartStore.getCurrentOrgchartResultName}:`}
            />

            <div className="flex-grow-1">
              <Input
                onKeyDown={(evt) => {
                  if (isSaveHotKey(evt)) {
                    resultInputRef.current?.blur();
                  }
                }}
                className="orgchart-function-drawer-header__result__input"
                ref={resultInputRef}
                readOnly={!claims.has(permissionKeys.orgchart.role.edit)}
                bordered={false}
                placeholder={t("ui:placeholder.valuable_final_product")}
                value={localRole.awaitedResult ?? undefined}
                size="small"
                style={{
                  fontSize: "14px",
                  padding: 0,
                  color: "var(--color-text-weaker)",
                }}
                onBlur={() =>
                  localRole.awaitedResult?.trim().length == 0
                    ? setLocalRole((prevState) => ({
                        ...prevState,
                        awaitedResult: props.role?.awaitedResult ?? undefined,
                      }))
                    : props.onRoleChange({ awaitedResult: localRole.awaitedResult?.trim() })
                }
                onInput={(event) =>
                  setLocalRole((prevState) => ({
                    ...prevState,
                    awaitedResult: (event.target as HTMLInputElement).value,
                  }))
                }
              />
            </div>
          </div>
          <div className="d-stack spacing-2">

            <Text className="no-select flex-shrink-0" type="secondary" children={`${t("parse:description")}:`} />
            <div
              className={clsx("flex-grow-1", "orgchart-function-drawer-header__description__wrapper", {
                __expanded: isDescriptionExpanded,
              })}
            >
              {isDescriptionEditMode ? (
                <TextArea
                  id="orgchartFunctionDrawerHeaderDescriptionInput"
                  onKeyDown={(evt) => {
                    if (isSaveHotKey(evt)) {
                      descriptionInputRef.current?.blur();
                    }
                  }}
                  ref={descriptionInputRef}
                  autoSize={{ minRows: 1, maxRows: isDescriptionExpanded ? 10 : 1 }}
                  readOnly={!claims.has(permissionKeys.orgchart.role.edit)}
                  bordered={false}
                  placeholder={t("parse:enter_description")}
                  value={localRole.description ?? undefined}
                  size="small"
                  style={{
                    fontSize: "14px",
                    padding: 0,
                    color: "var(--color-text-weaker)",
                    minHeight: "22px",
                  }}
                  onBlur={() => {
                    console.log(" localRole.description", localRole.description);
                    // localRole.description?.trim().length == 0
                    //   ? setLocalRole((prevState) => ({
                    //       ...prevState,
                    //       description: '',
                    //     }))
                    //   : props.onRoleChange({ description: localRole.description?.trim() });
                    props.onRoleChange({ description: localRole.description?.trim() });
                    setIsDescriptionEditMode(false);
                  }}
                  onInput={(event) =>
                    setLocalRole((prevState) => ({
                      ...prevState,
                      description: (event.target as HTMLInputElement).value,
                      // description: (event.target as HTMLInputElement).value.replace(/  +/g, " "),
                    }))
                  }
                />
              ) : (
                <div
                  style={{
                    minHeight: "22px",
                    maxHeight: isDescriptionExpanded ? "220px" : "22px",
                    overflow: isDescriptionExpanded ? "auto" : "hidden",
                    cursor: claims.has(permissionKeys.orgchart.role.edit) ? "pointer" : "default",
                  }}
                  onClick={(evt) =>
                    (evt.target as any).closest("a") != null || !claims.has(permissionKeys.orgchart.role.edit)
                      ? undefined
                      : setIsDescriptionEditMode(true)
                  }
                >
                  <LinkItUrl>
                    <Text
                      size="14px"
                      weight={"normal"}
                      style={{
                        whiteSpace: "pre-wrap",
                        color: "var(--color-text-weaker)",
                        opacity: !localRole.description?.trim().length ? 0.6 : 1,
                      }}
                      children={localRole.description?.trim() || t("parse:enter_description")}
                    />
                  </LinkItUrl>
                </div>
              )}
            </div>
            <Button
              className="pa-0"
              variant="link"
              size="small"
              style={{
                height: "22px",
              }}
              icon={
                <Icon
                  style={{
                    transform: isDescriptionExpanded ? "rotate(-180deg)" : undefined,
                    transition: "all 0.25s",
                  }}
                  component={() => <LucideChevronDown style={{ transformOrigin: "50% 50%" }} />}
                />
              }
              disabled={isExpandButtonDisabled}
              children={isDescriptionExpanded ? t("parse:collapse") : t("parse:show_all")}
              onClick={() => handleIsDescriptionExpandedChange(!isDescriptionExpanded)}
            />
          </div>
        </div>
      </Spin>
      <Tabs
        activeKey={props.currentTabKey ?? undefined}
        destroyInactiveTabPane
        className="orgchart-function-drawer__tabs_header ml-n2 mt-3"
        onChange={(newTabKey) => props.onCurrentTabChange(newTabKey as TOrgchartDrawerTabKey)}
        // tabBarStyle={{ display: "none" }}
        tabBarStyle={{ margin: 0 }}
      >
        {props.drawerTabs.map((tab) => (
          <TabPane
            disabled={props.role == null}
            tab={<OrgchartFunctionDrawerTab {...tab} />}
            key={tab.tabKey}
            destroyInactiveTabPane
          />
        ))}
      </Tabs>
    </div>
  );
};

export const OrgchartFunctionDrawerHeader = observer(OrgchartFunctionDrawerHeaderObserved);

import React, {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react";
import "./PlanHistoryModule.scss";
import { useTranslation } from "react-i18next";
import { initialEditorContent } from "../../../../../../functional/utils/textEditor";
import { PlanHistoryModuleInterface } from "./PlanHistoryModule.interface";
import { api } from "../../../../../../functional/services";
import { useNotifier, useRootStore, usePagingWithController } from "../../../../../../functional/hooks";
import { PlanHistoryListCards } from "./PlanHistoryListCards";
import { PlanHistoryEditor } from "./PlanHistoryEditor";
import { HistoryDto, StaticFileDto } from "../../../../../../functional/api";
import { Card } from "../../../../../uiKit";
import { HistoryListCards } from "../../../../../uiKit/History/HistoryListCards";

export const PlanHistoryModule = forwardRef((props: PlanHistoryModuleInterface, ref) => {
  const notifier = useNotifier();
  const { t } = useTranslation();

  const { authStore } = useRootStore();
  const user = useMemo(() => {
    return authStore.getInitialInfo?.identity ?? undefined
  }, [authStore.getInitialInfo?.identity]);


  const handlerApiError = () => {
    notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
  };

  const planHistory = usePagingWithController(
    api.planHistory,
    { planId: props.planId },
    undefined,
    undefined,
    handlerApiError
  );

  const handleUpdateComment = useCallback(async (id: number, data: HistoryDto) => {
    const response = await api.planHistory.edit(id, data);
    if (response) {
      planHistory.updateItem(id, data);
    }
    return !!response;
  }, [planHistory.items]);


  const handleRestartPlanHistory = useCallback(() => {
    planHistory.restart(true);
  }, []);

   const handleRestart = () => {
    // planHistory.reset();
    planHistory.restart(true);
  };

  useImperativeHandle(ref, () => ({
    restart: handleRestart,
  }));

  useEffect(() => {
    props.planId && planHistory.restart();
  }, [props.planId]);



  const handleRemoveComment = useCallback(async (id: number) => {
    const response = await api.planHistory.del(id);
    if (response) {
      notifier.show({
        message: t("notifier:success.deleted"),
        theme: "success",
      });
    } else {
      notifier.show({
        message: t("notifier:error.something_wrong"),
        theme: "error",
      });
    }
    planHistory.restart();
    return !!response;
  }, []);

  return (
    <Card className="pa-2 plan-history-card">
      <PlanHistoryEditor
        isLoading={planHistory.info.isLoading}
        user={user}
        planId={props.planId}
        onRestart={handleRestartPlanHistory}
      />
      <HistoryListCards
        historyItems={planHistory.items}
        isDone={planHistory.info.isDone}
        loadNext={planHistory.loadNext}
        handleUpdateComment={handleUpdateComment}
        handleRemoveComment={handleRemoveComment}
        reactionsStrategyController={api.planHistory}
      />
    </Card>
  );
});

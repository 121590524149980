import React, { useMemo, useState } from "react";
import { IIssueArchiveDialog } from "./IssueArchiveDialog.interface";
import { Button, Dialog, DialogActions, Input } from "../../../uiKit";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { CheckIcon2, CloseIcon } from "../../../../functional/constants/icon";
import clsx from "clsx";
import "./issueArchiveDialog.scss";

const IssueArchiveDialogMemo = ({ open, onClose, handleArchiveIssue }: IIssueArchiveDialog) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");
  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
    // setIsFocused(true);
  };

  const onFinish = async () => {
    handleArchiveIssue(description);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    form.submit();
  };

  // const [isFocused, setIsFocused] = useState<boolean>(false);

  // const checkValidation = useMemo(() => {
  //   return description.trim().length >= 15;
  // }, [description]);

  // const validationInfoUItemClass = clsx(
  //   "validation-info-item",
  //   { "validation-info-item_active": checkValidation },
  //   { "validation-info-item_error": !checkValidation && isFocused },
  // );

  return (
    <Dialog
      title={t("ui:title.archive_dialog")}
      open={open}
      onClose={onClose}
      closable={false}
      className="issue-archive-dialog"
    >
      <Form
        name="issue-copy"
        form={form}
        onFinish={onFinish}
        onFinishFailed={() => setIsLoading(false)}
        autoComplete="off"
      >
        <Form.Item
          className="full-width"
          name="description"
        >
          <Input
            value={description}
            onInput={handleChangeValue}
          />
        </Form.Item>
        {/*<div className="validation-info">*/}
        {/*    <div className={validationInfoUItemClass}>*/}
        {/*      {!checkValidation && isFocused ? (*/}
        {/*        <CloseIcon />*/}
        {/*      ) : (*/}
        {/*        <CheckIcon2 />*/}
        {/*      )}*/}
        {/*      {t("validation:min_char", { number: 15})}*/}
        {/*    </div>*/}
        {/*  </div>*/}
      </Form>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="text"
        >
          {t("ui:button.cancel")}
        </Button>
        <Button
          loading={isLoading}
          // disabled={!checkValidation}
          htmlType="submit"
          onClick={handleSubmit}
          type="primary"
          variant="text"
        >
          {t("ui:button.archive")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const IssueArchiveDialog = React.memo(IssueArchiveDialogMemo);

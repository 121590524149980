import React, { useState } from "react";
import { IOrgchartVfpCardViewState, OrgchartVfpCardView } from "./OrgchartVfpCardView";
import { api } from "../../../../../functional/services";
import { useNotifier, useRootStore } from "../../../../../functional/hooks";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

interface IOrgchartVfpCard {
  orgchartId?: number | null;
  value?: string | null;
  setIsSoftLoading?: (val: boolean) => void;
  onChange?: () => void;
  isHiddenPrintBlock?: boolean
}

const isJsonString = (jsonString: string) => {
  try {
    const o = JSON.parse(jsonString);
    if (o && typeof o === "object") {
      return o;
    }
  } catch (e) {}

  return false;
};

export const ORGCHART_VFP_FONT_SIZES = [14, 18, 24, 30, 36, 48, 60, 72] as const;
const parseVfpFromObjectString = (val?: string | null): IOrgchartVfpCardViewState => {
  try {
    let text: string = "";
    let fontSize: typeof ORGCHART_VFP_FONT_SIZES[number] = 14;
    if (val != null && val.trim().length > 0) {
      if (val.trim().startsWith("{")) {
        const obj = JSON.parse(val) as IOrgchartVfpCardViewState;
        text = obj.text ?? "";
        fontSize = obj.fontSize ?? 14;
      } else {
        text = val;
      }
    }
    return {
      text,
      fontSize,
    };
  } catch (e) {
    return {
      text: "",
      fontSize: 14,
    };
  }
};

const OrgchartVfpCardObserved = (props: IOrgchartVfpCard) => {
  const { orgchartStore, authStore } = useRootStore();
  const notifier = useNotifier();
  const { t } = useTranslation();

  const [state, setState] = useState<IOrgchartVfpCardViewState>(() => parseVfpFromObjectString(props.value));

  const handleStateChange = (s: IOrgchartVfpCardViewState) => {
    setState((prevState) => ({ ...prevState, ...s }));
    handleUpdate(s);
  };

  const handleUpdate = async (s: IOrgchartVfpCardViewState) => {
    if (
      (state.text.trim() == s.text.trim() && state.fontSize == s.fontSize) ||
      props.orgchartId == null ||
      orgchartStore.getCurrentOrgchart == null
    )
      return;
    props.setIsSoftLoading?.(true);
    const r = await api.orgchart.edit(props.orgchartId, {
      ...orgchartStore.getCurrentOrgchart,
      id: props.orgchartId,
      valuableFinalProduct: JSON.stringify({ text: (s.text ?? "").trim(), fontSize: s.fontSize ?? 14 }),
    });
    if (r == null) {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      props.setIsSoftLoading?.(false);
      return;
    }
    await authStore.refreshInitialInfo();
    props.setIsSoftLoading?.(false);
  };

  return (
    <OrgchartVfpCardView
      state={state}
      onStateChange={handleStateChange}
      isHiddenPrintBlock={props.isHiddenPrintBlock}
    />
  );
};

export const OrgchartVfpCard = observer(OrgchartVfpCardObserved);

import React from "react";
import { Avatar } from "antd";
import "./Avatar.scss";
import clsx from "clsx";
import { IAvatarView } from "../../../functional/types";
import { useAvatar } from "../../../functional/hooks";

export function AvatarView({
  className,
  isOutlined,
  useFullText,
  type,
  color,
  style,
  text,
  icon,
  children,
  isNoFlex,
  ...rest
}: IAvatarView) {
  const { getAvatarColor, getAvatarText, getAvatarIconByType, } = useAvatar({ useFullText, text });

  return (
    <Avatar
      className={clsx(
        "uiKit-avatar",
        "no-select",
        className ?? "",
        { __outlined: isOutlined },
        {"uiKit-avatar_no-flex": isNoFlex}
      )}
      icon={getAvatarIconByType(type, text)}
      style={{
        ...style,
        background: color ?? style?.background,
        border: rest.src != null ? "none" : style?.border,
        color: getAvatarColor(color ?? style?.color),
      }}
      children={getAvatarText(text, children)}
      {...rest}
      // gap={8}
    />
  );
}

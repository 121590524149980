import React from "react";
import { useTranslation } from "react-i18next";
import "./SignupPage.scss";
import SignupForm from "../../modules/forms/signupForm/SignupForm";
import InfoLinksBlock from "../../modules/layouts/infoLinksBlock/InfoLinksBlock";
import logoFullLight from "../../../assets/images/logo-full-light.svg";
import logoFullDark from "../../../assets/images/logo-full-dark.svg";
import { Card, Title } from "../../uiKit";
import CarrotQuestButton from "../../service/carrotQuestButton/CarrotQuestButton";
import LanguageSwitcher from "../../modules/pages/settings/languageSwitcher/LanguageSwitcher";
import { ThemeSwitcher } from "../../modules/pages/settings/themeSwitcher/ThemeSwitcher";
import { useIsMobile, useRootStore } from "../../../functional/hooks";
import { observer } from "mobx-react-lite";

function SignupPage() {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const isMobile = useIsMobile();

  return (
    <div className="full-height d-flex align-center justify-center">
      <Card
        size="default"
        className="layout-card-unauthorized__wrapper"
        style={{
          maxWidth: isMobile ? "100%" : "420px",
          width: "100%",
          maxHeight: isMobile ? "100%" : "700px",
          height: "100%",
        }}
        bodyStyle={{ flexGrow: 1 }}
        actions={[
          <ThemeSwitcher displayMode="button" />,
          <LanguageSwitcher miniVariant selectProps={{ bordered: false, style: { width: "100%" } }} />,
          <CarrotQuestButton buttonProps={{ children: "" }} />,
        ]}
      >
        <div className="full-height d-flex flex-column justify-space-between">
          {/*<div />*/}
          <div className="d-stack-column spacing-4 align-center justify-center">
            <img
              draggable="false"
              width="50%"
              style={{
                width: '70%',
                maxWidth: '235px'
              }}
              src={appStore.getAppTheme == 1 ? logoFullDark : logoFullLight}
              alt="BB Platform Logo"
            />
            <Title level={4}>{t("common:page_title.registration")}</Title>
          </div>
          <SignupForm />
          <InfoLinksBlock />
        </div>
      </Card>
    </div>
  );
}

export default observer(SignupPage);

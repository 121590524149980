import React, { createRef, useEffect, useMemo } from "react";
import "./TaskRecurringCreateEditForm.scss";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { useTranslation } from "react-i18next";
import ExecutorSelect from "../../../elements/executorSelect/ExecutorSelect";
import {
  AutocompleteModel,
  IssueProofRequirementDto,
  RecurringIssueDto,
  RecurringIssueExecutorConfigDto,
  RecurringIssueRuleDto,
  StaticFileDto,
} from "../../../../functional/api";
import IssueProofForm from "../issueProofForm/IssueProofForm";
import ExpandableContainer from "../../../elements/expandableContainer/ExpandableContainer";
import RecurringPeriodSelect from "../../../elements/recurringPeriodSelect/RecurringPeriodSelect";
import DeadlineSelect from "../../../elements/deadlineSelect/DeadlineSelect";
import IssueCustomFieldsForm from "../issueCustomFieldsForm/IssueCustomFieldsForm";
import { custonFieldsKeys } from "../types/consts";
import VideoDialog from "../../dialogs/videoDialog/VideoDialog";
import { permissionKeys } from "../../../../functional/utils/permissions";
import { useClaims, useRootStore } from "../../../../functional/hooks";
import { FiHelpCircle } from "@react-icons/all-files/fi/FiHelpCircle";
import { getRoutineIssueTooltip, routineIssueTooltipKeys } from "../../../../functional/utils/tooltip";
import FileUploader from "../../../service/fileUploader/FileUploader";
import { AiOutlinePaperClip } from "@react-icons/all-files/ai/AiOutlinePaperClip";
import { observer } from "mobx-react-lite";
import { UserSelectionRule } from "../../../../functional/api/types/userSelectionRule";
import { Button, DatePicker, Icon, Radio, RadioGroup, Switch, TextArea, TimeSpanPicker } from "../../../uiKit";
import { Space, Tooltip } from "antd";
import { AutocompleteAsync } from "../../../elements/autocompleteAsync/AutocompleteAsync";
import { OpeningGuideIcon } from "../../../elements/openingGuideIcon/OpeningGuideIcon";
import { LocalesKeys } from "../../../../functional/constants/localesKeys";
import { IssueTypeKeys } from "../../../../functional/constants/issueTypeKeys";
import { TextEditor } from "../../textEditor/TextEditor";
import { initialEditorContent } from "../../../../functional/utils/textEditor";
import { ToolbarType } from "../../textEditor/types/types";
import dayjs from "dayjs";
import { guideKeys, ITaskRecurringCreateEditFormView } from "../../../../functional/types";
import { TimePickerMask } from "../../../uiKit/dataEntry/pickers/timePickerMask/TimePickerMask";
import { TIME_PLAN_QUICK_VALUES } from "../../../../functional/constants/QuickValues";

function TaskRecurringCreateEditFormView(props: ITaskRecurringCreateEditFormView) {
  const { t } = useTranslation();
  const claims = useClaims();
  const { helperStore, appStore, orgchartStore, authStore } = useRootStore();


  const uiType = useMemo(() => {
    return authStore.getCurrentCompany?.uiType;
  }, []);

  const uploadFilesInputRef = createRef();

  const converseRecurringConfigToSelectionRule = (
    value: RecurringIssueExecutorConfigDto & { idx?: number }
  ): UserSelectionRule & { idx?: number } => {
    const newValue: UserSelectionRule & { idx?: number } = {
      id: value?.id,
      roleId: value?.roleId,
      rolePositionId: uiType === 2 ? undefined : value?.rolePositionId ?? undefined,
      rolePositionType: value?.rolePositionType as any,
      orgchartId: value?.orgchartId ?? undefined,
      isExcluded: value?.isExcluded as boolean,
      idx: value?.idx,
    };
    return newValue;
  };

  const converseSelectionRuleToRecurringConfig = (
    value: UserSelectionRule & { idx?: number }
  ): RecurringIssueExecutorConfigDto & { idx?: number } => {
    const newValue: RecurringIssueExecutorConfigDto & { idx?: number } = {
      id: value?.id,
      roleId: value?.roleId,
      recurringIssueId: props.data?.id,
      rolePositionId: uiType === 2 ? undefined : value?.rolePositionId ?? undefined,
      rolePositionType: value?.rolePositionType as any,
      isExcluded: value?.isExcluded as boolean,
      orgchartId: value.orgchartId,
      idx: value?.idx,
    };
    return newValue;
  };

  const getTooltip = getRoutineIssueTooltip(appStore.getAppLocale as LocalesKeys);

  // @ts-ignore
  return (
    <>
      <VideoDialog
        trainingKey="ed.t.wwrt"
        name="recurringTasksSettings"
        onWatchedClickObject={{ recurringTasksSettings: true }}
        hidden={
          !claims.hasOneOf([
            permissionKeys.recurringIssue.add,
            permissionKeys.recurringIssue.edit,
            permissionKeys.recurringIssue.delete,
            permissionKeys.recurringIssue.pauseManage,
            permissionKeys.recurringIssue.viewHistory,
          ])
        }
        // hidden={!claims.hasOneOf([permissionKeys.recurringIssue.add, permissionKeys.recurringIssue.edit])}
      />
      <div>
        <div
          className="d-stack-row align-stretch spacing-3"
          style={{ width: "100%" }}
          // divider={<divider orientation="vertical" flexItem />}
        >
          <div style={{ flexGrow: 1, maxWidth: "50%" }}>
            <ExecutorSelect
              value={[...(props.data.executorConfigs ?? [])?.map((i) => converseRecurringConfigToSelectionRule(i))]}
              guideIcon={<OpeningGuideIcon contentKey={guideKeys.recurringIssues} anchorId="1anchor" />}
              onChange={(data) =>
                props.setData({
                  ...props.data,
                  executorConfigs: [...(data ?? [])?.map((i) => converseSelectionRuleToRecurringConfig(i))],
                })
              }
            />

            <div style={{ height: "var(--indent-md" }} />
            <div
              className="d-stack-column spacing-2"
              style={{ width: "100%", marginBottom: 10, marginTop: 10 }}
            >
              <SubheaderText text={t("parse:intervals.date_start")} />
              <DatePicker
                className="full-width"
                value={props.data?.dateStart ? dayjs(props.data?.dateStart) : null }
                onChange={(value) => {
                  const date = value?.set('hour', 0).set('minute', 0).set('second', 0)
                  props.setData({
                    ...props.data,
                    dateStart: date?.toDate().toISOString(),
                  })
                }}
                allowClear
                size="large"
                disablePast
              />
            </div>
            <RecurringPeriodSelect
              data={props.data.rules ?? []}
              onChange={(data) =>
                props.setData({
                  ...props.data,
                  rules: data as RecurringIssueRuleDto[],
                })
              }
            />

            <div style={{ height: "var(--indent-md" }} />

            <Switch
              label={t("ui:label.do_not_create_at_weekends")}
              checked={!!props.data.createOnWeekends}
              onChange={(value) =>
                props.setData({
                  ...props.data,
                  createOnWeekends: value,
                  considerUserSchedule: false,
                })
              }
            />

            <div className="pb-2" />

            <Switch
              label={t("parse:recurring_task.take_schedule")}
              checked={!!props.data.considerUserSchedule}
              onChange={(value) =>
                props.setData({
                  ...props.data,
                  considerUserSchedule: value,
                  createOnWeekends: false,
                })
              }
            />

            <div style={{ height: "var(--indent-md" }} />

            <DeadlineSelect
              value={{
                dateDeadlineUntil: props.data.dateDeadlineUntil ?? null,
                dateDeadlineAfterTime: props.data.dateDeadlineAfterTime ?? null,
              }}
              clearable={props.data.fields?.some(
                (f) => f.key == custonFieldsKeys.strictDeadline && f.valueBool == false
              )}
              onChange={(value) => props.setData({ ...props.data, ...value })}
            />
            <div style={{ height: "var(--indent-md" }} />

            <IssueCustomFieldsForm
              value={props.data?.fields ?? []}
              onChange={(value: any) =>
                props.setData({
                  ...props.data,
                  fields: value,
                })
              }
              type={IssueTypeKeys.task}
              showHintIcons
            />

            <ExpandableContainer text={t("ui:title.additional_settings")}>
              {/*<FormControlLabel*/}
              {/*  control={*/}
              {/*    <CustomSwitch*/}
              {/*      checked={!!props.data.oneIssueToOneUser}*/}
              {/*      onChange={(event) => props.setData({ ...props.data, oneIssueToOneUser: !!event.target.checked })}*/}
              {/*    />*/}
              {/*  }*/}
              {/*  sx={{ pl: 0.25 }}*/}
              {/*  label={t("parse:recurring_task.person_task")}*/}
              {/*/>*/}
              <div className="my-2">
                <Switch
                  label={t("parse:recurring_task.create_to_manager_no_employees")}
                  checked={!!props.data.createIfNoUsersWithWorkTime}
                  onChange={(value) =>
                    props.setData({
                      ...props.data,
                      createIfNoUsersWithWorkTime: value,
                    })
                  }
                />
              </div>
              <div className="d-stack-row justify-start align-center spacing-1">
                <SubheaderText text={t("parse:recurring_task.task_performer_not_working")} />
                <div style={{ marginBottom: "4px" }}>
                  <OpeningGuideIcon ml={8} contentKey={guideKeys.recurringIssues} anchorId="7anchor" />
                </div>
              </div>
              <RadioGroup
                className="ml-1"
                value={Number(props.data.senderNotWorkingActionType)}
                onChange={(event) =>
                  props.setData({
                    ...props.data,
                    senderNotWorkingActionType: Number(event.target.value),
                  })
                }
              >
                <Space direction="vertical">
                  <Radio value={0}>{t("parse:recurring_task.cpnw_keys.create")}</Radio>
                  <Radio value={1}>
                    {t("parse:recurring_task.cpnw_keys.create_from_manager")}
                    {/*{authStore.getCurrentCompanyUiType != 2*/}
                    {/*  ? t("parse:recurring_task.cpnw_keys.create_from_manager")*/}
                    {/*  : t("parse:recurring_task.cpnw_keys.create_from_manager_v2")}*/}
                  </Radio>
                  {authStore.getCurrentCompanyUiType != 2 && (
                    <Radio value={2}>{t("parse:recurring_task.cpnw_keys.create_from_performer")}</Radio>
                  )}
                  <Radio value={3}>{t("parse:recurring_task.cpnw_keys.dont_create")}</Radio>
                </Space>
              </RadioGroup>

              <div style={{ height: 12 }} />

              <div className="d-stack-row justify-start align-center spacing-1">
                <SubheaderText text={t("ui:subheader.sender")} />
                <div style={{ marginBottom: "4px" }}>
                  <OpeningGuideIcon ml={8} contentKey={guideKeys.recurringIssues} anchorId="8anchor" />
                </div>
              </div>
              <RadioGroup
                value={props.data.senderType}
                onChange={(event) =>
                  props.setData({
                    ...props.data,
                    senderType: event.target.value,
                    senderRoleId: null,
                  })
                }
              >
                <Space direction="vertical">
                  <Radio value="nearest_admin">{t("ui:label.sender_type.nearest_admin")}</Radio>
                  {/*<Radio value="nearest_admin">{authStore.getCurrentCompanyUiType != 2 ? t("ui:label.sender_type.nearest_admin") : t("ui:label.sender_type.nearest_admin_v2")}</Radio>*/}
                  {authStore.getCurrentCompanyUiType != 2 && (
                    <Radio value="executor_of_role">
                      {
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>{t("ui:label.sender_type.executor_of_function")}</span>
                          {props.data.senderType == "executor_of_role" && (
                            <div
                              className="ml-2 d-flex flex-grow-1"
                              style={{ width: "250px" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                              }}
                            >
                              <AutocompleteAsync
                                type="role"
                                placeholder={t("ui:subheader.issue_role")}
                                style={{ minWidth: "250px" }}
                                value={props.data.senderRoleId ?? undefined}
                                onChange={(id) =>
                                  props.setData({
                                    ...props.data,
                                    senderRoleId: id,
                                  })
                                }
                              />
                            </div>
                          )}
                        </div>
                      }
                    </Radio>
                  )}
                  <Radio value="admin_of_role">
                    {
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          {authStore.getCurrentCompanyUiType != 2
                            ? t("ui:label.sender_type.admin_of_function")
                            : t("ui:label.sender_type.admin_of_function_v2")}
                        </span>
                        {props.data.senderType == "admin_of_role" && (
                          <div
                            className="ml-2"
                            style={{ width: "250px" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                            }}
                          >
                            <AutocompleteAsync
                              type="role"
                              placeholder={t("ui:subheader.issue_role")}
                              style={{ minWidth: "250px" }}
                              value={props.data.senderRoleId ?? undefined}
                              onChange={(id) =>
                                props.setData({
                                  ...props.data,
                                  senderRoleId: id,
                                })
                              }
                            />
                          </div>
                        )}
                      </div>
                    }
                  </Radio>
                </Space>
              </RadioGroup>

              <div style={{ height: 12 }} />

              <div className="d-stack-row justify-start align-center spacing-1">
                <SubheaderText text={t("ui:subheader.use_timezone_of")} />
                <div style={{ marginBottom: "4px" }}>
                  <OpeningGuideIcon ml={8} contentKey={guideKeys.recurringIssues} anchorId="9anchor" />
                </div>
              </div>
              <RadioGroup
                value={props.data.dateCreatedOnTimezoneType}
                onChange={(e) =>
                  props.setData({
                    ...props.data,
                    dateCreatedOnTimezoneType: e.target.value,
                  })
                }
              >
                <Space direction="vertical">
                  <Radio value="of_executor">
                    {authStore.getCurrentCompanyUiType != 2
                      ? t("ui:label.timezone_of.executor")
                      : t("ui:label.timezone_of.employee")}
                  </Radio>
                  <Radio value="of_company">{t("ui:label.timezone_of.company")}</Radio>
                </Space>
              </RadioGroup>
            </ExpandableContainer>

            {!props.hideSaveButton && (
              <Button
                onClick={() => (props.editMode ? props.onSave() : props.onCreate())}
                loading={props.isLoading}
                variant="filled"
                className="mt-2"
              >
                {props.editMode ? t("ui:button.save") : t("ui:button.create")}
              </Button>
            )}
          </div>

          <div style={{ flexGrow: 1, maxWidth: "50%" }}>
            <div className="d-stack-row justify-start align-center spacing-1">
              <SubheaderText text={t("ui:subheader.task_recurring_name")} />
              <Tooltip zIndex={1301} title={getTooltip(routineIssueTooltipKeys.name)} placement="bottom">
                <div style={{ marginBottom: "4px" }}>
                  <FiHelpCircle className="guide-icon" />
                </div>
              </Tooltip>
            </div>

            <TextArea
              value={props.data.name!}
              onKeyDown={(event: any) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              }}
              onInput={(event: any) =>
                props.setData({
                  ...props.data,
                  name: event.target.value.replace(/(?:\r\n|\r|\n)/g, " "),
                })
              }
              className="mb-2"
              placeholder={t("ui:placeholder.recurring_task_name")}
              autoSize
            />

            <div className="d-stack-row justify-start align-center spacing-1 mt-3">
              <SubheaderText text={t("ui:subheader.description")} />
              <Tooltip zIndex={1301} title={getTooltip(routineIssueTooltipKeys.description)} placement="bottom">
                <div style={{ marginBottom: "4px" }}>
                  <FiHelpCircle className="guide-icon" />
                </div>
              </Tooltip>
            </div>

            {/*<TextArea*/}
            {/*  value={props.data.description!}*/}
            {/*  onInput={(event: any) =>*/}
            {/*    props.setData({*/}
            {/*      ...props.data,*/}
            {/*      description: event.target.value,*/}
            {/*    })*/}
            {/*  }*/}
            {/*  className="mb-2"*/}
            {/*  placeholder={t("ui:placeholder.recurring_task_description")}*/}
            {/*  autoSize*/}
            {/*/>*/}
            <TextEditor
              id={
                props.editMode
                  ? "recurring-issue-form-description-editor" + props.data.id
                  : "recurring-issue-form-description-editor"
              }
              value={props.data.description !== "" ? props.data.description! : initialEditorContent}
              initialValue={props.data.description !== "" ? props.data.description! : initialEditorContent}
              onChange={(value: string) =>
                props.setData({
                  ...props.data,
                  description: value,
                })
              }
              placeholder={t("ui:placeholder.recurring_task_description")}
              toolbarType={ToolbarType.Dynamic}
              isSimplifiedToolbar
            />
            <div className="d-stack-row justify-start align-center spacing-1 mt-3">
              <SubheaderText text={orgchartStore.getCurrentOrgchartResultName} />
              <Tooltip zIndex={1301} title={getTooltip(routineIssueTooltipKeys.result)} placement="bottom">
                <div style={{ marginBottom: "4px" }}>
                  <FiHelpCircle className="guide-icon" />
                </div>
              </Tooltip>
            </div>
            <TextArea
              value={props.data.awaitedResult!}
              onInput={(event: any) =>
                props.setData({
                  ...props.data,
                  awaitedResult: event.target.value,
                })
              }
              className="mb-2"
              placeholder={t("ui:placeholder.recurring_task_result")}
              autoSize
            />

            <div className="d-stack-row justify-space-between align-center spacing-2 mt-3 mb-2">
              <SubheaderText
                noBottomOffset
                text={`${t("ui:subheader.attachments")} (${props.data.attachments?.length ?? 0})`}
              />
              <Button
                /*@ts-ignore*/
                onClick={() => uploadFilesInputRef?.current?.click()}
                icon={<Icon component={() => <AiOutlinePaperClip />} />}
                style={{ fontSize: "12px", fontWeight: "14px" }}
                size="small"
                variant="text"
              >
                {t("ui:button.attach")}
              </Button>
            </div>

            <FileUploader
              filesContainerMaxHeight="35vh"
              hideInfo
              uploadFilesInputRef={uploadFilesInputRef}
              value={props.data.attachments?.map((a) => a.file as StaticFileDto) ?? undefined}
              onUpload={(files: StaticFileDto[]) => {
                props.setData({
                  ...props.data,
                  attachments: [
                    ...(props.data.attachments ?? []),
                    ...files
                      .filter((x) => (x.url ?? "").length > 0)
                      .filter((f) => !props.data.attachments?.some((f2) => f2.fileId == f.id))
                      .map((f, index) => {
                        return {
                          id: 0,
                          fileId: f.id,
                          order: index,
                        };
                      }),
                  ],
                });
              }}
              onAnyItemDelete={(fileId) => {
                props.setData({
                  ...props.data,
                  attachments: props.data.attachments?.filter((a) => a.fileId != fileId) ?? [],
                });
              }}
              onValueItemDelete={(fileId) => {
                props.setData({
                  ...props.data,
                  attachments: props.data.attachments?.filter((a) => a.fileId != fileId) ?? [],
                });
              }}
            />
            {/*{(props.data.attachments == null || (props.data.attachments && props.data.attachments.length === 0)) && (*/}
            {/*  <Input autoComplete="off" name="empty" value={t("text:no_attachments")} />*/}
            {/*)}*/}
            <div className="mb-3" />
            <IssueProofForm
              showHintIcon={true}
              data={props.data.proofRequirement ?? null}
              issueId={props.data.id ?? -1}
              onChange={(data: IssueProofRequirementDto) =>
                props.setData({
                  ...props.data,
                  proofRequirement: data,
                })
              }
            />
            <div className="mb-3" />
            <SubheaderText text={t("ui:planning_table.plan")} />
            {/*<TimePicker*/}
            {/*  size="large"*/}
            {/*  className="full-width"*/}
            {/*  // onOk={(date) => props.setData({ ...props.data, timePlan: date.toDate().toISOString() })}*/}
            {/*  value={props.data.timePlan != null ? getObjectFromTimeSpan(props.data.timePlan).dayjsObject : null}*/}
            {/*  onChange={(date) =>*/}
            {/*    props.setData({*/}
            {/*      ...props.data,*/}
            {/*      timePlan: generateTimeSpan({ dateObject: date?.toDate() ?? undefined }),*/}
            {/*    })*/}
            {/*  }*/}
            {/*/>*/}
            <TimePickerMask
              size="large"
              className="full-width"
              value={props.data.timePlan}
              onChange={(date) =>
                props.setData({
                  ...props.data,
                  timePlan: date,
                })
              }
              quickValues={TIME_PLAN_QUICK_VALUES}
            />
            {/*<TimeSpanPicker*/}
            {/*  size="large"*/}
            {/*  className="full-width"*/}
            {/*  value={props.data.timePlan}*/}
            {/*  onChange={(date) =>*/}
            {/*    props.setData({*/}
            {/*      ...props.data,*/}
            {/*      timePlan: date,*/}
            {/*    })*/}
            {/*  }*/}
            {/*/>*/}
            {/*<pre>{JSON.stringify(props.data, null, 2)}</pre>*/}
          </div>
        </div>
      </div>
    </>
  );
}

export default observer(TaskRecurringCreateEditFormView);

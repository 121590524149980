import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import "./IssueHistoryCard.scss";
import { IIssueHistoryCard } from "../../../../../../../../functional/types/IssueHistoryCard.interface";
import { useDateHelpers, useNotifier, useRootStore, useColorSchemeStyles } from "../../../../../../../../functional/hooks";
import { Button, Card, Icon, IconButton, List, ListItem, Text, Tooltip } from "../../../../../../../uiKit";
import { useTranslation } from "react-i18next";
import { UserCard } from "../../../../../../../elements/userCard/UserCard";
import { IssueHistoryDto, IssueHistoryReactionDto, IssueStatusDto } from "../../../../../../../../functional/api";
import { api } from "../../../../../../../../functional/services";
import { Reactions } from "../../../../../../../elements/emojiPicker/Reactions";
import { ReactionsStrategy } from "../../../../../../../elements/emojiPicker/strategies/ReactionsStrategy";
import { MdCheck, MdClose } from "react-icons/md";
import { commentIconKeys } from "../../../../../types/consts";
import { TextEditor as TextEditorLexical } from "../../../../../../textEditor/TextEditor";
import FileUploader from "../../../../../../../service/fileUploader/FileUploader";
import { LucideCheck, LucideCheckCheck, LucideDot } from "lucide-react";
import { customEvent } from "../../../../../../../../functional/helpers/eventBus";
import { useUserNameFormat } from "../../../../../../../../functional/hooks/useUserNameFormat";
import { colorScheme, colorSchemeType } from "../../../../../../../../functional/utils/colorScheme";
import { getLexicalTextContentLength, initialEditorContent } from "../../../../../../../../functional/utils/textEditor";

enum EEditorType {
  plate,
  lexical,
  undefined,
}

const detectEditorValueType = (value?: string): EEditorType => {
  if (value == null || value.trim().length == 0) return EEditorType.undefined;
  return value.includes(`"root":`) ? EEditorType.lexical : EEditorType.plate;
};

export const IssueHistoryCard = memo((props: IIssueHistoryCard) => {
  const notifier = useNotifier();
  const { getFullName } = useUserNameFormat();
  const { orgchartStore, authStore } = useRootStore();
  const { t } = useTranslation();
  const { formatDate } = useDateHelpers();
  const { findColorByKey } = useColorSchemeStyles();
  const [isReadButtonLoading, setIsReadButtonLoading] = useState(false);
  const [isRead, setIsRead] = useState<boolean | null>(props.isRead ?? null);
  const [editorContent, setEditorContent] = useState<string>(props.comment ?? "");
  const editorRef = useRef<{ resetEditor: () => void; isEmptyEditor: () => boolean }>();

  const actionState = props.actions && props.actions.length > 0 && props.actions[props.actions.length - 1];
  const actionColor = actionState && actionState.colorSchemeKey!;
  const actionIconKey = actionState && actionState.icon;

  const issueReactionsStrategy = new ReactionsStrategy<IssueHistoryDto>(
    props.id!,
    api.issueHistory,
    (x: IssueHistoryReactionDto) => x.value as string,
    (x: IssueHistoryReactionDto) => getFullName(
      x?.user?.lastName ?? "",
      x?.user?.firstName ?? "",
      x?.user?.middleName ?? "",
      ) ?? ""
  );

  const handleUpdateIsReadStatus = async () => {
    setIsReadButtonLoading(true);
    const r = await api.issueHistory.setIsReadStatus(props.id!, { value: !isRead });
    if (r == null) {
      notifier.show({
        message: t("notifier:error.something_wrong"),
        theme: "error",
      });
      return;
    }
    setIsRead(!!r.isRead);
    customEvent.dispatch("reloadIssueStatuses");
    setIsReadButtonLoading(false);
  };

  useEffect(() => {
    if (props.isRead != isRead) setIsRead(props.isRead ?? null);
  }, [props.isRead]);

  // const handleGetCommentContent = useCallback((): JSX.Element => {
  //   if (!props.comment || props.comment?.length == 0) return <TextLinkified text={""} />;
  //
  //   try {
  //     if (getStringContentType(props.comment) == StringContentType.text) throw Error();
  //     const parsedContent = stringToContent(editorContent);
  //     return detectEditorValueType(props.comment) == EEditorType.plate ? (
  //       <div className={`${isEditMode ? "mx-2" : "ml-n2"}`}>
  //         <TextEditorOld
  //           id={"ds-te-comment-" + String(props.id ? props.id * props.index + 1 : props.index)}
  //           readOnly={!isEditMode}
  //           // onEditChange={setIsEdit}
  //           onChange={(value) => setEditorContent(JSON.stringify(value))}
  //           value={parsedContent}
  //           initialValue={stringToContent(props.comment)}
  //           isSimplifiedToolbar
  //         />
  //       </div>
  //     ) : (
  //       <TextEditorLexical
  //         value={editorContent}
  //         readOnly={!isEditMode}
  //         onChange={(value) => setEditorContent(value)}
  //         isSimplifiedToolbar
  //       />
  //     );
  //   } catch (e) {
  //     return (
  //       <div className="pt-2">
  //         <TextLinkified text={props.comment} />
  //       </div>
  //     );
  //   }
  // }, [editorContent, isEditMode, props.comment, props.id, props.index]);

  const handleCancelClick = useCallback(() => {
    setEditorContent(props.comment!);
    editorRef.current?.resetEditor();
    props.onEditingCommentIdChange(null);
    // setIsEditMode(false);
    localStorage.removeItem("issue-history-comment-" + String(props.id ? props.id * props.index + 1 : props.index));
  }, [props]);

  const handleEditClick = useCallback(() => {
    // handleCancelClick();
    // if (props.editingCommentId !== props.id) {
    //   setEditorContent(props.comment!);
    //   editorRef.current?.resetEditor();
    //   localStorage.removeItem("issue-history-comment-" + String(props.id ? props.id * props.index + 1 : props.index));
    // }
    props.onIssueHistoryIdChange(props.id!);
    // setIsEditMode(true);
    props.onEditingCommentIdChange(props.id!);
  }, [props]);

  const getStatusColorKey = (currentStatus: IssueStatusDto) => {
    if (currentStatus.boardStatus?.baseStatusKey == "pending") return "var(--color-primary-base)";
    return (
      findColorByKey((currentStatus.boardStatus?.colorSchemeKey as colorSchemeType) ?? colorScheme.Primary)
        .textColorAccent ?? "var(--color-primary-base)"
    );
  };

  const handleDeleteClick = useCallback(() => {
    props.onOpenDeleteCommentDialog(props.id!);
  }, [props]);

  const [attachmentsList, setAttachmentsList] = useState(props.attachments?.map((item) => item.file!) ?? []);

  const handleSaveClick = useCallback(() => {
    props.onEditComment(editorContent, attachmentsList);
    props.onEditingCommentIdChange(null);
    localStorage.removeItem("issue-history-comment-" + String(props.id ? props.id * props.index + 1 : props.index));
  }, [editorContent, props, attachmentsList]);


  const handleDeleteAttachmentById = (fileId: number) => {
    if (fileId == null) return;
    setAttachmentsList((prev) => prev.filter(({id}) => id != fileId));
  };

  return props.comment || props.status || props.actions || props.logs || props.attachments?.length ? (
    <Card
      className="full-width"
      bordered
      isShadowed={false}
      bodyStyle={{ padding: 0 }}
      title={
        <div className="py-2 full-width d-stack spacing-3 align-center flex-nowrap">
          <div className="flex-grow-1">
            <UserCard
              userInfo={props.createdByUser ?? undefined}
              nameSize={12}
              additionalInfo={
                <Text className="todo-list__card-footer__createdAt">
                  {props.dateCreated && formatDate(props.dateCreated, { formatObject: { month: "short" } })}
                </Text>
              }
              boldName
            />
          </div>
          <Reactions
            historyId={props.id ?? 0}
            reactions={props.reactions ?? []}
            strategy={issueReactionsStrategy}
            isShowFullEmoji
          />
          {props.isRead != null && (
            <Tooltip
              open={isReadButtonLoading ? false : undefined}
              trigger={["hover"]}
              placement="topRight"
              title={isRead ? t("parse:mark_as_unread") : t("parse:mark_as_read")}
            >
              <IconButton
                type="text"
                loading={isReadButtonLoading}
                icon={
                  <Icon
                    component={() =>
                      isRead ? (
                        <LucideCheckCheck stroke="var(--color-primary-base)" />
                      ) : (
                        <LucideCheck stroke="var(--color-text-weaker)" />
                      )
                    }
                  />
                }
                onClick={handleUpdateIsReadStatus}
              />
            </Tooltip>
          )}
        </div>
      }
    >
      <div className="issue-card-history__content">
        <List className="issue-card-history__content">
          {(props.actions ?? []).length > 0 &&
            props.actions?.map((action, index) => (
              <ListItem className="pl-15 py-2 pr-3" key={action.id}>
                <div className="">
                  {actionIconKey ? (
                    <div className="d-stack-row align-center spacing-2">
                      {actionIconKey === commentIconKeys.check ? (
                        <MdCheck fontSize={20} color="var(--color-icn-success)" />
                      ) : (
                        actionIconKey === commentIconKeys.cross && (
                          <MdClose fontSize={20} color="var(--color-icn-violation)" />
                        )
                      )}
                      <Text
                        style={{
                          color:
                            actionColor == "success"
                              ? "var(--color-txt-success)"
                              : actionColor == "error"
                              ? "var(--color-txt-error)"
                              : "var(--color-txt-active)",
                        }}
                        key={action.id}
                        children={t(`ui:issue.history.${action.key?.replace('issue.history.', '')}`)}
                      />
                    </div>
                  ) : action.key === "c.i.b" ? (
                    <Text
                      key={action.id}
                      style={{ color: "var(--color-txt-active)" }}
                      children={t("ui:text.created_task")}
                    />
                  ) : action.key === "archive" ? (
                    <Text
                      key={action.id}
                      style={{ color: "var(--color-txt-success)" }}
                      children={t("ui:text.archive_task")}
                    />
                  ) : action.key === "un-archive" ? (
                    <Text
                      key={action.id}
                      style={{ color: "var(--color-txt-success)" }}
                      children={t("ui:text.un_archive_task")}
                    />
                  ) : (
                    <Text
                      key={action.id}
                      style={{ color: "var(--color-txt-active)" }}
                      children={t(`ui:issue.history.${action.key?.replace('issue.history.', '')}`)}
                    />
                  )}
                </div>
              </ListItem>
            ))}
          {props.status != null && (
            <ListItem className="pl-15 py-2 pr-3">
              <div className="full-width no-select">
                <Text className="">
                  {`${t("parse:moved_to")}`}
                  <span
                    style={{
                      color:
                        findColorByKey(
                          (props.status.boardStatus?.colorSchemeKey as colorSchemeType) ?? colorScheme.Primary
                        )?.textColorAccent ?? "var(--color-txt-active)",
                    }}
                  >
                    {/*{` «${props.status.boardStatus?.name ?? ""}»`}*/}
                    {` «${t(`ui:issue_status.${props.status.boardStatus?.key}`)}»`}
                  </span>
                </Text>
              </div>
            </ListItem>
          )}
          {(props.logs ?? []).length > 0 &&
            props.logs?.map((log, index) => (
              <ListItem key={log.id} className="pl-15 py-2 pr-3">
                <div className="d-stack-row spacing-2 full-width">
                  {!!log.key && (
                    <Text
                      type="secondary"
                      weight="bold"
                      style={{ wordBreak: "keep-all", maxWidth: "120px", width: "100%" }}
                      children={
                        log.key == "awaited.result"
                          ? // ? orgchartStore.getOrgchartResultNameByOrgchartId(0)
                            t("ui:placeholder.glossary.r")
                          : t(`ui:issue.history.logs.${log.key?.replace(/proof\.requirement(?!\.\w+)/g, "proof.requirement.title")}`)
                      }
                    />
                  )}
                  <TextEditorLexical
                    value={log.text ?? initialEditorContent}
                    initialValue={initialEditorContent}
                    readOnly
                  />
                </div>
              </ListItem>
            ))}
          {props.comment != null &&
            props.comment.trim().length > 0 &&
            getLexicalTextContentLength(props.comment) > 0 &&
            !props.isDeleted && (
              <ListItem className={`${props.editingCommentId === props.id ? "pa-2" : "pl-15 py-1"}`}>
                <div className="issue-card-history__content-body-comment full-width" style={{ maxWidth: "100%" }}>
                  <TextEditorLexical
                    id={"issue-history-comment-" + String(props.id ? props.id * props.index + 1 : props.index)}
                    ref={editorRef}
                    value={props.comment ?? initialEditorContent}
                    users={props.users}
                    // readOnly={!isEditMode}
                    readOnly={props.editingCommentId !== props.id}
                    onChange={(value) => setEditorContent(value)}
                    isSimplifiedToolbar
                    initialValue={props.comment ?? initialEditorContent}
                  />
                </div>
              </ListItem>
            )}
          {(attachmentsList ?? []).length > 0 && !props.isDeleted&& (
            <ListItem className="pl-15 py-2 pr-3">
              <div className="full-width no-select">
                <FileUploader
                  noDragAndDrop
                  value={attachmentsList}
                  hideInfo
                  smallSize
                  noDelete={props.editingCommentId !== props.id}
                  onValueItemDelete={handleDeleteAttachmentById}
                  containerClass={"issue-card-history__files-container"}
                />
              </div>
            </ListItem>
          )}
          {(!(
            props.isDeleted ||
            props.currentStatusId == 10 ||
            props.currentFilter == "log" ||
            props.currentFilter == "action" ||
            props.comment == null ||
            props.comment.trim().length == 0 ||
            getLexicalTextContentLength(props.comment) == 0 ||
            props.createdByUser?.id != authStore.getInitialInfo?.identity?.id
          ) ||
            (!props.isDeleted && props.dateUpdated != null && props.dateUpdated.trim().length > 0) ||
            (props.isDeleted && props.dateDeleted != null && props.dateDeleted.trim().length > 0)) && (
            <ListItem className="pl-15 py-2 pr-3">
              {props.editingCommentId === props.id ? (
                <div className="d-stack-row spacing-2 align-center ml-auto">
                  <Button onClick={handleSaveClick} variant={"filled"} disabled={editorRef.current?.isEmptyEditor()}>
                    {t("ui:button.save")}
                  </Button>
                  <Button onClick={handleCancelClick} variant={"default"}>
                    {t("ui:button.cancel")}
                  </Button>
                </div>
              ) : (
                <div className="d-flex align-center justify-space-between full-width">
                  {props.isDeleted ||
                  props.currentStatusId == 10 ||
                  props.currentFilter == "log" ||
                  props.currentFilter == "action" ||
                  props.comment == null ||
                  props.comment.trim().length == 0 ||
                  getLexicalTextContentLength(props.comment) == 0 ||
                  props.createdByUser?.id != authStore.getInitialInfo?.identity?.id ? (
                    <div />
                  ) : (
                    <div className="d-stack-row align-center">
                      <Button
                        className="px-0"
                        disabled={props.editingCommentId !== null && props.editingCommentId !== props.id}
                        onClick={handleEditClick}
                        variant={"link"}
                        style={{ color: "var(--color-gray-strong)", fontSize: "12px" }}
                        children={t("ui:button.edit")}
                      />
                      <LucideDot className="mx-1" style={{ color: "var(--color-gray-strong)" }} />
                      <Button
                        className="px-0"
                        disabled={props.editingCommentId !== null && props.editingCommentId !== props.id}
                        onClick={handleDeleteClick}
                        variant={"link"}
                        style={{ color: "var(--color-gray-strong)", fontSize: "12px" }}
                        children={t("ui:button.delete")}
                      />
                    </div>
                  )}
                  {!props.isDeleted && props.dateUpdated != null && props.dateUpdated.trim().length > 0 && (
                    <Text className="todo-list__card-footer__createdAt d-stack align-center justify-end">
                      {`${t("ui:text.edited_comment")} ${formatDate(props.dateUpdated, {
                        formatObject: { month: "short" },
                      })}`}
                    </Text>
                  )}
                  {props.isDeleted && props.dateDeleted != null && props.dateDeleted.trim().length > 0 && (
                    <Text className="todo-list__card-footer__createdAt d-stack align-center justify-end">
                      {`${t("ui:text.deleted_comment")} ${formatDate(props.dateDeleted, {
                        formatObject: { month: "short" },
                      })}`}
                    </Text>
                  )}
                </div>
              )}
            </ListItem>
          )}
        </List>
      </div>
    </Card>
  ) : null;
});

// export const IssueHistoryCard = React.memo(IssueHistoryCardMemo, (prevProps, newProps) => {
//   let noChanged: boolean = true;
//   if (prevProps.id != newProps.id) noChanged = false;
//   if (prevProps.index != newProps.index) noChanged = false;
//   if (prevProps.comment != newProps.comment) noChanged = false;
//   if (prevProps.isRead != newProps.isRead) noChanged = false;
//   if (prevProps.attachments != newProps.attachments) noChanged = false;
//   if (prevProps.logs?.length != newProps.logs?.length) noChanged = false;
//   if (prevProps.reactions?.length != newProps.reactions?.length) noChanged = false;
//   return noChanged;
// });

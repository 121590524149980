import React from "react";
import { UserTooltip } from "../userTooltip/UserTooltip";
import { IUserCard } from "./UserCard.interface";
import { Avatar } from "../../uiKit";
import { css } from "@emotion/css";
import { useUserNameFormat } from "../../../functional/hooks";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

export const UserCardView = (props: IUserCard) => {
  const { t } = useTranslation();
  const { getUserShortFullName, getAvatarText } = useUserNameFormat();
  const userName =
    (props.userInfo?.nameShort ?? "").trim().length > 0
      ? props.userInfo?.nameShort
      : getUserShortFullName(
          props.userInfo?.lastName ?? "",
          props.userInfo?.firstName ?? "",
          props.userInfo?.middleName ?? undefined
        ) ?? t("ui:placeholder.new_employee", {id: props.userInfo?.id ?? 0}) ?? "";

  const isFullWidth = props.fullWidth ? css({ maxWidth: "100%" }) : "";

  return (
    <UserTooltip
      user={props.userInfo}
      isNewStyle={props.isNewStyle}
      isShowTooltip={props.userInfo?.isSystem ? false : undefined}
    >
      <div
        className={clsx("user-card", {
          "user-card_field": !!props.field,
          "user-card_new-style": props.isNewStyle,
          isFullWidth,
        })}
        style={{
          gap: props.gap ?? undefined
        }}
      >
        <div className="user-card__avatar">
          <Avatar
            size={props.avatarSize}
            src={props.userInfo?.avatar?.image?.url}
            text={getAvatarText(props.userInfo?.lastName ?? "", props.userInfo?.firstName ?? "")}
            color={props.userInfo?.color}
          />
        </div>
        { !props.hiddenUserInfo ? (
          <div className="user-card__info">
            <h2
              className="user-card__info-name"
              style={{
                padding: 0,
                margin: 0,
                fontSize: props.nameSize ?? 15,
                fontWeight: props.boldName ? 700 : 500,
                lineHeight: props.lineHeightName ?? 1,
              }}
            >
              {userName}
            </h2>
            {props.additionalInfo && typeof props.additionalInfo === "string" ? (
              <p className="user-card__info-additional-info">{props.additionalInfo}</p>
            ) : (
              props.additionalInfo
            )}
          </div>
        ) : null }

      </div>
    </UserTooltip>
  );
};

import React from "react";
import { useNavigate } from "react-router-dom";
import { CompanyGroupDto } from "../../../../../../functional/api";
import { Card, Text } from "../../../../../uiKit";
import { useDateHelpers } from "../../../../../../functional/hooks";

interface IGroupCompanyCard {
  groupCompany: CompanyGroupDto;
}

export const GroupCompanyCard = (props: IGroupCompanyCard) => {
  const navigate = useNavigate();
  const { formatDate } = useDateHelpers();

  const handleRedirectToGroup = () => navigate(`/driver/super-admin/${props.groupCompany.id}`);

  return (
    <Card size="default" isShadowed={false} className="br-none" clickable onClick={handleRedirectToGroup}>
      <div className="d-flex flex-nowrap align-center">
        <Text className="flex-grow-1" weight={500} children={props.groupCompany.name} />
        <Text
          className="ml-2"
          weight={500}
          style={{ opacity: 0.5, whiteSpace: "nowrap" }}
          children={formatDate(new Date(props.groupCompany.dateCreated!).toString(), {
            formatObject: {
              hour: undefined,
              minute: undefined,
            },
          })}
        />
      </div>
    </Card>
  );
};

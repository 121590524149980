import React, { useCallback, useMemo, useState } from "react";
import { IconButton, Input, Popover, Tag } from "../../../../../uiKit";
import { addAlphaToHex } from "../../../../../../functional/helpers/colorFunctions";
import { LucideChevronDown } from "lucide-react";
import "./InputWithPriorities.scss";
import { useRootStore } from "../../../../../../functional/hooks";
import { observer } from "mobx-react-lite";

interface IInputWithPriorities {
  value?: number | null;
  readOnly?: boolean;
  onChange: (value: number) => void;
  isUpdateForm: boolean;
}

export const InputWithPriorities = observer((props: IInputWithPriorities) => {
  const { boardStore } = useRootStore();
  const [priority, setPriority] = useState<{
    value: number | undefined;
    backgroundColor?: string | null;
    fontColor?: string | null;
  }>({
    value: undefined,
    backgroundColor: null,
    fontColor: null,
  });
  const [open, setOpen] = useState<boolean>(false);

  const currentPriority = useMemo(
    () => boardStore.getBoard?.priorities?.find((p) => p.id === props.value),
    [boardStore.getBoard?.priorities, props.value]
  );

  const handleGetPriorityColors = useCallback((): { backgroundColor?: string | null; fontColor?: string | null } => {
    if (currentPriority) {
      return { backgroundColor: currentPriority.backgroundColor, fontColor: currentPriority.fontColor };
    }
    return { backgroundColor: priority.backgroundColor, fontColor: priority.fontColor };
  }, [currentPriority, priority.backgroundColor, priority.fontColor]);

  const handleGetPriorityValue = useCallback(() => {
    if (props.value && currentPriority) {
      return currentPriority.id;
    }
    return priority?.value;
  }, [currentPriority, priority?.value, props.value]);

  const popoverItems = (
    <div style={{ display: "grid", gridTemplateColumns: "auto auto auto auto", gap: 8 }}>
      {boardStore.getBoard?.priorities?.map((p) => (
        <Tag
          key={p.id}
          style={{
            width: 24,
            height: 24,
            userSelect: "none",
            cursor: "pointer",
            padding: 0,
            margin: 0,
            textAlign: "center",
            backgroundColor: `var(--color-priority${p.id}-bg)`,
            color: `var(--color-priority${p.id}-text)`,
          }}
          bordered={false}
          onClick={() => {
            !props.isUpdateForm &&
              setPriority({
                value: p.id,
                backgroundColor: p.backgroundColor,
                fontColor: p.fontColor,
              });
            props.onChange(p.id!);
            setOpen(false);
          }}
        >
          {p.id}
        </Tag>
      ))}
    </div>
  );

  const selectAfter = (
    <Popover
      placement={"bottomRight"}
      overlayInnerStyle={{ marginRight: "-17px", marginTop: "4px" }}
      trigger={["click"]}
      content={popoverItems}
      arrow={false}
      open={open}
      onOpenChange={() => {
        setOpen((prev) => !prev);
      }}
    >
      <div className="d-stack align-center justify-center">
        <Tag
          hidden={
            !handleGetPriorityColors().backgroundColor &&
            !handleGetPriorityColors().fontColor &&
            !handleGetPriorityValue()
          }
          bordered={false}
          style={{
            backgroundColor: `var(--color-priority${handleGetPriorityValue()}-bg)`,
            color: `var(--color-priority${handleGetPriorityValue()}-text)`,
            width: 24,
            height: 24,
            padding: 0,
            textAlign: "center",
          }}
        >
          {handleGetPriorityValue()}
        </Tag>
        <IconButton type="text" icon={<LucideChevronDown />} disabled={props.readOnly} />
      </div>
    </Popover>
  );
  return <Input className="priorities-input" addonAfter={selectAfter} readOnly={props.readOnly} />;
});

import React, { memo } from "react";
import { IIssueActivityCreate } from "../../../../../../../../functional/types/IssueActivityCrate.interface";
import "./IssueActivityCreate.scss";
import { useTranslation } from "react-i18next";
import { Text } from "../../../../../../../uiKit";

export const IssueActivityCreate = memo((props: IIssueActivityCreate) => {
  const { t } = useTranslation();

  const statusText = t(`ui:issue_activity.issue_status.${props.boardStatus.key}`);

  return (
    <div className={"issue-activity-create"}>
      <div className="issue-activity-create__text">
        <Text
          style={{
            fontSize: 12,
            color: "var(--color-calendar-primary-base)",
          }}
        >
          {t("ui:text.created_task")}
        </Text>
      </div>
      <div className="issue-activity-create__text">
        <Text
          style={{
            fontSize: 12,
            color: "var(--color-primary-weaker-second)",
          }}
        >
          {t("parse:moved_to")}
        </Text>
        <Text
          style={{
            fontSize: 12,
            color: "var(--color-calendar-primary-base)",
          }}
        >
          "{statusText}"
        </Text>
      </div>
    </div>
 )
})

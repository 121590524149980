import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { Button, Dialog, DialogActions } from "../../../uiKit";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { AutocompleteAsync } from "../../../elements/autocompleteAsync/AutocompleteAsync";
import { driverTypeKeys } from "../../../../functional/helpers/groupCompany/helpers";
import { IAppendDriverToCompanyGroupViewDialog } from "./AppendDriverToCompanyGroupDialog.interface";

export const AppendDriverToCompanyGroupViewDialog: React.FC<IAppendDriverToCompanyGroupViewDialog> = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const handleSubmit = () => form.submit();

  return (
    <Dialog open={props.open} onClose={props.onClose} title={t("parse:add_employee_full")} closable={false}>
      <Form form={form} name="CreateCompanyGroupForm" onFinish={props.onAdd}>
        <SubheaderText
          text={props.type === driverTypeKeys.driver ? t("ui:subheader.drivers") : t("ui:subheader.drivers_admin")}
        />
        <Form.Item
          className="form-item__mb3"
          // label={t("ui:label.name")}
          name="drivers"
          rules={[{ required: true, message: t("validation:field_required") }]}
        >
          <AutocompleteAsync
            type="user"
            mode="multiple"
            value={props.driverIds}
            idsToFilter={props.onGetExcludedIds()}
            onChange={props.onChangeDriverIds}
            //requestOptions={{isDriverOrDriverAdminOfGroup : false }}
          />
        </Form.Item>
      </Form>
      <DialogActions>
        <Button onClick={props.onClose} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button onClick={handleSubmit} variant="filled" loading={props.isLoading}>
          {t("ui:button.add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { observer } from "mobx-react-lite";
import { useNotificationCenter } from "../../../functional/hooks";
import { NotificationCenterView } from "./NotificationCenterView";


export function NotificationCenterToExport() {
  const {
    currentTab,
    isOpen,
    isUnreadOnly,
    notificationData,
    unreadMessagesCount,
    notificationsWithInvertedReadStatusIds,
    loadingNotificationsIds,
    isMarkAllAsReadLoading,
    isNewNotificationTooltipOpen,
    handleCurrentTabChange,
    handleUnreadOnlyChange,
    handleOpenChange,
    handleNotificationClick,
    handleMarkAllAsReadClick,
    handleMarkAsReadUnreadClick,
    handleLoadMore,
  } = useNotificationCenter();

  return (
    <NotificationCenterView
      currentTab={currentTab}
      isOpen={isOpen}
      isUnreadOnly={isUnreadOnly}
      notificationData={notificationData}
      unreadNotificationsCount={unreadMessagesCount}
      notificationsWithInvertedReadStatusIds={notificationsWithInvertedReadStatusIds}
      loadingNotificationsIds={loadingNotificationsIds}
      isMarkAllAsReadLoading={isMarkAllAsReadLoading}
      isNewNotificationTooltipOpen={isNewNotificationTooltipOpen}
      onCurrentTabChange={handleCurrentTabChange}
      onUnreadOnlyChange={handleUnreadOnlyChange}
      onOpenChange={handleOpenChange}
      onNotificationClick={handleNotificationClick}
      onMarkAllAsReadClick={handleMarkAllAsReadClick}
      onMarkAsReadUnreadClick={handleMarkAsReadUnreadClick}
      onLoadMore={handleLoadMore}
    />
  );
}

export const NotificationCenter = observer(NotificationCenterToExport);

import React from "react";
import "./Switch.scss";
import { Form, FormItemProps, Switch, SwitchProps } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import clsx from "clsx";

interface ISwitchView extends SwitchProps {
  theme?: "error" | "success" | "warning" | "info" | "default";
  label?: string | JSX.Element | null;
  formItemProps?: FormItemProps;
  useAsPureComponent?: boolean;
  readOnly?: boolean;
  isHiddenCheckedChildren?: boolean;
}

export function SwitchView({
  theme,
  label,
  formItemProps,
  className,
  useAsPureComponent,
  readOnly,
  isHiddenCheckedChildren,
  ...rest
}: ISwitchView) {
  return useAsPureComponent == true || useAsPureComponent == null ? (
    <div className="d-stack spacing-2 align-center">
      <Switch
        className={clsx("uiKit-switch", className ?? "", {
          "no-interact": readOnly,
        })}
        checkedChildren={isHiddenCheckedChildren ? null : <CheckOutlined />}
        unCheckedChildren={isHiddenCheckedChildren ? null : <CloseOutlined />}
        {...rest}
      />
      <span className="no-select" style={{ color: "var(--color-txt-primary)" }} children={label ?? ""} />
    </div>
  ) : (
    <Form.Item {...formItemProps} className={"uiKit-switch__wrapper " + (formItemProps?.className ?? "")}>
      <Switch
        className={clsx("uiKit-switch", className ?? "", {
          "no-interact": readOnly,
        })}
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        {...rest}
      />
      {label != null &&
        (typeof label === "string" ? (
          <span className="no-select" style={{ marginLeft: "0.75em", lineHeight: "1.15em" }} children={label} />
        ) : (
          label
        ))}
    </Form.Item>
  );
}

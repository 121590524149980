import { memo, useCallback, useMemo, useRef, useState } from "react";
import { Button, DatePicker, Dialog, DialogActions } from "../../../../../uiKit";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import { BroadcastChannel } from "broadcast-channel";
import { api } from "../../../../../../functional/services";
import { IIssueChangeDeadlineDialogProps } from "./IIssueChangeDeadline.interface";
import "./IssueChangeDeadline.scss";

export const IssueChangeDeadlineDialog = memo((props: IIssueChangeDeadlineDialogProps) => {
  const { t } = useTranslation();

  const dateDeadline = useRef<Dayjs>(dayjs(props.dateDeadline));

  const customContainer = useRef(null);
  const issueChannel = new BroadcastChannel("issue");

  const handleChange = useCallback((value: Dayjs) => {
    // setDateDeadline(value);
    dateDeadline.current = value;
  }, []);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    // setIssueDeadline(dateDeadline.current);
    const status = await api.issue.editPatch(props.issueId,   [{
      value: dateDeadline.current?.toISOString(),
      path: "dateDeadline",
      op: "replace"
    }]);
    setIsLoading(false);
    console.log("status", status);
    if (status !== null) {
      issueChannel.postMessage({
        issueId: props.issueId,
        type: "issueBody",
      });
      props.onClose();
    }
  }

  const minDate = useMemo(() => {
    return dayjs();
  }, []);

  const dateDeadlineInit = useMemo(() => {
    return props.dateDeadline ? dayjs(props.dateDeadline) : dayjs().set('hour', 18).set('minute', 0);

  }, [props.dateDeadline]);

  const getPopupContainer = useCallback(() => customContainer.current!, []);
  const showTimeFormat = useMemo(() => {
    return {
      format: "HH:mm",
      defaultValue: dayjs('18:00', 'HH:mm'), //TODO установить время окончания рабочего времени компании
    };
  }, []);

  const datePickerStyle = useMemo(() => {
    return { display: 'none' };
  }, []);

  return (
    <Dialog
      destroyOnClose
      title={t("ui:issue_change_deadline_dialog.title")}
      open={true}
      closable={false}
      className={"issue-change-deadline-dialog"}
      width={550}
    >
      <div
        ref={customContainer}
        className="issue-change-deadline-dialog-container"
      >
        <DatePicker
          open={true}
          style={datePickerStyle}
          getPopupContainer={getPopupContainer}
          showTime={showTimeFormat}
          value={dateDeadlineInit}
          onSelect={handleChange}
          // minDate={minDate}
          readOnly
          isStatic
          hiddenExtraFooter
        />
      </div>
      <DialogActions>
        <Button
          onClick={props.onClose}
          variant="text"
        >
          {t("ui:button.cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          variant="filled"
          loading={isLoading}
        >
          {t("ui:button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  )
});

import React, { MouseEvent, useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import { useTranslation } from "react-i18next";
import { useApi, useClaims, useCopyToClipboard, useNotifier, useRootStore, useUserNameFormat } from "../../../functional/hooks";
import { api } from "../../../functional/services";
import "./EmployeeIndividualPage.scss";
import EmployeeDeleteDialog from "../../modules/dialogs/employeeDeleteDialog/EmployeeDeleteDialog";
import { permissionKeys } from "../../../functional/utils/permissions";
import { FiAtSign } from "@react-icons/all-files/fi/FiAtSign";
import { FiPhone } from "@react-icons/all-files/fi/FiPhone";
import { FiSend } from "@react-icons/all-files/fi/FiSend";
import { Avatar, Card, Chip, Dropdown, Skeleton } from "../../uiKit";
import { DropdownMenu } from "../../service/dropdownMenu/dropdownMenu";
import { PopupMenuItemTypes } from "../../service/dropdownMenu/dropdownMenu.interface";
import { EmployeeRolesList } from "../../modules/pages/employee/employeeRolesList/EmployeeRolesList";
import { EmployeeIndividualRegulationBlock } from "../../modules/pages/employee/employeeIndividualRegulationBlock/EmployeeIndividualRegulationBlock";
import { getContactLinkByType } from "../../../functional/utils/contactLinkByType";
import dayjs from "dayjs";
import clsx from "clsx";

export default function EmployeeIndividualPage() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const claims = useClaims();
  const navigate = useNavigate();
  const { getFullName, getAvatarText } = useUserNameFormat();

  const notifier = useNotifier();

  const [copyFn] = useCopyToClipboard();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleApiError = (r?: any) => {
    // console.log("Error ", err);
    if (r == null) {
      navigate("/employee");
    }
  };

  const handleEditClick = () => {
    navigate("/employee/" + id + "/edit");
  };
  const onDeleteSuccess = () => {
    navigate("/employee");
  };

  // const getContactLinkByType = (contact: UserContactDto | null) => {
  //   console.log(444, contact);
  //   return contact?.deepLink ?? "/#";
  // };

  const menuItems: PopupMenuItemTypes[] = [
    {
      id: 0,
      text: t("common:menu.employee_card.edit"),
      hidden: !claims.has(permissionKeys.user.edit),
      action: handleEditClick,
    },
    {
      id: 1,
      // disabled: authStore.getInitialInfo?.identity?.id == Number(id),
      text: t("common:menu.employee_card.delete"),
      hidden: !claims.has(permissionKeys.user.delete) || authStore.getInitialInfo?.identity?.id == Number(id),
      action: () => setIsDeleteDialogOpen(true),
    },
  ].filter((i) => !i.hidden);

  const userData = useApi(
    () => api.user.getById(Number(id)),
    () => handleApiError(),
    (r) => handleApiError(r)
  );

  const getYearDiffText = useCallback((year: number) => {
    const number = year % 10;
    if (number === 1) {
      return t("ui:date_format.year");
    } else if (number < 5) {
      return t("ui:date_format.year1");
    }
    return t("ui:date_format.year2");
  }, []);

  const getMontDiffText = useCallback((month: number) => {
    if (month === 1) {
      return t("ui:date_format.month");
    } else if (month < 5) {
      return t("ui:date_format.month1");
    }
    return t("ui:date_format.month2");
  }, []);

  const employmentDate = useMemo(() => {
    const currentDate = dayjs();
    const diff = currentDate.diff(dayjs(userData.value?.personalInfo?.employmentDate), 'month');
    const yearDiff = Math.floor(diff / 12);
    const yearDiffText = yearDiff ? `${yearDiff} ${getYearDiffText(yearDiff)} ` : "";

    const monthDiff = diff - yearDiff * 12;
    const monthDiffText = monthDiff ? `${monthDiff} ${getMontDiffText(monthDiff)}` : "";
    const diffText = `${yearDiffText}${monthDiffText}`;
    return diffText;
  }, [userData.value?.personalInfo?.employmentDate])

  const handleChangeAccessType = useCallback(async (type: number) => {
    const resp = await api.user.editPatch(userData.value?.id!, [{
      value: type,
      path: "currentAccessType",
      op: "replace"
    }]);

    if (resp === null) {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
    } else {
      userData.setDataSync(resp);
    }
  }, [userData.value]);

  const items = useMemo(() => {
    return [
      {
        key: 0,
        text: t("parse:employee_type.full"),
        disabled: userData.value?.currentAccessType === 0,
        onClick: (e: MouseEvent<HTMLSpanElement>) => {
          e.stopPropagation();
          handleChangeAccessType(0)
        },
      },
      {
        key: 1,
        text: t("parse:employee_type.limited"),
        disabled: userData.value?.currentAccessType === 1,
        onClick: (e: MouseEvent<HTMLSpanElement>) => {
          e.stopPropagation();
          handleChangeAccessType(1)
        },
      },
    ]
  }, [userData.value?.currentAccessType]);

  return (
    <>
      <PageHeader title={t("common:page_title.employee")} />
      <PageContent isLoading={userData.isLoading || userData.value == null} className="d-stack-column spacing-7">
        <EmployeeDeleteDialog
          open={isDeleteDialogOpen}
          userId={Number(id)}
          companyId={authStore.getInitialInfo?.identity?.currentCompanyId as number}
          onSuccess={onDeleteSuccess}
          onClose={() => setIsDeleteDialogOpen(false)}
        />
        <Card
          className={"employee-page-individual-container"}
          bodyStyle={{ display: "flex", flexWrap: "nowrap" }}
          // loading={userData.isLoading || userData.value == null}
        >
          <Skeleton loading={userData.isLoading || userData.value == null} avatar={{ size: 80 }}>
            <Avatar
              text={getAvatarText(userData.value?.lastName ?? "", userData.value?.firstName ?? "")}
              color={userData.value?.color}
              src={userData.value?.avatar?.image?.url}
              size={80}
            />
            <div className="flex-grow-1 d-stack-column justify-center ml-3">
              <div className="d-stack spacing-3 align-center">
                <span className="employee-page-individual_user-name">
                  {getFullName(
                    userData.value?.lastName ?? "",
                    userData.value?.firstName ?? "",
                    userData.value?.middleName ?? ""
                  )  ?? t("ui:placeholder.new_employee", {id: userData.value?.id ?? 0}) ?? ""
                  }
                </span>
                <span
                  className={clsx("employee-page-individual-access-type", {
                    "employee-page-individual-access-type_prevent": !claims.has(permissionKeys.user.edit)
                  })}
                  onClick={(e: MouseEvent<HTMLSpanElement>) => {
                    !claims.has(permissionKeys.user.edit) && e.stopPropagation();
                  }}
                >
                  <Dropdown
                    items={items}
                    trigger={["click"]}
                    disabled={!claims.has(permissionKeys.user.edit)}
                  >
                    <span>
                      <Chip
                        type={userData.value?.currentAccessType == 0 ? "default" : "warning"}
                        label={
                          userData.value?.currentAccessType == 0
                            ? t("parse:employee_type.full")
                            : t("parse:employee_type.limited")
                        }
                      />
                    </span>
                  </Dropdown>
                </span>
              </div>
              <div className="my-2">
                <EmployeeRolesList roles={userData.value?.roles ?? []} />
              </div>
              {/*<div className="employee-page-individual_roles-list">*/}
              {/*  /!*[...Array.from(userData.value?.roles?.map((u2r: User2RoleDto) => u2r.role?.name ?? []) ?? [])]*!/*/}
              {/*  {userData.value?.roles*/}
              {/*    ?.map((u2r: User2RoleDto) => u2r.role?.name ?? [])*/}
              {/*    ?.map((roleName, index) => (*/}
              {/*      <span key={index}>*/}
              {/*        {index > 0 && <span className="employee-page-individual_role-divider">·</span>}*/}
              {/*        <span className="employee-page-individual_role" key={index}>*/}
              {/*          {roleName}*/}
              {/*        </span>*/}
              {/*      </span>*/}
              {/*    ))}*/}
              {/*</div>*/}
              <div className="employee-page-individual_info-block">
                {userData.value?.email && userData.value?.email?.length > 0 && (
                  <div className="employee-page-individual_info-element __email">
                    <FiAtSign fontSize="small" />
                    <a href={"mailto:" + userData.value?.email}>{userData.value?.email}</a>
                  </div>
                )}
                {claims.has(permissionKeys.user.viewUserPhoneNumber) &&
                  userData.value?.phoneNumber &&
                  userData.value?.phoneNumber?.length > 0 && (
                    <div className="employee-page-individual_info-element __phone">
                      <FiPhone fontSize="small" />
                      <a href={"tel:" + userData.value?.phoneNumber}>{userData.value?.phoneNumber}</a>
                    </div>
                  )}
                {(userData.value?.contact?.name ?? "").trim().length > 0 && (
                  <div className="employee-page-individual_info-element __contact-type">
                    <FiSend fontSize="small" />
                    <span>{t("ui:subheader.communication_type")}:</span>
                    <a
                      onClick={() => copyFn(userData.value?.contact?.value ?? "")}
                      // target="_blank"
                      href={getContactLinkByType(userData.value?.contact ?? null)}
                    >
                      {userData.value?.contact?.name}{" "}
                      {(userData.value?.contact?.value ?? "").length > 0 ? ` (${userData.value?.contact?.value})` : ""}
                    </a>
                  </div>
                )}
              </div>
              {(!!userData.value?.personalInfo?.birthDate || !!userData.value?.personalInfo?.employmentDate) && (
                <div className="employee-page-individual-personal-info">
                  {!!userData.value?.personalInfo?.birthDate && (
                    <div className="employee-page-individual-date">
                      <div className="employee-page-individual-date__text">
                        {t("ui:subheader.birth_date")}
                      </div>
                      <div className="employee-page-individual-date__value">
                        {dayjs(userData.value?.personalInfo?.birthDate).format("L")}
                      </div>
                    </div>
                  )}
                  {!!userData.value?.personalInfo?.employmentDate && (
                    <div className="employee-page-individual-date">
                      <div className="employee-page-individual-date__text">
                        {t("ui:subheader.employment_date")}
                      </div>
                      <div className="employee-page-individual-date__value">
                        { dayjs(userData.value?.personalInfo?.employmentDate).format("L") }
                        {!!employmentDate && (
                          <span>|</span>
                        )}
                        {employmentDate}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            {(menuItems.filter((i) => !i.hidden).length > 0) && (
              <div className="d-stack-column justify-start ml-2">
                <DropdownMenu items={menuItems} />
              </div>
            )}
          </Skeleton>
        </Card>
        <EmployeeIndividualRegulationBlock userId={id != null ? Number(id) : undefined} />
      </PageContent>
    </>
  );
}

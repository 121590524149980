import React from "react";
import EmployeeDeleteDialogView from "./EmployeeDeleteDialogView";
import { api } from "../../../../functional/services";
import { useTranslation } from "react-i18next";
import { useNotifier } from "../../../../functional/hooks";

export interface IEmployeeDeleteDialog {
  open: boolean;
  userId: number;
  companyId: number;

  onSuccess(): void;
  onClose(): void;
}

function EmployeeDeleteDialog(props: IEmployeeDeleteDialog) {
  const { t } = useTranslation();
  const notifier = useNotifier();

  const handleDelete = async () => {
    const r = await api.user.leaveCompany(props.userId, props.companyId);
    notifier.showSuccessError(t("notifier:success.user_deleted"), t("notifier:error.user_deleted"), !!r);
    if (!!r) {
      props.onSuccess();
      props.onClose();
    }
  };

  return <EmployeeDeleteDialogView open={props.open} onDelete={handleDelete} onClose={props.onClose} />;
}

export default EmployeeDeleteDialog;

import React, { useEffect, useRef, useState } from "react";
import EmployeeEditFormView from "./EmployeeEditFormView";
import {
  useApi,
  useEmployeeEditForm,
  useNotifier,
  useRootStore,
  useUpdateWithController
} from "../../../../functional/hooks";
import { observer } from "mobx-react-lite";
import { api } from "../../../../functional/services";
import { useTranslation } from "react-i18next";
import { UserDto } from "../../../../functional/api";
import {  useNavigate } from "react-router-dom";
import { IEmployeeEditForm } from "../../../../functional/types";

function EmployeeEditForm(props: IEmployeeEditForm) {
  const {
    employeeData,
    userId,
    isUserTypeEditAvailable,
    canShowError,
    updater,
    codeSendTimer,
    checkEmailCode,
    setCheckEmailCode,
    setCanShowError,
    handleConfirmEmail,
    currentUserId,
    handleAvatarChange,
    handleAvatarDelete,
    sendConfirmationCodeAgain,
  } = useEmployeeEditForm(props);

  return (
    <div
      style={{ maxWidth: "444px", width: "100%", overflowY: "auto", maxHeight: "100%" }}
      id="employee-edit-form"
    >
      {/*<CustomConfirmDialog*/}
      {/*  open={isOpenConfirmTimeZone}*/}
      {/*  onClose={() => setIsOpenConfirmTimeZone(false)}*/}
      {/*  subTitle={`${t("parse:confirm_timezone1")} ${handleGetSubtitleForConfirmTimezone()}. ${t(*/}
      {/*    "parse:confirm_timezone2"*/}
      {/*  )}`}*/}
      {/*  // buttonText={{ cancel: "" }}*/}
      {/*  onConfirm={handlerConfirmSetTimezoneDialog}*/}
      {/*/>*/}
      <EmployeeEditFormView
        firstLaunch={props?.firstLaunch ?? false}
        employeeData={employeeData.value ?? null}
        userId={userId as number}
        isUserTypeEditAvailable={isUserTypeEditAvailable}
        isAccessTypeEditAvailable={props.userId != null}
        canShowError={canShowError}
        updater={updater}
        codeSendTimer={codeSendTimer}
        checkEmailCode={checkEmailCode}
        setCheckEmailCode={setCheckEmailCode}
        setCanShowError={setCanShowError}
        handleConfirmEmail={handleConfirmEmail}
        currentUserId={currentUserId as number}
        handleAvatarChange={handleAvatarChange}
        handleAvatarDelete={handleAvatarDelete}
        sendConfirmationCodeAgain={sendConfirmationCodeAgain}
        onSaveClick={props.onSaveClick}
      />
    </div>
  );
}

export default observer(EmployeeEditForm);

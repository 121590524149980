import { useTranslation } from "react-i18next";
import {
  Bar,
  CartesianGrid,
  ComposedChart, LabelList,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { abbreviateNumber, splitNumberWithSeparator } from "../../../../../functional/helpers/numberFunctions";
import { Card, Shape, Text, Tooltip as UITooltip } from "../../../../uiKit";
import { METRIC_COLORS, TDashboardChartDataItem } from "./DashboardChart";
import "./DashboardChart.scss";
import React, { useEffect, useMemo, useState } from "react";
import { CompanyIntervalDto, MetricDividerDto, MetricReadDto } from "../../../../../functional/api";
import { findLastIndex } from "../../../../../functional/helpers/arrayFunctions";

export type TDashboardChartViewChartsData = {
  chartItemsIds: number[];
  lineCharts: {
    id: number;
    name: string;
    color?: string;
    data: { itemId: number; date: string; label: string; value: number | null }[];
  }[];
  quotaChart: { itemId: number; date: string; label: string; value: number | null }[];
};

type TDashboardChartViewChartIntervalType = null | "day" | "week" | "month" | "year";

interface IDashboardChartView {
  data: TDashboardChartDataItem[];
  metricId: number;
  metricSource2UserId?: number;
  metricData: MetricReadDto;
  chartsData: TDashboardChartViewChartsData;
  yDividers: MetricDividerDto[];
  // { [key: string | number]: any }
  isReadOnly?: boolean;
  isReversed?: boolean;
  isLegendEnabled?: boolean;
  isColored?: boolean;
  viewSettings?: {
    min: number | null;
    max: number | null;
    isTrendActive: boolean;
    isLinesColorActive: boolean;
  };
  onChange: (itemId: number) => void;
  isPrint?: boolean;
}

interface IDashboardChartViewChartData {
  label?: string;
  date?: string;
  quota?: number;

  [key: number]: number | undefined;
}

interface IDashboardChartViewTrendData {
  id: number;
  x1: string;
  x2: string;
  y1: number | undefined;
  y2: number | undefined;
  color: string;
}

interface IDashboardChartViewGradientData {
  id: number;
  data: {
    percentage: number;
    color: string;
  }[];
}

const CustomizedYTick = (props: any, yDividers: MetricDividerDto[], isNativeTick: boolean) => {
  const { x, y, payload } = props;

  return (
    <text x={x} y={y} fill="var(--color-txt-secondary)" fontSize="8px">
      <UITooltip title={splitNumberWithSeparator(payload.value)} className="no-interact">
        <tspan x={x} dy="0.355em" textAnchor="end">
          {isNativeTick
            ? abbreviateNumber(props.payload?.value)
            : yDividers.find((d) => d.value == props.payload?.value)?.label ?? ""}
        </tspan>
      </UITooltip>
    </text>
  );
};

const CustomizedXTick = (
  props: any,
  isChartCompact: boolean,
  chartIntervalType: TDashboardChartViewChartIntervalType,
  chartData: IDashboardChartViewChartData[]
) => {
  const { x, y, payload, orientation } = props;
  const date = chartData.find((d) => d.label == payload.value)?.date;

  const formatLabelToDatesArray = (): [string, string] => {
    let divider = payload.value.replace(/\d+/g, "")?.[0] ?? "";
    divider = divider.trim().length == 0 ? "." : divider;

    if (isChartCompact && chartIntervalType != null && chartIntervalType != "day" && chartIntervalType != "week") {
      if (date == null) return ["", ""];
      const ymd = date.substring(0, 10).match(/\d+/g);
      if (chartIntervalType == "month") return [(ymd?.[1] ?? "") + divider + (ymd?.[0] ?? ""), ""];
      if (chartIntervalType == "year") return [ymd?.[0] ?? "", ""];
      return ["", ""];
    } else {
      const dates = payload.value.match(/\d+/g);
      if (dates == null || dates.length < 3) return [payload.value, ""];
      return [dates[0] + divider + dates[1], dates[2]];
    }
  };

  return (
    <text
      x={x}
      y={y}
      fill={"var(--color-txt-semisecondary"}
      fontSize="8px"
      textAnchor={isChartCompact ? (orientation == "top" ? "start" : "end") : "middle"}
      transform={isChartCompact ? `rotate(-90 ${x} ${y})` : undefined}
      dominantBaseline="central"
      // style={{ fontVariantNumeric: "tabular-nums" }}
    >
      <tspan
        x={x}
        dy={isChartCompact ? 0 : orientation == "top" ? "-1.4em" : "0.6em"}
        children={formatLabelToDatesArray()[0]}
      />
      {!isChartCompact && (
        <tspan x={x} dy={"1.2em"} fill="var(--color-txt-secondary)" children={formatLabelToDatesArray()[1]} />
      )}
    </text>
  );
};

const CustomizedTooltipCursor = (props: any) => {
  const { x, y, width, height, points, top, bottom } = props;
  return (
    <>
      <line
        x1={points?.[0]?.x}
        x2={points?.[1]?.x}
        y1={points?.[0]?.y}
        y2={points?.[1]?.y}
        stroke="var(--color-brdr-default)"
      />
      <svg
        x={points?.[0]?.x - 8}
        y={points?.[0]?.y + height / 2 - 8}
        // opacity={0.5}
        viewBox="0 0 24 24"
        width="16"
        height="16"
      >
        <ellipse fill="rgb(0, 128, 103)" cx="12" cy="12" rx="12" ry="12" />
        <rect fill="rgb(255, 255, 255)" x="3.192" y="9.426" width="16.536" height="4.681" rx="45.5" ry="45.5" />
        <rect
          fill="rgb(255, 255, 255)"
          x="7.472"
          y="6.444"
          width="16.535"
          height="4.68"
          rx="45.5"
          ry="45.5"
          transform="matrix(0, -1, 1, 0, 2.514317, 27.1945)"
        />
        <ellipse fill="rgb(0, 128, 103)" cx="12" cy="12" rx="12" ry="12" />
        <rect
          fill="rgb(255, 255, 255)"
          transform="matrix(0, -1, 1, 0, 5.8305, 27.7395)"
          x="7.472"
          y="4.526"
          width="16.535"
          height="3.287"
          rx="1.6"
          ry="1.6"
        />
        <rect fill="rgb(255, 255, 255)" x="3.733" y="10.356" width="16.535" height="3.287" rx="1.6" ry="1.6" />
      </svg>
    </>
  );
};

const CustomizedTooltipContent = (props: any, isChartCompact: boolean) => {
  const { t } = useTranslation();
  if (props.active && props.payload && props.payload.length) {
    return (
      <Card
        title={props.label}
        headStyle={{ fontSize: "12px", minHeight: "32px" }}
        className="dashboard-chart__tooltip"
        bodyStyle={{ padding: "8px 12px", maxWidth: props.viewBox?.width ?? undefined }}
      >
        <div className="d-stack-column spacing-1">
          {props.chartsData.lineCharts.map(
            (lC: {
              id: number;
              name: string;
              color?: string;
              data: { itemId: number; date: Date; value: number | null }[];
            }) =>
              props.payload.find((p: any) => p.dataKey == lC.id)?.value != null ? (
                <div key={lC.id} className="d-stack-row spacing-2 align-center">
                  <Shape
                    size={10}
                    type="circle"
                    backgroundColor={lC?.color ?? props.payload.find((p: any) => p.dataKey == lC.id)?.color}
                  />
                  {!isChartCompact && (
                    <Text
                      className="flex-grow-1"
                      style={{ lineHeight: "1.25em" }}
                      size="12px"
                      weight={500}
                      children={`${lC.name}:`}
                    />
                  )}
                  <Text
                    style={{ whiteSpace: "nowrap", lineHeight: "1.25em", textAlign: "end" }}
                    size="12px"
                    weight="bold"
                    children={props.payload.find((p: any) => p.dataKey == lC.id)?.value}
                  />
                </div>
              ) : null
          )}
        </div>
        {props.payload.find((p: any) => p.dataKey == "quota") != null && (
          <div className="d-stack-row spacing-2 align-center mt-1">
            <Shape size={10} type="circle" backgroundColor="var(--color-txt-tertiary)" />
            {!isChartCompact && (
              <Text
                className="flex-grow-1"
                style={{ lineHeight: "1.25em" }}
                size="12px"
                weight={500}
                children={`${t("parse:quantitative_plan")}:`}
              />
            )}
            <Text
              style={{ whiteSpace: "nowrap", lineHeight: "1.25em", textAlign: "end" }}
              size="12px"
              weight="bold"
              children={props.payload.find((p: any) => p.dataKey == "quota")?.value}
            />
          </div>
        )}
      </Card>
    );
  }
  return null;
};

const CustomizedLegend = (
  props: any,
  isReversed: boolean = false,
  isChartCompact: boolean,
  onHoverStateChange: (id: number | null) => void
) => {
  return (
    <div
      className={`d-stack-column spacing-0 full-width ${
        isReversed ? (isChartCompact ? "mt-3" : "mt-3") : isChartCompact ? "mt-2" : "mt-1"
      }`}
    >
      {/*onMouseLeave={() => onHoverStateChange(null)}*/}
      {props.payload
        .filter((p: any) => p.dataKey != "quota")
        .map((p: any) => (
          <UITooltip key={p.dataKey} title={p.value} placement="topLeft" className="no-interact">
            <div key={p.dataKey} className="d-stack spacing-2 align-center">
              <Shape backgroundColor={p.payload.dot.stroke} size={8} type="circle" />
              <Text
                style={{
                  whiteSpace: "nowrap",
                  color: p.payload.dot.stroke,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  lineHeight: "1em",
                  padding: "2px 0",
                  verticalAlign: "middle",
                }}
                size="10px"
                children={p.value}
                // onMouseEnter={() => onHoverStateChange(p.dataKey)}
                // onMouseLeave={() => onHoverStateChange(null)}
              />
            </div>
          </UITooltip>
        ))}
    </div>
  );
};

type Point = { x: number; y: number | null };

function calculateTrendline(data: Point[]): { slope: number; yIntercept: number } {
  let xSum = 0,
    ySum = 0,
    xySum = 0,
    xSquaredSum = 0,
    count = 0;

  for (let i = 0; i < data.length; i++) {
    if (data[i].y != null) {
      xSum += data[i].x;
      // @ts-ignore
      ySum += data[i].y;
      // @ts-ignore
      xySum += data[i].x * data[i].y;
      xSquaredSum += data[i].x * data[i].x;
      count++;
    }
  }

  const n = count;
  const slope = (n * xySum - xSum * ySum) / (n * xSquaredSum - xSum * xSum);
  const yIntercept = (ySum - slope * xSum) / n;

  return { slope, yIntercept };
}

export function DashboardChartView(props: IDashboardChartView) {
  const [hoveredLineId, setHoveredLineId] = useState<number | null>(null);
  const [chartData, setChartData] = useState<IDashboardChartViewChartData[]>([]);
  const [trendData, setTrendData] = useState<IDashboardChartViewTrendData[]>([]);
  const [gradientData, setGradientData] = useState<IDashboardChartViewGradientData[]>([]);
  const [isChartCompact, setIsChartCompact] = useState<boolean>(false);

  const handleChartClick = (args: any) => {
    if (args == null || !Object.keys(args).length || props.isReadOnly) return;
    props.onChange(props.data.find((d) => d.label == args.activeLabel)?.id as number);
  };

  const getDomain = (min: any, max: any): [number | string, number | string] => {
    if (min == null && max == null) {
      return ["dataMin", "dataMax"];
      // return [dataMin == null ? "auto" : dataMin - dataMin / 100, dataMax == null ? "auto" : dataMax - dataMax / 100];
    }
    if (min == max) {
      return ["dataMin", "dataMax"];
      // return ["auto", "auto"];
    }
    // props.chartsData.quotaChart.some((q) => q.value != null) ? 0
    return [min ?? "dataMin", max ?? "dataMax"];
  };

  const generateChartData = (
    chartItemsIds: number[],
    data: TDashboardChartDataItem[],
    chartsData: TDashboardChartViewChartsData
  ) => {
    return chartItemsIds.map((itemId) => {
      if (data.length == 0) {
        return {
          quota: undefined,
          label: undefined,
          date: undefined,
        };
      }
      const lineObj = data
        .find((d) => d.id == itemId)
        ?.charts.line.map((c) => c.id)
        .reduce(
          (ac, a) => ({
            ...ac,
            [a]: chartsData.lineCharts.find((c) => c.id == a)?.data.find((d) => d.itemId == itemId)?.value,
          }),
          {}
        );
      return {
        date: data.find((d) => d.id == itemId)?.dateISO,
        label: data.find((d) => d.id == itemId)?.label,
        // TODO: Return undefined or !null!
        quota: chartsData.quotaChart.find((q) => q.itemId == itemId)?.value ?? undefined,
        ...lineObj,
      };
    });
  };

  const generateGradientData = (data: TDashboardChartViewChartsData) => {
    let newData: IDashboardChartViewGradientData[] = [];
    data.lineCharts.forEach((lineChart, index) => {
      const lineValues = lineChart.data.map((d) => d.value);
      let colorData: { percentage: number; color: string }[] = [];
      const correctDataStart = lineValues.findIndex((v) => v != null);
      const correctDataEnd = findLastIndex(lineValues, (v) => v != null) + 1;
      const correctDataLength = correctDataEnd - correctDataStart;
      const slicedLineValues = lineValues.slice(correctDataStart, correctDataEnd);
      let prevPercent = 0;
      slicedLineValues.forEach((l, i) => {
        const currentPercent = parseFloat((i / ((correctDataLength - 1) / 100)).toFixed(3));
        const nextPercent = parseFloat(((i + 1) / ((correctDataLength - 1) / 100)).toFixed(3));
        prevPercent = nextPercent;

        let color =
          l == null ||
          slicedLineValues[i + 1] == null ||
          // @ts-ignore TODO: Refactor
          (props.isReversed ? l <= slicedLineValues[i + 1] : l > slicedLineValues[i + 1])
            ? METRIC_COLORS.line[index].decrease
            : METRIC_COLORS.line[index].increase;

        // if (i != correctDataLength - 1) {
        colorData = [...colorData, { percentage: currentPercent, color: color }];
        // prevPercent != 100 &&
        if (nextPercent <= 100) {
          colorData = [...colorData, { percentage: nextPercent, color: color }];
        }
      });
      // console.log(colorData.slice(0, colorData.length - 1));
      newData = [
        ...newData,
        {
          id: lineChart.id,
          data:
            colorData.filter((v) => v.percentage == 100).length > 1
              ? colorData.slice(0, colorData.length - 1)
              : colorData,
        },
      ];
    });
    setGradientData(newData);
  };

  const generateTrendData = (data: TDashboardChartViewChartsData) => {
    let newData: IDashboardChartViewTrendData[] = [];
    data.lineCharts.forEach((lineChart, index) => {
      const trend = calculateTrendline(lineChart.data.map((d, i) => ({ x: i, y: d.value })));
      if (trend.yIntercept == null || trend.slope == null) return null;
      const x1Index = lineChart.data.findIndex((d) => d.value != null);
      const x2Index = findLastIndex(lineChart.data, (d) => d.value != null);
      if (x1Index == x2Index || x1Index == -1 || x2Index == -1) return null;
      const y1 = trend.slope * x1Index + trend.yIntercept;
      const y2 = trend.slope * x2Index + trend.yIntercept;
      newData = [
        ...newData,
        {
          id: lineChart.id,
          x1: lineChart.data[x1Index].label,
          x2: lineChart.data[x2Index].label,
          y1: y1,
          y2: y2,
          color: (props.isReversed ? y1 < y2 : y1 > y2)
            ? METRIC_COLORS.trend[index].decrease
            : METRIC_COLORS.trend[index].increase,
        },
      ];
    });
    setTrendData(newData);
  };

  const isChartZoomed = useMemo<boolean>(
    () =>
      props.viewSettings?.min != props.viewSettings?.max &&
      (props.viewSettings?.min != null || props.viewSettings?.max != null),
    [props.viewSettings]
  );

  const chartIntervalType = useMemo<TDashboardChartViewChartIntervalType>(() => {
    let companyInterval: CompanyIntervalDto | null;
    if (props.metricData.sources?.every((s) => s.companyInterval == null)) {
      companyInterval =
        props.metricData.sources?.find((s) => s.existingSource != null)?.existingSource?.companyInterval ?? null;
    } else {
      companyInterval = props.metricData.sources?.find((s) => s.companyInterval != null)?.companyInterval ?? null;
    }

    if (companyInterval?.day != null && companyInterval?.day > 0) return "day";
    if (companyInterval?.week != null && companyInterval?.week > 0) return "week";
    if (companyInterval?.month != null && companyInterval?.month > 0) return "month";
    if (companyInterval?.year != null && companyInterval?.year > 0) return "year";
    return null;
  }, [props.metricData.sources]);

  useEffect(() => {
    setChartData(generateChartData(props.chartsData.chartItemsIds, props.data, props.chartsData));
  }, [props.chartsData.chartItemsIds, props.data, props.chartsData]);

  useEffect(() => {
    props.viewSettings?.isTrendActive && generateTrendData(props.chartsData);
  }, [props.chartsData, props.viewSettings?.isTrendActive]);

  useEffect(() => {
    props.viewSettings?.isLinesColorActive && generateGradientData(props.chartsData);
  }, [props.chartsData, props.viewSettings?.isLinesColorActive]);

  // useEffect(() => {
  //   const chartEl = document.getElementsByClassName("recharts-wrapper")?.[0];
  //   if (chartEl == null) return;
  //   setIsChartCompact(chartEl.clientWidth < 350);
  // }, []);

  return (
    <ResponsiveContainer
      className="dashboard-chart__wrapper"
      width="100%"
      aspect={16 / 12}
      debounce={20}
      onResize={(w) => setIsChartCompact(w != 0 && w < 350)}
    >
      <ComposedChart
        id={`chart-rc-${props.metricId}${props.metricSource2UserId != null ? "-" + props.metricSource2UserId : ""}`}
        margin={{
          top: props.isPrint ? 12 : 0,
          right: props.isPrint ? 12 : 0,
          left: props.isPrint ? 12 : 0,
          bottom: props.isPrint ? 12 : 0,
        }}
        data={chartData}
        onClick={handleChartClick}
        // onMouseLeave={() => setHoveredLineId(null)}
      >



        <defs>
          {gradientData.map((g, i) => (
            <linearGradient
              key={g.id}
              id={`lineGradient${g.id}-${props.metricSource2UserId ?? "m"}-${props.metricId ?? "mm"}${props.isPrint ? "-print" : ""}`}
              x1="0%"
              y1="0%"
              x2="0%"
              y2="100%"
              gradientTransform="rotate(-90)"
              gradientUnits={
                g.data
                  .filter((v) => v != null && v.color != null)
                  .every((v) => v.color == g.data.filter((v) => v != null && v.color != null)[0].color)
                  ? "userSpaceOnUse"
                  : "objectBoundingBox"
              }
              height="100%"
              width="100%"
            >
              {g.data.map((c, i) => (
                <stop key={i} offset={c.percentage + "%"} stopColor={c.color} />
              ))}
            </linearGradient>
          ))}
        </defs>
        <XAxis
          orientation={props.isReversed ? "top" : "bottom"}
          dataKey="label"
          interval={0}
          allowDataOverflow={false}
          // minTickGap={40}
          axisLine={{ stroke: "var(--color-metric-gridlines)" }}
          tickLine={{ stroke: "var(--color-metric-gridlines)" }}
          tick={(params) => CustomizedXTick(params, isChartCompact, chartIntervalType, chartData)}
        />
        <YAxis
          width={48}
          interval={0}
          minTickGap={0}
          // interval="preserveStartEnd"
          axisLine={{ stroke: "var(--color-metric-gridlines)" }}
          tickLine={{ stroke: "var(--color-metric-gridlines)" }}
          reversed={props.isReversed}
          type="number"
          // includeHidden={true}
          allowDataOverflow={true}
          tick={(_props) => CustomizedYTick(_props, props.yDividers ?? [], false)}
          ticks={(props.yDividers?.map((d) => d.value!) ?? []) as number[]}
          tickCount={props?.yDividers?.length ?? 0}
          domain={
            props?.yDividers.length > 1
              ? [props.yDividers[0].value!, props.yDividers[props.yDividers.length - 1].value!]
              : undefined
          }
          // domain={getDomain(props.viewSettings?.min ?? null, props.viewSettings?.max ?? null)}
        />
        <CartesianGrid
          strokeDasharray="2 2"
          vertical={false}
          style={{ stroke: "var(--color-metric-gridlines)", strokeWidth: "1px" }}
        />
        {props.yDividers.some((d) => d.value == 0) && (
          <ReferenceLine y={0} strokeDasharray="2 2" stroke="var(--color-txt-secondary)" />
        )}
        <Bar legendType="none" isAnimationActive={false} dataKey="quota" fill={METRIC_COLORS.quota.normal} />
        {props.chartsData.lineCharts.map((lC, i) => (
          <Line
            isAnimationActive={false}
            key={lC.id}
            dataKey={lC.id}
            // stroke={lC.color ?? METRIC_COLORS.line[i].normal}
            stroke={
              props.viewSettings?.isLinesColorActive
                ? `url(#lineGradient${lC.id}-${props.metricSource2UserId ?? "m"}-${props.metricId ?? "mm"}${props.isPrint ? "-print" : ""})`
                : lC.color
            }
            name={lC.name}
            // @ts-ignore
            dot={{ fill: "var(--color-srf-primary)", stroke: lC.color }}
            activeDot={{ stroke: "var(--color-brdr-default)", fill: lC.color }}
            // dot={{ stroke: "--color-layout-container" }}
            // strokeOpacity={lC.id != hoveredLineId && hoveredLineId != null ? 0.3 : 1}
          >
            <LabelList
              className={"dashboard-chart-label-list"}
              dataKey={lC.id}
              position="top"
            />
          </Line>
        ))}
        {props.viewSettings?.isTrendActive &&
          trendData.map((t, i) => (
            <ReferenceLine
              stroke={t.color}
              // ifOverflow={isChartZoomed ? "hidden" : "extendDomain"}
              ifOverflow="hidden"
              strokeDasharray="4 2"
              segment={[
                { x: t.x1, y: t.y1 },
                { x: t.x2, y: t.y2 },
              ]}
            />
          ))}
        <Tooltip
          cursor={props.isReadOnly ? { stroke: "var(--color-brdr-default)" } : <CustomizedTooltipCursor />}
          wrapperStyle={{ outline: "none", border: "none", zIndex: 1 }}
          contentStyle={{ border: "none", borderRadius: "8px" }}
          offset={16}
          allowEscapeViewBox={{ x: false, y: true }}
          content={({ ...args }) =>
            CustomizedTooltipContent({ ...args, data: props.data, chartsData: props.chartsData }, isChartCompact)
          }
        />
        <Legend
          verticalAlign="bottom"
          content={(_props) => CustomizedLegend(_props, props.isReversed, isChartCompact, (id) => setHoveredLineId(id))}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

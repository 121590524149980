import React from "react";
import PageContent from "../../../../layouts/pageContent/PageContent";
import { useRootStore } from "../../../../../../functional/hooks";
import { useRouterHandler } from "../../../../../pages/regulation/hooks/useRouterHandler";
import { IMainRegulation } from "./MainRegulation.interface";
import { observer } from "mobx-react-lite";
import { MainHeader } from "../../headers/mainHeader/MainHeader";
import { MainRegulationContent } from "../../pages";
import PageHeader from "../../../../layouts/pageHeader/PageHeader";
import "./MainRegulation.scss";

export const MainRegulationObserver: React.FC<IMainRegulation> = (props) => {
  const { regulationStore, breadcrumbsStore } = useRootStore();
  const { availableToolsKeys, toolsHide, showBreadcrumbs, getTypeContent } = useRouterHandler();
  const routeToken = breadcrumbsStore.getBreadcrumbsToken;

  return (
    <>
      <PageContent style={{ overflow: "hidden" }} wrapperClassName="" className="d-stack-row spacing-4">
        {/*<div className={isSidebarOpen ? "sidebar-visible" : "sidebar-hidden"}>*/}
        {/*  <RegulationSidebar isSidebarOpen={isSidebarOpen} onSidebarOpenChange={() => setIsSidebarOpen((v) => !v)} />*/}
        {/*</div>*/}
        <div className="flex-grow-1 d-flex flex-column full-height full-width pt-1 pb-4">
          <PageHeader>
            <MainHeader
              isSidebarOpen={props.isSidebarOpen}
              availableToolsKeys={availableToolsKeys}
              toolsHide={toolsHide}
              showBreadcrumbs={showBreadcrumbs}
              routeToken={routeToken}
              onSidebarOpenChange={props.onSidebarOpenChange}
            />
          </PageHeader>
          <PageContent
            tabs={props.tabs}
            isLoading={regulationStore.getLoading}
            isNewDesign={true}
          >
            <MainRegulationContent regulationContentType={getTypeContent()} />
          </PageContent>
        </div>
      </PageContent>
      {/*<PageHeader>*/}
      {/*  <MainHeader*/}
      {/*    availableToolsKeys={availableToolsKeys}*/}
      {/*    toolsHide={toolsHide}*/}
      {/*    showBreadcrumbs={showBreadcrumbs}*/}
      {/*    routeToken={routeToken}*/}
      {/*  />*/}
      {/*</PageHeader>*/}
      {/*<PageContent tabs={props.tabs} isLoading={regulationStore.getLoading}>*/}
      {/*  <MainRegulationContent regulationContentType={getTypeContent()} />*/}
      {/*</PageContent>*/}
    </>
  );
};
export const MainRegulation = observer(MainRegulationObserver);

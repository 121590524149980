import React, { useCallback, useMemo } from "react";
import { addAlphaToHex } from "../../../../functional/helpers/colorFunctions";
import { Tag } from "../../../uiKit";

interface IPriorityComponent {
  value: number;
}

export const PriorityComponent = (props: IPriorityComponent) => {

  return (
    <Tag
      bordered={false}
      style={{
        backgroundColor: `var(--color-priority${props.value}-bg)`,
        color: `var(--color-priority${props.value}-text)`,
        width: 24,
        height: 24,
        padding: 0,
        textAlign: "center",
        margin: "0 0 2px",
      }}
    >
      { props.value }
    </Tag>
  );
};

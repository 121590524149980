import React from "react";
import { useTranslation } from "react-i18next";
import { RoleDto } from "../../../../functional/api";
import { Button, Dialog, DialogActions, Select, Shape, Switch } from "../../../uiKit";
import { LabelSwitch } from "./LabelSwitch";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";

export interface IOrgchartFunctionMoveDialogView {
  allRoles: RoleDto[];
  filteredRoles: RoleDto[];
  roleId: number;
  selectedRoleId: number | null;
  changeFunctionColor: boolean;

  setChangeFunctionColor: (value: boolean) => void;

  onChipClick(id: number): void;

  onSave(): void;

  onClose(): void;
}

function OrgchartFunctionMoveDialogView(props: IOrgchartFunctionMoveDialogView) {
  const { t } = useTranslation();

  return (
    <Dialog
      title={t("ui:title.function_move")}
      open={true}
      onClose={props.onClose}
      width={480}
      scrollStrategy="dialogBody"
      closable={false}
    >
      <div className="d-stack-column spacing-4">
        <div>
          <SubheaderText text={t("ui:subheader.function_name")} />
          <Select
            className="full-width"
            value={props.selectedRoleId}
            onChange={(v) => props.onChipClick(v)}
            showSearch
            items={props.filteredRoles.map((r) => ({
              id: r.id as number,
              text: r.name ?? "",
              value: r.id as number,
              icon: (
                <Shape
                  className="mr-1"
                  type="circle"
                  backgroundColor={r.colorHex ?? "var(--color-primary-base)"}
                  size={14}
                />
              ),
            }))}
          />
        </div>
        <Switch
          disabled={props.selectedRoleId == null}
          onChange={(value) => props.setChangeFunctionColor(value)}
          checked={props.changeFunctionColor}
          label={<LabelSwitch allRoles={props.allRoles} selectedRoleId={props.selectedRoleId} />}
        />
      </div>

      <DialogActions>
        <Button onClick={() => props.onClose()} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button disabled={props.selectedRoleId == null} onClick={() => props.onSave()} variant="filled">
          {t("ui:button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OrgchartFunctionMoveDialogView;

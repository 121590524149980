import React from "react";
import "./Dialog.scss";
import { Modal } from "antd";
import clsx from "clsx";
import { IDialogView } from "../../../functional/types";

export function DialogView({ className, scrollStrategy, isNewDesign, ...rest }: IDialogView) {
  return (
    <Modal
      className={clsx("uiKit-dialog", className ?? "", {
        "__ss-dialog-body": scrollStrategy == "dialogBody",
        "uiKit-dialog_new-style": isNewDesign
      })}
      destroyOnClose={true}
      // destroyOnClose={rest.destroyOnClose ?? scrollStrategy == "dialogBody"}
      centered
      {...rest}
    />
  );
}

import { User2RoleDto, UserScheduleDto, UserScheduleSubstituteDto } from "../../../../../functional/api";
import { Alert, Button, Checkbox, DatePicker, Dialog, DialogActions, Text } from "../../../../uiKit";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserInfo } from "../../../../elements/userInfo/UserInfo";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import { TimeSpanPicker } from "../../../../uiKit/dataEntry/pickers/timeSpanPicker/TimeSpanPicker";
import { ScheduleSetupDialogSubstituteContainer } from "./ScheduleSetupDialogSubstituteContainer";
import { api } from "../../../../../functional/services";
import { useNotifier } from "../../../../../functional/hooks";

interface IScheduleSetupDialogProps {
  config: IScheduleSetupDialogConfig;

  onSave: (userSchedule: UserScheduleDto, forceReload?: boolean) => void;
  onCancel: () => void;
}

export interface IScheduleSetupDialogConfig {
  dateFrom: Dayjs | null;
  dateTo: Dayjs | null;
  type: TScheduleSetupDialogType;
  user2role: User2RoleDto | null;
}

interface IScheduleSetupDialogState {
  timeFrom: string | null;
  timeTo: string | null;
  dateFrom: Dayjs | null;
  dateTo: Dayjs | null;
  applyToAllUserRoles: boolean;
  substituteConfig: UserScheduleSubstituteDto[];
}

const initialState: IScheduleSetupDialogState = {
  timeFrom: "09:00:00",
  timeTo: "18:00:00",
  dateFrom: dayjs
    .utc({
      hours: 0,
      minutes: 0,
      seconds: 0,
    })
    .startOf("day"),
  dateTo: dayjs
    .utc({
      hours: 0,
      minutes: 0,
      seconds: 0,
    })
    .startOf("day"),
  applyToAllUserRoles: false,
  substituteConfig: [],
};

export type TScheduleSetupDialogType = "fullDay" | "weekend" | "vacation" | "sick" | null;

export const ScheduleSetupDialog = (props: IScheduleSetupDialogProps) => {
  const { t } = useTranslation();
  const notifier = useNotifier();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [state, setState] = useState<IScheduleSetupDialogState>({ ...initialState });

  const dialogTitle = useMemo(() => {
    switch (props.config.type) {
      case "fullDay":
        return t("ui:title.mark_working_day");
      case "weekend":
        return t("ui:title.mark_weekend");
      case "vacation":
        return t("ui:title.mark_vacation");
      case "sick":
        return t("ui:title.mark_medical");
      case null:
        return "";
    }
  }, [props.config.type]);

  const getIdByType = (type: TScheduleSetupDialogType): number => {
    switch (type) {
      case "fullDay":
        return 1;
      case "weekend":
        return 2;
      case "vacation":
        return 3;
      case "sick":
        return 4;
      default:
        return 1;
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    const r = await api.userSchedule.create({
      id: 0,
      dateFrom: state.dateFrom?.toISOString() as string,
      dateTo: state.dateTo?.toISOString() as string,
      type: getIdByType(props.config.type),
      overrideAllUsersFunctions: state.applyToAllUserRoles,
      timeFrom: state.timeFrom,
      timeTo: state.timeTo,
      user2RoleId: props.config.user2role?.id!,
      substitutesConfig: state.substituteConfig.filter((x) => !!x.userId && !!x.roleId),
    });
    setIsLoading(false);

    if (r == null) {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      return;
    }

    props.onSave(r, state.applyToAllUserRoles || state.substituteConfig.length > 0);
    props.onCancel();
  };

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      dateFrom: props.config.dateFrom,
      dateTo: props.config.dateTo,
    }));
  }, [props.config.dateFrom, props.config.dateTo]);

  useEffect(() => {
    if (props.config.type == null) setState({ ...initialState });
  }, [props.config.type]);

  return (
    <Dialog open={props.config.type != null} title={dialogTitle} closable={false}>
      <div className="d-stack-column spacing-3">
        <div className="d-flex align-center">
          <Text size="14px" className="mr-2" children={t("ui:subheader.employee") + ": "} />
          <UserInfo user={props.config.user2role?.user} />
        </div>
        <div className="d-stack-column">
          <SubheaderText text={t("ui:text.period")} />
          <div className="d-flex justify-space-between">
            <DatePicker
              value={state.dateFrom?.utc(true).tz(undefined, true)}
              disablePast
              allowClear={false}
              style={{ width: "calc(50% - 4px)" }}
              onChange={(date) => setState((prev) => ({ ...prev, dateFrom: date }))}
            />
            <DatePicker
              value={state.dateTo?.utc(true).tz(undefined, true)}
              disablePast
              allowClear={false}
              style={{ width: "calc(50% - 4px)" }}
              onChange={(date) => setState((prev) => ({ ...prev, dateTo: date }))}
            />
          </div>
        </div>

        {props.config.type == "fullDay" && (
          <div className="d-stack-column">
            <SubheaderText text={t("ui:text.beginning_and_end_of_shift")} />
            <div className="d-flex justify-space-between">
              <TimeSpanPicker
                value={state.timeFrom}
                allowClear={false}
                style={{ width: "calc(50% - 4px)" }}
                minuteStep={5}
                hideDisabledOptions
                onChange={(time) => setState((prev) => ({ ...prev, timeFrom: time }))}
              />
              <TimeSpanPicker
                value={state.timeTo}
                allowClear={false}
                style={{ width: "calc(50% - 4px)" }}
                minuteStep={5}
                hideDisabledOptions
                onChange={(time) => setState((prev) => ({ ...prev, timeTo: time }))}
              />
            </div>
          </div>
        )}

        {(props.config.type == "vacation" || props.config.type == "sick") && props.config.user2role != null && (
          <ScheduleSetupDialogSubstituteContainer
            applyToAllUserRoles={state.applyToAllUserRoles}
            user2role={props.config.user2role}
            onChange={(value) => setState((prev) => ({ ...prev, substituteConfig: value }))}
          />
        )}

        <Checkbox
          checked={state.applyToAllUserRoles}
          onChange={(event) => setState((prev) => ({ ...prev, applyToAllUserRoles: event.target.checked }))}
          children={t("ui:text.apply_to_all_functions")}
        />

        <div className="d-stack-column spacing-2">
          {(state.timeFrom != null && state.timeTo != null ? state.timeFrom >= state.timeTo : false) && (
            <Alert type="error" message={t("ui:text.start_day_exceeds_end")} />
          )}
          {state.dateFrom?.isAfter(state.dateTo, "date") && (
            <Alert type="error" message={t("ui:text.start_period_exceeds_end")} />
          )}
        </div>
      </div>
      <DialogActions>
        <Button variant="text" onClick={props.onCancel} children={t("ui:button.cancel")} />
        <Button
          disabled={
            (state.timeFrom != null && state.timeTo != null ? state.timeFrom >= state.timeTo : false) ||
            state.dateFrom?.isAfter(state.dateTo, "date")
          }
          onClick={handleSave}
          loading={isLoading}
          variant="filled"
          children={t("ui:button.save")}
        />
      </DialogActions>
    </Dialog>
  );
};

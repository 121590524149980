import React, { memo, useCallback, useMemo } from "react";
import { IListEducationCourseCard } from "./listEducationCourseCard.interface";
import "./listEducationCourseCard.scss";
import { EducationCard } from "../edicationCard/EducationCard";
import { ITrainingData } from "../listEducationSections/ListEducationSections.interface";
import Logo from "../../../../../../assets/images/logo.svg";
import LogoWhite from "../../../../../../assets/images/logoWhite.svg";
import { useRootStore } from "../../../../../../functional/hooks";
import { observer } from "mobx-react-lite";

export const ListEducationCourseCard = observer((props: IListEducationCourseCard) => {
  const { appStore } = useRootStore();


  const handleClick = useCallback((trainingData: ITrainingData) => {
    props.onOpenCourse(trainingData.itemId as number);
  }, []);

  const cover = useMemo(() => {
    return (
      <div className="education-course-card-cover">
        <img src={appStore.getAppTheme == 1 ? LogoWhite : Logo} alt="" />
      </div>
    )
  }, [appStore.getAppTheme]);

  return (
    <EducationCard
      educationItem={props.course}
      trainingKey={props.course.key}
      onOpenTrainingDialog={handleClick}
      cover={cover}
      isShowProgress
    />
  )
});

import React, { useMemo } from "react";
import { useDateHelpers, useRootStore } from "../../../functional/hooks";
import { CommentCardView } from "./CommentCardView";
import dayjs from "dayjs";
import { ICommentCard } from "../../../functional/types/CommentCard.interface";

export const CommentCard = <T,>(props: ICommentCard<T>) => {
  const { formatDate } = useDateHelpers();

  const { authStore } = useRootStore();

  const dateSent = useMemo(() => {
    return props.format
      ? dayjs(props.dateCreated!)
        .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
        .format(props.format)
      : formatDate(new Date(props.dateCreated!).toString(), {
          formatObject: { hour: undefined, minute: undefined },
        });
  }, [props.dateCreated])

  if (!props.creator && !props.commentContent) return null;



  return (
    <CommentCardView
      dateSented={dateSent}
      reactions={props.reactions}
      strategy={props.reactionStrategy}
      commentAttachments={props.commentAttachments}
      commentContent={props.commentContent}
      index={props.index}
      creator={props.creator}
      historyId={props.historyId}
      isNewStyle={props.isNewStyle}
      isEditing={props.isEditing}
      editCommentId={props.editCommentId}
      handleEditClick={props.handleEditClick}
      handleSaveClick={props.handleSaveClick}
      handleRemoveComment={props.handleRemoveComment}
      item={props.item}
      isHiddenUser={props.isHiddenUser}
      isHiddenReactions={props.isHiddenReactions}
      isSimplifiedToolbar={props.isSimplifiedToolbar}
      isShowBorder={props.isShowBorder}
      editedInfo={props.editedInfo}
    />
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import { IOrgchartFunctionCreateDialog } from "./OrgchartFunctionCreateEditDialog";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { CirclePicker } from "react-color";
import "./OrgchartFunctionCreateEditDialog.scss";
import { FiMoreHorizontal } from "@react-icons/all-files/fi/FiMoreHorizontal";
import { FiMoreVertical } from "@react-icons/all-files/fi/FiMoreVertical";
import { useRootStore } from "../../../../functional/hooks";
import { observer } from "mobx-react-lite";
import { Button, Dialog, DialogActions, Input, Segmented, Select, SelectOption, TextArea } from "../../../uiKit";
import { theme } from "antd";
import { orgchartHexToVariables, orgchartVariablesToHex } from "../../../../functional/utils/hexToVariables";

function OrgchartFunctionCreateEditDialogView(props: IOrgchartFunctionCreateDialog) {
  const { t } = useTranslation();
  const { orgchartStore } = useRootStore();
  const { useToken } = theme;
  const { token } = useToken();

  return (
    <Dialog
      title={props.roleId ? t("ui:title.function_edit") : t("ui:title.function_create")}
      open={true}
      onClose={props.onClose}
      closable={false}
    >
      <div className="d-stack-column spacing-4">
        <div>
          <SubheaderText text={t("ui:subheader.function_name")} />
          <Input
            value={props.functionData?.name ?? ""}
            autoComplete="off"
            onInput={(event) =>
              props.setFunctionData &&
              props.setFunctionData({ ...props.functionData, name: (event.target as HTMLInputElement).value })
            }
            placeholder={t("ui:placeholder.function_name")}
          />
        </div>

        <div>
          <SubheaderText text={orgchartStore.getCurrentOrgchartResultName} />
          <Input
            value={props.functionData?.awaitedResult as any}
            autoComplete="off"
            onInput={(event) =>
              props.setFunctionData &&
              props.setFunctionData({ ...props.functionData, awaitedResult: (event.target as HTMLInputElement).value })
            }
            placeholder={t("ui:placeholder.valuable_final_product")}
          />
        </div>

        <div>
          <SubheaderText text={t("parse:function_description")} />
          <TextArea
            autoSize={{ minRows: 1, maxRows: 10 }}
            placeholder={t("parse:enter_description")}
            value={props.functionData?.description ?? undefined}
            onInput={(event) =>
              props.setFunctionData &&
              props.setFunctionData({
                ...props.functionData,
                description: (event.target as HTMLInputElement).value.replace(/  +/g, " "),
              })
            }
          />
        </div>

        <div>
          <SubheaderText text={t("parse:subfunctions_arrangement") as string} />
          <Segmented
            value={props.functionData?.childrenAreHorizontal ? "h" : "v"}
            onChange={(value) =>
              props.setFunctionData &&
              props.setFunctionData({ ...props.functionData, childrenAreHorizontal: value == "h" ?? false })
            }
            options={[
              {
                label: (
                  <div className="d-flex align-center">
                    <FiMoreVertical />
                    <span style={{ marginLeft: "0.5em" }}>{t("parse:vertically")}</span>
                  </div>
                ),
                value: "v",
              },
              {
                label: (
                  <div className="d-flex align-center">
                    <FiMoreHorizontal />
                    <span style={{ marginLeft: "0.5em" }}>{t("parse:horizontally")}</span>
                  </div>
                ),
                value: "h",
              },
            ]}
          />
        </div>

        <div>
          <SubheaderText text={t("ui:subheader.function_color")} />
          <div
            className="br-md"
            style={{
              background: token.colorBgContainer,
              border: `1px solid ${token.colorBorder}`,
              padding: "7px 11px",
            }}
          >
            <CirclePicker
              width="100%"
              circleSpacing={8}
              circleSize={24}
              // className="orgchart-function-card_color-picker"
              color={props.functionData?.colorHex ? orgchartHexToVariables(props.functionData.colorHex) : undefined}
              colors={props.functionColorsArray}
              onChangeComplete={(color, e) => {
                props.setFunctionData && props.setFunctionData({ ...props.functionData, colorHex: orgchartVariablesToHex(e.target.title), });
              }}
            />
          </div>
        </div>

        <div>
          {props.roleId == null && (
            <>
              <SubheaderText text={t("ui:subheader.parent_function")} />
              <Select
                placeholder={t("ui:placeholder.parent_function")}
                disabled={props.roleId != null}
                value={props.functionData?.parentId ?? ""}
                onChange={(value) => {
                  props.setFunctionData &&
                    props.setFunctionData({
                      ...props.functionData,
                      parentId: Number(value),
                    });
                }}
              >
                {props.allRoles?.map((role) => (
                  <SelectOption key={role.id} value={role.id} children={role.name} />
                ))}
              </Select>
            </>
          )}
        </div>
      </div>

      <DialogActions>
        <Button onClick={() => props.onClose()} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button
          disabled={!(props.functionData?.name ?? "").trim().length}
          onClick={() => (props.roleId ? props.onSave() : props.onCreate && props.onCreate())}
          variant="filled"
          loading={props.isLoader}
        >
          {props.roleId ? t("ui:button.save") : t("ui:button.create")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default observer(OrgchartFunctionCreateEditDialogView);

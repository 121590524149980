import React from "react";
import RecurringIssueCreateEditDialog from "../../modules/dialogs/recurringIssueCreateEditDialog/RecurringIssueCreateEditDialog";
import { IssueCommonDialog } from "../../modules/dialogs/issueCommonDialog/IssueCommonDialog";
import { IssueFormKeys } from "../../../functional/types/IssueCommonDialog.interface";
import { useCommonDialogByQP } from "../../../functional/hooks";

export const CommonDialogWrapperByQP = () => {
  const {
    isOpenCreateRoutine,
    handleCloseCreateRoutine,
    isOpenCommonIssueDialog,
    currentIssueCommonType,
    handleCloseIssueCommonDialog,
  } = useCommonDialogByQP();

  return (
    <>
      {isOpenCreateRoutine && (
        <RecurringIssueCreateEditDialog
          open={isOpenCreateRoutine}
          onCreate={handleCloseCreateRoutine}
          onClose={handleCloseCreateRoutine}
        />
      )}
      {isOpenCommonIssueDialog && currentIssueCommonType && (
        <IssueCommonDialog
          issueType={currentIssueCommonType}
          formType={IssueFormKeys.create}
          onClose={handleCloseIssueCommonDialog}
          open={isOpenCommonIssueDialog}
        />
      )}
    </>
  );
};

import React from "react";
import CarrotQuestButtonView from "./CarrotQuestButtonView";
import { IButton } from "../../uiKit/general/button/Button";
import { useCarrotQuestButton, useNotifier } from "../../../functional/hooks";
import { useTranslation } from "react-i18next";
import { openURL } from "../../../functional/helpers/urlFunctions";
import { ICarrotQuestButton } from "../../../functional/types";


function CarrotQuestButton(props: ICarrotQuestButton) {
  const { handleClick } = useCarrotQuestButton();

  return <CarrotQuestButtonView onClick={handleClick} buttonProps={props.buttonProps} />;
}

export default CarrotQuestButton;

import React, { memo, useEffect } from "react";
import { IOpeningGuideIcon } from "../../../functional/types/OpeningGuideIcon.interface";
import { useRootStore } from "../../../functional/hooks";
import { OpeningGuideIconView } from "./OpeningGuideIconView";
import { useSearchParams } from "react-router-dom";

export const OpeningGuideIconMemoized = (props: IOpeningGuideIcon) => {
  const { hintStore, appStore } = useRootStore();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOpenGuideDrawer = (lang?: string, fromQuery?: boolean) => {
    if (!fromQuery) {
      searchParams.set("eduGuideKey", props.contentKey);
      props.anchorId != null && searchParams.set("eduGuideAnchor", String(props.anchorId) ?? "");
      searchParams.set("eduGuideLang", appStore.getAppLocale ?? "en");
      setSearchParams(searchParams);
    }
    hintStore.onOpenHint(props.contentKey, props.anchorId, lang ?? appStore.getAppLocale ?? "en");
  };

  const handleOpenGuideDrawerByQuery = () => {
    if (searchParams.has("eduGuideKey")) {
      const itemKeyFromQuery = searchParams.get("eduGuideKey");
      const langKeyFromQuery = searchParams.get("eduGuideLang");
      const langKey =
        langKeyFromQuery &&
        (langKeyFromQuery == "en" || langKeyFromQuery == "ru" || langKeyFromQuery == "uk" || langKeyFromQuery == "zh" || langKeyFromQuery == "de")
          ? langKeyFromQuery ?? "en"
          : appStore.getAppLocale ?? "en";
      if (itemKeyFromQuery == null || itemKeyFromQuery.trim().length == 0 || itemKeyFromQuery != props.contentKey) {
        return;
      }
      handleOpenGuideDrawer(langKey, true);
    }
  };

  useEffect(() => {
    handleOpenGuideDrawerByQuery();
  }, []);

  return <OpeningGuideIconView onClick={handleOpenGuideDrawer} mr={props.mr} ml={props.ml} mt={props.mt} />;
};

export const OpeningGuideIcon = memo(OpeningGuideIconMemoized);

import React, { useEffect, useState } from "react";
import { OrgchartDrawerTabMetricsView } from "./OrgchartDrawerTabMetricsView";
import { useNotifier, usePagingWithController } from "../../../../../../functional/hooks";
import { api } from "../../../../../../functional/services";
import { useTranslation } from "react-i18next";
import { MetricDto } from "../../../../../../functional/api";
import { MetricFilter } from "../../../../../../functional/api/filters/metricFilter";

interface IOrgchartDrawerTabMetricsProps {
  roleId: number;
}

export const OrgchartDrawerTabMetrics = (props: IOrgchartDrawerTabMetricsProps) => {
  const notifier = useNotifier();
  const { t } = useTranslation();

  const [metricId, setMetricId] = useState<number>(0);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCreateEditDialogOpen, setIsCreateEditDialogOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const metrics = usePagingWithController<MetricDto, MetricFilter>(
    api.metric,
    {
      roleId: props.roleId,
    },
    { pageSize: 20 }
  );

  const handleMetricDelete = async () => {
    setIsDeleteDialogOpen(false);
    setIsLoading(true);
    const r = await api.metric.del(metricId);
    if (r) {
      notifier.show({
        message: t("notifier:success.metric_delete"),
        theme: "success",
      });
      await handleRestartLoad();
      setIsLoading(false);
    } else {
      setIsLoading(false);
      notifier.show({
        message: t("notifier:error.something_wrong"),
        theme: "error",
      });
    }
  };

  const handleMetricCreateEdit = async () => {
    setIsLoading(true);
    await handleRestartLoad();
    setIsLoading(false);
  };

  const handleRestartLoad = async () => {
    await metrics.restart(true);
  };

  useEffect(() => {
    metrics.restart();
  }, []);

  return (
    <OrgchartDrawerTabMetricsView
      metricId={metricId}
      isCreateEditDialogOpen={isCreateEditDialogOpen}
      isDeleteDialogOpen={isDeleteDialogOpen}
      isLoading={isLoading}
      isEditMode={isEditMode}
      metrics={{
        info: metrics.info,
        items: metrics.items,
      }}
      onCreateEditDialogOpenChange={setIsCreateEditDialogOpen}
      onDeleteDialogOpenChange={setIsDeleteDialogOpen}
      onMetricDelete={handleMetricDelete}
      onMetricIdChange={setMetricId}
      onIsEditModeChange={setIsEditMode}
      onMetricCreateEdit={handleMetricCreateEdit}
      onLoadNext={metrics.loadNext}
      // onDialogOpenChange={setIsDialogOpen}
      // toggleEditMode={toggleEditMode}
      // editMode={editMode}
      // openDeleteDialog={openDeleteDialog}
      // isLoading={metrics.info.isLoading}
      // metrics={metrics.items}
      // isDone={metrics.info.isDone}
      // onLoadNext={metrics.loadNext}
      // handleOpenDialog={handleOpenDialog}
      // closeDeleteDialog={handleCloseDeleteDialog}
      // deleteMetric={handleDeleteMetric}
      // onSuccess={handleRestartLoad}
    />
  );
};

import React, { createRef, memo, MouseEventHandler, useCallback, useEffect, useRef, useState } from "react";
import InputMask from "react-input-mask";
import { Icon } from "../../../icon/Icon";
import { LucideClock } from "lucide-react";
import "./TimePickerMask.scss";
import { TimePickerQuickValue } from "./TimePickerQuickValue/TimePickerQuickValue";
import { IQuickValue, ITimePickerMask } from "./ITimePickerMask";
import clsx from "clsx";
import { useDebounce } from "../../../../../functional/hooks";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import { FiChevronUp } from "@react-icons/all-files/fi/FiChevronUp";


export const TimePickerMask = memo((props: ITimePickerMask) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [time, setTime] = useState((props.value ?? "").split(":").slice(0, 2).join(":"));

  const debounceTime = useDebounce(time, 800);

  useEffect(() => {
    const value = (props.value ?? "").split(":").slice(0, 2).join(":");
    setTime(value);
  }, [props.value]);

  const handleChange = (e: any) => {
    const inputValue = e.target.value;
    if (inputValue) {
      const [hours = "", minutes = ""] = inputValue.split(":");

      const valueHours = +(hours.replaceAll("_", "")) > 23 ? "23" : hours;
      const valueMinutes = +(minutes.replaceAll("_", "")) > 59 ? "59" : minutes;
      setTime(`${valueHours}:${valueMinutes.length === 1 ? `${valueMinutes}_` : valueMinutes}`);
    }
  };

  const isValidTime = (time: string) => {
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
    return timePattern.test(time);
  };

  useEffect(() => {
    if (debounceTime === "__:__" || !debounceTime) {
      props.onChange && props.onChange(null);
    }
    if (isValidTime(debounceTime) && `${debounceTime}:00` !== props.value) {
      props.onChange && props.onChange(`${debounceTime}:00`);
    }
  }, [debounceTime]);

  const [isFocus, setIsFocus] = useState<boolean>(false);
  const handleBlur = useCallback((e) => {
    const { relatedTarget } = e;
    if (!relatedTarget?.classList?.contains("input-wrapper-arrow__button") && relatedTarget !== inputRef.current) {
      setTimeout(() => {
        setIsFocus(false);
      }, 150);
    }

  }, []);

  const handleFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  const handleChangeQuickValues = useCallback((value: string) => {
    setTime(value.split(":").slice(0, 2).join(":"));
  }, []);

  const handleClear = useCallback(() => {
    setTime("");
  }, []);

  const updateTime = (hours: number, minutes: number) => {
    const timeHours = hours < 10 ? `0${hours}` : `${hours}`;
    const timeMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    // if (inputRef.current) {
    //   inputRef.current.focus();
    // }
    // setTime(`${timeHours}:${timeMinutes}`);
    return `${timeHours}:${timeMinutes}`;
  }

  const handleAddedTime = (time: string) => {
    const [hours = "", minutes = ""] = time.split(":");
    const minutesNumber = isNaN(+minutes) ? 0 : +minutes;
    const hoursNumber = isNaN(+hours) ? 0 : +hours;
    const timeHoursNumber = minutesNumber === 59 && hoursNumber < 23 ? hoursNumber + 1 : hoursNumber;
    const minutesValue = hoursNumber < 23 ? 0 : minutesNumber;
    const timeMinutesNumber = minutesNumber < 59 ? minutesNumber + 1 : minutesValue;
    return updateTime(timeHoursNumber, timeMinutesNumber);
  }

  const currentTime = useRef<string>("");
  const intervalId =  useRef<ReturnType<typeof setInterval> | null>(null);

  const handleClickPlus = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    document.addEventListener("mouseup", handleMouseUp);
    currentTime.current = handleAddedTime(time);
    setTime(currentTime.current);
    intervalId.current = setInterval(() => {
      currentTime.current = handleAddedTime(currentTime.current);
      setTime(currentTime.current);
    }, 150);
  };

  const handleMinusTime = (time: string) => {
    const [hours = "", minutes = ""] = time.split(":");
    const minutesNumber = isNaN(+minutes) ? 0 : +minutes;
    const hoursNumber = isNaN(+hours) ? 0 : +hours;
    if (hoursNumber !== 0 || minutesNumber > 0) {
      const timeHoursNumber = minutesNumber > 0 ? hoursNumber : hoursNumber - 1;
      const timeMinutesNumber = minutesNumber > 0 ? minutesNumber - 1 : 59;
      return updateTime(timeHoursNumber, timeMinutesNumber);
    }
    return time;
  }

  const handleClickMinus = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    document.addEventListener("mouseup", handleMouseUp);
    currentTime.current = handleMinusTime(time);
    setTime(currentTime.current);
    intervalId.current = setInterval(() => {
      currentTime.current = handleMinusTime(currentTime.current);
      setTime(currentTime.current);
    }, 150);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp);
    if (intervalId.current !== null) {
      clearInterval(intervalId.current);
    }

  }

  return (
    <div className={"time-picker-mask-container"}>
      <InputMask
        disabled={props.disabled}
        mask="99:99"
        value={time ?? ''}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        {(inputProps: any) => (

          <div className={clsx("input-wrapper", {
            "input-wrapper_disabled": props.disabled,
          })}>
              <div className="input-wrapper__suffix">
                <Icon component={() => <LucideClock size={16} color="var(--color-gray-base)" />} />
              </div>
              <input
                ref={inputRef}
                type="text"
                disabled={props.disabled}
                {...inputProps}
              />
            <div className="input-wrapper-arrow">
              <button
                className="input-wrapper-arrow__button input-wrapper-arrow__button_plus "
                onMouseDown={handleClickPlus}
              >
                <Icon component={() => <FiChevronUp/>}></Icon>
              </button>
              <button
                className="input-wrapper-arrow__button input-wrapper-arrow__button_minus"
                onMouseDown={handleClickMinus}
              >
                <Icon component={() => <FiChevronDown/>}></Icon>
              </button>
            </div>
            {/*<div*/}
              {/*  className="input-wrapper__clear"*/}
              {/*  onClick={handleClear}*/}
              {/*>*/}
              {/*  <Icon*/}
              {/*    component={() => <MdCancel size={16} color="var(--color-gray-base)" />}*/}
              {/*  />*/}
              {/*</div>*/}
          </div>
        )}
      </InputMask>
      {isFocus && !!props.quickValues?.length && (
        <div className="time-picker-quick-values-list">
          {props.quickValues.map((item: IQuickValue) => (
            <TimePickerQuickValue
              key={item.value}
              item={item}
              onChange={handleChangeQuickValues}
              isActive={item.value === time}
            />
          ))}
        </div>
      )}
    </div>
  )
})

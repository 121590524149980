import "./NotificationCenterTab.scss";
import { Empty, Link, Spin, Text } from "../../../uiKit";
import ScrollTrigger from "../../scrollTrigger/ScrollTrigger";
import { NotificationCenterCard } from "../notificationCenterCard/NotificationCenterCard";
import { If } from "../../conditionStatement";
import { useTranslation } from "react-i18next";
import { INotificationCenterTabProps } from "../../../../functional/types";



export const NotificationCenterTab = (props: INotificationCenterTabProps) => {
  const { t } = useTranslation();

  if (props.notificationData.items.length == 0) {
    return (
      <div className="my-10 d-flex align-center justify-center">
        <Spin size="large" spinning={props.notificationData.isLoading} children={<Empty />} />
      </div>
    );
  }

  return (
    <div className="notification-center-tab__wrapper">
      <If condition={props.unreadMessagesCount > 0}>
        <div className="px-4 pb-2 pt-4" style={{ position: "absolute", right: 0, top: 0, zIndex: 3 }}>
          <Link
            onClick={props.onMarkAllAsReadClick}
            disabled={props.notificationData.isLoading || props.isMarkAllAsReadLoading}
            style={{ filter: "grayscale(1)" }}
            className="__read-all"
            children={t("parse:read_all")}
          />
        </div>
      </If>
      <If condition={props.notificationData.items.filter((notification) => !notification.isRead).length > 0}>
        <div>
          <div
            className="px-4 pb-2 pt-4"
            style={{ position: "sticky", top: 0, zIndex: 2, backgroundColor: "var(--color-srf-primary)" }}
          >
            <Text
              className="no-select"
              weight="bold"
              size="14px"
              type="secondary"
              children={t("parse:unread_notifications")}
            />
          </div>
          {props.notificationData.items
            .filter((notification) => !notification.isRead)
            .map((notification) => (
              <NotificationCenterCard
                key={notification.id}
                data={notification}
                isIsReadStatusInverted={props.notificationsWithInvertedReadStatusIds.includes(notification.id!)}
                isLoading={props.loadingNotificationsIds.includes(notification.id!)}
                onNotificationClick={() =>
                  props.onNotificationClick(
                    notification.id!,
                    notification.additionalParams?.entity,
                    notification.additionalParams?.id
                  )
                }
                onMarkAsReadUnreadClick={() => props.onMarkAsReadUnreadClick(notification.id!, !!notification.isRead)}
              />
            ))}
        </div>
      </If>
      <If condition={props.notificationData.items.filter((notification) => notification.isRead).length > 0}>
        <div>
          <div
            className="px-4 pb-2 pt-4"
            style={{ position: "sticky", top: 0, zIndex: 2, backgroundColor: "var(--color-srf-primary)" }}
          >
            <Text
              className="no-select"
              weight="bold"
              size="14px"
              type="secondary"
              children={t("parse:read_notifications")}
            />
          </div>
          {props.notificationData.items
            .filter((notification) => notification.isRead)
            .map((notification) => (
              <NotificationCenterCard
                key={notification.id}
                data={notification}
                isIsReadStatusInverted={props.notificationsWithInvertedReadStatusIds.includes(notification.id!)}
                isLoading={props.loadingNotificationsIds.includes(notification.id!)}
                onNotificationClick={() =>
                  props.onNotificationClick(
                    notification.id!,
                    notification.additionalParams?.entity,
                    notification.additionalParams?.id
                  )
                }
                onMarkAsReadUnreadClick={() => props.onMarkAsReadUnreadClick(notification.id!, !!notification.isRead)}
              />
            ))}
        </div>
      </If>
      <ScrollTrigger
        style={{ borderRadius: 0 }}
        hidden={props.notificationData.isDone}
        disabled={props.notificationData.isLoading}
        onIntersection={props.onLoadMore}
        marginTop={false}
      />
    </div>
  );
};

import React, { memo } from "react";
import { Text } from "../../../uiKit";
import "./PrintDashboardDialog.scss";

import { Portal } from "react-portal";
import { DashboardContent } from "../../pages/dashboards/contents/DashboardContent/DashboardContent";
import { IPrintDashboardDialog } from "./PrintDashboardDialog.interface";

export const PrintDashboardDialog = memo((props: IPrintDashboardDialog) => {


  return (
    <Portal>
      <div className="print-dashboard">
        <div className="print-dashboard-wrapper">
          <div className="print-metric__title mb-6">
            <Text
              className="print-metric-company__name"
              children={props.dashboard?.name ?? ""}
              style={{
                fontSize: 16,
                color: "#000000"
              }}
            />
          </div>

          <DashboardContent
            dashboard={props.dashboard}
            dashboardColumnsAmount={2}
            metrics={props.metrics}
            isPrint={true}
            printMetricId={props.printMetricId}
            onSetIsPrintMetricId={props.onSetIsPrintMetricId}
          />
        </div>
      </div>
    </Portal>
  )
})

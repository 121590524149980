import { AutocompleteModel, RoleDto, RolePositionDto } from "../../../../functional/api";
import { UserSelectionRule } from "../../../../functional/api/types/userSelectionRule";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import React from "react";

export enum positionsKeys {
  allManagers = "all_managers",
  allPerformers = "all_performers",
}

export type PositionsType = number | null | positionsKeys.allPerformers | positionsKeys.allManagers;

export interface IUserSelectionDialog {
  open: boolean;
  orgchartsForBlockAllAll?: number[];
  onClose: () => void;
  isExcludedType?: boolean;
  disabledRules?: UserSelectionRule[];
  onSave: (userSelectionRule: UserSelectionRule, role?: RoleDto | AutocompleteModel) => void;
  propsRoles?: RoleDto[];
}

export interface IUserSelectionDialogView {
  open: boolean;
  orgchartsForBlockAllAll?: number[];
  orgchartId: number | null;
  onClose: () => void;
  rolesIsDone: boolean;
  roles: RoleDto[];
  rolesAutocomplete: AutocompleteModel[];
  isLoading: boolean;
  searchRoleValue: string;
  onChangeSearchRoleValue: (newValue: string) => void;
  onSelectActiveRole: (id: number | null) => void;
  onChangeRolePositionId: (event: RadioChangeEvent) => void;
  onChangeOrgchartId: (id: string | number) => void;
  rolePositionId: PositionsType;
  activeRoleId: number | null;
  disabledRules?: UserSelectionRule[];
  currentPositions?: RolePositionDto[];
  loadNextRoles: () => void;
  onSave: () => void;
}

type roleLabelType = { id: number; name: string };

export interface IRoleSelectionBox {
  labels: roleLabelType[];
  isSupportOldUi?: boolean;
  rolesIsDone: boolean;
  activeLabelId: number | null;
  orgchartId: number | null;
  onChangeOrgchartId: (id: string | number) => void;
  onSelectActiveRole: (id: number | null) => void;
  searchRoleValue: string;
  loadNextRoles: () => void;
  onChangeSearchRoleValue: (newValue: string) => void;
}

export interface IPositionSelectionBox {
  orgchartId: number | null;
  setDisabledFromDown: React.Dispatch<React.SetStateAction<boolean>>;
  positions?: RolePositionDto[];
  disabledAllAll?: boolean;
  disabledRules?: UserSelectionRule[];
  rolePositionId: PositionsType;
  roleId?: number | null;
  onChangeRolePositionId: (event: RadioChangeEvent) => void;
}

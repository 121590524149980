import React, { memo, useMemo } from "react";
import { initialEditorContent } from "../../../../../../../../functional/utils/textEditor";
import { TextEditor as TextEditorLexical } from "../../../../../../textEditor/TextEditor";
import { IIssueActivityText } from "../../../../../../../../functional/types/IssueActivityText.interface";
import "./IssueActivityText.scss";
import { Text } from "../../../../../../../uiKit";
import clsx from "clsx";

export const IssueActivityText = memo((props: IIssueActivityText) => {
  function getValidJson(jsonString: string) {
    try {
      return !!JSON.parse(jsonString);
    } catch (e) {
      return false;
    }
  }

  const isValidJson = useMemo(() => !props.isInlineText ? getValidJson(props.text ?? "") : false,
    [props.text, props.isInlineText]);

  return (
    <div
      className={clsx("issue-activity-text", {
        "issue-activity-text_inline": props.isInlineText,
      })}
      style={{
        background: props.isInlineText ? props.background : undefined,
      }}
    >
      {props.isInlineText || !isValidJson ? (
        <Text
          style={{
            fontSize: 14,
            lineHeight: props.lineHeight ?? 1.4,
            color: props.color,
          }}
        >
          {props.text}
        </Text>
        ) : (
        <TextEditorLexical
          value={props.text || initialEditorContent}
          initialValue={initialEditorContent}
          readOnly
          variant={"new-style"}
        />
      )}

    </div>
  )
})

import React, { useState } from "react";
import TopAppBarMenuView from "./TopAppBarMenuView";
import { useTopAppBarMenu } from "../../../../functional/hooks";
import { observer } from "mobx-react-lite";

function TopAppBarMenu() {
  const {
    menuItems,
    isMenuOpen,
    menuAnchorEl,
    userData,
    handleMenuButtonClick,
    handleMenuClose,
  } = useTopAppBarMenu();

  return (
    <TopAppBarMenuView
      menuItems={menuItems}
      userData={userData}
      isMenuOpen={isMenuOpen}
      menuAnchorEl={menuAnchorEl}
      handleMenuButtonClick={handleMenuButtonClick}
      handleMenuClose={handleMenuClose}
    />
  );
}

export default observer(TopAppBarMenu);

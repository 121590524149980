import React from "react";
import "./GuideDrawer.scss";
import { CommunicationGuide } from "./components/CommunicationGuide";
import { OrgchartGuide } from "./components/OrghartGuide";
import { InviteEmployeeGuide } from "./components/InviteEmployeeGuide";
import { RecurringIssuesGuide } from "./components/RecurringIssuesGuide";
import { PermissionsGuide } from "./components/PermissionsGuide";
import { useRootStore } from "../../../../functional/hooks";
import { observer } from "mobx-react-lite";
import { ScheduleGuide } from "./components/ScheduleGuide";
import { Drawer, IconButton } from "../../../uiKit";
import { useTranslation } from "react-i18next";
import { FiX } from "@react-icons/all-files/fi/FiX";
import { DashboardGuide } from "./components/DashboardGuide";
import { DecisionGuide } from "./components/DecisionGuide";
import { MessageGuide } from "./components/MessageGuide";
import { MultiaccountGuide } from "./components/MultiaccountGuide";
import { PlaningGuide } from "./components/PlaningGuide";
import { PoliciesGuide } from "./components/PoliciesGuide";
import { useSearchParams } from "react-router-dom";
import { guideKeys } from "../../../../functional/types";

const HintDrawerObserver = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { hintStore } = useRootStore();

  const onClose = () => {
    searchParams.delete("eduGuideKey");
    searchParams.delete("eduGuideAnchor");
    searchParams.delete("eduGuideLang");
    setSearchParams(searchParams);
    hintStore.onCloseHint();
  };

  const handleRenderGuide = () => {
    switch (hintStore.getKey) {
      case guideKeys.none:
        return <div />;
      case guideKeys.communications:
        return <CommunicationGuide />;
      case guideKeys.orgchart:
        return <OrgchartGuide />;
      case guideKeys.inviteEmployee:
        return <InviteEmployeeGuide />;
      case guideKeys.recurringIssues:
        return <RecurringIssuesGuide />;
      case guideKeys.permissions:
        return <PermissionsGuide />;
      case guideKeys.schedule:
        return <ScheduleGuide />;
      case guideKeys.dashboard:
        return <DashboardGuide />;
      case guideKeys.decision:
        return <DecisionGuide />;
      case guideKeys.message:
        return <MessageGuide />;
      case guideKeys.multiaccount:
        return <MultiaccountGuide />;
      case guideKeys.planning:
        return <PlaningGuide />;
      case guideKeys.policies:
        return <PoliciesGuide />;
      default:
        return <div />;
    }
  };

  return (
    <Drawer
      width={550}
      zIndex={1500}
      // destroyOnClose
      title={t(`ui:hint.${hintStore.getKey}`)}
      closable={false}
      extra={
        <IconButton size="large" onClick={onClose}>
          <FiX />
        </IconButton>
      }
      placement="right"
      open={hintStore.getOpen}
      onClose={onClose}
    >
      {handleRenderGuide()}
    </Drawer>
  );
};

export const GuideDrawer = observer(HintDrawerObserver);

import React, { forwardRef, useRef, useState } from "react";
import "./CollapsibleContainer.scss";
import { useForkedRef } from "../../../functional/hooks";
import { CSSTransition } from "react-transition-group";
import clsx from "clsx";

interface ICollapsibleContainerProps {
  isOpen?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export const CollapsibleContainer = forwardRef<HTMLDivElement, ICollapsibleContainerProps>(
  (props: ICollapsibleContainerProps, ref) => {
    const collapseRef = useRef<HTMLDivElement>(null);
    const forkedRef = useForkedRef(ref, collapseRef);

    const [height, setHeight] = useState<number>();

    const onEntering = () => {
      collapseRef.current && setHeight(collapseRef.current.scrollHeight);
    };

    const onEntered = () => {
      setHeight(0);
    };

    const onExit = () => {
      collapseRef.current && setHeight(collapseRef.current.scrollHeight);
    };

    const onExiting = () => {
      setHeight(0);
    };

    const onExited = () => {
      setHeight(0);
    };

    return (
      <CSSTransition
        in={props.isOpen}
        nodeRef={collapseRef}
        onEntering={onEntering}
        onEntered={onEntered}
        onExit={onExit}
        onExiting={onExiting}
        onExited={onExited}
        timeout={350}
      >
        {(state) => {
          const currentHeight = height === 0 ? null : { height };
          return (
            <div
              className={clsx(props.className, "uiKit-collapsibleContainer", {
                collapsing: state === "entering" || state === "exiting",
                "collapse show": state === "entered",
                collapse: state === "exited",
              })}
              style={{ ...currentHeight }}
              ref={forkedRef}
              children={props.children}
            />
          );
        }}
      </CSSTransition>
    );
  }
);

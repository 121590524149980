import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { EditableTypography } from "../../../../../elements/editableTypography/EditableTypography";
import { UserCard } from "../../../../../elements/userCard/UserCard";
import { IRegulationContentHeader } from "./RegulationContentHeader.interface";
import { useDateHelpers, useRootStore } from "../../../../../../functional/hooks";
import "./RegulationContentHeader.scss";
import dayjs from "dayjs";
import { Tooltip } from "../../../../../uiKit";
import { allowsRegulationKeys } from "../../../../../../functional/constants/regulation/consts";
import { FiEye } from "@react-icons/all-files/fi/FiEye";
import { FiEdit3 } from "@react-icons/all-files/fi/FiEdit3";
import { LuCheckCheck, LuGraduationCap } from "react-icons/lu";
import { getAccessKey } from "../../../../../../functional/utils/getAccessKey";
import { MenuItemClaimType } from "../../../../../../functional/api/models/NavigationMenuDto";

export const RegulationContentHeader: React.FC<IRegulationContentHeader> = (props) => {
  const { authStore } = useRootStore();

  const { t } = useTranslation();

  const currentUserId = authStore.getInitialInfo?.identity?.id;


  const dateCreated = useMemo(() => {
    return dayjs(props.dateCreated!)
      .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
      .format("DD MMM YYYY")
  }, [props.dateCreated]);

  const getIconComponent = useCallback((actions: allowsRegulationKeys[]) => {
    const key = getAccessKey(actions);
    switch (key) {
      case MenuItemClaimType.Read:
        return <FiEye/>
      case MenuItemClaimType.Write:
        return <FiEdit3/>
      case MenuItemClaimType.Assignment:
        return <LuGraduationCap/>
      case MenuItemClaimType.FullAccess:
        return <LuCheckCheck/>
      default:
        return null;
    }
  }, []);

  const getAccessText = useCallback((actions: allowsRegulationKeys[]) => {
    const key = getAccessKey(actions);
    return t(`ui:title.regulation_access_dialog_collapse.${key}`);
  }, []);

  return (
    <div className="regulation-content-header">
      {!props.isEditable && (
        <EditableTypography
          multiline
          big
          value={props.currentTitle}
          disabled={true}
          onChange={props.onChangeTitle}
        />
      )}

      <div className="d-stack-row spacing-4 justify-space-between align-center mt-2">
        <UserCard
          userInfo={props.createdByUser}
          avatarSize={35}
          additionalInfo={`${t("parse:created_in")} ${dateCreated}`}
          boldName
          isNewStyle
        />
        {!props.isEditable && currentUserId !== props.createdByUser?.id && (
          <div
            className="regulation-content-header-access-icon"
            onClick={props.onOpenRequestChangeAccessTypeDialog}
          >
            <Tooltip title={getAccessText(props.allowedActions ?? [])}>
              {getIconComponent(props.allowedActions ?? [])}
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

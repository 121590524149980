import React, { useMemo } from "react";
import { ICommentCard } from "../../../functional/types/History.interface";
import { HistoryDto, HistoryReactionDto } from "../../../functional/api";
import { CommentCard } from "../../elements/commentCard/CommentCard";
import { ReactionsStrategy } from "../../../functional/strategies/ReactionsStrategy";
import { useUserNameFormat } from "../../../functional/hooks";

const HistoryCardMemo = (props: ICommentCard) => {
  const { getFullName } = useUserNameFormat();

  const reactionsStrategy = new ReactionsStrategy<HistoryDto>(
    props.historyId,
    props.reactionsStrategyController,
    (x: HistoryReactionDto) => x.value as string,
    (x: HistoryReactionDto) => getFullName(
      x?.user?.lastName ?? "",
      x?.user?.firstName ?? "",
      x?.user?.middleName ?? "",
    ) ?? ""
  );

  const dateFormat = useMemo(() => {
    const date = new Date(props.dateCreated);
    const currentYear = new Date().getFullYear();
    return currentYear === date.getFullYear() ? 'DD MMM HH:mm' : 'DD MMM YYYY HH:mm';
  }, [props.dateCreated]);

  return (
    <CommentCard
      creator={props.creator}
      index={props.index}
      historyId={props.historyId}
      reactions={props.reactions}
      reactionStrategy={reactionsStrategy}
      commentContent={props.commentContent}
      commentAttachments={props.commentAttachments}
      dateCreated={props.dateCreated}
      creatorId={props.creatorId}
      isEditing={props.isEditing}
      editCommentId={props.editCommentId}
      handleEditClick={props.handleEditClick}
      handleSaveClick={props.handleSaveClick}
      handleRemoveComment={props.handleRemoveComment}
      item={props.item}
      isNewStyle={true}
      format={dateFormat}
      isHiddenUser={props.isHiddenUser}
      isHiddenReactions={props.isHiddenReactions}
      isSimplifiedToolbar={props.isSimplifiedToolbar}
      isShowBorder={props.isShowBorder}
      editedInfo={props.editedInfo}
    />
  );
};

export const HistoryCard = React.memo(
  HistoryCardMemo,
  (x, y) =>
    x?.historyId === y?.historyId &&
    x?.editCommentId === y?.editCommentId &&
    x?.commentContent === y?.commentContent
);

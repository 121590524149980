import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { IVerificationCodeForm } from "./VerificationCodeForm.interface";
import { VerificationCodeFormView } from "./VerificationCodeFormView";
import { InfoBlock } from "./InfoBlock";
import { useTimer } from "../../../../functional/hooks";

export const VerificationCodeForm = forwardRef((props: IVerificationCodeForm, ref) => {
  const [code, setCode] = useState<number | string | undefined>(undefined);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  const timer = useTimer();

  const valueIsNumber = (value: number | string): boolean => !isNaN(Number(value));

  const handleChangeCode = (newCode: number | string) => {
    props.codeIsNumberOnly ? valueIsNumber(newCode) && setCode(newCode) : setCode(newCode);
  };

  const handleStartTimer = () => props.timerValueMinute && timer.start(props.timerValueMinute);

  const handleTryAgain = async () => {
    setIsLoadingBtn(true);
    await props.onTryActivateAgain();
    handleStartTimer();
    setIsLoadingBtn(false);
  };

  const handleSendCode = async () => {
    setIsLoadingBtn(true);
    await props.onCodeSubmit(code!);
    setIsLoadingBtn(false);
  };

  const handleResetTimer = () => timer.end();

  useImperativeHandle(ref, () => ({ resetTimer: handleResetTimer }));

  useEffect(() => {
    handleStartTimer();
  }, []);

  const verifyCodeForm = (
    <VerificationCodeFormView
      onChangeCode={handleChangeCode}
      code={code}
      isLoadingBtn={isLoadingBtn}
      onCodeSubmit={handleSendCode}
      defaultCodeLength={props.defaultCodeLength}
      codeIsNumberOnly={props.codeIsNumberOnly}
      remainingTime={props.withTimer ? timer.remainingTime : undefined}
    />
  );

  return props.withTimer ? timer.isEnd ? <InfoBlock onClick={handleTryAgain} /> : verifyCodeForm : verifyCodeForm;
});

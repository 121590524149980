import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useApi, useDateHelpers, useDebounce } from "../../../../functional/hooks";
import { api } from "../../../../functional/services";
import { AdminCompaniesStatsDialogView } from "./AdminCompaniesStatsDialogView";
import { TariffType } from "../../../../functional/api/models/CompanyForAdminDto";

interface IAdminCompaniesStatsDialog {
  open: boolean;

  onClose(): void;
}

export function AdminCompaniesStatsDialog(props: IAdminCompaniesStatsDialog) {
  const dateHelpers = useDateHelpers();

  const [displayMode, setDisplayMode] = useState<"chart" | "table">("table");

  const [companiesFilters, setCompaniesFilters] = useState<any>({
    tariffCode: "null",
    academyClientStatus: "null",
  });

  const handleChangeTariffFilter = useCallback((tariffCode: TariffType | "null") => {
    setCompaniesFilters((prev: any) => ({ ...prev, tariffCode }));
  }, []);

  const handleChangeAcademyClientStatus = useCallback((academyClientStatus: string) => {
    setCompaniesFilters((prev: any) => ({ ...prev, academyClientStatus }));
  }, []);

  const debouncedFilters = useDebounce(companiesFilters, 500);

  const [isPaid, setIsPaid] = useState<string>("null");
  const [isTest, setIsTest] = useState<string>("false");
  const [hasDriver, setHasDriver] = useState<boolean | null>(null);
  const [tableFilters, setTableFilters] = useState({
    dateFrom: dayjs().tz("Indian/Antananarivo").startOf('day').subtract(1, "day"),
    dateTo: dayjs().tz("Indian/Antananarivo").endOf('day'),
  });

  const convertToReqParam = (v: string, key?: string) => {
    if (key) return v === key || null;
    if (v == "true") return true;
    if (v == "false") return false;
    if (v == "null") return null;
    return null;
  };

  const companiesStatsData = useApi(() =>
     api.adminCompany.getCompaniesStats(
      tableFilters.dateFrom.tz("Indian/Antananarivo").toISOString(),
      tableFilters.dateTo.tz("Indian/Antananarivo").toISOString(),
      convertToReqParam(isPaid),
      convertToReqParam(isPaid, 'isPartiallyPaid'),
      convertToReqParam(isTest),
       convertToReqParam(isTest) !== null
         ? false
         : convertToReqParam(isTest, 'isInactive'),
      hasDriver,
      companiesFilters.tariffCode === "null" ? null : companiesFilters.tariffCode,
      convertToReqParam(companiesFilters.academyClientStatus),
      companiesFilters.academyClientStatus === "undefined" ? true : null,
    )
  );


  useEffect(() => {
    if (props.open) {
      companiesStatsData.fetch();
    } else {
      // employeeStatsData.set([]);
    }
  }, [props.open, tableFilters, isPaid, hasDriver, isTest, debouncedFilters]);

  const handleClose = () => {
    props.onClose();
    companiesStatsData.set([]);
    setIsPaid("null");
    setHasDriver(null);
    setTableFilters({
      dateFrom: dayjs().tz("Indian/Antananarivo").startOf('day').subtract(1, "day"),
      dateTo: dayjs().tz("Indian/Antananarivo").endOf('day'),
    });
    setDisplayMode("table");
  };

  const tableItems = useMemo(() => {
    return (companiesStatsData.value ?? []).map((c) => ({
      date: dateHelpers.formatDate(c.date as string, {
        showYearWhenIsCurrent: true,
        formatObject: { month: "short", hour: undefined, minute: undefined },
      }),
      value: c.value,
      value2: c.value2,
      full: c.usersCount?.Full ?? 0,
      restricted: c.usersCount?.Restricted ?? 0,
    }))
  }, [companiesStatsData.value]);

  return (
    <AdminCompaniesStatsDialogView
      {...props}
      displayMode={displayMode}
      isPaid={isPaid}
      isTest={isTest}
      hasDriver={hasDriver}
      setHasDriver={setHasDriver}
      setIsPaid={setIsPaid}
      setIsTest={setIsTest}
      isLoading={companiesStatsData.isLoading}
      setDisplayMode={setDisplayMode}
      onClose={handleClose}
      tableFilters={tableFilters}
      setTableFilters={setTableFilters}
      tableItems={tableItems}
      companiesFilters={companiesFilters}
      handleChangeTariffFilter={handleChangeTariffFilter}
      handleChangeAcademyClientStatus={handleChangeAcademyClientStatus}
    />
  );
}

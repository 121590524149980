import React, { useEffect, useState } from "react";
import { OrgchartCreateEditDialogView } from "./OrgchartCreateEditDialogView";
import { OrgchartDto } from "../../../../functional/api";
import { api } from "../../../../functional/services";
import { observer } from "mobx-react-lite";
import { useNotifier, useRootStore } from "../../../../functional/hooks";
import { useTranslation } from "react-i18next";

interface IOrgchartCreateEditDialog {
  open: boolean;
  orgchartData?: OrgchartDto;
  isUploadJson: boolean;
  onSuccess: (orgchartId: number) => void;
  onClose: () => void;
}

function Dialog(props: IOrgchartCreateEditDialog) {
  const { authStore, helperStore } = useRootStore();
  const { t } = useTranslation();
  const notifier = useNotifier();

  const initialState: OrgchartDto = {
    id: 0,
    name: "",
    description: "",
    imageId: null,
    resultName: "",
    valuableFinalProduct: "",
    colorHex: helperStore.getColors?.[0] ?? null,
  };

  const [state, setState] = useState<OrgchartDto>(initialState);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCreate = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("File", state.file ?? "");
    formData.append("name", state.name ?? "")
    if (state.colorHex) {
      formData.append("colorHex", state.colorHex ?? "");
    }
    if (state.description) {
      formData.append("description", state.description);
    }

    const r = props.isUploadJson
      ? await api.orgchart.fromFile(formData)
      : await api.orgchart.create(state);
    if (r == null) {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      setIsLoading(false);
      return;
    }
    // (authStore.getInitialInfo?.orgcharts ?? []).length == 0 && (await authStore.authorizeWithRefreshToken());
    await authStore.refreshInitialInfo();
    // await authStore.authorizeWithRefreshToken();
    // @ts-ignore
    const id = props.isUploadJson ? r.data?.id : r.id;
    props.onSuccess(id);
    props.onClose();
    setState(initialState);
    setIsLoading(false);
  };

  const handleSave = async () => {
    setIsLoading(true);
    const r = await api.orgchart.edit(state.id as number, state);
    if (r == null) {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      setIsLoading(false);
      return;
    }
    await authStore.refreshInitialInfo();
    props.onSuccess(r.id as number);
    props.onClose();
    setState(initialState);
    setIsLoading(false);
  };

  const handleClose = () => {
    props.onClose();
    setState(initialState);
  };

  useEffect(() => {
    if (props.open) {
      props.orgchartData != null && setState(props.orgchartData);
      props.orgchartData == null && setState({ ...state, colorHex: helperStore.getColors?.[0] ?? null });
    } else {
      setState({ ...state, companyId: authStore.getCurrentCompanyId as number });
    }
  }, [props.open]);

  return (
    <OrgchartCreateEditDialogView
      open={props.open}
      state={state}
      isLoading={isLoading}
      isUploadJson={props.isUploadJson}
      colors={helperStore.getColors}
      isEditMode={props.orgchartData != null}
      setState={setState}
      onCreate={handleCreate}
      onSave={handleSave}
      onClose={handleClose}
    />
  );
}

export const OrgchartCreateEditDialog = observer(Dialog);

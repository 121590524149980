import React, { useEffect, useMemo } from "react";
import { AppTabsByPage } from "../../../functional/utils/appLinksAndTabs";
import { useRouterHandler } from "./hooks/useRouterHandler";
import { useNotifier, useRootStore } from "../../../functional/hooks";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { breadcrumbKeys } from "../../../functional/constants/regulation/breadcrumbsKeys";
import Error404Page from "../error404/Error404Page";
import { RegulationRouter } from "../../modules/pages/regulation/regulationRouter/RegulationRouter";

const RegulationsPageObserver = () => {
  const navigate = useNavigate();
  const notifier = useNotifier();
  const { t } = useTranslation();

  const { regulationStore, breadcrumbsStore } = useRootStore();

  const routeToken = breadcrumbsStore.getBreadcrumbsToken;

  const { toolsHide, showBreadcrumbs, pathname, getTypeContent } = useRouterHandler();

  // const [isEditorDialogShow, setIsEditorDialogShow] = useLocalStorage<boolean>("regulationsTextEditorDialog", true);

  // const handleClose = useCallback(() => {
  //   setIsEditorDialogShow(false);
  // }, [setIsEditorDialogShow]);

  const noTabs = toolsHide && showBreadcrumbs && !(pathname === "/policy" || pathname === "/policy/");

  const currentTabs = useMemo(
    () =>
      !noTabs
        ? regulationStore.getPageTabs
            .map((item) => AppTabsByPage.regulation.find((item2) => item2.key === item.key)!)
            .filter((item) => item !== undefined)
            .map((item) => ({ ...item, name: t(item.name as string) }))
        : undefined,
    [noTabs, regulationStore.getPageTabs?.length]
  );

  const handleError = () => {
    notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
  };

  useEffect(() => {
    if (pathname === "/policy" || pathname === "/policy/") {
      currentTabs && currentTabs[0]?.link && navigate(currentTabs[0].link);
    }
  }, [pathname, currentTabs]);

  useEffect(() => {
    regulationStore.fetchPageTabs();
    regulationStore.setOnError(handleError);
    return () => {
      breadcrumbsStore.resetBreadcrumbsToken();
      breadcrumbsStore.resetBreadcrumbsToken();
    };
  }, []);

  const handleGetLoading = (): boolean => {
    let pageIsDisabledLoading: boolean = false;
    switch (routeToken?.key) {
      case breadcrumbKeys.statsOverall:
        pageIsDisabledLoading = true;
        break;
      case breadcrumbKeys.createSection:
        pageIsDisabledLoading = true;
        break;
      case breadcrumbKeys.tabToLearn: {
        if (!!regulationStore.getRegulations.length || !!regulationStore.getSections.length)
          pageIsDisabledLoading = true;
        break;
      }
    }
    return !pageIsDisabledLoading && regulationStore.getLoading;
  };

  return (
    <>
      {/*<Alert type="warning" className="py-1 px-2" message={t("parse:text_editor_bug_alert")} />*/}
      {/*<TextEditorBugInfoDialog isOpen={isEditorDialogShow} setIsOpen={handleClose} />*/}
      {!handleGetLoading() && routeToken?.key === breadcrumbKeys.page404 ? (
        <Error404Page />
      ) : (
        <RegulationRouter
          regulationContentType={getTypeContent()}
          routeToken={routeToken}
          noTabs={noTabs}
          tabs={currentTabs}
        />
      )}
    </>
  );
};

export default observer(RegulationsPageObserver);

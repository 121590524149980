import "./ScheduleGrid.scss";
import { Alert } from "../../../../uiKit";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RoleDto, User2RoleDto, UserScheduleDto } from "../../../../../functional/api";
import { ScheduleRow } from "../scheduleRow/ScheduleRow";
import dayjs, { Dayjs } from "dayjs";
import { ISchedulePageExpandedRolesStateItem } from "../../../../pages/schedule/SchedulePage";

interface IScheduleGridProps {
  roles: {
    items: RoleDto[];
    isLoading: boolean;
    isDone: boolean;
  };
  defaultSchedule: {
    items: UserScheduleDto[];
  };
  datesToDisplay: Dayjs[];
  periodPickerDate: {
    start: Dayjs;
    end: Dayjs;
  };
  selectionResetTrigger: number;
  useUserTimeZone: boolean;
  currentCollapsedRolesState: ISchedulePageExpandedRolesStateItem | null;

  onRowExpandChange: (roleId: number) => void;
  onSelectionFinish: (selectedItems: any[], user2role: User2RoleDto) => void;
}

export const ScheduleGrid = (props: IScheduleGridProps) => {
  const { t } = useTranslation();

  const defaultPeriodSchedule: (UserScheduleDto | undefined)[] = useMemo(() => {
    return props.datesToDisplay.map((date) =>
      props.defaultSchedule.items.find((s) =>
        date.isBetween(dayjs.utc(s.dateFrom).startOf("day"), dayjs.utc(s.dateTo).endOf("day"), "date", "[]")
      )
    );
  }, [props.defaultSchedule.items, props.datesToDisplay]);

  if (props.roles.isDone && props.roles.items.length == 0) {
    return (
      <div className="schedule-grid__wrapper __pztZoom">
        <Alert description={t("parse:no_schedule_for_period")} />
      </div>
    );
  }

  return (
    <div className="schedule-grid__wrapper d-stack-column spacing-2">
      {props.roles.items.map((role) => (
        <ScheduleRow
          key={role.id}
          role={role}
          isExpanded={
            (props.currentCollapsedRolesState?.isAllCollapsed &&
              props.currentCollapsedRolesState?.invertedRolesIds?.includes(role.id!)) ||
            (!props.currentCollapsedRolesState?.isAllCollapsed &&
              !props.currentCollapsedRolesState?.invertedRolesIds?.includes(role.id!))
          }
          useUserTimeZone={props.useUserTimeZone}
          defaultPeriodSchedule={defaultPeriodSchedule}
          datesToDisplay={props.datesToDisplay}
          selectionResetTrigger={props.selectionResetTrigger}
          onExpandChange={() => props.onRowExpandChange(role.id!)}
          onSelectionFinish={props.onSelectionFinish}
        />
      ))}
    </div>
  );
};

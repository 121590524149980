import React, { useEffect, useRef, useState } from "react";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import "./DriverPage.scss";
import { useDidMountEffect, useNotifier, usePagingWithController } from "../../../functional/hooks";
import { api } from "../../../functional/services";
import { DriverCardDto, UserContactDto } from "../../../functional/api";
import DriverCalledDialog from "../../modules/dialogs/driverCalledDialog/DriverCalledDialog";
import { FiStopCircle } from "@react-icons/all-files/fi/FiStopCircle";
import { useNavigate } from "react-router-dom";
import DriverCalledAdminDialog from "../../modules/dialogs/driverCalledAdminDialog/DriverCalledAdminDialog";
import DriverExecutorAdminsDialog from "../../modules/dialogs/driverExecutorAdminsDialog/DriverExecutorAdminsDialog";
import Visibility from "visibilityjs";
import { Alert, Button, Card, Empty, Icon, Input, Text } from "../../uiKit";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import { clearInterval as wtClearInterval, setInterval as wtSetInterval } from "worker-timers";
import { AxiosError } from "axios";
import ScrollTrigger from "../../service/scrollTrigger/ScrollTrigger";
import { DriverHistoryCard } from "./components/driverHistoryCard/DriverHistoryCard";
import { UserBlock } from "./components/userBlock/UserBlock";
import { DriverIssuesCollapse } from "./components/driverIssuesCollapse/driverIssuesCollapse";
import { DriverTextCollapse } from "./components/driverTextCollapse/DriverTextCollapse";
import { getContactLinkByType } from "../../../functional/utils/contactLinkByType";

function DriverPage() {
  const { t } = useTranslation();
  const notifier = useNotifier();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [comment, setComment] = useState<string>("");
  const [isDriverCalledDialogOpen, setIsDriverCalledDialogOpen] = useState<boolean>(false);
  const [isDriverCalledAdminDialogOpen, setIsDriverCalledAdminDialogOpen] = useState<boolean>(false);
  const [isDriverExecutorAdminsDialogOpen, setIsDriverExecutorAdminsDialogOpen] = useState<boolean>(false);
  const [cardsInQueue, setCardsInQueue] = useState<number>(0);
  // const [sessionHistory, setSessionHistory] = useState<ControlSessionHistoryDto[] | null>(null);
  //
  const [disabledButtonsKeys, setDisabledButtonsKeys] = useState<string[]>([]);
  const [toStopInterval, setToStopInterval] = useState<boolean>(false);
  // const userIdToGetHistory = useRef<number | null>(null);
  const [userIdToGetHistory, setUserIdToGetHistory] = useState<number | null>(null);
  const minute = 60 * 1000;
  const [driverData, setDriverData] = useState<DriverCardDto | null>(null);
  const queueTimeout = useRef<any>(null);
  const relaodNextIntervalRef = useRef<any>(null);

  const handleApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const loadData = async () => {
    const r = await api.driver.next((error) => {
      setIsLoading(false);
      if (error.response?.status == 404)
        return notifier.show({
          message: t("notifier:info.no_cards"),
          theme: "info",
        });
      if ((error.response?.status ?? 400) >= 500 || !error.response) {
        typeof relaodNextIntervalRef.current == "number" && setToStopInterval(true);
        notifier.show({ message: t("notifier:error.try_again_later"), theme: "error" });
      }
    });
    setDriverData(r);
    if (r?.user?.id == userIdToGetHistory) handleHistoryRefresh();
  };

  const refreshCardsInQueue = async (t?: any) => {
    const r = await api.driver.getCardsInQueueCount();
    if (r == null) {
      return;
    }
    // queueTimeout != null && (queueTimeout.current = t);
    setCardsInQueue(r ?? 0);
    // queueTimeout.current?.refresh();
  };

  useEffect(() => {
    loadData();
    refreshCardsInQueue();
    const t = setInterval(refreshCardsInQueue, 7000);

    return () => {
      clearInterval(t);
      queueTimeout.current = null;
    };
  }, []);

  const sessionHistory = usePagingWithController(
    api.controlSessionHistory,
    {
      userId: userIdToGetHistory,
    },
    { pageSize: 20 }
  );

  const handleReloadBoard = async () => {
    if (Visibility.isSupported()) {
      setIsLoading(true);
      const r = await api.driver.next(handlerLoadDataError);
      setIsLoading(false);
      setDriverData(r);
      if (r?.user?.id == userIdToGetHistory) handleHistoryRefresh();
    }
  };

  const handlerLoadDataError = (error: AxiosError) => {
    setIsLoading(false);
    if (error.response?.status == 404)
      return notifier.show({
        message: t("notifier:info.no_cards"),
        theme: "info",
      });
    if (!error.response || (error.response?.status ?? 400) >= 500) {
      setToStopInterval(true);
      notifier.show({ message: t("notifier:error.try_again_later"), theme: "error" });
      return loadData();
    }
  };

  const handleSendCommentClick = async () => {
    setIsLoading(true);
    const r = await api.controlSessionHistory.create({
      id: 0,
      controlSessionId: driverData?.controlSessionId,
      text: comment,
    });
    if (r == null) {
      notifier.show({ message: t("notifier:error.history_added_comment"), theme: "error" });
      setIsLoading(false);
      return;
    }
    notifier.show({ message: t("notifier:success.history_added_comment"), theme: "success" });
    setComment("");
    await handleHistoryRefresh();
  };

  const handleNextClick = async () => {
    setIsLoading(true);
    // setSessionHistory(null);
    const r = await api.driver.proceed(driverData?.controlSessionId as number);
    if (r == null) {
      setIsLoading(false);
      return handleApiError();
    }
    // const r = await api.driver.proceed(driverData.value?.controlSessionId as number);
    // if (r == null) {
    //   notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
    //   setIsLoading(false);
    //   return;
    // }
    // driverData.set(null);
    // TODO: Check
    // setDriverData(null);
    await loadData();
  };

  const handleHistoryRefresh = async () => {
    sessionHistory.reset();
    await sessionHistory.restart();
    setIsLoading(false);
  };

  useDidMountEffect(() => {
    if (userIdToGetHistory != null && driverData != null) {
      sessionHistory.reset();
      sessionHistory.restart().finally(() => {
        setDisabledButtonsKeys([]);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [userIdToGetHistory]);

  const handleWroteButtonClick = async () => {
    setIsLoading(true);
    setDisabledButtonsKeys([...disabledButtonsKeys, "driver.action.write"]);
    const r = await api.controlSessionHistory.create({
      id: 0,
      controlSessionId: driverData?.controlSessionId,
      // text: comment,
      baseKey: "driver.action.write",
    });
    if (r == null) {
      notifier.show({ message: t("notifier:error.history_added_wrote"), theme: "error" });
      setIsLoading(false);
      setDisabledButtonsKeys(disabledButtonsKeys.filter((k) => k != "driver.action.write"));
      return;
    }
    await loadData();
  };

  useEffect(() => {
    setUserIdToGetHistory(driverData?.user?.id ?? null);
  }, [driverData]);

  const handleCallButtonClick = async () => {
    setIsDriverCalledDialogOpen(true);
  };

  const handleCallToAdminButtonClick = async () => {
    setIsDriverCalledAdminDialogOpen(true);
  };

  const handleCalledDialogSuccess = async () => {
    setIsDriverCalledDialogOpen(false);
    setIsLoading(true);
    setDisabledButtonsKeys([...disabledButtonsKeys, "driver.action.call"]);
    await loadData();
  };

  const handleCalledAdminDialogSuccess = async () => {
    setIsDriverCalledAdminDialogOpen(false);
    setIsLoading(true);
    setDisabledButtonsKeys([...disabledButtonsKeys, "driver.action.call.admin"]);
    await loadData();
  };

  const handleWriteToMessengerButtonCLick = async () => {
    setIsLoading(true);
    // setDisabledButtonsKeys([...disabledButtonsKeys, "driver.action.write.to_messenger"]);
    await handleCopy(driverData?.text?.trim() ?? "");
    const r = await api.controlSessionHistory.create({
      id: 0,
      controlSessionId: driverData?.controlSessionId,
      // text: comment,
      baseKey: "driver.action.write.to_messenger",
    });
    if (r == null) {
      notifier.show({ message: t("notifier:error.history_added_write_to_messenger"), theme: "error" });
      setIsLoading(false);
      setDisabledButtonsKeys(disabledButtonsKeys.filter((k) => k != "driver.action.write.to_messenger"));
      return;
    }
    notifier.show({ message: t("notifier:success.history_added_write_to_messenger"), theme: "success" });
    await loadData();
  };

  const handleCopy = async (text: string) => {
    await navigator.clipboard
      .writeText(text)
      .then(() => notifier.show({ message: t("notifier:success.copy"), theme: "success" }))
      .catch(() => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" }));
  };

  const actionsList = [
    { id: 0, key: "driver.action.write", action: handleWroteButtonClick },
    { id: 1, key: "driver.action.call", action: handleCallButtonClick },
    { id: 2, key: "driver.action.call.admin", action: handleCallToAdminButtonClick },
    { id: 3, key: "driver.action.write.to_messenger", action: handleWriteToMessengerButtonCLick },
  ];

  const handlePhoneCopy = async (number: string) => {
    await handleTextCopy(number);
  };

  const handlePhoneCall = async (phoneNumber: string) => {
    if ((phoneNumber ?? "").trim().length == 0) {
      handleApiError();
      return;
    }
  };

  const handleTextCopy = async (text: string) => {
    await navigator.clipboard
      .writeText(text)
      .then(() => notifier.show({ message: t("notifier:success.copy"), theme: "success" }))
      .catch(() => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" }));
  };

  const handleWorkFinish = async () => {
    if (driverData?.user != null) {
      setIsLoading(true);
      const r = await api.driver.proceed(driverData?.controlSessionId as number);
      if (r == null) {
        handleApiError();
        setIsLoading(false);
        return;
      }
      setDriverData(null);
      sessionHistory.reset();
      setIsLoading(false);
    }
    navigate("/driver");
  };

  const copyLogin = async (event: any, login: string) => {
    // event.preventDefault();
    await navigator.clipboard
      .writeText(login)
      .then(() => notifier.show({ message: t("notifier:success.copy"), theme: "success" }))
      .catch(() => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" }));
  };

  /*const handleLoadDataWithResStatus = async () => {
    setIsLoading(true);
    const r = await api.driver.next((error) => {
      handleApiError();
      const status = error.response?.status ?? 400;
      if (status >= 400 && status < 500) handleReloaderByTimeout();
      if (status >= 500)
    });
    setIsLoading(false);
    if (r == null) return handleApiError();
  };

  const handleReloaderByTimeout = (timeout?: number) => {
    const currentTimeout = timeout ?? minute * 0.1;
    setTimeout(handleLoadDataWithResStatus, currentTimeout);
  }*/

  useEffect(() => {
    if (driverData == null && !isLoading && relaodNextIntervalRef.current != "number")
      relaodNextIntervalRef.current = wtSetInterval(handleReloadBoard, minute * 0.5);
    if (driverData == null && typeof relaodNextIntervalRef.current == "number")
      wtClearInterval(relaodNextIntervalRef.current);
  }, []);

  useEffect(() => {
    return () => {
      typeof relaodNextIntervalRef.current == "number" && wtClearInterval(relaodNextIntervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (toStopInterval) {
      typeof relaodNextIntervalRef.current == "number" && wtClearInterval(relaodNextIntervalRef.current);
      setToStopInterval(false);
    }
  }, [toStopInterval]);

  return (
    <>
      <DriverCalledDialog
        open={isDriverCalledDialogOpen}
        controlSessionId={driverData?.controlSessionId as number}
        onSuccess={handleCalledDialogSuccess}
        onClose={() => setIsDriverCalledDialogOpen(false)}
      />
      <DriverCalledAdminDialog
        open={isDriverCalledAdminDialogOpen}
        controlSessionId={driverData?.controlSessionId as number}
        onSuccess={handleCalledAdminDialogSuccess}
        onClose={() => setIsDriverCalledAdminDialogOpen(false)}
      />
      <DriverExecutorAdminsDialog
        open={isDriverExecutorAdminsDialogOpen}
        onClose={() => setIsDriverExecutorAdminsDialogOpen(false)}
        companies={driverData?.user?.companies ?? null}
      />
      <PageHeader title={t("common:page_title.driver")}>
        <div className="flex-grow-1" />
        <Text
          style={{ color: "var(--color-primary-base)" }}
          weight="bold"
          children={t("parse:queue_cards", { number: cardsInQueue })}
        />
        <div className="flex-grow-1" />
        <Button
          disabled={isLoading}
          icon={<Icon component={() => <FiStopCircle />} />}
          style={{ marginLeft: "auto" }}
          onClick={handleWorkFinish}
        >
          {t("ui:button.finish_work")}
        </Button>
      </PageHeader>
      <PageContent isLoading={isLoading}>
        {driverData == null ? (
          !isLoading && <Alert description={t("text:driver.no_cards_found")} />
        ) : (
          <div className="driver-admin-page__wrapper">
            <Card className="mr-1 flex-grow-1 flex-shrink-1" style={{ flexBasis: "35%" }} scrollStrategy="cardBody">
              <div className="full-height d-flex flex-column">
                <UserBlock
                  user={driverData?.user}
                  getContactLinkByType={getContactLinkByType}
                  onOpenExecutorAdminsDialog={() => setIsDriverExecutorAdminsDialogOpen(true)}
                  onCopyLogin={copyLogin}
                  onPhoneCopy={handlePhoneCopy}
                  onPhoneCall={handlePhoneCall}
                />
                <div className="mt-3 flex-grow-1 d-stack-column spacing-2" style={{ overflowY: "auto" }}>
                  <DriverIssuesCollapse issues={driverData?.issues} violationGroups={driverData?.violationGroups} />
                  <DriverTextCollapse text={driverData?.text?.trim() ?? ""} onCopy={handleCopy} />
                </div>
              </div>
            </Card>
            <div className="mx-1 flex-grow-1 flex-shrink-1 d-flex flex-column full-height" style={{ flexBasis: "45%" }}>
              <Card className="mb-2 flex-shrink-0" scrollStrategy="cardBody">
                <div className="d-flex justify-space-between">
                  <Input
                    placeholder={t("ui:placeholder.comment")}
                    value={comment}
                    onInput={(event: any) => setComment(event.target.value)}
                  />
                  <Button
                    className="ml-2"
                    loading={isLoading}
                    onClick={handleSendCommentClick}
                    disabled={comment.length == 0}
                    size="large"
                    variant="filled"
                  >
                    {t("ui:button.send")}
                  </Button>
                </div>
              </Card>
              <Card className="flex-grow-1" title={t("ui:caption.interaction_history")} scrollStrategy="cardBody">
                {sessionHistory.items.length == 0 && !sessionHistory.info.isLoading && <Empty className="my-4" />}
                <div className="d-stack-column spacing-2">
                  {sessionHistory.items?.map((sH, index) => (
                    <DriverHistoryCard sH={sH} />
                  ))}
                  <ScrollTrigger
                    onIntersection={() => {
                      if (userIdToGetHistory == null) return;
                      sessionHistory.loadNext();
                    }}
                    hidden={sessionHistory.info.isDone}
                    marginTop={sessionHistory.items.length > 0}
                  />
                </div>
              </Card>
            </div>
            <Card
              className="ml-1 flex-grow-1 flex-shrink-1"
              style={{ flexBasis: "20%", minWidth: "264px" }}
              scrollStrategy="cardBody"
            >
              <div className="full-height d-flex flex-column justify-space-between">
                <div className="d-stack-column spacing-2">
                  {driverData?.calculated?.actions
                    ?.filter((a) => a.isRequired)
                    ?.map((action) => (
                      <Button
                        style={{ width: "100%" }}
                        size="large"
                        allowTextWrap
                        loading={isLoading}
                        key={action.key}
                        onClick={() => actionsList.find((a) => a.key == action.key)?.action()}
                        variant="outlined"
                        disabled={disabledButtonsKeys.find((x) => x == action.key) != null}
                        children={action.name}
                      />
                    ))}
                </div>
                <div className="d-stack-column spacing-2">
                  {driverData?.calculated?.actions
                    ?.filter((a) => !a.isRequired)
                    ?.map((action) => (
                      <Button
                        style={{ width: "100%" }}
                        size="large"
                        allowTextWrap
                        loading={isLoading}
                        key={action.key}
                        onClick={() => actionsList.find((a) => a.key == action.key)?.action()}
                        variant="outlined"
                        disabled={disabledButtonsKeys.find((x) => x == action.key) != null}
                        children={action.name}
                      />
                    ))}
                </div>
                <Button
                  style={{ width: "100%" }}
                  size="large"
                  onClick={() => handleNextClick()}
                  disabled={!driverData?.calculated?.isProceedAvailable}
                  variant="filled"
                  loading={isLoading}
                  children={t("ui:button.next")}
                />
              </div>
            </Card>
          </div>
        )}
      </PageContent>
    </>
  );
}

export default DriverPage;

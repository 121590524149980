import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import "./OrgchartDrawerTabRecurringTasks.scss";
import { RecurringIssueDto, RoleDto } from "../../../../../../functional/api";
import { OrgchartContext } from "../../../../../../functional/contexts/orgchartContext";
import { useClaims, useRootStore } from "../../../../../../functional/hooks";
import { TIssuesFilters } from "../../orgchartCommonDrawerTabs/orgchartCommonDrawerTabRecurringTasks/OrgchartCommonDrawerTabRecurringTasks";
import { observer } from "mobx-react-lite";
import { Button, Empty, Icon, IconButton, Input, Select, Spin } from "../../../../../uiKit";
import TaskRecurringCard from "../../taskRecurringCard/TaskRecurringCard";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";
import { permissionKeys } from "../../../../../../functional/utils/permissions";
import DeleteRecurringIssueDialog from "../../../../dialogs/deleteRecurringIssueDialog/DeleteRecurringIssueDialog";
import RecurringIssueHistoryDialog from "../../../../dialogs/recurringIssueHistoryDialog/RecurringIssueHistoryDialog";
import RecurringIssueCreateEditDialog from "../../../../dialogs/recurringIssueCreateEditDialog/RecurringIssueCreateEditDialog";
import VideoDialog from "../../../../dialogs/videoDialog/VideoDialog";
import { FiX } from "react-icons/fi";
import { PagingInfo } from "../../../../../../functional/types";

export interface IOrgchartDrawerTabRecurringTasks {
  currentRole: RoleDto;
  isLoading: boolean;
  issueToManageId: number | null;
  issueToManageData: RecurringIssueDto | null;
  isIssueCreateEditDialogOpen: boolean;
  isIssueDeleteDialogOpen: boolean;
  isIssueHistoryDialogOpen: boolean;
  recurringIssue: {
    items: RecurringIssueDto[];
    info: PagingInfo;
  };
  // createOn: "admins" | "executors" | "all" | undefined;
  tabFilters: TIssuesFilters;
  tabSort: { by: string; order: string };

  setTabFilters: (value: TIssuesFilters) => void;
  setTabSort: (value: { by: string; order: string }) => void;
  // setCreateOn: (value: "admins" | "executors" | "all" | undefined) => void;
  // setIssueToManageData: (data: RecurringIssueDto | null) => void;
  onIssueCreateClick: () => void;
  onIssuePauseClick: (issueId: number) => void;
  onIssueHistoryClick: (issueId: number) => void;
  onIssueEditClick: (issueId: number) => void;
  onIssueDeleteClick: (issueId: number) => void;
  onIssueAnyDialogClose: () => void;
  onIssueDelete: () => void;
  onIssueCreate: () => void;
  onIssueSave: () => void;
  onLoadNext: () => void;
}

function OrgchartDrawerTabRecurringTasksViewObserved(props: IOrgchartDrawerTabRecurringTasks) {
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const orgchartContext = useContext(OrgchartContext);
  const claims = useClaims();

  const executorTypes = [
    { id: 0, name: t("common:menu.executor_select.admins_of_function"), value: "role_admins", disabled: false },
    { id: 1, name: t("common:menu.executor_select.executors_of_function"), value: "role_executors", disabled: false },
    { id: 2, name: t("common:menu.executor_select.all_admins"), value: "all_admins", disabled: false },
    { id: 3, name: t("common:menu.executor_select.all"), value: "all", disabled: false },
  ];

  const pauseTypes = [
    { id: 0, text: t("parse:all_tasks"), value: null, valueSelect: "null", disabled: false },
    { id: 1, text: t("parse:on_pause"), value: true, valueSelect: "true", disabled: false },
    { id: 2, text: t("parse:active"), value: false, valueSelect: "false", disabled: false },
  ];

  const sortBy = [
    { id: 0, name: t("parse:sort.by_date"), value: "date_created", disabled: false },
    { id: 1, name: t("parse:sort.by_name"), value: "name", disabled: false },
  ];

  const sortOrders = [
    { id: 0, text: t("common:misc.sort_items.ones_first"), value: "desc", disabled: false },
    { id: 1, text: t("common:misc.sort_items.old_ones"), value: "asc", disabled: false },
  ];

  const senderTypes = [
    { id: 0, name: t("parse:for_all"), value: null, valueSelect: "null", disabled: false },
    { id: 1, name: t("parse:for_admins"), value: 1, valueSelect: "1", disabled: false },
    { id: 2, name: t("parse:for_executors"), value: 2, valueSelect: "2", disabled: false },
  ];

  const executorPositionsTypes = [
    { id: 0, text: t("parse:for_all"), value: null, valueSelect: "null", disabled: false },
    { id: 1, text: t("parse:for_admins"), value: 1, valueSelect: "1", disabled: false },
    { id: 2, text: t("parse:for_executors"), value: 2, valueSelect: "2", disabled: false },
  ];

  return (
    <>
      <VideoDialog
        trainingKey="ed.o.s"
        name="recurringTasks"
        onWatchedClickObject={{ recurringTasks: true }}
        hidden={
          !claims.hasOneOf([
            permissionKeys.recurringIssue.add,
            permissionKeys.recurringIssue.edit,
            permissionKeys.recurringIssue.delete,
            permissionKeys.recurringIssue.pauseManage,
            permissionKeys.recurringIssue.viewHistory,
          ])
        }
        // hidden={!(orgchartContextState.openedDialog == "openFunction")}
      />
      <DeleteRecurringIssueDialog
        open={props.isIssueDeleteDialogOpen}
        onDelete={props.onIssueDelete}
        onClose={props.onIssueAnyDialogClose}
      />
      <RecurringIssueHistoryDialog
        open={props.isIssueHistoryDialogOpen}
        issueId={props.issueToManageId}
        onClose={props.onIssueAnyDialogClose}
      />
      <RecurringIssueCreateEditDialog
        open={props.isIssueCreateEditDialogOpen}
        functionData={props.currentRole ?? undefined}
        createOn={props.issueToManageData == null ? "all" : undefined}
        data={props.issueToManageData ?? undefined}
        onCreate={props.onIssueCreate}
        onSave={props.onIssueSave}
        onClose={props.onIssueAnyDialogClose}
      />
      <Spin
        spinning={(props.recurringIssue.info.isLoading && props.recurringIssue.items.length == 0) || props.isLoading}
        className="orgchart-function-drawer__tabs__loader"
      >
        <div
          className="mt-n3 pb-3 d-flex flex-nowrap justify-space-between"
          style={{
            position: "sticky",
            top: "-12px",
            backgroundColor: "var(--color-srf-primary)",
            zIndex: 1,
          }}
        >
          {claims.has(permissionKeys.recurringIssue.add) ? (
            <Button size="middle" children={t("ui:button.create_routine")} onClick={props.onIssueCreateClick} />
          ) : (
            <div />
          )}

          <div className="d-stack spacing-2 align-center">
            <Input
              size="middle"
              style={{ width: "220px" }}
              placeholder={t("parse:task_name") as string}
              value={props.tabFilters.name}
              onInput={(event: any) => props.setTabFilters({ ...props.tabFilters, name: event.target.value })}
            />
            {authStore.getCurrentCompanyUiType != 2 && (
              <Select
                size="middle"
                style={{ width: "140px" }}
                value={
                  executorPositionsTypes.find((t) => t.value == props.tabFilters.executorPositionType)?.valueSelect ??
                  "null"
                }
                onChange={(value: string | null) =>
                  props.setTabFilters({
                    ...props.tabFilters,
                    executorPositionType: executorPositionsTypes.find((t) => t.valueSelect == value)?.value ?? null,
                  })
                }
                items={executorPositionsTypes.map((ex) => ({ id: ex.id, value: ex.valueSelect, text: ex.text }))}
              />
            )}
            <Select
              size="middle"
              style={{ width: "110px" }}
              value={pauseTypes.find((t) => t.value == props.tabFilters.isPaused)?.valueSelect ?? "null"}
              onChange={(value) =>
                props.setTabFilters({
                  ...props.tabFilters,
                  isPaused: pauseTypes.find((t) => t.valueSelect == value)?.value ?? null,
                })
              }
              items={pauseTypes.map((ex) => ({ id: ex.id, value: ex.valueSelect, text: ex.text }))}
            />
            <IconButton
              size="middle"
              disabled={
                !(
                  props.tabFilters.name.length > 0 ||
                  props.tabFilters.roleId != null ||
                  props.tabFilters.isPaused != null ||
                  props.tabFilters.executorConfigType.length != 0 ||
                  props.tabFilters.executorPositionType != null
                )
              }
              onClick={() =>
                props.setTabFilters({
                  ...props.tabFilters,
                  name: "",
                  roleId: null,
                  isPaused: null,
                  executorConfigType: "",
                  executorPositionType: null,
                })
              }
            >
              <Icon component={() => <FiX />} />
            </IconButton>
          </div>

          <div className="d-stack spacing-2 align-center">
            <Select
              size="middle"
              value={props.tabSort.order ?? "desc"}
              onChange={(value) =>
                props.setTabSort({
                  ...props.tabSort,
                  order: value ?? "desc",
                })
              }
              items={sortOrders}
            />
          </div>
        </div>

        {props.recurringIssue.items.length == 0 && props.recurringIssue.info.isDone ? (
          <div className="d-flex align-center justify-center" style={{ height: "calc(100% - 56px)" }}>
            <Empty />
          </div>
        ) : (
          <div className="d-stack-column spacing-2">
            {props.recurringIssue.items.map((recurringIssue) => (
              <TaskRecurringCard
                key={recurringIssue.id}
                data={recurringIssue}
                onPauseClick={() => props.onIssuePauseClick(recurringIssue.id as number)}
                onWatchHistoryClick={() => props.onIssueHistoryClick(recurringIssue.id as number)}
                onEditClick={() => props.onIssueEditClick(recurringIssue.id as number)}
                onDeleteClick={() => props.onIssueDeleteClick(recurringIssue.id as number)}
              />
            ))}
            <ScrollTrigger
              onIntersection={() => {
                if (props.currentRole?.id == null) return;
                props.onLoadNext();
              }}
              disabled={props.recurringIssue.info.isLoading}
              hidden={props.recurringIssue.info.isDone}
            />
          </div>
        )}
      </Spin>
    </>
  );
}

export const OrgchartDrawerTabRecurringTasksView = observer(OrgchartDrawerTabRecurringTasksViewObserved);

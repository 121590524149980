import React from "react";
import { observer } from "mobx-react-lite";
import { LocalStorageHelpers, useRootStore } from "../../../../functional/hooks";
import EmployeeSettingsOnFirstLoginDialogView from "./EmployeeSettingsOnFirstLoginDialogView";
import { toJS } from "mobx";

function EmployeeSettingsOnFirstLoginDialog() {
  const { authStore, appStore } = useRootStore();
  const lsKey = toJS(appStore.getLsKeys.isTutorialWatched) ?? "isTutorialWatched";

  const handleSave = () => {
    LocalStorageHelpers.set(lsKey, Boolean(false));
    window.location.reload();
  };

  return (authStore.getInitialInfo?.identity?.firstName ?? "").trim().length == 0 ||
    (authStore.getInitialInfo?.identity?.lastName ?? "").trim().length == 0 ||
    (authStore.getInitialInfo?.identity?.phoneNumber ?? "").trim().length == 0 ? (
    <EmployeeSettingsOnFirstLoginDialogView handleSave={handleSave} />
  ) : null;
}

export default observer(EmployeeSettingsOnFirstLoginDialog);

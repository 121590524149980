import { AiOutlinePaperClip } from "@react-icons/all-files/ai/AiOutlinePaperClip";
import { useTranslation } from "react-i18next";
import FileUploader from "../../../../../service/fileUploader/FileUploader";
import { Button, Icon } from "../../../../../uiKit";
import { IPlanHistoryEditor } from "./PlanHistoryModule.interface";
import React, { useCallback } from "react";
import { TextEditor as TextEditorLexical } from "../../../../textEditor/TextEditor";
import { initialEditorContent } from "../../../../../../functional/utils/textEditor";
import { HistorySendBlock } from "../../../../../uiKit/History/HistorySendBlock";
import { api } from "../../../../../../functional/services";
import type { PlanCommentAttachmentDto } from "../../../../../../functional/api";

export const PlanHistoryEditor = (props: IPlanHistoryEditor) => {

  const handleCreateHistory = useCallback(async (content: string, attachments: PlanCommentAttachmentDto[]) => {

    const resp = await api.planHistory.create({
      planId: props.planId,
      comment: {
        content,
        attachments,
      },
    });
    if (resp) {
      props.onRestart();
    }
    return !!resp;
  }, [props.planId]);


  return (
      <HistorySendBlock
        itemId={props.planId}
        user={props.user}
        isLoading={props.isLoading}
        onCreateHistory={handleCreateHistory}
        isShowUpload
      />
  );
};

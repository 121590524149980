import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { CreateRegulationSectionForm } from "../../../forms/createRegulationSectionForm/CreateRegulationSectionForm";
import { Card, Dropdown, Empty, Icon, IconButton, Tooltip } from "../../../../uiKit";
import { useNotifier, useRootStore } from "../../../../../functional/hooks";
import { SectionContentHeaderCreator } from "../components/SectionContentHeader/SectionContentHeaderCreator";
import { regulationContentTypeKeys } from "../../../../../functional/constants/regulation/headerTools";
import { CardRegulation } from "../components/CardRegulation/CardRegulation";
import { SortTable } from "../../../../elements/SortTable/SortTable";
import { REGULATION_TABLE_COLUMNS } from "../../../../../functional/constants/regulationTableColumns";
import { useTranslation } from "react-i18next";
import type { ColumnsType } from "antd/es/table";
import { ISortTableDataType } from "../../../../elements/SortTable/SortTable.interface";
import { DocIcon, FolderOpenIcon, StarIcon, StarIconWithoutBackground } from "../../../../../functional/constants/icon";
import "./SectionContent.scss";
import { UserInfo } from "../../../../elements/userInfo/UserInfo";
import dayjs from "dayjs";
import { ActionButton } from "../components/ButtonIcons/ActionButton";
import { useSectionHooks } from "../hooks/useSectionHooks";
import { SelectApproverDialog } from "../../../dialogs/selectApproverDialog/SelectApproverDialog";
import { CustomConfirmDialog } from "../../../dialogs/customConfirmDialog/СustomConfirmDialog";
import { useNavigate } from "react-router-dom";
import { RegulationStatus } from "../../../../../functional/constants/regulationStatus";
import { SettingUpAccessDialog } from "../../../dialogs/settingUpAccessDialog/SettingUpAccessDialog";
import clsx from "clsx";
import { defineAllow } from "../../../../../functional/helpers/helpers";
import { allowsRegulationKeys } from "../../../../../functional/constants/regulation/consts";
import {
  RegulationAccessControlDialog
} from "../../../dialogs/regulationAccessControlDialog/RegulationAccessControlDialog";
import { FiEye } from "@react-icons/all-files/fi/FiEye";
import { FiEdit3 } from "@react-icons/all-files/fi/FiEdit3";
import { LuCheckCheck, LuGraduationCap } from "react-icons/lu";
import { RegulationDto } from "../../../../../functional/api";
import { RoleSelectorDto } from "../../../../../functional/api/models/RoleSelectorDto";
import { MenuItemClaimType } from "../../../../../functional/api/models/NavigationMenuDto";
import { keysRulesFor } from "../../../dialogs/settingUpAccessDialog/SettingUpAccessDialog.interface";
import { ISectionContent } from "./Pages.interface";

const SectionContentObserver = (props: ISectionContent) => {
  const { authStore } = useRootStore();
  const { handleClickCopyLink, getStatusCell, getLink } = useSectionHooks();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const currentUserId = authStore.getInitialInfo?.identity?.id;

  const handleGetIsToStudy = (regulation?: RegulationDto): boolean => {
    return !regulation?.usersWhoStudied?.find((user) => user.userId === authStore.getInitialInfo?.identity?.id);
  };

  const columns: ColumnsType<ISortTableDataType> = useMemo(() => {
    return REGULATION_TABLE_COLUMNS.map((item) => ({
      ...item,
      // title: item.icon ?? t(item.title),
      title: t(item.title),

    }));
  }, []);


  const handleDragEnd = useCallback(async (id: number, overId: number) => {
    await props.onMove(id, overId);
  }, [props.section?.items]);


  const [itemId, setItemId] = useState<number | null>(null);
  const [isRegulation, setIsRegulation] = useState<boolean>(false);
  const [isShowApproverDialog, setIsShowApproverDialog] = useState<boolean>(false);

  const handleCloseApproverDialog = useCallback(() => {
    setIsShowApproverDialog(false);
    setItemId(null);
  }, []);

  const handleAppointApprover = useCallback((approverId, resetTestResults, urgency) => {
    if (itemId) {
      props.onAppointApprover(itemId, approverId, resetTestResults, urgency);
      // handleCloseApproverDialog();
    }
  }, [itemId]);

  const [isShowCustomConfirmDialog, setIsShowCustomConfirmDialog] = useState<boolean>(false)
  const handleCloseCustomConfirmDialog = useCallback(() => {
    setIsShowCustomConfirmDialog(false);
    setItemId(null);
    setIsRegulation(false);
  }, [])

  const handleRemoveFromSection = useCallback(async (id, isRegulation) => {
    // await onRemovalRegulationsFromSection(id)
    setItemId(id);
    setIsRegulation(isRegulation);
    setIsShowCustomConfirmDialog(true);
  }, []);

  const handleClickConfirm = useCallback(async () => {
    await props.onRemovalRegulationsFromSection(itemId!, isRegulation);
    handleCloseCustomConfirmDialog();
  }, [itemId, isRegulation]);

  const handleSubmitForApproval = useCallback((id: number) => {
    setItemId(id);
    setIsShowApproverDialog(true);
  }, []);

  const [isOpenAccessSettingsDialog, setIsShowAccessSettingsDialog] = useState<boolean>(false);
  const handleCloseAccessSettingsDialog = useCallback(() => {
    setIsShowAccessSettingsDialog(false);
    setItemId(null);
  }, []);


  const handleSetAccess = useCallback((id: number) => {
    setItemId(id);
    setIsShowAccessSettingsDialog(true);
  }, []);


  const handleGoToItem = useCallback((id: number, isRegulation: boolean) => {
    const link = getLink(id, isRegulation, props.section?.id);
    navigate(link);
  }, [props.section?.id]);

  const handleToggleFavorites = (id: number) => {
    console.log("handleToggleFavorites", id);
  }

  const [isOpenAssignStudyDialog, setIsOpenAssignStudyDialog] = useState<boolean>(false);
  const handleCloseAssignStudyDialog = () => setIsOpenAssignStudyDialog(false);
  const handleOpenAssignStudyDialog = () => setIsOpenAssignStudyDialog(true);

  const handleSaveAccessSettings = async (rules: RoleSelectorDto[]): Promise<boolean> => {
    return true;
  }

  const handleAssignStudyRegular = useCallback((id) => {
    // await onRemovalRegulationsFromSection(id)
    setItemId(id);
    handleOpenAssignStudyDialog();
  }, []);

  const itemTitle = useMemo(() => {
    // 'props.section?.items'
    console.log("props.section?.items", props.section?.items);
    console.log("itemId", itemId);
    const currentItem =
      props.section?.items?.find(({id, regulationId}) => id === itemId || regulationId === itemId);
    return currentItem?.name ?? "";
  }, [props.section?.items, itemId]);

  console.log("props.section?.items", props.section?.items);
  console.log("itemTitle", itemTitle);

  const sectionChilds = useMemo(() => {

    return (props.section?.items ?? [])
      // .sort((a, b) => a.order - b.order)
      .map((item) => {
        const {
          regulationId,
          stats,
          createdByUser,
          id,
          regulation,
          actions: navigationItemActions,
        } = item;

        // console.log("item", item);

        const { studiedPercent } = stats ?? {};

        const {
          createdByUser: createdByUserRegulation,
          datePublish: datePublishRegulation,
          state,
          actions,
          name: regulationName,
        } = regulation ?? {};

        const publicationDate = datePublishRegulation ? dayjs(datePublishRegulation)
          .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
          .format("LL") : null;
        return {
          ...item,
          // favorites: (
          //   <div
          //     className={clsx(
          //       "section-content-cell-favorites",
          //       {
          //         "section-content-cell-favorites_active": a === 1,
          //       }
          //     )}
          //     onClick={() => handleToggleFavorites(regulationId ? regulationId : item.id)}
          //   >
          //     {
          //       a === 1
          //       ? (<StarIcon />)
          //       : (<StarIconWithoutBackground />)
          //     }
          //   </div>
          // ),
          statusCell: getStatusCell(regulationId ? state : studiedPercent, !!regulationId),
          name: (
            <div
              className="section-content-cell-title"
              onClick={() => handleGoToItem(item.id, !!regulationId)}
            >
              <div className="section-content-cell-title__icon">
                { regulationId
                  ? (<DocIcon />)
                  : (<FolderOpenIcon size={20}/>)
                }
              </div>
              { regulationId ? regulationName : item.name }
            </div>
          ),
          user: (
            <UserInfo
              nameStyle={{ fontWeight: "bold" }}
              avatarSize={25}
              user={regulationId ? createdByUserRegulation : createdByUser }
            />
          ),
          publicationDate,
          action: (
            <ActionButton
              isRegulation={!!regulation}
              isSubmitForApproval={defineAllow(allowsRegulationKeys.requestPublication, navigationItemActions ?? actions)}
              isSubmitForPublish={defineAllow(allowsRegulationKeys.publish, navigationItemActions ?? actions)}
              isSubmitForReject={defineAllow(allowsRegulationKeys.reject, navigationItemActions ?? actions)}
              isStudyAssignment={defineAllow(allowsRegulationKeys.edit, navigationItemActions ?? actions)}
              isAccessToRegulations={defineAllow(allowsRegulationKeys.edit, navigationItemActions ?? actions)}
              isRemoveFromSection={defineAllow(allowsRegulationKeys.delete, props.section?.actions ?? actions)}
              isCopyLink={true}
              onRemoveFromSection={() => handleRemoveFromSection(id, !!regulationId)}
              onSubmitForApproval={() => handleSubmitForApproval(regulationId ?? id)}
              onSubmitPublish={() => props.handleSubmitForPublish(regulationId ?? id)}
              onSubmitReject={() => props.handleSubmitForReject(regulationId ?? id)}
              onAssignStudyRegular={() => handleAssignStudyRegular(regulationId ?? id)}
              onSetAccess={() => handleSetAccess(id)}
              onCopyLink={() =>
                handleClickCopyLink(item.id ?? regulationId, !!regulationId, props.section?.id)
              }
            />
          ),
        };
      });

  }, [props.section?.items]);

  const getAccessKey = (actions: allowsRegulationKeys[]) => {
    if (actions.includes(allowsRegulationKeys.edit) && actions.includes(allowsRegulationKeys.assignment)) {
      return MenuItemClaimType.FullAccess;
    }
    if (actions.includes(allowsRegulationKeys.edit)) {
      return MenuItemClaimType.Write;
    }
    if (actions.includes(allowsRegulationKeys.assignment)) {
      return MenuItemClaimType.Assignment;
    }
    return MenuItemClaimType.Read;
  }

  const getIconComponent = useCallback((actions: allowsRegulationKeys[]) => {
    const key = getAccessKey(actions);
    switch (key) {
      case MenuItemClaimType.Read:
        return <FiEye/>
      case MenuItemClaimType.Write:
        return <FiEdit3/>
      case MenuItemClaimType.Assignment:
        return <LuGraduationCap/>
      case MenuItemClaimType.FullAccess:
        return <LuCheckCheck/>
      default:
        return null;
    }
  }, []);

  const getAccessText = useCallback((actions: allowsRegulationKeys[]) => {
    const key = getAccessKey(actions);
    return t(`ui:title.regulation_access_dialog_collapse.${key}`);
  }, []);

  return (
    <>
      {props.isEditable ? (
        props.section && (
          <CreateRegulationSectionForm
            toEdit={props.toEdit}
            section={props.section}
          />
        )
      ) : (
        <>
          {isOpenAssignStudyDialog && (
            <SettingUpAccessDialog
              keyRuleTo={keysRulesFor.regulation}
              // onSave={handleSaveAccessSettings}
              isOpen={isOpenAssignStudyDialog}
              currentRules={[]}
              regulationId={itemId ?? undefined}
              title={itemTitle}
              onClose={handleCloseAssignStudyDialog}
            />
          )}

          {isOpenAccessSettingsDialog && (
            <RegulationAccessControlDialog
              isOpen={isOpenAccessSettingsDialog}
              onClose={handleCloseAccessSettingsDialog}
              sectionId={itemId}
              title={itemTitle}
              createdByUser={props.section?.createdByUser}
            />
          )}

          {isShowApproverDialog && (
            <SelectApproverDialog
              withCheckbox
              // withUrgency
              open={isShowApproverDialog}
              onClose={handleCloseApproverDialog}
              onSuccess={handleAppointApprover}
            />
          )}
          {isShowCustomConfirmDialog && (
            <CustomConfirmDialog
              open={true}
              width={622}
              onConfirm={handleClickConfirm}
              onClose={handleCloseCustomConfirmDialog}
              title={t("ui:confirm_dialog_remove_from_section.title")}
              subTitle={t("ui:confirm_dialog_remove_from_section.text", { sectionTitle: props.section?.name ?? ""})}
            />
          )}
          { !props.isLoading && (
            <Card
              title={props.section?.name}
              size="default"
              scrollStrategy="cardBody"
              className={"section-content"}
            >
              <div className="section-content-header">
                <SectionContentHeaderCreator
                  creator={props.section?.createdByUser}
                  dateCreated={props.section?.dateCreated ?? ""}
                />
                {props.section?.createdByUser?.id !== currentUserId && (
                  <div
                    className="section-content-header-access-icon"
                    onClick={props.onOpenRequestChangeAccessTypeDialog}
                  >
                    <Tooltip title={getAccessText(props.section?.actions ?? [])}>
                      {getIconComponent(props.section?.actions ?? [])}
                    </Tooltip>
                  </div>
                )}
              </div>
              {!!sectionChilds.length && (
                <SortTable
                  items={sectionChilds}
                  columns={columns}
                  handleDragEnd={handleDragEnd}
                />
              )}
              {(props.section?.items ?? []).length == 0 && (
                <Empty
                  className="mt-4"
                  description={t("ui:section.empty_data")}
                />
              )}
            </Card>
          )}
          </>
      )}
    </>
  );
};

export const SectionContent = observer(SectionContentObserver);

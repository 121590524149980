import React, { memo, useCallback, useContext, useEffect, useRef, useState } from "react";
import "./CreateRegulationForm.scss";
import { CreateRegulationFormView } from "./CreateRegulatiomFormView";
import { SettingUpAccessDialog } from "../../dialogs/settingUpAccessDialog/SettingUpAccessDialog";
import { UserSelectionRule } from "../../../../functional/api/types/userSelectionRule";
import { useNavigate } from "react-router-dom";
import { api } from "../../../../functional/services";
import { useNotifier } from "../../../../functional/hooks";
import { useTranslation } from "react-i18next";
import { RegulationContentQuestionDto, RegulationDto } from "../../../../functional/api";
import { initialEditorContent } from "../../../../functional/utils/textEditor";
import { CustomConfirmDialog } from "../../dialogs/customConfirmDialog/СustomConfirmDialog";
import { errorKeys } from "../../../../functional/constants/consts";
import { keysRulesFor } from "../../dialogs/settingUpAccessDialog/SettingUpAccessDialog.interface";
import { resetIds, validateTest } from "../../../../functional/helpers/createTestsForm/helpers";
import { regulationContentTypeKeys } from "../../../../functional/constants/regulation/headerTools";
import { GlobalAuthorizedContext } from "../../../../functional/contexts/globalAuthorizedContext";
import { RegulationContext } from "../../../../functional/contexts/RegulationPage/RegulationContext";
import { RoleSelectorDto } from "../../../../functional/api/models/RoleSelectorDto";

export const CreateRegulationForm = memo(() => {
  // const globalAuthorizedContext = useContext(GlobalAuthorizedContext);
  const {
    handleSave,
    regulationTitle,
    setRegulationTitle,
    editorRef,
    testRef,
    isLoading,
    setIsLoading,
    setUserSelectionRules,
    isOpenConfirmDialog,
    setIsOpenConfirmDialog,
    titleError,
    setTitleError,
    isOpenAccessSettingsDialog,
    setIsOpenAccessSettingsDialog,
    regulationContent,
    setRegulationContent,
  } = useContext(RegulationContext);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isOpenCreatingTest, setIsOpenCreatingTest] = useState<boolean>(false);

  const handleChangeRegulationTitle = useCallback((newValue: string) => {
    titleError && newValue.trim().length > 0 && setTitleError(false);
    setRegulationTitle(newValue.length < 2 ? newValue.trim() : newValue);
  }, [titleError]);

  const handleChangeRegulationContent = useCallback((newValue: string) => setRegulationContent(newValue), []);
  const handleOpenAccessSettingsDialog = useCallback(() => setIsOpenAccessSettingsDialog(true), []);
  const handleCloseAccessSettingsDialog = useCallback(() => setIsOpenAccessSettingsDialog(false), []);

  const handleRedirectToCreateSection = useCallback(() => navigate("/policy/create-section"), []);
  const handleCancel = useCallback(() => navigate("/policy"), []);

  const handleChangeIsLoading = useCallback((loading: boolean) => setIsLoading(loading), []);

  const handleCloseConfirmDialog = useCallback(() => setIsOpenConfirmDialog(false), []);

  const handleOpenTest = useCallback(() => setIsOpenCreatingTest(true), []);
  const handleCloseTest = useCallback(() => setIsOpenCreatingTest(false), []);

  const handleSaveAccessSettings = useCallback(async (rules: RoleSelectorDto[]): Promise<boolean> => {
    setUserSelectionRules([...rules]);
    handleCloseAccessSettingsDialog();
    return true;
  }, []);

  return (
    <>
      <CustomConfirmDialog
        open={isOpenConfirmDialog}
        onClose={handleCloseConfirmDialog}
        title={t("parse:are_you_sure")}
        subTitle={t("parse:complete_test")}
        onConfirm={() => handleSave(true)}
      />
      <SettingUpAccessDialog
        // onSave={handleSaveAccessSettings}
        isOpen={isOpenAccessSettingsDialog}
        keyRuleTo={keysRulesFor.regulation}
        onClose={handleCloseAccessSettingsDialog}
        isCreateMode
      />
      <CreateRegulationFormView
        editorRef={editorRef}
        testRef={testRef}
        isLoading={isLoading}
        titleError={titleError}
        isOpenCreatingTest={isOpenCreatingTest}
        regulationTitle={regulationTitle}
        regulationContent={regulationContent}
        test={testRef.current?.test ?? []}
        onOpenTest={handleOpenTest}
        onCloseTest={handleCloseTest}
        onCancel={handleCancel}
        onOpenAccessSettingsDialog={handleOpenAccessSettingsDialog}
        onChangeRegulationTitle={handleChangeRegulationTitle}
        onChangeRegulationContent={handleChangeRegulationContent}
        onRedirectToCreateSection={handleRedirectToCreateSection}
        onChangeIsLoading={handleChangeIsLoading}
        onSave={handleSave}
      />
    </>
  );
});

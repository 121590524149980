import React, { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import { useLocalStorage, useRootStore } from "../../../functional/hooks";
import { CalendarPlanningSidebar } from "../../modules/pages/communications/components/communicationSidebarCalendarPlanning/CommunicationSidebarCalendarPlanning";
import { CalendarPlanningCalendar } from "../../modules/pages/communications/components/calendarPlanningCalendar/CalendarPlanningCalendar";

const CommunicationPageContentCalendarPlanningObserved = () => {
  const { communicationCalendarPlanningStore } = useRootStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSidebarResizing, setIsSidebarResizing] = useState<boolean>(false);
  const [sidebarSize, setSidebarSize] = useLocalStorage("calendarPlanningSidebarSize", {
    width: 300,
  });

  const calendarSidebarContainer = useRef<HTMLInputElement | null>(null);

  return (
    <div
      className="d-stack-row ml-n2 my-n2 full-height"
      style={{ overflow: "hidden", width: "calc(100% + 8px)", height: "calc(100% + 16px)" }}
    >
      <div
        className={
          communicationCalendarPlanningStore.getIsSidebarOpen
            ? "calendar-planning-sidebar_visible"
            : "calendar-planning-sidebar_hidden"
        }
        style={{
          width: isSidebarResizing
            ? "auto"
            : communicationCalendarPlanningStore.getIsSidebarOpen
            ? (sidebarSize.width ?? 300) + "px"
            : 0,
          transition: isSidebarResizing ? "none" : "width 0.15s",
        }}
        ref={calendarSidebarContainer}
      >
        <CalendarPlanningSidebar
          isSidebarOpen={true}
          sidebarSize={sidebarSize}
          onSidebarOpenChange={() => communicationCalendarPlanningStore.setIsSidebarOpen(false)}
          onSidebarSizeChange={setSidebarSize}
          onSidebarResizing={setIsSidebarResizing}
        />
      </div>
      <PageContent
        style={{ overflow: "hidden" }}
        isLoading={isLoading}
      >
        <CalendarPlanningCalendar
          onIsLoadingChange={setIsLoading}
          calendarSidebarContainer={calendarSidebarContainer.current}
        />
      </PageContent>
    </div>
  );
};

export const CommunicationPageContentCalendarPlanning = observer(CommunicationPageContentCalendarPlanningObserved);

import React, { memo, useCallback } from "react";
import { Answer } from "../Answer/Answer";
import { IAnswersGenerator } from "../CreateTestsForm.interface";
import { Button, Icon } from "../../../../uiKit";
import { PlusIcon } from "../../../../../functional/constants/icon";
import { useTranslation } from "react-i18next";
import "./AnswersGenerator.scss";

export const AnswersGenerator: React.FC<IAnswersGenerator> = (props) => {
  const { t } = useTranslation();

  const handleChangeAnswerRight = (newValue: boolean, id: number) =>
    props.onChangeAnswerRight(newValue, props.questionId, id);

  const handleChangeAnswerName = (newValue: string, id: number): void =>
    props.onChangeAnswerName(newValue, props.questionId, id);

  const handleDeleteAnswer = (id: number) => props.onDeleteAnswer(props.questionId, id);

  const addNewAnswerIsDisabled = () =>
    props.answers.length === 0 ? false : !props.answers[props.answers.length - 1]?.name?.trim().length;

  const handleAppendEmptyAnswer = () => props.onAppendEmptyAnswer(props.questionId);

  return (
    <div className="answer-wrap">
      <div className="answer-list">
        {props.answers.map((answer) => (
          <Answer
            key={answer.id}
            id={answer.id!}
            checked={answer.isRight!}
            name={answer.name!}
            onChangeChecked={handleChangeAnswerRight}
            onChangeName={handleChangeAnswerName}
            onDelete={handleDeleteAnswer}
          />
        ))}
      </div>
      <div className="answer-wrap-button">
        <Button
          onClick={handleAppendEmptyAnswer}
          disabled={addNewAnswerIsDisabled() || props.disabled}
          icon={<Icon component={() => <PlusIcon />}/>}
          variant={"default"}
          >
          { t("ui:button.add_answer") }
        </Button>
      </div>
      {/*<AddAnswerButton*/}
      {/*  onClick={handleAppendEmptyAnswer}*/}
      {/*  disabled={addNewAnswerIsDisabled() || props.disabled}*/}
      {/*/>*/}
    </div>

  );
};

import React from "react";
import { observer } from "mobx-react-lite";
import { LocalStorageHelpers, useClaims, useRootStore } from "../../../../functional/hooks";
import CompanySettingsOnFirstLoginDialogView from "./CompanySettingsOnFirstLoginDialogView";
import { toJS } from "mobx";
import { permissionKeys } from "../../../../functional/utils/permissions";

function CompanySettingsOnFirstLoginDialog() {
  const { authStore, appStore } = useRootStore();
  const claims = useClaims();

  const lsKey = toJS(appStore.getLsKeys.isTutorialWatched) ?? "isTutorialWatched";

  const handleSave = () => {
    LocalStorageHelpers.set(lsKey, Boolean(false));
    window.location.reload();
  };


  return (!authStore.getCurrentCompany?.timeZoneId && claims.has(permissionKeys.company.edit.timeZone))
  || (authStore.getInitialInfo?.identity?.name?.trim().length != 0 &&
    authStore.getCurrentCompany?.name?.trim()?.length) == 0  ? (
    <CompanySettingsOnFirstLoginDialogView handleSave={handleSave} />
  ) : null;
}

export default observer(CompanySettingsOnFirstLoginDialog);

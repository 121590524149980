import React, { ReactElement, useMemo } from "react";
import { Dropdown, Icon, Tooltip } from "../../../../../uiKit";
import { LucidePlus, LucideSettings } from "lucide-react";
import { useTranslation } from "react-i18next";
import { IDropdownItem } from "../../../../../uiKit/navigation/dropdown/Dropdown";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../../../../../functional/hooks";
import { useNavigate } from "react-router-dom";
import { allowsRegulationKeys } from "../../../../../../functional/constants/regulation/consts";
import { defineAllow } from "../../../../../../functional/helpers/helpers";

interface SidebarTreeControlsProps {
  onOpenAccessControlDialog: () => void;
  onOpenDeletingTopicDialog: () => void;
  isLeaf: boolean;
  onSelect: () => void;
  treeItemKey: string;
  treeItemId: number;
  actions: allowsRegulationKeys[];
  parentName?: string;
  withoutATopic: boolean;
}

export const SidebarTreeControls = observer((props: SidebarTreeControlsProps) => {
  const {
    onOpenAccessControlDialog,
    onOpenDeletingTopicDialog,
    isLeaf,
    onSelect,
    treeItemKey,
    treeItemId,
    actions,
    parentName,
    withoutATopic,
  } = props;
  const { regulationStore } = useRootStore();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const setSectionIsEditable = (isEditable: boolean) => regulationStore.setSectionIsEditable(isEditable);
  const setRegulationIsEditable = (isEditable: boolean) => regulationStore.setRegulationIsEditable(isEditable);
  const setParentId = (id: number) => regulationStore.setParentId(id);

  const handleGetEditCallback = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onSelect();
    return isLeaf ? setRegulationIsEditable(true) : setSectionIsEditable(true);
  };

  const cancelEditable = () => {
    setParentId(-1);
    setSectionIsEditable(false);
    setRegulationIsEditable(false);
  }

  const handleClickCreateDocument = () => {
    cancelEditable()
    setParentId(treeItemId);
    navigate(`/policy/topics/${treeItemId}/policy/create-policy`);
  };

  const handleClickCreateSection = () => {
    cancelEditable();
    setParentId(treeItemId);
    navigate(`/policy/topics/${treeItemId}/create-topic`);
  };


  const creationDropdownItems: IDropdownItem[] = useMemo(
    () => [
      {
        key: 1,
        text: t("common:menu.regulation_creating_dropdown.new_document"),
        onClick: handleClickCreateDocument,
      },
      {
        key: 2,
        text: t("common:menu.regulation_creating_dropdown.new_topic"),
        onClick: handleClickCreateSection,
      },
    ],
    [t]
  );

  const handleClickOpenAccessControlDialog = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onOpenAccessControlDialog();
  };

  const handleClickOpenDeletingTopicDialog = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onOpenDeletingTopicDialog();
  }

  const settingsDropdownItems: IDropdownItem[] = useMemo(
    () => {
      const arr = [
        {
          key: 1,
          text: (
            <Tooltip
              title={withoutATopic ? t(`ui:tooltip.access_settings_without_topic`) : ""}
            >
              {t("common:menu.regulation_settings_dropdown.access_control") }
            </Tooltip>
          ),
          disabled: withoutATopic,
          onClick: handleClickOpenAccessControlDialog,
        },
        {
          key: 2,
          text: t("common:menu.regulation_settings_dropdown.edit"),
          onClick: handleGetEditCallback,
        },
      ];
      if (defineAllow(allowsRegulationKeys.delete, actions)) {
        arr.push( {
          key: 3,
          text: t(`common:menu.regulation_settings_dropdown.${parentName ? "remove_from_section" : "delete" }`),
          onClick: handleClickOpenDeletingTopicDialog,
        })
      }
      return arr;
    },
    [t, actions]
  );

  const withDropdown = (items: IDropdownItem[], icon: ReactElement) => (
    <Dropdown
      className={"regulation-menu-item__button"}
      trigger={["click"]}
      items={items}
      placement="bottomCenter"
    >
      <Icon
        className="arrow-button"
        component={() => icon}
      />
    </Dropdown>
  );

  const CreationDropdown = withDropdown(creationDropdownItems, <LucidePlus size={16} />);
  const SettingsDropdown = withDropdown(settingsDropdownItems, <LucideSettings size={16} />);

  return (
    <div
      className="d-stack spacing-2"
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      {!isLeaf && CreationDropdown}
      {SettingsDropdown}
    </div>
  );
});

import { useEffect, useRef } from "react";

export function usePrevious<T>(value: T): T {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref: any = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

// Using:
// State value and setter for our example
// const [count, setCount] = useState<number>(0);
// Get the previous value (was passed into hook on last render)
// const prevCount: number = usePrevious<number>(count);

import React from "react";
import { ConnectionCheckerView } from "./ConnectionCheckerView";
import { useConnectionChecker } from "../../../functional/hooks";

interface IConnectionChecker {
  //
}

export function ConnectionChecker(props: IConnectionChecker) {
  useConnectionChecker();

  return <ConnectionCheckerView />;
}

import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { api } from "../../../functional/services";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import { useApi, useNotifier } from "../../../functional/hooks";
import { ListEducationSections } from "../../modules/pages/education/components/listEducationSections/ListEducationSections";
import { ListEducationCourse } from "../../modules/pages/education/components/listEducationCourse/listEducationCourse";
import "./EducationPage.scss";

const EducationPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { t, i18n: { language } } = useTranslation();
  const notifier = useNotifier();

  const handleApiError = () => {
    setIsLoading(false);
    notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
  };

  const educationData = useApi(
    () => api.education.getEducationItems(),
    handleApiError,
    () => setIsLoading(false)
  );

  const fetchEducationData = useCallback(() => {
    educationData.fetch();
  }, []);

  const [activeCourseId, setActiveCourseId] = useState<number | null>(null);

  const handleOpenCourse = useCallback((courseId: number | null) => {
    setActiveCourseId(courseId);
  }, []);
  console.log("educationData", educationData);
  const activeCourse = useMemo(() => {
    return activeCourseId
      ? (educationData.value ?? []).find((course) => course.id === activeCourseId)
      : undefined;
  }, [activeCourseId]);

  const activeContent = useMemo(() => {
    return activeCourse ? activeCourse.content[language] : null;
  }, [activeCourse, language]);

  return (
    <>
      <PageHeader
        title={activeCourse ? "" : t("common:page_title.training")}
      >
        {activeCourse && (
          <div className="education-breadcrumbs"> {/*TODO переделать после тогого как база знаний попадет в dev*/}
            <div
              className="education-breadcrumbs__item education-breadcrumbs__item_link"
              onClick={() => handleOpenCourse(null)}
            >
              {t("common:page_title.training")}
            </div>
            <div className="education-breadcrumbs__item">
              /
            </div>
            <div className="education-breadcrumbs__item education-breadcrumbs__item_active">
              {activeContent?.name ?? ""}
            </div>
          </div>
        )}
      </PageHeader>
      {/*tabs={AppTabsByPage["supportCenter"]}*/}
      <PageContent isLoading={isLoading} isFilled>
        {activeCourse ? (
          <ListEducationSections
            educationItems={activeCourse?.children ?? []}
            refetch={fetchEducationData}
          />
        ) : (
          <ListEducationCourse
            courses={educationData.value ?? []}
            handleOpenCourse={handleOpenCourse}
          />
        )}

        {/*<ListEducationSections educationItems={educationData.value ?? []} />*/}
        {/* <Education educationItems={educationData.value ?? []} />*/}
      </PageContent>
    </>
  );
};

export default EducationPage;

import React from "react";
import "./NotificationCenter.scss";
import { Badge, IconButton, Popover, Tooltip } from "../../uiKit";
import { FiBell } from "@react-icons/all-files/fi/FiBell";
import { observer } from "mobx-react-lite";
import { NotificationCenterPopover } from "./notificationCenterPopover/NotificationCenterPopover";
import { useTranslation } from "react-i18next";
import { INotificationCenterViewProps } from "../../../functional/types";


function NotificationCenterViewObserved(props: INotificationCenterViewProps) {
  const { t } = useTranslation();

  return (
    <Popover
      open={props.isOpen}
      overlayClassName="notification-center__wrapper"
      placement="bottomRight"
      trigger={["click"]}
      destroyTooltipOnHide
      overlayInnerStyle={{ padding: "0", overflow: "hidden" }}
      color={"var(--color-srf-primary)"}
      content={
        <NotificationCenterPopover
          currentTab={props.currentTab}
          isUnreadOnly={props.isUnreadOnly}
          unreadMessagesCount={props.unreadNotificationsCount}
          notificationData={props.notificationData}
          notificationsWithInvertedReadStatusIds={props.notificationsWithInvertedReadStatusIds}
          loadingNotificationsIds={props.loadingNotificationsIds}
          isMarkAllAsReadLoading={props.isMarkAllAsReadLoading}
          onCurrentTabChange={props.onCurrentTabChange}
          onUnreadOnlyChange={props.onUnreadOnlyChange}
          onNotificationClick={props.onNotificationClick}
          onMarkAllAsReadClick={props.onMarkAllAsReadClick}
          onMarkAsReadUnreadClick={props.onMarkAsReadUnreadClick}
          onLoadMore={props.onLoadMore}
        />
      }
      onOpenChange={props.onOpenChange}
    >
      {/*offset={[-10, 4]} dot={true} blink*/}
      <Tooltip
        title={t("parse:np_new_notification")}
        placement="bottom"
        // align={{ offset: [0, 4] }}
        open={props.isNewNotificationTooltipOpen}
        overlayClassName="no-interact"
      >
        <Badge
          count={props.unreadNotificationsCount.needActions}
          overflowCount={9}
          style={{ padding: "0 4px", pointerEvents: "none" }}
          size="small"
          offset={props.unreadNotificationsCount.all > 9 ? [-8, 7] : [-9, 7]}
        >
          <IconButton className="flex-shrink-0" onClick={() => {}}>
            <FiBell fontSize={24} />
          </IconButton>
        </Badge>
      </Tooltip>
    </Popover>
  );
}

export const NotificationCenterView = observer(NotificationCenterViewObserved);

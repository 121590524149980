import React from "react";
import { ShowUpdateNoteViewDialog } from "./ShowUpdateNoteViewDialog";
import {
  useShowUpdateNoteDialog
} from "../../../../functional/hooks";

export const ShowUpdateNoteDialog = () => {
  const {
    editorRef,
    isLoadingCheckNext,
    handleCloseDialog,
    handleIncrementCurrentNote,
    noCheckedNotes,
    currentIndexNote,
    isOpenDialog,
  } = useShowUpdateNoteDialog();

  return (
    <ShowUpdateNoteViewDialog
      ref={editorRef}
      isLoadingCheckNextBtn={isLoadingCheckNext}
      onClose={handleCloseDialog}
      onCheck={handleIncrementCurrentNote}
      noCheckedNotes={noCheckedNotes}
      currentNote={noCheckedNotes[currentIndexNote]}
      currentIndexNote={currentIndexNote}
      isOpen={isOpenDialog}
    />
  );
};

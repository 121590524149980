import React, { memo, useCallback, useEffect, useMemo } from "react";
import { ICreateRegulationSectionFormView } from "./CreateRegulationSectionForm.interface";
import { useTranslation } from "react-i18next";
import { Button, Empty, Icon, TextArea } from "../../../uiKit";
import { SortTable } from "../../../elements/SortTable/SortTable";
import dayjs from "dayjs";
import { DocIcon, FolderOpenIcon } from "../../../../functional/constants/icon";
import { UserInfo } from "../../../elements/userInfo/UserInfo";
import { ActionButton } from "../../pages/regulation/components/ButtonIcons/ActionButton";
import { useRootStore } from "../../../../functional/hooks";
import type { ColumnsType } from "antd/es/table";
import { ISortTableDataType } from "../../../elements/SortTable/SortTable.interface";
import { REGULATION_TABLE_COLUMNS } from "../../../../functional/constants/regulationTableColumns";
import { useSectionHooks } from "../../pages/regulation/hooks/useSectionHooks";

export const CreateRegulationSectionFormView = memo((props: ICreateRegulationSectionFormView) => {
  const { t } = useTranslation();
  const { authStore } = useRootStore();

  const { handleClickCopyLink, getStatusCell } = useSectionHooks();

  const handleRemoveRegular = useCallback(async (id, isRegulation) => {
    props.onRemoveNewRegulation(id);
  }, []);

  const sectionChilds = useMemo(() => {

    return (props.section?.items ?? [])
      .map((item) => {
        const {
          regulationId,
          stats,
          createdByUser,
          id,
          regulation,
        } = item;
        const { studiedPercent } = stats ?? {};
        const {
          createdByUser: createdByUserRegulation,
          datePublish: datePublishRegulation,
          state,
        } = regulation ?? {};
        if (!regulationId) {
        }
        const publicationDate = datePublishRegulation ? dayjs(datePublishRegulation)
          .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
          .format("LL") : null;
        return {
          ...item,
          statusCell: getStatusCell(regulationId ? state : studiedPercent, !!regulationId),
          name: (
            <div
              className="section-content-cell-title"
            >
              <div className="section-content-cell-title__icon">
                { regulationId ? (<DocIcon /> ): (<FolderOpenIcon size={20}/>) }
              </div>
              { item.name }
            </div>
          ),
          user: (
            <UserInfo
              nameStyle={{ fontWeight: "bold" }}
              avatarSize={25}
              user={regulationId ? createdByUserRegulation : createdByUser }
            />
          ),
          publicationDate,
          action: (
            <ActionButton
              isRegulation={!!regulation}
              isRemoveFromSection={!props.section?.id}
              isCopyLink={true}
              onRemoveFromSection={() => handleRemoveRegular(id, !!regulationId)}
              onCopyLink={() =>
                handleClickCopyLink(item.id ?? regulationId, !!regulationId, props.section?.id)}
            />
          ),

        };
      });

  }, [props.section?.items]);

  const columns: ColumnsType<ISortTableDataType> = useMemo(() => {
    return REGULATION_TABLE_COLUMNS.map((item) => ({
      ...item,
      title: t(item.title),
    }));
  }, []);

  const handleDragEnd = useCallback((id: number, overId: number, ids?: number[]) => {
    props.onMoveRegulations(ids ?? []);
  }, []);

  const handleChangeValue = (event: any) => {
    props.onChangeTitleSection(event.target.value as string);
  };

  return (
    <div className="create-regulation-section-form">
      <div className="create-regulation-section-form__header d-stack-row d-stack-row justify-space-between align-center">
          <TextArea
            autoFocus
            isHiddenBorder
            autoSize={{ maxRows: 6 }}
            value={props.titleSection}
            placeholder={t("ui:placeholder.section_name")}
            onInput={handleChangeValue}
          />
      </div>
      {sectionChilds.length ? (
        <SortTable
          items={sectionChilds}
          columns={columns}
          handleDragEnd={handleDragEnd}
        />
      ) : (
        <Empty
          className="mt-4"
          description={t("ui:section.empty_data")}
        >
            <Button
              // variant="text"
              onClick={props.onOpenAddingRegulation}
            >
              {t("ui:button.add")}
            </Button>

        </Empty>
      )}
     </div>
  );
});

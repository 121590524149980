import React, { useCallback, useMemo, useRef, useState } from "react";
import { OrgchartDto } from "../../../functional/api";
import { Avatar, AvatarGroup, Button, Divider, Dropdown, Icon, Popover, Select, Text } from "../../uiKit";
import { truncateString } from "../../../functional/helpers/stringFunctions";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import { OrgchartCreateEditDialog } from "../../modules/dialogs/orgchartCreateEditDialog/OrgchartCreateEditDialog";
import { ISelect } from "../../uiKit/select/Select";
import { useTranslation } from "react-i18next";
import { useClaims } from "../../../functional/hooks";
import { permissionKeys } from "../../../functional/utils/permissions";
import "./OrgchartSwitcher.scss";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import clsx from "clsx";

interface IOrgchartSwitcherView {
  drawType?: "avatarsWithMenu" | "textWithMenu" | "sortTextWithMenu" | "select";
  state: OrgchartDto[];
  createEditDialogValue?: OrgchartDto;
  currentOrgchartId: number | null | undefined;
  isCreateDialogOpen: boolean;
  value?: number[] | number;
  useAsFilter?: boolean;
  selectProps?: ISelect;
  isViewUploadFile?: boolean;

  onSelectAllClick?: () => void;
  setIsCreateDialogOpen: (value: boolean) => void;
  onCreateSuccess: (orgchartId: number) => void;
  onChange: (orgchartId: number | number[]) => void;
  onCreateEditDialogClose?: () => void;
  onChangePosition?: (orgchartId: number, oldIndex: number, newIndex: number) => void;
}

const SwitcherSelect = (props: IOrgchartSwitcherView) => {
  return props.state.length == 0 ? (
    // <Text style={{ fontWeight: "bold" }} children="Tonnus" />
    null
  ) : (
    <Select
      {...props.selectProps}
      value={props.value}
      autoFocus={false}
      defaultActiveFirstOption={false}
      onChange={(value) => props.onChange(value)}
      dropdownRender={(menu) => (
        <>
          {props.useAsFilter && (
            <>
              <Button
                onClick={() => props.onSelectAllClick && props.onSelectAllClick()}
                className="full-width"
                variant="text"
              >
                Все
              </Button>
              <Divider className="mb-1" />
            </>
          )}
          {menu}
        </>
      )}
      items={[
        ...props.state.map((o: OrgchartDto) => ({
          key: (o.id as number).toString(),
          value: o.id as number,
          text: truncateString(o.name as string, 35, "end"),
          icon: <Avatar text={o.name} size="small" color={o.colorHex} src={o.image?.url} />,
          // icon: <Icon component={() => <Avatar text={o.name} size="small" color={o.colorHex} src={o.image?.url} />} />,
        })),
      ]}
    />
  );
};



const SwitcherSortingTextWithMenu = (props: IOrgchartSwitcherView) => {
  const { t } = useTranslation();
  const claims = useClaims();

  const orgchartList = useRef<HTMLDivElement>(null);
  const [isOpenPopover, setIsOpenPopover] = useState(false);

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;
    const newIndex = destination?.index ?? 0;
    const oldIndex = source.index;
    console.log("result", result);
    props.onChangePosition?.(+draggableId, oldIndex, newIndex);
    // onChangePosition
    if (orgchartList.current) {
      orgchartList.current.style.width = "";
      orgchartList.current.style.height = "";
    }
  }

  const onBeforeDragStart = () => {
    const { offsetWidth, offsetHeight } = orgchartList.current ?? {};
    if (orgchartList.current) {
      orgchartList.current.style.width = `${offsetWidth}px`;
      orgchartList.current.style.height = `${offsetHeight}px`;
    }
  };

  const handleOpenCreateDialog = useCallback(() => {
    setIsOpenPopover(false);
    props.setIsCreateDialogOpen(true);
  }, []);

  const handleChangeOrgchart = useCallback((id: number) => {
    setIsOpenPopover(false);
    props.onChange(id);
  }, []);

  const isEditClaims = useMemo(() => {
    return claims.has(permissionKeys.orgchart.role.edit);
  }, []);

  return props.state.length == 0 ? (
    // <Text style={{ fontWeight: "bold" }} children="Tonnus" />
    null
  ) : (
    <Popover
      className={"orgchart-switcher-content"}
      open={isOpenPopover}
      onOpenChange={setIsOpenPopover}
      placement="bottomLeft"
      content={(
        <DragDropContext
          onDragEnd={onDragEnd}
          onBeforeDragStart={onBeforeDragStart}
        >
          <div
            ref={orgchartList}
            className={"orgchart-switcher-content-list"}
          >
            <Droppable
              droppableId="orgchart-switcher-content-list"

            >
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {props.state.map((opt: OrgchartDto, index) => (
                    <Draggable
                      key={opt.id}
                      draggableId={(opt.id!).toString()}
                      index={index}
                      isDragDisabled={!isEditClaims}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{ ...provided.draggableProps.style }}
                          onClick={() => handleChangeOrgchart(opt.id as number)}
                        >
                          <div
                            className={clsx("orgchart-switcher-item", {
                              "orgchart-switcher-item_active": opt.id == props.currentOrgchartId
                            })}
                            style={{ cursor: "pointer" }}
                          >
                            <Avatar
                              isOutlined={Array.isArray(props.value) && props.value?.some((v) => v == opt.id)}
                              text={opt.name}
                              size="small"
                              src={opt.image?.url}
                              color={opt.colorHex}
                            />
                            <Text
                              style={{ fontSize: 14 }}
                              children={truncateString(opt.name as string, 35, "end")}
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
            {/*{ key: "div1", isDivider: true },*/}
          </div>
          {isEditClaims && (
            <div
              className={"orgchart-switcher-item"}
              style={{ cursor: "pointer" }}
              onClick={handleOpenCreateDialog}
            >
              <Icon
                style={{ verticalAlign: "middle" }}
                component={() => <FiPlus />}
              />
              {t("parse:create_orgchart")}
            </div>
          )}
        </DragDropContext>
      )}
      arrow={{ pointAtCenter: false }}
      trigger="click"
    >
      <div className="d-stack spacing-2 align-center" style={{ cursor: "pointer" }}>
        <Avatar
          text={props.state.find((o: OrgchartDto) => o.id == props.currentOrgchartId)?.name}
          src={props.state.find((o: OrgchartDto) => o.id == props.currentOrgchartId)?.image?.url}
          color={props.state.find((o: OrgchartDto) => o.id == props.currentOrgchartId)?.colorHex}
          size={24}
        />
        <Text
          style={{ fontWeight: "bold" }}
          children={truncateString(
            props.state.find((o: OrgchartDto) => o.id == props.currentOrgchartId)?.name ?? "",
            25
          )}
        />
        <FiChevronDown />
      </div>
    </Popover>
  )
}

const SwitcherTextWithMenu = (props: IOrgchartSwitcherView) => {
  const { t } = useTranslation();
  const claims = useClaims();

  return props.state.length == 0 ? (
    // <Text style={{ fontWeight: "bold" }} children="Tonnus" />
    null
  ) : (
    <Dropdown
      destroyPopupOnHide
      items={[
        ...props.state.map((o: OrgchartDto) => ({
          key: (o.id as number).toString(),
          text: truncateString(o.name as string, 35, "end"),
          disabled: props.currentOrgchartId == o.id,
          icon: (
            <Avatar
              isOutlined={Array.isArray(props.value) && props.value?.some((v) => v == o.id)}
              text={o.name}
              size="small"
              src={o.image?.url}
              color={o.colorHex}
            />
          ),
          onClick: () => props.onChange(o.id as number),
        })),
        { key: "div1", isDivider: true },
        {
          key: "create_btn",
          text: t("parse:create_orgchart"),
          onClick: () => props.setIsCreateDialogOpen(true),
          icon: <FiPlus />,
        },
      ].filter((m) => (claims.has(permissionKeys.orgchart.add) ? m : m.key != "create_btn" && m.key != "div1"))}
      trigger={["hover"]}
      overlayClassName="orgchart_switcher-overlay"
      // overlayStyle={{ maxHeight: "50vh", overflow: "auto" }}
      // overlayStyle={{ maxWidth: "400px" }}
    >
      <div className="d-stack spacing-2 align-center" style={{ cursor: "pointer" }}>
        <Avatar
          text={props.state.find((o: OrgchartDto) => o.id == props.currentOrgchartId)?.name}
          src={props.state.find((o: OrgchartDto) => o.id == props.currentOrgchartId)?.image?.url}
          color={props.state.find((o: OrgchartDto) => o.id == props.currentOrgchartId)?.colorHex}
          size={24}
        />
        <Text
          style={{ fontWeight: "bold" }}
          children={truncateString(
            props.state.find((o: OrgchartDto) => o.id == props.currentOrgchartId)?.name ?? "",
            25
          )}
        />
        <FiChevronDown />
      </div>
    </Dropdown>
  );
};

const SwitcherAvatarsWithMenu = (props: IOrgchartSwitcherView) => {
  const { t } = useTranslation();

  return props.state.length == 0 ? (
    // <Text style={{ fontWeight: "bold" }} children="Tonnus" />
    null
  ) : (
    <Dropdown
      items={[
        ...(props.useAsFilter
          ? [
              {
                key: "select_all",
                text: t("parse:all"),
                onClick: () => props.onSelectAllClick && props.onSelectAllClick(),
              },
              { key: "div2", isDivider: true },
            ]
          : []),
        ...props.state.map((o: OrgchartDto) => ({
          key: (o.id as number).toString(),
          text: truncateString(o.name as string, 35, "end"),
          disabled: props.useAsFilter ? false : props.currentOrgchartId == o.id,
          icon: (
            <Avatar
              isOutlined={Array.isArray(props.value) && props.value?.some((v) => v == o.id)}
              text={o.name}
              src={o.image?.url}
              size="small"
              color={o.colorHex}
            />
          ),
          onClick: () => props.onChange(o.id as number),
        })),
      ]}
      trigger={["hover"]}
      overlayClassName="orgchart_switcher-overlay"
      // overlayStyle={{ maxWidth: "400px" }}
    >
      <div style={{ cursor: "pointer" }}>
        <AvatarGroup maxCount={5}>
          {props.state.map((o: OrgchartDto) => (
            <Avatar
              // size="small"
              // isOutlined={props.currentOrgchartId == o.id}
              src={o.image?.url}
              key={o.id}
              text={o.name}
              color={o.colorHex}
            />
          ))}
        </AvatarGroup>
      </div>
    </Dropdown>
  );
};

export function OrgchartSwitcherView(props: IOrgchartSwitcherView) {
  const [isUploadJson, setIsUploadJson] = useState<boolean>(false);

  const handleUploadJson = useCallback(() => {
    props.setIsCreateDialogOpen(true);
    setIsUploadJson(true);
  }, []);


  return (
    <>
      <OrgchartCreateEditDialog
        open={props.isCreateDialogOpen}
        isUploadJson={isUploadJson}
        orgchartData={props.createEditDialogValue}
        onSuccess={(orgchartId: number) => {
          props.onCreateSuccess(orgchartId);
        }}
        onClose={() => {
          props.setIsCreateDialogOpen(false);
          props.onCreateEditDialogClose && props.onCreateEditDialogClose();
          setIsUploadJson(false);
        }}
      />
      {props.drawType == "select" && SwitcherSelect(props)}
      {props.drawType == "avatarsWithMenu" && SwitcherAvatarsWithMenu(props)}
      {props.drawType == "textWithMenu" && SwitcherTextWithMenu(props)}
      {props.drawType == "sortTextWithMenu" && SwitcherSortingTextWithMenu(props)}
      {props.isViewUploadFile && (
        <Button
          style={{ marginLeft: 15 }}
          onClick={handleUploadJson}
        >
          Импортировать оргструктуру
        </Button>
      )}
    </>
  );
}

import React, { memo, useEffect, useState } from "react";
import { DashboardChartView, TDashboardChartViewChartsData } from "./DashboardChartView";
import { removeDuplicatesFromArrayByKey } from "../../../../../functional/helpers/arrayFunctions";
import { MetricDividerDto, MetricReadDto } from "../../../../../functional/api";

export type TDashboardChartDataItem = {
  id: number;
  dateISO: string;
  label: string;
  charts: {
    quota: number | null;
    line: { id: number; name: string; color?: string; isReadOnly?: boolean; value: number | null }[];
  };
};

interface IDashboardChart {
  data: TDashboardChartDataItem[];
  metricId: number;
  metricSource2UserId?: number;
  metricData: MetricReadDto;
  yDividers: MetricDividerDto[];
  isReadOnly?: boolean;
  isReversed?: boolean;
  isLegendEnabled?: boolean;
  isColored?: boolean;
  viewSettings?: {
    min: number | null;
    max: number | null;
    isTrendActive: boolean;
    isLinesColorActive: boolean;
  };
  // isSegmentColorHighlightingEnabled?: boolean;

  onChange?: (itemId: number) => void;
  isPrint?: boolean;
  // onChange?: (value: TDashboardChartDataItem) => void;
}

export const METRIC_COLORS = {
  line: [
    {
      normal: "var(--color-metric-line-1-normal)",
      increase: "var(--color-metric-line-1-increase)",
      decrease: "var(--color-metric-line-1-decrease)",
    },
    {
      normal: "var(--color-metric-line-2-normal)",
      increase: "var(--color-metric-line-2-increase)",
      decrease: "var(--color-metric-line-2-decrease)",
    },
  ],
  quota: {
    normal: "var(--color-metric-quota-normal)",
  },
  trend: [
    { increase: "var(--color-metric-trend-1-increase)", decrease: "var(--color-metric-trend-1-decrease)" },
    { increase: "var(--color-metric-trend-2-increase)", decrease: "var(--color-metric-trend-2-decrease)" },
  ],
};

export const DashboardChart = memo((props: IDashboardChart) => {
  const [chartsData, setChartsData] = useState<TDashboardChartViewChartsData>({
    lineCharts: [],
    chartItemsIds: [],
    quotaChart: [],
  });

  const mapPropsChartsDataToViewChartsData = (
    data: TDashboardChartDataItem[] | null
  ): TDashboardChartViewChartsData => {
    if (data == null || data.length == 0) {
      return {
        lineCharts: [],
        chartItemsIds: [],
        quotaChart: [],
      };
    }
    const allLineCharts: { id: number; name: string; color?: string }[] = removeDuplicatesFromArrayByKey(
      data.map((d) => d.charts.line).flat(),
      "id"
    );
    let lineChartsTemp: {
      id: number;
      name: string;
      color?: string;
      isReadOnly?: boolean;
      data: { itemId: number; date: string; label: string; value: number | null }[];
    }[] = allLineCharts.map((c) => ({ id: c.id, name: c.name, color: c.color, data: [] }));
    data.forEach((d) => {
      d.charts.line.forEach((l, i) => {
        //
        let chartTemp = lineChartsTemp.find((c) => c.id == l.id) as {
          id: number;
          name: string;
          color?: string;
          isReadOnly?: boolean;
          data: { itemId: number; date: string; label: string; value: number | null }[];
        };
        chartTemp = {
          ...chartTemp,
          color: METRIC_COLORS.line[i]?.normal ?? 'transparent',
          data: [...chartTemp.data, { itemId: d.id, date: d.dateISO, label: d.label, value: l.value }],
        };
        lineChartsTemp = lineChartsTemp.map((t) => (t.id == chartTemp.id ? chartTemp : t));
      });
    });

    return {
      chartItemsIds: props.data.map((d) => d.id),
      lineCharts: lineChartsTemp,
      quotaChart: data.map((d) => ({ itemId: d.id, date: d.dateISO, label: d.label, value: d.charts.quota })),
    };
  };

  const handleChange = async (itemId: number) => {
    props.onChange && props.onChange(itemId);
  };

  useEffect(() => {
    setChartsData(mapPropsChartsDataToViewChartsData(props.data));
  }, [props.data]);

  return (
    <DashboardChartView
      data={props.data}
      metricData={props.metricData}
      metricId={props.metricId}
      metricSource2UserId={props.metricSource2UserId}
      yDividers={props.yDividers}
      chartsData={chartsData}
      isReversed={props.isReversed}
      isReadOnly={props.isReadOnly}
      isColored={props.isColored}
      isLegendEnabled={props.isLegendEnabled}
      viewSettings={props.viewSettings}
      onChange={handleChange}
      isPrint={props.isPrint}
    />
  );
});

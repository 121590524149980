import React from "react";
import { IconButton, Tooltip } from "../../../../../uiKit";
import { IRegulationIcons } from "./RegulationIcons.interface";
import { useTranslation } from "react-i18next";
import { EditIcon } from "../../../../../../functional/constants/icon";

export const EditButton: React.FC<IRegulationIcons> = (props) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t("ui:tooltip.edit")}>
      <IconButton
        className="regulation-button-icons"
        disabled={props.disabled}
        onClick={props.onClick}
        isShowBorder={true}
      >
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
};

import { AppTabsByPage } from "../../../../../../functional/utils/appLinksAndTabs";
import { TabPane, Tabs } from "../../../../../uiKit";
import { IPlanningPageAdminHeaderView } from "./PlanningPageAdminHeader.interface";
import "./PlanningPageAdminHeader.scss";

export const PlanningPageAdminHeaderView = (props: IPlanningPageAdminHeaderView) => {
  return (
    <div className="planning-page-admin-header_tabs">
      <Tabs tabBarStyle={{ marginBottom: "0" }} activeKey={props.url}>
        {AppTabsByPage["planning"].map((tab) => (
          <TabPane key={tab.link ?? props.allTabs.find((t) => t.key == tab.key)?.link} tab={props.onTabNameGet(tab)} />
        ))}
      </Tabs>
    </div>
  );
};

import React from "react";
import { IPlanningPageMenu } from "./PlanningPageMenu.interface";
import { IconButton, Select, Text } from "../../../../../uiKit";
import { formatDateToDateString } from "../../../../../../functional/helpers/formatFunctions";
import { useTranslation } from "react-i18next";
import { UserCard } from "../../../../../elements/userCard/UserCard";
import { Label } from "../../../../../uiKit/label/Label";
import { colorSchemeType } from "../../../../../../functional/utils/colorScheme";
import { GoArrowLeft } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { PlaningFilterType } from "../../../../../../functional/constants/PlaningFilterType";
import "./PlanningPageWorkPlanMenu.scss";
import { ISelectItem } from "../../../../../../functional/types/Select.Interface";

export const PlanningPageWorkPlanMenu = (props: IPlanningPageMenu) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClickGoToBack = () => {
    const uri =  props.isApproval
      ? "/communication/require-approval"
      : "/communication/work-plans";
    navigate(uri);
  };

  const groupingList: ISelectItem[] = [
      {
        id: 0,
        text: t("ui:planingGrouping.roles"),
        value: PlaningFilterType.roles,
      },
      {
        id: 1,
        text: t("ui:planingGrouping.tags"),
        value: PlaningFilterType.tags,
      },
      {
        id: 2,
        text: t("ui:planingGrouping.no_grouping"),
        value: PlaningFilterType.no_grouping,
      },
   ];

  const handleChangeGroupingType = (value: PlaningFilterType) => {
    props.onChangeGroupingType?.(value);
  };

  return (
    <>
      <IconButton
        onClick={handleClickGoToBack}
        className="page-work-back mb-2"
      >
        <GoArrowLeft />
      </IconButton>
      <div className="d-stack-row justify-space-between mb-3">
        <Text
          weight="bold"
          className="planning-page-main-content__title mr-6"
          children={`${t("ui:subheader.week_plan")} ${formatDateToDateString(
            new Date(props.plan?.dateFrom as string)
          )} - ${formatDateToDateString(new Date(props.plan?.dateTo as string))}`}
        />
        <div className="d-stack-row align-center">
          {/*<UserCard userInfo={props.plan?.user} />*/}
          { !!props.plan?.status && (
            <Label
              type={props.plan?.statusColorScheme as colorSchemeType}
              text={t(`ui:plan_status.type${props.plan.status}`)}
            />
          )}
        </div>
      </div>
      <UserCard userInfo={props.plan?.user} />
      <div className={"mt-4 mb-4"}>
        <Select
          className={"planing-group-type"}
          style={{
            width: 180,
            height: 36,

          }}
          size="middle"
          value={props.groupingType}
          onChange={handleChangeGroupingType}
          items={groupingList}
          isNewDesign
        />
      </div>
    </>
  );
};

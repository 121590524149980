import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import "./CreateRegulationSectionForm.scss";
import { CreateRegulationSectionFormView } from "./CreateRegulationSectionFormView";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AutocompleteModel, RegulationDto } from "../../../../functional/api";
import { useNotifier, useRootStore, useDebounce } from "../../../../functional/hooks";
import { SettingUpAccessDialog } from "../../dialogs/settingUpAccessDialog/SettingUpAccessDialog";
import { UserSelectionRule } from "../../../../functional/api/types/userSelectionRule";
import { observer } from "mobx-react-lite";
import { ICreateRegulationSectionForm } from "./CreateRegulationSectionForm.interface";
import { keysRulesFor } from "../../dialogs/settingUpAccessDialog/SettingUpAccessDialog.interface";
import { GlobalAuthorizedContext } from "../../../../functional/contexts/globalAuthorizedContext";
import {
  AddingRegulationsToSectionDialog
} from "../../dialogs/addingRegulationsToSectionDialog/AddingRegulationsToSectionDialog";
import { NavigationMenuItemDto } from "../../../../functional/api/models/NavigationMenuDto";
import {
  RegulationAccessControlDialog
} from "../../dialogs/regulationAccessControlDialog/RegulationAccessControlDialog";
import { SectionContext } from "../../../../functional/contexts/RegulationPage/SectionContext";

const CreateRegulationSectionFormObserver: React.FC<ICreateRegulationSectionForm> = (props) => {
  const {
    handleSave,
    newRegulations,
    setNewRegulations,
    setUserSelectionRules,
    titleSection,
    setTitleSection,
    userSelectionRules,
    isRequestLoading,
    isOpenAccessSettingsDialog,
    isOpenAssignForStudyDialog,
    handleOpenAssignForStudyDialog,
    handleCloseAssignForStudyDialog,
    handleCloseAccessSettingsDialog,
    handleCancel,
    setRegulationIds,

    isOpenAddingRegulations,
    handleCloseAddingRegulation,
    handleOpenAddingRegulation,
    handleAddedRegulationsToSection,
    getRegulations,
    setSection,
  } = useContext(SectionContext);

  const globalAuthorizedContext = useContext(GlobalAuthorizedContext);

  const notifier = useNotifier();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { regulationStore } = useRootStore();

  const [initialFetchIsFinish, setInitialFetchIsFinish] = useState<boolean>(false);

  const [isChanged, setIsChanged] = useState<boolean>(false);


  const [activeSectionIdFilter, setActiveSectionIdFilter] = useState<number | undefined>(undefined);
  const [regulationSearchValue, setRegulationSearchValue] = useState<string>("");

  const debouncedSearchValue = useDebounce(regulationSearchValue, 400);

  const commonRegulations = regulationStore.getAutocompleteRegulations;
  const sections = regulationStore.getAutocompleteSections;
  const regulationsIsUploaded = regulationStore.getRegulationAutocompletePagingOptions.pageIsLast;

  const totalItems = useMemo(() => {
    return regulationStore.getRegulationAutocompletePagingOptions.totalItems ?? 0;
  }, [regulationStore.getRegulationAutocompletePagingOptions.totalItems])

  const handleError = () => {
    notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
  };

  const handleRedirectToCreateRegulation = useCallback(() => navigate("/regulation/create-regulation"), []);

  const handleChangeActiveSectionFilter = useCallback((newValue: number) => setActiveSectionIdFilter(newValue), []);

  const handleChangeRegulationSearchValue = useCallback(
    (newValue: string) => setRegulationSearchValue(newValue)
    , []);
  const handleChangeTitleSection = (newValue: string) => {
    setTitleSection(newValue);
    setIsChanged(true);
  };

  const unmountClean = () => {
    setRegulationSearchValue("");
    regulationStore.setRegulationsAutoComplete([]);
    regulationStore.resetRegulationAutocompleteOptions();
  };

  const handleAppendNewRegulation = useCallback((ids: number[]) => {
    const currentRegulations = commonRegulations.filter((item) => ids.includes(item.id!));
    currentRegulations.length && setNewRegulations((prev: AutocompleteModel[]) => [...prev, ...currentRegulations]);
    setIsChanged(true);
  }, [commonRegulations]);

  const handleRemoveNewRegulation = useCallback((id: number) => {
    setRegulationIds((prev: number[]) =>
      prev.filter((item) => item !== id)
    );
    props.removeRegulation && props.removeRegulation(id);
  }, []);

  const handleMoveRegulations = useCallback((ids: number[]) => {
    setRegulationIds(ids);
  }, []);

  const handleSaveAccessSettings = async (rules: UserSelectionRule[]): Promise<boolean> => {
    setUserSelectionRules([...rules]);
    handleCloseAssignForStudyDialog();
    return true;
  };

  const handleLoadNextRegulations = () => regulationStore.fetchNextPageRegulationAutocomplete();

  useEffect(() => {
    if (initialFetchIsFinish) {
      regulationStore.setAutocompleteRegulationFilters({
        sectionId: activeSectionIdFilter,
        hasActiveContent: true,
      });
      regulationStore.initialFetchRegulationAutocomplete(true);
    }
  }, [activeSectionIdFilter]);

  useEffect(() => {
    if (initialFetchIsFinish) {
      regulationStore.setAutocompleteRegulationFilters({
        name: regulationSearchValue.length > 0 ? regulationSearchValue : undefined,
      });
      regulationStore.initialFetchRegulationAutocomplete(true);
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    regulationStore.setAutocompleteRegulationFilters({ hasActiveContent: true });
    regulationStore.setAutocompleteSectionFilters({ pageSize: 50 });
    regulationStore.setOnError(handleError);
    //
    // if (props.toEdit?.isEdit && props.toEdit.currentRegulations) {
    //   setNewRegulations(props.toEdit.currentRegulations);
    //   setTitleSection(props.toEdit.sectionTitle ?? "");
    //   setUserSelectionRules(props.toEdit.userSelectionRules);
    // }

    const promises: Promise<void>[] = [
      regulationStore.initialFetchRegulationAutocomplete(),
      regulationStore.initialFetchSectionAutocomplete(),
    ];

    Promise.all(promises)
      .then((results) => {
        setInitialFetchIsFinish(true);
      })
      .catch((err) => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" }));

    return unmountClean;
  }, []);

  const handleClickSave = useCallback(() => {
    handleSave(props.toEdit);
  }, [props.toEdit]);

  const handleClickCancel = useCallback(() => {
    handleCancel(props.toEdit);
  }, [props.toEdit]);

  const handleAddedRegular = useCallback(async (regulations: number[], sections: number[]) => {
    if (props.toEdit?.isEdit) {
      const regulationsList: RegulationDto[] = await getRegulations(regulations, sections);
      const list: NavigationMenuItemDto[] = regulationsList.map((item) => {
        const userSelectionRules = item.userSelectionRules as UserSelectionRule[];
        return {
          ...item,
          userSelectionRules,
          id: item.id ?? -1,
          name: item.name ?? "",
          order: -1,
          breadcrumbs: [],
          isFavorite: false,
          regulationId: item.id,
          regulation: item,
          allowedActions: item.actions ?? [],
        }
      });

      const status = await handleAddedRegulationsToSection(regulations, sections);
      if (status) {
        setSection((prev)=> {
            return prev
              ? { ...prev, items: [...list, ...(prev.items ?? [])] }
              : null
          }
        );
        handleCloseAddingRegulation();
      }
    } else {
      const regulationsList: RegulationDto[] = await getRegulations(regulations, sections);
      const list = regulationsList.map((item) => ({
        ...item,
        regulationId: item.id,
        regulation: item,
      }));
      // @ts-ignore
      props.addedRegulations && props.addedRegulations(list as NavigationMenuItemDto[]);
      setRegulationIds(regulationsList.map((item) => item.id!));
      // setNavigationMenuItemIds(sections);
      handleCloseAddingRegulation();
      // globalAuthorizedContext?.regulation?.reloadSidebarData?.();
    }
  }, []);

  return (
    <>
      {isOpenAssignForStudyDialog && !props.toEdit?.isEdit && (
        <SettingUpAccessDialog
          keyRuleTo={keysRulesFor.section}
          currentRules={userSelectionRules}
          // onSave={handleSaveAccessSettings}
          isOpen={isOpenAssignForStudyDialog}
          onClose={handleCloseAssignForStudyDialog}
        />
      )}
      {isOpenAccessSettingsDialog && !props.toEdit?.isEdit && (
        <RegulationAccessControlDialog
          isOpen={isOpenAccessSettingsDialog}
          onClose={handleCloseAccessSettingsDialog}
          createdByUser={props.section?.createdByUser}
        />
      )}
      { isOpenAddingRegulations && !props.toEdit?.isEdit && (
        <AddingRegulationsToSectionDialog
          onAddedRegular={handleAddedRegular}
          section={props.section}
          onClose={handleCloseAddingRegulation}
        />
      )}
      <CreateRegulationSectionFormView
        isChanged={isChanged}
        isEdit={!!props.toEdit?.isEdit}
        isLoading={regulationStore.getAutocompleteLoading}
        isRequestLoading={isRequestLoading}
        regulationsIsUploaded={regulationsIsUploaded}
        totalItems={totalItems}
        activeSectionIdFilter={activeSectionIdFilter}
        titleSection={titleSection}
        sections={sections}
        commonRegulations={commonRegulations}
        newRegulations={newRegulations}
        regulationSearchValue={regulationSearchValue}
        onChangeRegulationSearchValue={handleChangeRegulationSearchValue}
        onChangeTitleSection={handleChangeTitleSection}
        onAppendNewRegulation={handleAppendNewRegulation}
        onRemoveNewRegulation={handleRemoveNewRegulation}
        onRedirectToCreateSection={handleRedirectToCreateRegulation}
        onResetNewRegulations={handleClickCancel}
        onChangeActiveSectionFilter={handleChangeActiveSectionFilter}
        onSave={handleClickSave}
        onMoveRegulations={handleMoveRegulations}
        onLoadNextRegulations={handleLoadNextRegulations}
        onOpenAddingRegulation={handleOpenAddingRegulation}
        onOpenDialog={handleOpenAssignForStudyDialog}
        section={props.section}
      />
    </>
  );
};

export const CreateRegulationSectionForm = observer(CreateRegulationSectionFormObserver);

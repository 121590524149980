import React from "react";
import { FiArrowRight } from "@react-icons/all-files/fi/FiArrowRight";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { findId } from "../../../../../../functional/helpers/pathname";
import { regulationContentTypeKeys } from "../../../../../../functional/constants/regulation/headerTools";
import { Button, Icon } from "../../../../../uiKit";

export const RedirectedRegulationButtons = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const location: any = useLocation();

  // if (!location.state || Object.keys(location.state).length === 0) return null;

  const id = findId(pathname, 1);

  const fromSectionId: number | undefined = location.state?.fromSectionId;
  const toLearnRegulationsIds: number[] | undefined = location.state?.noLearntRegulationsIds;
  const allRegulationsIds: number[] | undefined = location.state?.allRegulationsIds;

  const handleGetNextRegulationId = (): number => {
    const nextId = allRegulationsIds!.shift()!;
    return nextId === id ? allRegulationsIds![0] ?? nextId : nextId;
  };

  const handleRedirectToTopic = () => {
    navigate(`/policy/topics/${fromSectionId}`, {
      state: {
        fromRegulationId: id,
      },
    });
  };

  const handleRedirectToNextRegulation = () => {
      // ?contentType=${regulationContentTypeKeys.approved}
    navigate(`/policy/all/${handleGetNextRegulationId()}/`, {
      state: {
        fromSectionId: fromSectionId,
        noLearntRegulationsIds: toLearnRegulationsIds,
        allRegulationsIds: allRegulationsIds?.filter((item) => item !== id),
        fromRegulationId: id,
      },
    });
  };

  return !!fromSectionId || !!allRegulationsIds?.length ? (
      <div className="d-stack-row spacing-2 justify-start align-center mt-2">
        {!!fromSectionId && (
          <Button className="stats-icon" onClick={handleRedirectToTopic} variant="default">
            {t("ui:button.back_to_topic")}
          </Button>
        )}
        {allRegulationsIds?.length && (
          <Button
            // TODO: Check this className
            className="button-icon_17"
            icon={<Icon component={() => <FiArrowRight />} />}
            onClick={handleRedirectToNextRegulation}
            variant="filled"
          >
            {t("ui:button.next_regulation")}
          </Button>
        )}
      </div>
    ) : null
};

import React, { memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./MetricToDashboard.scss";
import { UpdateMetricDialog } from "../../../../dialogs/updateMetricDialog/UpdateMetricDialog";
import { api } from "../../../../../../functional/services";
import { LocalStorageHelpers, useNotifier } from "../../../../../../functional/hooks";
import { useTranslation } from "react-i18next";
import { Card, Dropdown, Icon, IconButton, Popover, Text, Tooltip } from "../../../../../uiKit";
import DeleteMetricOrDashboardDialog from "../../../../dialogs/deleteMetricOrDashboardDialog/DeleteMetricOrDashboardDialog";
import { MetricSettingsDialog } from "../../../../dialogs/metricSettingsDialog/MetricSettingsDialog";
import { DashboardChart, TDashboardChartDataItem } from "../../dashboardChart/DashboardChart";
import {
  MetricDividerDto,
  MetricGraphDto,
  MetricGraphPeriodDto,
  MetricReadDto,
  MetricValueDto,
  UserShortDto
} from "../../../../../../functional/api";
import { useParams } from "react-router-dom";
import { DropdownKeys, TChartSettings } from "./MetricToDashboard";
import { dashboardSectionAndRoutesKeys, dashboardSections } from "../../misc/constants/routesKeys";
import { getDashboardSection } from "../../misc/helpers/urlFunctions";
import { FiHelpCircle } from "@react-icons/all-files/fi/FiHelpCircle";
import { FiMoreVertical } from "@react-icons/all-files/fi/FiMoreVertical";
import { removeDuplicatesFromArrayByKey } from "../../../../../../functional/helpers/arrayFunctions";
import { FiSave } from "react-icons/fi";
import dayjs from "dayjs";
import { UserInfo } from "../../../../../elements/userInfo/UserInfo";
import clsx from "clsx";
import { PrintMetricDialog } from "../../../../dialogs/printMetricDialog/PrintMetricDialog";
import {
  MetricDataDialog
} from "../../../../dialogs/metricDataDialog/MetricDataDialog";
import { IMetricDataData, IMetricPrintData } from "../../../../../../functional/types/MetricDataDialog.interface";
interface IMetricToDashboardView {
  // metric?: MetricSource2UserForDashboardDto;
  // metricSource2UserId?: number;
  metric: MetricReadDto;
  metricSource2UserId?: number;
  deleteMetric: (id: number) => void;
  chartData: TDashboardChartDataItem[];
  handleRestartLoad?: () => void;
  onDropdownKeyChange: (key: DropdownKeys) => void;
  openDeleteMetricDialog: boolean;
  onOpenDeleteMetricDialogChange: (isOpen: boolean) => void;
  openMetricSettingsDialog: boolean;
  onOpenMetricSettingsDialogChange: (isOpen: boolean) => void;
  openMetricDataDialog: boolean;
  onOpenMetricDataDialogChange: (isOpen: boolean) => void;
  popoverContent: ReactNode;
  chartSettings: TChartSettings;
  // chartSettingsFromLS: TChartSettings;
  // onChartSettingsChange: (value: TChartSettings) => void;
  onChartDataChange: (value: TDashboardChartDataItem[]) => void;
  onSuccess: () => void;
  chartSettingsLocal: TChartSettings;
  onChartSettingsLocalChange: (value: TChartSettings) => void;
  isPrint?: boolean;
  printMetricId: number | null;
  data: IMetricDataData[];
  printData: IMetricPrintData | null;
}

export const MetricToDashboardView = memo((props: IMetricToDashboardView) => {
  const notifier = useNotifier();
  const { t } = useTranslation();
  const { uri } = useParams();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [chartData, setChartData] = useState<TDashboardChartDataItem[]>(props.chartData ?? []);
  const [yDividers, setYDividers] = useState<MetricDividerDto[]>(
    props.metric.renderType == 1
      ? props.metric?.yDividers ?? []
      : props.metric?.graphs?.find((g) => g.metricSource2UserId == props.metricSource2UserId)?.yDividers ?? []
  );
  const [currentMetricDataToUpdate, setCurrentMetricDataToUpdate] = useState<TDashboardChartDataItem | null>(null);
  const [metricChangedFromDataDialogState, setMetricChangedFromDataDialogState] = useState([]);

  const findLastUser = (graph?: MetricGraphDto) => {
    const { periods = [] } = graph ?? {};
    const periodsValue = (periods ?? []).filter(({value}) => value);
    const lastPeriod = periodsValue.at(-1);
    // const periods
    const user = lastPeriod?.value?.user;
    // const user = props.metric.sources[0]?.source2Users[0].values
    setLastUser(user ?? null);
  };

  const [lastUser, setLastUser] = useState<UserShortDto | null>(null);
  useEffect(() => {
    findLastUser(props.metric.graphs?.[0]);
  }, [props.metric]);

  const handleUpdateMetricData = async () => {
    // Type: 1 - value, 2 - quota
    setIsOpen(false);
    // if (currentMetricDataToUpdate) setMetricData([...metricData, currentMetricDataToUpdate]);
    if (currentMetricDataToUpdate == null) return;
    setIsLoading(true);
    let arr2send: MetricValueDto[] = [];
    if (props.metric.renderType === 0 || !(props.metric.renderType === 1 && (props.metric.graphs ?? []).length > 1)) {
      arr2send = [
        ...arr2send,
        {
          metricSource2UserId: currentMetricDataToUpdate.charts.line[0]?.id as number,
          value: currentMetricDataToUpdate.charts.quota,
          date: currentMetricDataToUpdate.dateISO,
          type: 2,
        },
      ];
    }
    arr2send = [
      ...arr2send,
      ...currentMetricDataToUpdate.charts.line.map((l) => ({
        metricSource2UserId: l.id,
        value: l.value,
        date: currentMetricDataToUpdate.dateISO,
        type: 1,
      })),
    ];
    const r = await api.metric.updateValueOrQuota(arr2send);
    if (r == null) {
      setIsLoading(false);
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      return;
    }
    setChartData(chartData.map((i) => (i.id == currentMetricDataToUpdate.id ? currentMetricDataToUpdate : i)));
    await handleUpdateYDividers();
    setIsLoading(false);
  };

  const handleUpdateYDividers = async () => {
    const r = await api.metricRead.getById(props.metric?.id!);
    if (r == null) return;
    // console.log(
    //   "upd",
    //   props.metricSource2UserId,
    //   r.renderType == 1 ? r?.yDividers ?? [] : r?.graphs?.find((g) => g.metricSource2UserId == props.metricSource2UserId)?.yDividers ?? []
    // );
    // props.onChartDataChange(generateUpdatedChartData(r));
    const graph =  r?.graphs?.find((g) => g.metricSource2UserId == props.metricSource2UserId);
    findLastUser(graph);
    setYDividers(
      r.renderType == 1
        ? r?.yDividers ?? []
        : graph?.yDividers ?? []
    );
  };

  const generateDataToChangeByItemId = useCallback(
    (itemId: number): TDashboardChartDataItem | null => {
      const el = chartData.find((d) => d.id == itemId);
      return el != null ? { ...el } : null;
    },
    [chartData]
  );

  const handleGetDropdownItems = useMemo(() => {
    return [
      { key: DropdownKeys.SETTINGS, text: t("common:menu.metric_settings_dropdown.settings") },
      { key: DropdownKeys.DATA, text: t("common:menu.metric_settings_dropdown.data") },
      { key: DropdownKeys.PRINT, text: t("common:menu.metric_settings_dropdown.print") },
      // { key: DropdownKeys.DELETE, text: t("common:menu.metric_settings_dropdown.delete") },
    ].filter((el) =>
      dashboardSections.includes(getDashboardSection(uri) as dashboardSectionAndRoutesKeys)
        ? el.key != DropdownKeys.DELETE
        : true
    );
  }, [t, uri]);

  const generateUpdatedChartData = (metric: MetricReadDto): TDashboardChartDataItem[] => {
    const graphPeriodsArray: MetricGraphPeriodDto[] = removeDuplicatesFromArrayByKey(
      (metric.graphs?.map((g) => g.periods)?.flat() ?? []) as MetricGraphPeriodDto[],
      "label"
    )
      .filter((g: MetricGraphPeriodDto) => g.date != null)
      .sort(function (a: MetricGraphPeriodDto, b: MetricGraphPeriodDto) {
        return a.date! < b.date! ? -1 : a.date! > b.date! ? 1 : 0;
      });

    return graphPeriodsArray.map((period) => {
      return {
        id: Number(new Date(period.date!).getTime()),
        // id: Number(new Date(p.date!).getDate() + new Date(p.date!).getMonth() + new Date(p.date!).getFullYear()),
        dateISO: period.date as string,
        label: period.label as string,
        charts: {
          quota:
            props.metricSource2UserId != null
              ? metric.graphs
                  ?.find((g) => g.metricSource2UserId == props.metricSource2UserId)
                  ?.periods?.find((_p) => _p.label == period.label)?.quota?.quota ?? null
              : null,
          line:
            props.metricSource2UserId != null
              ? [
                  {
                    id: metric.graphs?.find((g) => g.metricSource2UserId == props.metricSource2UserId)
                      ?.metricSource2UserId!,
                    name: metric.graphs?.find((g) => g.metricSource2UserId == props.metricSource2UserId)?.name ?? "",
                    value:
                      metric.graphs
                        ?.find((g) => g.metricSource2UserId == props.metricSource2UserId)
                        ?.periods?.find((_p) => _p.label == period.label)?.value?.value ?? null,
                  },
                ]
              : metric.graphs?.map((g) => ({
                  id: g.metricSource2UserId!,
                  name: g.name ?? "",
                  value: g.periods?.find((_p) => _p.label == period.label)?.value?.value ?? null,
                })) ?? [],
        },
      };
    });
  };

  const handleMetricChangeFromDataDialog = async (updatedMetric: MetricReadDto) => {
    if (updatedMetric == null) return;
    const r = await api.metricRead.getById(updatedMetric.id!);
    if (r == null) {
      return;
    }
    setIsLoading(true);
    const newChartData = generateUpdatedChartData(r);
    setChartData(newChartData);
    // setChartData(newChartData.map((i) => (i.id == currentMetricDataToUpdate.id ? currentMetricDataToUpdate : i)));
    const graph =  r?.graphs?.find((g) => g.metricSource2UserId == props.metricSource2UserId);
    findLastUser(graph);
    setYDividers(
      r.renderType == 1
        ? r?.yDividers ?? []
        : graph?.yDividers ?? []
    );
    props.onChartDataChange(newChartData);
    setIsLoading(false);
  };

  const handleUpdateMetric = useCallback(
    (updatedMetric: MetricReadDto) => {
      handleMetricChangeFromDataDialog(updatedMetric);
      props.onOpenMetricDataDialogChange(false);
    },
    [handleMetricChangeFromDataDialog, props]
  );

  const handleDeleteMetric = useCallback(() => {
    // props.deleteMetric(
    //   props.metric?.renderType === 1
    //     ? props.metric?.id!
    //     : props.metric.graphs?.find((g) => g.id == props.metricSource2UserId)?.metricSource2UserId!
    // );
    props.deleteMetric(props.metric.metric2DashboardId!);
    LocalStorageHelpers.set(
      // String(
      //   props.metric?.renderType === 1
      //     ? `${props.metric?.id}/${props.metric?.graphs?.[0]?.id}`
      //     : `${props.metric?.id}/${props.metricSource2UserId}`
      // ),
      String(props.metric.metric2DashboardId),
      null
    );
    props.onOpenDeleteMetricDialogChange(false);
  }, [props]);

  const handleDashboardChartChange = useCallback(
    (itemId: number) => {
      isChartAvailableToRestart.current = false;
      setCurrentMetricDataToUpdate(generateDataToChangeByItemId(itemId));
      setIsOpen(true);
    },
    [generateDataToChangeByItemId]
  );

  const metricData = useMemo(
    () => ({
      name: props?.metric?.name ?? null,
      date: currentMetricDataToUpdate?.label ?? null,
      isQuotaAvailable:
        props.metric.renderType === 0 || !(props.metric.renderType === 1 && (props.metric.graphs ?? []).length > 1),
    }),
    [currentMetricDataToUpdate?.label, props.metric.graphs, props.metric?.name, props.metric.renderType]
  );

  const isChartAvailableToRestart = useRef(true);

  const handleReloadAfterDataChange = () => {
    isChartAvailableToRestart.current = true;
    props.handleRestartLoad?.();
  };

  useEffect(() => {
    if (isChartAvailableToRestart.current) {
      setChartData(props.chartData.map((chart) => ({
        ...chart,
        label: dayjs(chart.label).format("L").replaceAll(".", "/"),
      })));
    }
  }, [props.chartData]);

  useEffect(() => {
    setYDividers(
      props.metric.renderType == 1
        ? props.metric?.yDividers ?? []
        : props.metric?.graphs?.find((g) => g.metricSource2UserId == props.metricSource2UserId)?.yDividers ?? []
    );
  }, [props.metric]);

  useEffect(() => {
    if (
      yDividers.length > 0 &&
      !yDividers.some((d) => d.value == 0) &&
      yDividers.some((d) => (d.value ?? 0) > 0) &&
      yDividers.some((d) => (d.value ?? 0) < 0)
    ) {
      const sortedYDividers = yDividers.sort((a, b) => (a.value ?? 0) - (b.value ?? 0));
      if (sortedYDividers == null || sortedYDividers.length == 0) return;
      if (sortedYDividers[0].value! < 0 && sortedYDividers[sortedYDividers.length - 1].value! > 0) {
        setYDividers([...yDividers, { value: 0, label: "0" }].sort((a, b) => (a.value ?? 0) - (b.value ?? 0)));
      }
    }
  }, [yDividers]);


  return (
    <>
      {props.printMetricId === props.metric.id && !!props.printData && (
        <PrintMetricDialog
          metric={props.metric}
          chartData={chartData}
          chartSettings={props.chartSettings}
          yDividers={yDividers}
          metricSource2UserId={props.metricSource2UserId}
          printData={props.printData}
        />
      )}
      <UpdateMetricDialog
        onUpdateClick={handleUpdateMetricData}
        onToggleDialogVisibility={setIsOpen}
        open={isOpen}
        onChangeCurrentMetricData={setCurrentMetricDataToUpdate}
        value={currentMetricDataToUpdate}
        metricData={metricData}
      />
      <DeleteMetricOrDashboardDialog
        open={props.openDeleteMetricDialog}
        onOpen={props.onOpenDeleteMetricDialogChange}
        onDelete={handleDeleteMetric}
        title={`${t("ui:title.delete_metric_dialog_title")} "${props.metric?.name}"`}
        description={t("ui:text.delete_metric_dialog_warning")}
      />
      <MetricSettingsDialog
        open={props.openMetricSettingsDialog}
        onOpen={props.onOpenMetricSettingsDialogChange}
        onSuccess={() => {
          props.onSuccess();
          handleUpdateYDividers();
        }}
        title={t("ui:title.metric_settings")}
        chartSettings={props.chartSettingsLocal}
        // chartSettingsFromLS={props.chartSettingsFromLS}
        onChartSettingsChange={props.onChartSettingsLocalChange}
      />
      <MetricDataDialog
        isOpen={props.openMetricDataDialog}
        metric={props.metric}
        metricSource2UserId={props.metricSource2UserId}
        onOpen={props.onOpenMetricDataDialogChange}
        onSave={handleUpdateMetric}
      />
      {/*<Spin spinning={true}>*/}
      <Card
        className={clsx("metric-to-dashboard", {
          "printHidden": props.printMetricId && props.printMetricId !== props.metric.id
        })}
        // bodyStyle={{ padding: 0 }}
      >
        <div className="metric-to-dashboard__container__header d-flex flex-nowrap align-start mb-4">
          <div className={"d-flex flex-column metric-to-dashboard-header-wrap"}>
            <div className="d-flex align-center">
              <Tooltip title={props.metric.nameFormatted}>
                {/*<Tooltip title={props?.metric?.user2Role?.user?.name}>*/}
                <Text className="metric-to-dashboard__container__title">{props.metric.nameFormatted}</Text>
              </Tooltip>
              <Popover
                content={props.popoverContent}
                placement="bottom"
                arrowPointAtCenter={false}
                trigger="click"
                // overlayInnerStyle={{ padding: "16px" }}
              >
                <IconButton
                  size="small"
                  className="metric-to-dashboard__info-icon pa-0 ml-1"
                  shape="circle"
                  type="link"
                  icon={<Icon component={() => <FiHelpCircle />} />}
                />
              </Popover>
            </div>
            {lastUser && (
              <UserInfo
                user={lastUser}
                nameDisplayMode={"lastNameAndFirstName"}
                avatarSize={20}
                nameStyle={{
                  fontSize: 12,
                  // color: "var(--color-primary-weaker-second)"
                }}
              />
            )}

          </div>

          {isLoading && false && (
            <Icon
              className="ml-auto blink"
              style={{ position: "absolute", left: "12px", bottom: "12px" }}
              component={() => <FiSave stroke="var(--color-success-base)" />}
            />
          )}
          <Dropdown
            items={[
              ...handleGetDropdownItems.map((i) => ({
                key: i.key,
                text: i.text,
                onClick: () => props.onDropdownKeyChange(i.key),
              })),
            ]}
            trigger={["click"]}
            overlayClassName="orgchart_switcher-overlay"
          >
            <IconButton
              size="small"
              className="metric-to-dashboard__action pa-0 ml-auto"
              shape="circle"
              type="link"
              icon={<Icon component={() => <FiMoreVertical />} />}
            />
          </Dropdown>


        </div>
        <div className="metric-to-dashboard__container__content mt-1">
          {!!props.metric && (
            <>
            <DashboardChart
              data={chartData}
              metricData={props.metric}
              yDividers={yDividers}
              metricId={props.metric.id as number}
              metricSource2UserId={props.metricSource2UserId}
              isReversed={props.metric?.isReversed}
              isReadOnly={
                props.metricSource2UserId != null
                  ? props.metric?.graphs?.find((c) => c.metricSource2UserId == props.metricSource2UserId)
                      ?.canBeEdited != true
                  : props.metric.graphs?.some((c) => c.canBeEdited == false)
                // : props.metric.graphs?.every((c) => c.canBeEdited == false)
              }
              onChange={handleDashboardChartChange}
              viewSettings={props.chartSettings}
              isPrint={props.isPrint}
            />
              {(props.isPrint || props.printMetricId === props.metric.id) && (
                <div className="metric-to-dashboard-description">
                  {props.metric.description}
                </div>
              )}
              {/*function*/}
              {/*{(props.isPrint || props.printMetricId === props.metric.id) && (*/}
              {/*  <div className="metric-to-dashboard-function">*/}
              {/*    <div className="metric-to-dashboard-function__text">*/}
              {/*      { t("common:function") }*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*)}*/}
            </>
          )}
        </div>
        {/*{JSON.stringify(yDividers)}*/}
      </Card>
      {/*</Spin>*/}
    </>
  );
});

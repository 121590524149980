import React from "react";
import "./CreateIssueButton.scss";
import { ICreateIssueButton } from "./CreateIssueButton.interface";
import { useTranslation } from "react-i18next";
import { useClaims } from "../../../../../../functional/hooks";
import { permissionKeys } from "../../../../../../functional/utils/permissions";
import { Button, DropdownButton } from "../../../../../uiKit";
import { IssueQueryKeys } from "../../../../../../functional/constants/issueTypeKeys";
import { useNavigate } from "react-router-dom";

export const CreateIssueButton = (props: ICreateIssueButton) => {
  const { t } = useTranslation();
  const claims = useClaims();
  const navigate = useNavigate();

  const handleOpenOnetimeTask = () => navigate({ search: `?${IssueQueryKeys.taskCreate}=${true}` });
  const handleOpenRoutineDialog = () => navigate({ search: `?${IssueQueryKeys.routineCreate}=${true}` });

  const sendPopupItems = [
    // {
    //   id: 1,
    //   text: t("ui:button.create_task"),
    //   action: handleOpenOnetimeTask,
    // },
    // {
    //   id: 2,
    //   text: t("ui:button.create_decision"),
    //   action: handleOpenDecisionDialog,
    // },
    // {
    //   id: 3,
    //   text: t("ui:button.create_message"),
    //   action: handleOpenAppealDialog,
    // },
    {
      id: 4,
      text: t("ui:button.create_routine"),
      action: handleOpenRoutineDialog,
      permissionKey: permissionKeys.recurringIssue.add,
    },
  ].filter((item) => (item.permissionKey ? claims.has(item?.permissionKey) : true));

  return (
    // <>
    //   {sendPopupItems.length > 0 ? (
    //     <DropdownButton
    //       items={sendPopupItems}
    //       size="middle"
    //       trigger={["click"]}
    //       text={t("ui:button.create_task")}
    //       onClick={handleOpenOnetimeTask}
    //     />
    //   ) : (
    //     <Button
    //       // icon={<IoIosAdd />}
    //       className="simple-button"
    //       variant="filled"
    //       onClick={handleOpenOnetimeTask}
    //       //size="large"
    //     >
    //       {t("ui:button.create_task")}
    //     </Button>
    //   )}
    // </>
    <Button
      // icon={<IoIosAdd />}
      className="simple-button"
      variant="filled"
      onClick={handleOpenOnetimeTask}
      //size="large"
    >
      {t("ui:button.create_task")}
    </Button>
  );
};

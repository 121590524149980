import { IControlBar } from "./ControlBar.interface";
import { useTranslation } from "react-i18next";
import { Icon, IconButton, Title } from "../../../../../uiKit";
import { ControlButtons } from "../ControlButtons/ControlButtons";
import "./../DashboardSidebar/DashboardSidebar.scss";
import { useParams } from "react-router-dom";
import React from "react";
import { FiSidebar } from "@react-icons/all-files/fi/FiSidebar";
import { dashboardSectionAndRoutesKeys, dashboardSections } from "../../misc/constants/routesKeys";
import { getDashboardSection } from "../../misc/helpers/urlFunctions";
import { staticElementsArr } from "../DashboardSidebar/SidebarStaticElement/sidebarStaticArray";

export const ControlBar = (props: IControlBar) => {
  const { uri } = useParams();
  const uriSection: dashboardSectionAndRoutesKeys | number | null = getDashboardSection(uri);
  const { t } = useTranslation();

  const getDashboardTitle = (): string => {
    if (props.dashboard) {
      return props.dashboard.createdByUserId == props.userId
        ? `${t("common:breadcrumbs.my")} / ${props.dashboard.name}`
        : `${t("common:breadcrumbs.available_to_me")} / ${props.dashboard.name}`;
    }
    if (dashboardSections.includes(uriSection as dashboardSectionAndRoutesKeys)) {
      return t(staticElementsArr.find((el) => el.uri == uriSection)?.title ?? "");
    }
    return "";
  };

  return (
    <div className="d-stack-row justify-space-between">
      <div className="d-stack-row align-center spacing-2">
        {props.isHideSidebar ? (
          <IconButton onClick={props.onHidingSidebarChange} children={<Icon component={() => <FiSidebar />} />} />
        ) : null}
        {/*<OpeningGuideIcon contentKey={guideKeys.dashboard} mr={8} anchorId="2anchor" />*/}
        <div className="d-flex align-center justify-center mr-4">
          <Title weight="normal" style={{ marginBottom: 0, color: "var(--color-txt-secondary)", fontSize: "14px" }}>
            {getDashboardTitle()}
          </Title>
        </div>
        {/*<Tooltip title={props.dashboard?.description} placement="bottom">*/}
        {/*  <div className="mt-1">{props.dashboard ? <AiOutlineQuestionCircle size={20} color={"#8E939D"} /> : null}</div>*/}
        {/*</Tooltip>*/}
      </div>
      <ControlButtons
        dashboard={props.dashboard}
        dashboardColumnsAmount={props.dashboardColumnsAmount}
        onDashboardColumnsAmountChange={props.onDashboardColumnsAmountChange}
        onOpenDeleteDashboardDialogChange={props.onOpenDeleteDashboardDialogChange}
        onOpenCreateEditDashboardDialogChange={props.onOpenCreateEditDashboardDialogChange}
        onPrint={props.onPrint}
      />
    </div>
  );
};

// import { i18nextPlugin } from "translation-check";
import { resources as enResources } from "./en/index";
import { resources as ruResources } from "./ru/index";
import { resources as ukResources } from "./uk/index";
import { resources as zhResources } from "./zh/index";
import { resources as esResources } from "./es/index";
import { resources as uzLatnResources } from "./uzLatan/index";
import { resources as uzCyrlResources } from "./uzCyrl/index";

export const resources = {
  en: enResources,
  ru: ruResources,
  uk: ukResources,
  zh: zhResources,
  es: esResources,
  "uz-latn": uzLatnResources,
  "uz-cyrl": uzCyrlResources,
};

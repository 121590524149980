import React from "react";
import {  useRouterRoute } from "../../../functional/hooks";
import { observer } from "mobx-react-lite";
import { Navigate } from "react-router-dom";
import UnauthorizedLayout from "../../../layouts/unauthorized";
import AuthorizedLayout from "../../../layouts/authorized";
import { IRouterRoute } from "../../../functional/types";


function RouterRoute(props: IRouterRoute) {
  const { navigateTo, isLoading, isAuthorized } = useRouterRoute(props);

  // Если необходимо куда-то перенаправить, рендерим компонент для перенаправления
  if (navigateTo.length > 0) {
    return <Navigate to={navigateTo} />;
  }


  // Если грузим данные, ничего не показываем - срабатывает глобальный лоадер
  // TODO: Добавить глобальный лоадер
  if (isLoading) {
    return null;
  }

  // Если грузим данные, ничего не показываем - срабатывает глобальный лоадер
  if (props.isPrivate && !isAuthorized) {
    return null;
    // return (
    //   <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "green" }} />
    // );
  }

  // TODO: Переработать, добавить EmptyLayout для сервисных страниц (Сайт на техработах)
  return props.isPrivate ? (
    // authStore.isAuthorized ? props.content : null
    <AuthorizedLayout children={props.content} pageKey={props.pageKey} />
  ) : (
    <UnauthorizedLayout children={props.content} />
  );
}

export default observer(RouterRoute);

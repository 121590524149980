import React, { useContext, useEffect } from "react";
import { IDropdownContentElement, IFilterDropdownContent } from "./FilterDropdownContent.interface";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../../../elements/subheaderText/SubheaderText";
import { RelationsKeys } from "../../constants/keys";
import { Segmented, Switch } from "../../../../../uiKit";
import { AutocompleteAsync } from "../../../../../elements/autocompleteAsync/AutocompleteAsync";
import { SegmentedLabeledOption } from "antd/lib/segmented";
import { Tags } from "../tags/Tags";
import "./FilterDropdownContent.scss";
import { BoardFilterContext } from "../../../../../../functional/contexts";

const FilterDropdownContentMemo = (props: IFilterDropdownContent) => {
  const {
    userRelation,
    senderId,
    executorId,
    hasViolation,
    flowType,
    isDeadlineToday,
    scheduledToday,
    tags,
  } = useContext(BoardFilterContext);

  const { t } = useTranslation();

  const executorSelect = (
    <>
      <SubheaderText text={t("ui:subheader.executor")} />
      <div className="d-stack-row justify-start align-center spacing-1 mb-2">
        <div
          style={{
            maxWidth: "240px !important",
            width: "240px !important",
          }}
        >
          <AutocompleteAsync
            style={{ minWidth: 270 }}
            dropdownStyle={{ zIndex: 1300 }}
            type="user"
            queryUri="current-executors"
            requestOptions={{
              userRelation,
              isArchived: false ,
            }}
            allowClear
            placeholder={t("ui:placeholder.click_to_select")}
            value={executorId}
            onChange={props.onChangeExecutorId}
          />
        </div>
      </div>
    </>
  );

  const senderSelect = (
    <>
      <SubheaderText text={t("ui:subheader.issue_sender")} />
      <div className="d-stack-row justify-start align-center spacing-1">
        <div
          style={{
            maxWidth: "240px !important",
            width: "240px !important",
          }}
        >
          <AutocompleteAsync
            style={{ minWidth: 270 }}
            dropdownStyle={{ zIndex: 1300 }}
            type="user"
            queryUri="current-initiators"
            requestOptions={{
              userRelation,
              isArchived: false,
            }}
            allowClear
            placeholder={t("ui:placeholder.click_to_select")}
            value={senderId}
            onChange={props.onChangeSenderId}
          />
        </div>
      </div>
    </>
  );

  const hasViolationSwitch = (
    <>
      <Switch
        // className="mt-2"
        disabled={props.isLoading}
        label={t("ui:label.issues_with_violations").toString()}
        checked={hasViolation}
        onChange={props.onChangeHasViolation}
      />
    </>
  );

  const scheduledForTodaySwitch = (
    <Switch
      // className="mt-2"
      disabled={props.isLoading}
      label={t("ui:label.scheduled_for_today")}
      checked={scheduledToday}
      onChange={props.onChangeScheduledToday}
    />
  );

  const forTodaySwitch = (
    <>
      <Switch
        // className="mt-2 mb-2"
        disabled={props.isLoading}
        label={t("common:misc.sort_items.for_today").toString()}
        checked={isDeadlineToday}
        onChange={props.onChangeIsDeadlineToday}
      />
    </>
  );

  const groupingByOrgchartsSwitch = (
    <>
      <Switch
        // className="mt-2 mb-2"
        disabled={props.isLoading}
        label={t("ui:label.grouping_by_orgcharts").toString()}
        checked={props.isGroupingByOrgcharts}
        onChange={props.onChangeIsGroupingByOrgcharts}
      />
    </>
  );

  const groupingByTagsSwitch = (
    <>
      <Switch
        // className="mt-2 mb-2"
        disabled={props.isLoading}
        label={t("ui:label.grouping_by_tags").toString()}
        checked={props.isGroupingByTags}
        onChange={props.onChangeIsGroupingByTags}
      />
    </>
  );

  const groupingByUsersSwitch = (
    <>
      <Switch
        // className="mt-2 mb-2"
        disabled={props.isLoading}
        label={t("ui:label.grouping_by_users").toString()}
        checked={props.isGroupingByUsers}
        onChange={props.onChangeIsGroupingByUsers}
      />
    </>
  );

  const flowTypeSegment: SegmentedLabeledOption[] = [
    {
      value: 0,
      disabled: false,
      label: t("parse:no"),
    },
    {
      // @ts-ignore
      value: 3,
      disabled: false,
      // label: `<${t("parse:emptiness")}>`,
      label: t("parse:by_default"),
    },
    {
      value: 1,
      disabled: false,
      label: t("parse:yes"),
    },
  ];

  const onVerificationSegment = (
    <div style={{ width: "max-content" }}>
      <SubheaderText className="mt-1" text={t("ui:label.on_verification")} />
      {/* @ts-ignore*/}
      <Segmented options={flowTypeSegment} value={flowType} onChange={props.onChangeFlowTypeFilter} />
    </div>
  );

  const onVerificationSwitch = (
    <>
      <Switch
        label={t("ui:label.on_verification").toString()}
        checked={!!flowType}
        //onChange={props.onChangeFlowTypeFilter}
      />
    </>
  );

  const handleVerifyToRenderElement = (elementId: number): boolean =>
    elements.some((element) => element.id == elementId && element.allowedRelationsToRender.includes(userRelation));

  const elements: IDropdownContentElement[] = [
    {
      id: 1,
      name: "executor_select",
      allowedRelationsToRender: [RelationsKeys.Participated, RelationsKeys.Controlled, RelationsKeys.Participated],
      component: <React.Fragment key={1}>{executorSelect}</React.Fragment>,
    },
    {
      id: 2,
      name: "sender_select",
      allowedRelationsToRender: [RelationsKeys.My, RelationsKeys.Participated],
      component: <React.Fragment key={2}>{senderSelect}</React.Fragment>,
    },
    {
      id: 3,
      name: "has_violation_switch",
      allowedRelationsToRender: [RelationsKeys.My, RelationsKeys.Controlled, RelationsKeys.Participated],
      component: <React.Fragment key={3}>{hasViolationSwitch}</React.Fragment>,
    },
    {
      id: 4,
      name: "scheduled_for_today",
      allowedRelationsToRender: [RelationsKeys.My, RelationsKeys.Controlled, RelationsKeys.Participated],
      component: <React.Fragment key={4}>{scheduledForTodaySwitch}</React.Fragment>,
    },
    {
      id: 5,
      name: "tasks_with_a_deadline_for_today_switch",
      allowedRelationsToRender: [RelationsKeys.My, RelationsKeys.Controlled, RelationsKeys.Participated],
      component: <React.Fragment key={5}>{forTodaySwitch}</React.Fragment>,
    },
    {
      id: 6,
      name: "grouping_by_orgcharts_switch",
      allowedRelationsToRender: [RelationsKeys.My, RelationsKeys.Controlled, RelationsKeys.Participated],
      component: <React.Fragment key={6}>{groupingByOrgchartsSwitch}</React.Fragment>,
    },
    {
      id: 7,
      name: "grouping_by_tags_switch",
      allowedRelationsToRender: [RelationsKeys.My, RelationsKeys.Controlled, RelationsKeys.Participated],
      component: <React.Fragment key={7}>{groupingByTagsSwitch}</React.Fragment>,
    },
    {
      id: 8,
      name: "grouping_by_users_switch",
      allowedRelationsToRender: props.isShowUserGrouping
        ? [RelationsKeys.My, RelationsKeys.Controlled, RelationsKeys.Participated]
        : [],
      component: <React.Fragment key={8}>{groupingByUsersSwitch}</React.Fragment>,
    },
    {
      id: 9,
      name: "tags",
      allowedRelationsToRender: [RelationsKeys.My, RelationsKeys.Controlled, RelationsKeys.Participated],
      component: (
        <Tags
          key={9}
          selectedTags={tags}
          onSelectedTags={props.onSelectedTags}
          onChangeIsOpenFilter={props.onChangeIsOpenFilter}
        />
      ),
    },
    {
      id: 10,
      name: "on_verification_segment",
      allowedRelationsToRender: [RelationsKeys.My, RelationsKeys.Controlled, RelationsKeys.Participated],
      component: <React.Fragment key={10}>{onVerificationSegment}</React.Fragment>,
    },
  ];

  return (
    <div className="filter-dropdown-content d-stack-column spacing-2 pa-2" style={{ width: "320px !important" }}>
      {elements.filter((item) => handleVerifyToRenderElement(item.id)).map((item) => item.component)}
    </div>
  );
};

export const FilterDropdownContent = React.memo(FilterDropdownContentMemo);

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import { useParams } from "react-router-dom";
import { useApi, useNotifier, usePagingWithController } from "../../../functional/hooks";
import { api } from "../../../functional/services";
import { observer } from "mobx-react-lite";
import DriverExecutorAdminsDialog from "../../modules/dialogs/driverExecutorAdminsDialog/DriverExecutorAdminsDialog";
import { Alert, Card, Empty } from "../../uiKit";
import { UserBlock } from "./components/userBlock/UserBlock";
import { DriverIssuesCollapse } from "./components/driverIssuesCollapse/driverIssuesCollapse";
import { DriverHistoryCard } from "./components/driverHistoryCard/DriverHistoryCard";
import ScrollTrigger from "../../service/scrollTrigger/ScrollTrigger";
import { getContactLinkByType } from "../../../functional/utils/contactLinkByType";

function DriverUserPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const notifier = useNotifier();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDriverExecutorAdminsDialogOpen, setIsDriverExecutorAdminsDialogOpen] = useState<boolean>(false);
  const [expandedViolationCardId, setExpandedViolationCardId] = useState<number | null>(null);

  const driverData = useApi(
    () => api.driver.getById(Number(id ?? 0)),
    () => {
      setIsLoading(false);
    },
    (x) => {
      handleHistoryRefresh(x?.user?.id as number);
      setIsLoading(false);
    }
  );

  const handlePhoneCall = async (phoneNumber: string) => {
    if ((phoneNumber ?? "").trim().length == 0) {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      return;
    }
  };

  const sessionHistory = usePagingWithController(
    api.controlSessionHistory,
    {
      userId: id ?? (driverData.value?.user?.id as number),
    },
    { pageSize: 20 }
  );

  const handleHistoryRefresh = async (userId?: number) => {
    if (id == null) {
      return;
    }
    sessionHistory.reset();
    await sessionHistory.restart();
  };

  const handlePhoneCopy = async (number: string) => {
    await handleTextCopy(number);
  };

  const handleTextCopy = async (text: string) => {
    await navigator.clipboard
      .writeText(text)
      .then(() => notifier.show({ message: t("notifier:success.copy"), theme: "success" }))
      .catch(() => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" }));
  };

  const handleViolationItemClick = (id: number) => {
    if (expandedViolationCardId == id) {
      setExpandedViolationCardId(null);
    } else {
      setExpandedViolationCardId(id);
    }
    // expandedViolationCardId == id ? setExpandedViolationCardId(null) : setExpandedViolationCardId(id);
  };

  const copyLogin = async (event: any, login: string) => {
    // event.preventDefault();
    await navigator.clipboard
      .writeText(login)
      .then(() => notifier.show({ message: t("notifier:success.copy"), theme: "success" }))
      .catch(() => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" }));
  };

  return (
    <>
      <PageHeader title={t("common:page_title.driver_user")} />
      <PageContent isLoading={isLoading}>
        <DriverExecutorAdminsDialog
          open={isDriverExecutorAdminsDialogOpen}
          onClose={() => setIsDriverExecutorAdminsDialogOpen(false)}
          companies={driverData.value?.user?.companies ?? null}
        />
        {driverData.value == null ? (
          !isLoading && <Alert description={t("text:driver.no_cards_found")} />
        ) : (
          <div className="driver-admin-page__wrapper">
            <Card className="mr-1 flex-grow-1 flex-shrink-1" style={{ flexBasis: "40%" }} scrollStrategy="cardBody">
              <div className="full-height d-flex flex-column">
                <UserBlock
                  user={driverData.value?.user}
                  getContactLinkByType={getContactLinkByType}
                  onOpenExecutorAdminsDialog={() => setIsDriverExecutorAdminsDialogOpen(true)}
                  onCopyLogin={copyLogin}
                  onPhoneCopy={handlePhoneCopy}
                  onPhoneCall={handlePhoneCall}
                />
                <div className="mt-3 flex-grow-1 d-stack-column spacing-2" style={{ overflowY: "auto" }}>
                  <DriverIssuesCollapse
                    issues={driverData.value?.issues}
                    violationGroups={driverData.value?.violationGroups}
                  />
                </div>
              </div>
            </Card>
            <Card
              title={t("ui:caption.interaction_history")}
              className="ml-1 flex-grow-1 flex-shrink-1"
              style={{ flexBasis: "60%" }}
              scrollStrategy="cardBody"
            >
              {sessionHistory.items.length == 0 && !sessionHistory.info.isLoading && <Empty className="my-4" />}
              <div className="d-stack-column spacing-2">
                {sessionHistory.items?.map((sH, index) => (
                  <DriverHistoryCard sH={sH} />
                ))}
                <ScrollTrigger
                  onIntersection={sessionHistory.loadNext}
                  hidden={sessionHistory.info.isDone}
                  marginTop={sessionHistory.items.length > 0}
                />
              </div>
            </Card>
          </div>
        )}
      </PageContent>
    </>
  );
}

export default observer(DriverUserPage);

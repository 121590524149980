import { useEffect, useRef, useState } from "react";
import { TimezoneNotificationDialogView } from "./TimezoneNotificationDialogView";
import { useClaims, useLocalStorage, useRootStore, useTimezoneNotificationDialog } from "../../../../functional/hooks";
import { observer } from "mobx-react-lite";
import { permissionKeys } from "../../../../functional/utils/permissions";
import { api } from "../../../../functional/services";
import { CompanyDto, UserDto } from "../../../../functional/api";

export type TTimezoneNotificationDialogTimeZoneValue = {
  user?: string | null;
  company?: string | null;
};

const TimezoneNotificationDialogObserved = () => {
  const {
    isOpen,
    timeZoneValue,
    isLoading,
    isLoadingCanonical,
    timeZones,
    isSetTimeZone,
    handleSave,
    handleCloseModal,
    handleChangeTimeZone,
  } = useTimezoneNotificationDialog();

  return isOpen ? (
    <TimezoneNotificationDialogView
      isOpen={isOpen}
      timeZoneValue={timeZoneValue}
      isLoading={isLoading}
      isLoadingCanonical={isLoadingCanonical}
      timeZones={timeZones}
      isSetTimeZone={isSetTimeZone}
      onTimeZoneValueChange={handleChangeTimeZone}
      onSave={handleSave}
      handleCloseModal={handleCloseModal}
    />
  ) : null;
};

export const TimezoneNotificationDialog = observer(TimezoneNotificationDialogObserved);

import React, { memo, useCallback, useState } from "react";
import { CalendarTimeBlockView } from "./CalendarTimeBlockView";
import { useTranslation } from "react-i18next";
import { CalendarEventTimeBlockDto } from "../../../../functional/api/models/CalendarEventTimeBlockDto";
import { TimeBlockSubmitFormDto } from "../../../../functional/api/models/TimeBlockSubmitFormDto";
import { ICalendarTimeBlock } from "../../../../functional/types";

export const CalendarTimeBlock = memo((props: ICalendarTimeBlock) => {
  const { item , onClose, handleSaveTimeBlock, popupItems, isLoadingTimeBlock } = props;

  const [eventTimeBlock, setEventTimeBlock] = useState<CalendarEventTimeBlockDto>(item);

  const handleSubmitForm = useCallback(() => {
    handleSaveTimeBlock && handleSaveTimeBlock(eventTimeBlock);
  }, [eventTimeBlock])

  const handleChange = useCallback(({ value, key }: TimeBlockSubmitFormDto) => {
    setEventTimeBlock((prev: CalendarEventTimeBlockDto) => ({
      ...prev,
      [key]: value
    }))
  }, []);

  return (
    <CalendarTimeBlockView
      handleClose={onClose}
      eventTimeBlock={eventTimeBlock}
      handleChange={handleChange}
      handleSubmitForm={handleSubmitForm}
      popupItems={popupItems}
      isLoadingTimeBlock={isLoadingTimeBlock}
    />
  )
});

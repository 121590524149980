import React, { memo } from "react";
import { IconButton, Text } from "../../../../uiKit";
import { TrashIcon } from "../../../../../functional/constants/icon";
import "./FunctionCardItem.scss";
import { IFunctionCardItem } from "./IFunctionCardItem.interface";

export const FunctionCardItem = memo((props: IFunctionCardItem) => {
  const handleDelete = () => {
    props.onDelete(props.item);
  }

  return (
    <div className="function-card-item">
      <Text className="function-card-item__name">
        {props.item.role.name}
      </Text>
      <IconButton
        className="function-card-item__icon"
        type="text"
        icon={<TrashIcon size={24} />}
        onClick={handleDelete}
      />
    </div>
  );
})

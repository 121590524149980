import React, { useContext, useState } from "react";
import OrgchartDrawerTabEmployeeView from "./OrgchartDrawerTabEmployeeView";
import { RoleDto, RolePositionDto, User2RoleDto } from "../../../../../../functional/api";
import { useNotifier } from "../../../../../../functional/hooks";
import { useTranslation } from "react-i18next";
import { OrgchartContext } from "../../../../../../functional/contexts/orgchartContext";
import { api } from "../../../../../../functional/services";
import { ExecutorTypes } from "../../../../../pages/driver/types/DriverTypes";

export interface IOrgchartDrawerTabEmployee {
  functionData: RoleDto;

  onRoleChange: (role: RoleDto, withoutApiCall: boolean) => void;
}

function OrgchartDrawerTabEmployee(props: IOrgchartDrawerTabEmployee) {
  const notifier = useNotifier();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [executorType, setExecutorType] = useState<ExecutorTypes | null>("admin");
  const orgchartContext = useContext(OrgchartContext);
  const [positionToCreateName, setPositionToCreateName] = useState<string>("");
  const [isPositionCreateDialogOpen, setIsPositionCreateDialogOpen] = useState<boolean>(false);

  const handleUserAddOnPosition = async (positionId: number, userId: number) => {
    setIsLoading(true);
    const r = await api.role.edit(
      props.functionData.id as number,
      {
        ...props.functionData,
        users: [
          ...(props.functionData.users ?? []),
          {
            id: 0,
            userId: userId,
            positionId: positionId,
            // type:
            //   props.functionData.positions?.find((fp) => fp.parentPositionId == null) != null
            //     ? "admin"
            //     : "executor_specialist",
          },
        ],
      },
      { includeClaims: true }
    );
    setIsLoading(false);
    orgchartContext?.refreshState(true);
    if (r == null) return;
    props.onRoleChange(r, true);
  };

  const handleUserDeleteFromPosition = async (positionId: number, userId: number) => {
    setIsLoading(true);
    const r = await api.role.edit(
      props.functionData.id as number,
      {
        ...props.functionData,
        users: [
          ...(props.functionData.users ?? []).filter(
            (u2r: User2RoleDto) => !(u2r.userId == userId && u2r.positionId == positionId)
          ),
        ],
      },
      { includeClaims: true }
    );
    setIsLoading(false);
    orgchartContext?.refreshState(true);
    if (r == null) return;
    props.onRoleChange(r, true);
  };

  const handlePositionDataUpdate = async (data: RolePositionDto) => {
    const positionToEdit: RolePositionDto | null | undefined = props.functionData.positions?.find(
      (p) => p.id == data.id
    );
    if (positionToEdit == null) return;
    setIsLoading(true);
    const r = await api.role.edit(
      props.functionData.id as number,
      {
        ...props.functionData,
        positions: [
          ...(props.functionData.positions ?? []).filter((p) => (p.id as number) != (data.id as number)),
          { ...positionToEdit, ...data, name: (data.name ?? "").length == 0 ? positionToEdit.name : data.name },
        ].map((p: RolePositionDto, index) => ({ ...p })),
      },
      { includeClaims: true }
    );
    setIsLoading(false);
    orgchartContext?.refreshState(true);
    if (r == null) return;
    props.onRoleChange(r, true);
  };

  const handlePositionCreate = async () => {
    setIsLoading(true);
    // const parentPositionId = props.functionData.positions?.find((p) => p.parentPositionId == null);
    // if (parentPositionId == null) return;
    // , parentPositionId: parentPositionId as number
    const r = await api.role.edit(
      props.functionData.id as number,
      {
        ...props.functionData,
        positions: [
          ...(props.functionData.positions ?? []),
          {
            id: 0,
            name: positionToCreateName,
            order: getNewPositionOrder(),
          },
        ],
      },
      { includeClaims: true }
    );
    setIsPositionCreateDialogOpen(false);
    setPositionToCreateName("");
    setIsLoading(false);
    orgchartContext?.refreshState(true);
    if (r == null) return;
    props.onRoleChange(r, true);
  };

  const handlePositionCreateClick = () => {
    setIsPositionCreateDialogOpen(true);
  };

  const handlePositionCreateDialogClose = () => {
    setIsPositionCreateDialogOpen(false);
    setPositionToCreateName("");
  };

  const handlePositionDelete = async (positionId: number) => {
    setIsLoading(true);
    // const parentPositionId = props.functionData.positions?.find((p) => p.parentPositionId == null);
    // if (parentPositionId == null) return;
    // , parentPositionId: parentPositionId as number
    const r = await api.role.edit(
      props.functionData.id as number,
      {
        ...props.functionData,
        positions: [...(props.functionData.positions ?? []).filter((p) => p.id != positionId)],
      },
      { includeClaims: true }
    );
    setIsLoading(false);
    orgchartContext?.refreshState(true);
    if (r == null) return;
    props.onRoleChange(r, true);
  };

  const getNewPositionOrder = (): number => {
    const p = (props.functionData.positions ?? []).sort((p1, p2) => (p1.order ?? 0) - (p2?.order ?? 0));
    console.log(p[p.length - 1]?.order ?? p.length + 1);
    return (p[p.length - 1]?.order ?? p.length) + 1;
  };

  return (
    <OrgchartDrawerTabEmployeeView
      functionData={props.functionData}
      isLoading={isLoading}
      positionToCreateName={positionToCreateName}
      isPositionCreateDialogOpen={isPositionCreateDialogOpen}
      setPositionToCreateName={setPositionToCreateName}
      handleUserAddOnPosition={handleUserAddOnPosition}
      handleUserDeleteFromPosition={handleUserDeleteFromPosition}
      handlePositionDataUpdate={handlePositionDataUpdate}
      handlePositionCreateClick={handlePositionCreateClick}
      onPositionCreateDialogClose={handlePositionCreateDialogClose}
      handlePositionCreate={handlePositionCreate}
      handlePositionDelete={handlePositionDelete}
    />
  );
}

export default OrgchartDrawerTabEmployee;

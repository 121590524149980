import { useTranslation } from "react-i18next";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import { observer } from "mobx-react-lite";
import { ThemeSwitcher } from "../../modules/pages/settings/themeSwitcher/ThemeSwitcher";
import { Card, Icon, Select, Switch, Text, Tooltip } from "../../uiKit";
import SubheaderText from "../../elements/subheaderText/SubheaderText";
import { useRootStore } from "../../../functional/hooks";
import { AutocompleteAsync } from "../../elements/autocompleteAsync/AutocompleteAsync";
import { FiHelpCircle } from "react-icons/fi";

function DevPageObserved() {
  const { t } = useTranslation();
  const { devStore, authStore } = useRootStore();

  return (
    <>
      <PageHeader title={"Dev Page - absolutely secret page 😾"} />
      <PageContent>
        <div className="d-stack-column spacing-2">
          <Card>
            <div className="d-stack-column spacing-2">
              <SubheaderText text={"Debug-значения"} />
              <Text weight="bold">
                Company UI type:
                <Text
                  weight="normal"
                  children={
                    " " +
                    authStore.getCurrentCompanyUiType +
                    " " +
                    (authStore.getCurrentCompanyUiType == 2 ? "(New)" : "(Old)")
                  }
                />
              </Text>
            </div>
          </Card>
          <Card>
            <div className="d-stack-column spacing-2" style={{ maxWidth: "250px" }}>
              <ThemeSwitcher />
            </div>
          </Card>
          <Card>
            <div className="d-stack-column spacing-2" style={{ maxWidth: "250px" }}>
              <SubheaderText text={"AutocompleteAsync для теста"} />
              <AutocompleteAsync type={"user"} />
              <SubheaderText text={"Select для теста"} />
              <Select
                items={[
                  { id: 0, text: "1st element", value: 0 },
                  { id: 1, text: "2nd element", value: 1 },
                  { id: 2, text: "3rd element", value: 2 },
                ]}
              />
            </div>
          </Card>
          <Card>
            <div className="d-stack-column spacing-2">
              <SubheaderText text={"Переключалочки"} />
              <Switch
                checked={devStore.getIsThemeSwitcherFloatButtonVisible}
                onChange={(v) => devStore.setIsThemeSwitcherFloatButtonVisible(v)}
                label="Отображать переключатель темы приложения в виде фиксированной кнопки"
              />
              <Switch
                checked={devStore.getIsEntitiesIdsVisibleInAutocompleteAsync}
                onChange={(v) => devStore.setIsEntitiesIdsVisibleInAutocompleteAsync(v)}
                label="Отображать ID сущностей в AutocompleteAsync"
              />
              <Switch
                disabled
                checked={devStore.getIsEntitiesIdsVisibleInSelect}
                onChange={(v) => devStore.setIsEntitiesIdsVisibleInSelect(v)}
                label={
                  <span>
                    Отображать ID сущностей в обычных Select
                    <Tooltip title="[Пока что не работает] Данная опция может работать нестабильно, т.к. ориентируется на ID каждого элемента в селекте, который может быть задан вручную. Это не страшно, но, определённо, стоит принять это во внимание">
                      <Icon className="ml-2" component={() => <FiHelpCircle />} />
                    </Tooltip>
                  </span>
                }
              />
            </div>
          </Card>
        </div>
      </PageContent>
    </>
  );
}

export const DevPage = observer(DevPageObserved);

import React from "react";
import SignupFormView from "./SignupFormView";
import { useSignupForm } from "../../../../functional/hooks";
import { observer } from "mobx-react-lite";

function SignupForm() {
  const {
    isLoading,
    step,
    companyData,
    setStep,
    handleSignup,
    handleCodeCheck,
  } = useSignupForm();

  return (
    <SignupFormView
      isLoading={isLoading}
      step={step}
      companyData={companyData}
      setStep={setStep}
      handleSignup={handleSignup}
      handleCodeCheck={handleCodeCheck}
    />
  );
}

export default observer(SignupForm);

import React, { memo } from "react";
import "./Metrics.scss";
import { IMetrics } from "./Metrics.interface";
import { MetricToDashboard } from "../Metric2Dashboard/MetricToDashboard";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";
import { Empty, Spin } from "../../../../../uiKit";
import { EmptyMetric } from "../Metric2Dashboard/EmptyMetric/EmptyMetric";
import clsx from "clsx";

export const Metrics = memo((props: IMetrics) => {
  return (
    <Spin spinning={false} size="large">
      {!props.metrics?.length && !props.isLoading ? (
        <>
          <Empty />
        </>
      ) : (
        <div className={"metrics-wrap"}>
          {props.isLoading && !props.metrics?.length ? (
            <div className="full-height full-width d-flex align-center justify-center pt-4 pb-4">
              <Spin spinning={true} size="large" />
            </div>
          ) : (
            <>
              <div
                className={clsx("metrics full-height", {
                  "metrics_print": props.printMetricId,
                  "metrics_row": props.isRow
                })}
                style={{ gridTemplateColumns: `repeat(${props.dashboardColumnsAmount ?? 3}, minmax(0, 1fr))` }}
              >
                {props.metrics?.map((m) => {
                  return m.renderType === 1 ? (
                    <MetricToDashboard
                      key={m.id}
                      metric={m}
                      deleteMetric={props.deleteMetric}
                      handleRestartLoad={props.restart}
                      isPrint={props.isPrint}
                      printMetricId={props.printMetricId}
                      onSetIsPrintMetricId={props.onSetIsPrintMetricId}
                    />
                  ) : m.graphs?.length ? (
                    m.graphs.map((g, index) => (
                      <MetricToDashboard
                        key={String(g.metricSource2UserId) + "/" + index}
                        metric={m}
                        metricSource2UserId={g.metricSource2UserId}
                        deleteMetric={props.deleteMetric}
                        handleRestartLoad={props.restart}
                        isPrint={props.isPrint}
                        printMetricId={props.printMetricId}
                        onSetIsPrintMetricId={props.onSetIsPrintMetricId}
                      />
                    ))
                  ) : (
                    <div className={clsx("empty-metric", {
                      "printHidden": props.isPrint || props.printMetricId,
                    })
                    }>
                      <EmptyMetric
                        metric={m}
                        deleteMetric={props.deleteMetric}
                      />
                    </div>
                  );
                })}
              </div>
              <div className={clsx("ml-2 scroll-trigger-block", {
                "printHidden": props.isPrint || props.printMetricId,
              })}>
                <ScrollTrigger
                  fullWidth
                  marginTop={!!props.metrics?.length}
                  disabled={props.isDone}
                  hidden={props.isDone}
                  onIntersection={props.loadNext}
                />
              </div>
            </>
          )}


        </div>
      )}
    </Spin>
  );
});

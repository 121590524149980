import React from "react";
import { useTranslation } from "react-i18next";
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import { Avatar, Button, Dialog, DialogActions } from "../../../../uiKit";
import { IAvatarUploadFormView } from "../../../../../functional/types";

function AvatarUploadFormView(props: IAvatarUploadFormView) {
  const { t } = useTranslation();

  return (
    <Dialog
      title={t("ui:title.avatar_change")}
      width="350px"
      open={props.open}
      destroyOnClose
      onClose={() => props.onClose()}
    >
      <div style={{ display: "flex", flexWrap: "nowrap", justifyContent: "center", alignItems: "center" }}>
        <Avatar color={props.color} src={props.imageUrl} text={props.text} size={120} />
        {props.selectedFile != null && (
          <>
            <FiChevronRight style={{ margin: "0 8px" }} fontSize={24} color="var(--color-primary-base)" />
            <Avatar color={props.color} src={URL.createObjectURL(props.selectedFile)} size={120} />
          </>
        )}
      </div>

      <input
        style={{ display: "none" }}
        type="file"
        onChange={props.onInputChanged}
        accept="image/*"
        ref={(input) => (props.uploadInputRef.current = input)}
      />

      <DialogActions>
        {/*<Button onClick={() => props.onClose()} variant="text">*/}
        {/*  {t("ui:button.cancel")}*/}
        {/*</Button>*/}
        <Button onClick={() => props.onChangeClick()} variant="text">
          {t("ui:button.edit")}
        </Button>
        <Button disabled={props.selectedFile == null} onClick={() => props.onUpload()} variant="filled">
          {t("ui:button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AvatarUploadFormView;

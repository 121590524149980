import { useNotifier, useRootStore } from "../../../../../functional/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalAuthorizedContext } from "../../../../../functional/contexts/globalAuthorizedContext";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { api } from "../../../../../functional/services";
import { UserSelectionRule } from "../../../../../functional/api/types/userSelectionRule";
import {
  AutocompleteModel,
  RegulationDto,
  type UserShortDto
} from "../../../../../functional/api";
import { IeditableSectionForm } from "../../../forms/createRegulationSectionForm/CreateRegulationSectionForm.interface";
import { findId } from "../../../../../functional/helpers/pathname";
import { HomeIcon } from "../../../../../functional/constants/icon";
import { BreadcrumbDto, NavigationMenuItemDto } from "../../../../../functional/api/models/NavigationMenuDto";
import { RegulationStatus } from "../../../../../functional/constants/regulationStatus";
import { TagLabel } from "../../../../uiKit/dataDisplay/TabLabel/TagLabel";
import { getStatusLabelColor } from "../../../../../functional/utils/getStatusLabelColor";
import { Progress } from "antd";
import { BreadcrumbsType } from "../../../../elements/breadcrumbsNew/BreadcrumbsNew.interface";
import { NO_ACCESS_TO_RESOURCE } from "../../../../../functional/constants/ApiErrorCode";

export const useSectionHooks = () => {

  const { t } = useTranslation();
  const globalAuthorizedContext = useContext(GlobalAuthorizedContext);
  const { regulationStore } = useRootStore();

  const notifier = useNotifier();
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const [section, setSection] = useState<NavigationMenuItemDto | null>(null);

  useEffect(() => {
    if (regulationStore.getParentId === -1 && section) {
      setSection(null);
    }
  }, [regulationStore.getParentId]);

  const breadcrumbsList: BreadcrumbsType[] = useMemo(() => {
    if (!section) return [{
      name: (<HomeIcon/>),
      id: -1,
      path: "all",
    }];
    const breadcrumbsList: BreadcrumbsType[] = (section?.breadcrumbs ?? []).length
      ? (section?.breadcrumbs ?? []).map((item) => ({
        ...item,
        path: `topics/${item.id}`
      }))
      : [{
        name: section.name!,
        id: section.id!,
        path: `topics/${section.id!}`,
      }];
    return [
      {
        name: (<HomeIcon/>),
        id: -1,
        path: "all",
      },
      ...breadcrumbsList,
    ]
  }, [section?.breadcrumbs]);

  const handleError = (err: any) => {
    const { data: { apiError, data } } =  err.response;
    if (apiError === NO_ACCESS_TO_RESOURCE) {
      const [user] = data.ResponsibleUsers ?? [];
      const [item] = data.Items ?? [];

      const noAccessData = {
        user: user as UserShortDto,
        title: item?.name ?? "",
        link: window.location.href,
        isSection: true,
      }
      regulationStore.setNoAccessData(noAccessData);
      navigate(`/policy/all/`);
    } else {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
    }
  }

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fetchSection = async (id: number) => {
    setIsLoading(true);

    const res =   await api.navigationMenu.getItemById(id, handleError);
    if (res) {
      setSection(res);
      setTitleSection(isEditable ? res.name : "");
      if (regulationStore.getParentId !== res.id) {
        regulationStore.setParentId(res.id)
      }
    }
    setIsLoading(false);
  };

  const handleLoadSection = useCallback(() => {
    const id = findId(pathname, 2) || findId(pathname);
    if (id) {
      fetchSection(id);
    }
  }, [pathname])


  const handleClickCreateDocument = useCallback(() => {
    // handleClickCancel();
    regulationStore.setParentId(section?.id ?? -1);
    navigate(`/policy/topics/${section?.id}/policy/create-policy`);
  }, [section?.id]);

  const handleClickCreateSection = useCallback(() => {
    // handleClickCancel();
    regulationStore.setParentId(section?.id ?? -1);
    navigate(`/policy/topics/${section?.id}/create-topic`);
  }, [section?.id]);
  // useEffect(() => {
  //   if (!pathname.includes('policy/all/')) {
  //     handleLoadSection();
  //   }
  // }, [pathname]);

  const [titleSection, setTitleSection] = useState<string>("");
  const [userSelectionRules, setUserSelectionRules] = useState<UserSelectionRule[]>();

  const [isOpenAssignForStudyDialog, setIsOpenAssignForStudyDialog] = useState<boolean>(false);
  const [isOpenAccessSettingsDialog, setIsOpenAccessSettingsDialog] = useState<boolean>(false);


  const handleOpenAssignForStudyDialog = useCallback(() =>
      setIsOpenAssignForStudyDialog(true)
    , []);
  const handleCloseAssignForStudyDialog = useCallback(() => {
    setIsOpenAssignForStudyDialog(false);
  }, []);

  const handleCloseAccessSettingsDialog = useCallback(() => setIsOpenAccessSettingsDialog(false), []);
  const handleOpenAccessSettingsDialog = useCallback(() =>
      setIsOpenAccessSettingsDialog(true)
    , []);

  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const [newRegulations, setNewRegulations] = useState<AutocompleteModel[]>([]);

  const [regulationIds, setRegulationIds] = useState<number[]>([]);
  const [navigationMenuItemIds, setNavigationMenuItemIds] = useState<number[]>([]);


  const handleMapAutocompleteRegulations = (regulations: AutocompleteModel[]): number[] =>
    regulations.map((item) => item.id!);

  const handleSave = async (toEdit?: IeditableSectionForm) => {
    setIsRequestLoading(true);
    if (titleSection.trim().length === 0) {
      setIsRequestLoading(false);
      return notifier.show({ message: t("notifier:error.empty_section_name"), theme: "error" });
    }
    // if (!userSelectionRules || userSelectionRules?.length === 0) {
    //   setIsRequestLoading(false);
    //   return notifier.show({ message: t("notifier:error.section_cannot_be_inaccessible"), theme: "error" });
    // }

    if (toEdit?.isEdit && toEdit.section) {
      const resp = await api.navigationMenu.editNavigationItem(toEdit.section.id!, titleSection);
        if (resp) {
        toEdit?.onCancel();
        notifier.show({ message: t("notifier:success.section_edited"), theme: "success" });
        globalAuthorizedContext?.regulation?.reloadSidebarData?.(toEdit.section.id);
        console.log(9111111);
      } else {
        notifier.show({ message: t("notifier:success.section_edited"), theme: "success" });
      }
    } else {
      const parentId = regulationStore.getParentId;
      console.log("parentId===========================================", parentId);
      const generatedSection = {
        name: titleSection,
        description: "",
        userSelectionRules,
        regulationsFilter: {
          regulationIds,
          navigationMenuItemIds,
        },
        parentId: parentId !== -1 ? parentId : undefined,
      };
      api.navigationMenu
        .create(generatedSection)
        .then((res) => {
          notifier.show({ message: t("notifier:success.section_created"), theme: "success" });
          navigate(`/policy/topics/${res.id}`);
          globalAuthorizedContext?.regulation?.reloadSidebarData?.(parentId !== -1 ? parentId : undefined);
        })
        .catch(() => {
          notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
        })
        .finally(() => {
          setIsRequestLoading(false);
        });
    }
  };

  //
  const handlePutAccessSettings = async (rules: UserSelectionRule[]): Promise<boolean> => {
    if (section) {

      // await api.section.edit(section.id!, { ...section, userSelectionRules: [...rules] });
      handleLoadSection();
      handleCloseAssignForStudyDialog();
      return true;
    }
    handleCloseAssignForStudyDialog();
    return false;
  };

  const handleCancelCreate = useCallback(() => {
    setNewRegulations([]);
    const parentId = regulationStore.getParentId;
    regulationStore.setParentId(-1);
    if (parentId !== -1) {
      navigate(`/policy/topics/${parentId}`);
    } else {
      navigate("/policy");
    }
  }, []);

  const handleCancel = useCallback((toEdit?: IeditableSectionForm) => {
    return toEdit?.isEdit ? toEdit?.onCancel() : handleCancelCreate();
  }, []);

  const isEditable = regulationStore.getSectionIsEditable;
  const setIsEditable = (isEditable: boolean) => regulationStore.setSectionIsEditable(isEditable);

  // const [isEditable, setIsEditable] = useState<boolean>(false);

  const handleSwitchEditable = useCallback(() => {
    console.log("handleSwitchEditable");
    if (!isEditable && section) {
      setTitleSection(section.name);
    }
    setIsEditable(!isEditable);
  }, [section, isEditable]);

  const handleOffEditable = () => {
    console.log("handleOffEditable");
    handleLoadSection();
    setIsEditable(false);
  };


  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState<boolean>(false);
  const handleOpenConfirmDialog = useCallback(() => {
    setIsOpenConfirmDialog(true)
  }, []);
  const handleCloseConfirmDialog = useCallback(() => setIsOpenConfirmDialog(false), []);

  // const handleDeleteSection = () => {
  //   api.section
  //     .del(section?.id!)
  //     .then(() => {
  //       notifier.show({ message: t("notifier:success.delete_section"), theme: "success" });
  //       navigate("/policy/topics");
  //     })
  //     .finally(() => globalAuthorizedContext?.regulation?.reloadSidebarData?.())
  //     .catch(handlerApiError);
  // };

  const onMove = useCallback(async (id: number, overId?: number) => {
    // console.log('onMove', section);
    const data = {
      itemIds: [id],
      insertAfterItemId: overId !== -1 ? overId : undefined,
      newParentId: section?.id!,
    }
    const resp = await api.navigationMenu.move(data);
    if (resp !== null) {
      globalAuthorizedContext?.regulation?.reloadSidebarData?.(section?.id);
    }
    console.log("resp", resp);
  }, [section]);


  const onRemovalRegulationsFromSection = useCallback(
    async (id: number, isRegular: boolean
    ): Promise<boolean | null> => {
    const status = isRegular
      ? await api.navigationMenu.deleteItem(id)
      : await api.navigationMenu.move({
        itemIds: [id],
        newParentId: null,
      });
     return status;

  }, []);

  const handleApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const onAppointApprover = async (
    regulationId: number,
    approverId: number,
    resetTestResults: boolean,
    urgency?: string,
  ) => {
    const status = await api.regulation.review(regulationId, {
      userPublisherId: approverId,
      resetTestResultsOnPublish: resetTestResults,
      timeToStudy: urgency,
      })
    console.log('status', status);
    if (status === null) {
      handleApiError();
    }
      // .then(() => notifier.show({ message: t("notifier:success.submitted_for_approval"), theme: "success" }))
      // .finally(() => globalAuthorizedContext?.regulation?.reloadSidebarData?.())
      // .catch(handleApiError);
  };

  const [isOpenAddingRegulations, setIsOpenAddingRegulations] = useState<boolean>(false);

  const handleOpenAddingRegulation = useCallback(() => setIsOpenAddingRegulations(true), []);
  const handleCloseAddingRegulation = useCallback(() => setIsOpenAddingRegulations(false), []);

  const handleSubmitForPublish = useCallback(async (id: number, resetTestResults?: boolean): Promise<boolean | null> => {
    const status = await api.regulation.publish(id, true, resetTestResults);
    if (status === null) {
      handleApiError();
    } else {
      notifier.show({ message: t("notifier:success.regulation_is_approved"), theme: "success" });
      // globalAuthorizedContext?.regulation?.reloadSidebarData?.();
    }
    return status;
  }, []);


  const handleSubmitForReject = useCallback(async (id: number) => {
    const status = await api.regulation.reject(id);
    console.log("section", section);
    if (status === null) {
      handleApiError();
    } else {
      notifier.show({ message: t("notifier:success.regulation_is_rejected"), theme: "success" });
    }
  }, []);

  const handleAddedRegulationsToSection = useCallback(
    async (regulationIds: number[], navigationMenuItemIds: number[]
    ): Promise<boolean | null> => {
    console.log("regulations", regulationIds);
    console.log("sections", navigationMenuItemIds);
    console.log(section);
    const status = await api.navigationMenu
      .addRegulationsToSection(
        section?.id!,
        {
          regulationIds,
          navigationMenuItemIds,
        });
    if (status !== null) {
      globalAuthorizedContext?.regulation?.reloadSidebarData?.(section?.id!);
    }
    return status;
  }, [section]);

  const getRegulations = useCallback(
    async (regulationIds: number[], navigationMenuItemIds: number[]): Promise<RegulationDto[]> => {
    const response = await api.navigationMenu
      .getRegulations(
        {
          regulationIds,
          navigationMenuItemIds,
          pageSize: -1,
        });
    if (response !== null) {
      return response.items ?? [];
    }
    return [];
  }, [section]);

  const getLink = useCallback((id: number, isRegulation: boolean, sectionId?: number): string => {
    if (isRegulation) {
      return sectionId
        ? `/policy/topics/${sectionId}/policy/${id}`
        : `/policy/all/${id}/`;
    }
    return `/policy/topics/${id}`;
  }, []);

  const handleClickCopyLink = useCallback(async (id: number, isRegulation: boolean, sectionId?: number) => {
    const pathname = getLink(id, isRegulation, sectionId);
    const link = `${window.location.origin}${pathname}`;
    await navigator.clipboard
      .writeText(link)
      .then(() => notifier.show({ message: t("notifier:success.copy"), theme: "success" }))
      .catch(() => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" }));
  }, []);


  const getStatusCell = useCallback((status: RegulationStatus | number | undefined, isRegular?: boolean) => {
    if (isRegular) {
      return (
        <TagLabel
          text={ t(`ui:regulation_status.${status as RegulationStatus}`)}
          color={getStatusLabelColor(status! as RegulationStatus)}
        />
      );
    }
    return (
      <Progress
        style={{ fontSize: 14 }}
        percent={status ? Math.round(+status) : 0}
        status={"active"}
      />
    )
  } ,[]);

  const handleToggleFavorites = async (id: number, isRegular: boolean, isFavorite: boolean) => {
    const uri: "addFavorite" | "removeFavorite" = isFavorite ? "removeFavorite" : "addFavorite";

    const status = isRegular
      ? await api.regulation[uri](id)
      : await api.navigationMenu[uri](id);

    const regulation = regulationStore.getRegulations;
    const currentItem = regulation
      .find((item) => isRegular ? item.regulationId === id : item.id === id);
    console.log("currentItem", currentItem);
    if (currentItem) {
      console.log("regulation", currentItem.regulation);
      console.log("isRegular", isRegular);
      const updateItem = {
        ...currentItem,
        isFavorite: !isFavorite,
      }
      console.log("updateItem", updateItem);
      regulationStore.updateRegulationById(updateItem);
    }
  }

  return {
    isLoading,
    globalAuthorizedContext,
    titleSection,
    newRegulations,
    handleSave,
    setNewRegulations,
    setUserSelectionRules,
    setTitleSection,
    userSelectionRules,
    isRequestLoading,
    isOpenAccessSettingsDialog,
    isOpenAssignForStudyDialog,
    handleOpenAssignForStudyDialog,
    handleCloseAssignForStudyDialog,
    handleCloseAccessSettingsDialog,
    handleOpenAccessSettingsDialog,
    handleCancel,
    breadcrumbsList,
    section,
    handlePutAccessSettings,
    isEditable,
    handleSwitchEditable,
    handleOffEditable,
    isOpenConfirmDialog,
    handleOpenConfirmDialog,
    handleCloseConfirmDialog,
    regulationIds,
    setRegulationIds,
    navigationMenuItemIds,
    setNavigationMenuItemIds,
    onMove,
    onRemovalRegulationsFromSection,
    onAppointApprover,
    isOpenAddingRegulations,
    handleOpenAddingRegulation,
    handleCloseAddingRegulation,
    handleSubmitForPublish,
    handleSubmitForReject,
    fetchSection,
    handleAddedRegulationsToSection,
    getRegulations,
    setSection,
    handleClickCopyLink,
    getStatusCell,
    getLink,
    handleToggleFavorites,
    handleLoadSection,
    handleClickCreateDocument,
    handleClickCreateSection,
  }
}

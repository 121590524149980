import React from "react";
import CompanySwitcherView from "./CompanySwitcherView";
import { useCompanySwitcher } from "../../../../functional/hooks";
import { observer } from "mobx-react-lite";

function CompanySwitcher() {
  const {
    isMenuOpen,
    menuAnchorEl,
    getCurrentCompanyId,
    companiesList,
    handleMenuButtonClick,
    handleMenuClose,
    handleMenuItemClick,
    handleCompanyManageClick,
  } = useCompanySwitcher();

  return (
    <CompanySwitcherView
      isMenuOpen={isMenuOpen}
      menuAnchorEl={menuAnchorEl}
      currentCompanyId={getCurrentCompanyId}
      companiesList={companiesList}
      handleMenuButtonClick={handleMenuButtonClick}
      handleMenuClose={handleMenuClose}
      handleMenuItemClick={handleMenuItemClick}
      handleCompanyManageClick={handleCompanyManageClick}
    />
  );
}

export default observer(CompanySwitcher);

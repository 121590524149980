import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage, useRootStore, useTheme, useThemeSwitcher } from "../../../../../functional/hooks";
import { observer } from "mobx-react-lite";
import { Badge, ButtonFloat, Icon, IconButton, Select, SelectOption } from "../../../../uiKit";
import { FiMoon, FiSun } from "react-icons/fi";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import { Portal } from "react-portal";
import { ThemeSwitchSuggestionDialog } from "../../../dialogs/themeSwitchSuggestionDialog/ThemeSwitchSuggestionDialog";
import dayjs from "dayjs";
import { FiClock } from "@react-icons/all-files/fi/FiClock";
import { api } from "../../../../../functional/services";
import { IThemeSwitcher } from "../../../../../functional/types";

function ThemeSwitcherObserved(props: IThemeSwitcher) {
  const { devStore } = useRootStore();
  const { t } = useTranslation();
  const {
    isSuggestionDialogOpen,
    closeThemeSwitchSuggestionDialog,
    handleDecline,
    handleUserTZChange,
    handleThemeChange,
    themeId,
    appThemesArray,
  } = useThemeSwitcher();

  return (
    <>
      <ThemeSwitchSuggestionDialog
        isOpen={isSuggestionDialogOpen}
        onClose={closeThemeSwitchSuggestionDialog}
        onDecline={handleDecline}
      />
      {(devStore.getIsThemeSwitcherFloatButtonVisible || window.location?.hostname == "localhost") && (
        <Portal>
          <ButtonFloat
            style={{ zIndex: 2147483647, bottom: 104 }}
            onClick={handleUserTZChange}
            description={<span style={{ fontSize: "10px" }} children={dayjs().tz().utcOffset() / 60} />}
            shape="square"
            className="printHidden"
            icon={<Icon component={() => <FiClock opacity={0.7} fontSize={20} />} />}
          />
          <ButtonFloat
            style={{ zIndex: 2147483647 }}
            onClick={() => handleThemeChange(themeId == 1 ? 0 : 1)}
            className="printHidden"
            icon={
              <Icon
                component={() =>
                  themeId == 1 ? <FiSun opacity={0.7} fontSize={20} /> : <FiMoon opacity={0.7} fontSize={20} />
                }
              />
            }
          />
        </Portal>
      )}
      {props.displayMode == "button" ? (
        <Badge
          count="β"
          size="small"
          offset={[-18, 18]}
          style={{ opacity: 0.85, transform: "scale(0.8)", pointerEvents: "none" }}
          // style={{ minWidth: "10px", height: "10px", fontSize: "8px", lineHeight: "1em" }}
          // style={{ backgroundColor: "transparent", color: "var(--color-error-base)" }}
        >
          <IconButton
            className="flex-shrink-0"
            onClick={() => handleThemeChange(themeId == 1 ? 0 : 1)}
            icon={
              <Icon
                component={() =>
                  themeId == 1 ? <FiSun fontSize={24} /> : <FiMoon fontSize={24} />
                }
              />
            }
          />
        </Badge>
      ) : (
        <>
          <SubheaderText text={t("ui:subheader.app_theme") + " (β)"} />
          <Select value={themeId} fullWidth onChange={(value) => handleThemeChange(Number(value))}>
            {appThemesArray.map((theme) => (
              <SelectOption key={theme.id} value={theme.id}>
                {theme.name}
              </SelectOption>
            ))}
          </Select>
        </>
      )}
    </>
  );
}

export const ThemeSwitcher = observer(ThemeSwitcherObserved);

import React, { useEffect, useState } from "react";
import { Avatar, Card, Divider, Icon, Link, Text } from "../../../../uiKit";
import { FiAlertTriangle } from "@react-icons/all-files/fi/FiAlertTriangle";
import { FiMail } from "@react-icons/all-files/fi/FiMail";
import { FiArrowRight } from "@react-icons/all-files/fi/FiArrowRight";
import { FiCheck } from "@react-icons/all-files/fi/FiCheck";
import { FiX } from "@react-icons/all-files/fi/FiX";
import { FiPhone } from "@react-icons/all-files/fi/FiPhone";
import { FiSend } from "@react-icons/all-files/fi/FiSend";
import { useDateHelpers, useColorSchemeStyles, useUserNameFormat } from "../../../../../functional/hooks";
import { api } from "../../../../../functional/services";
import { ControlSessionHistoryDto } from "../../../../../functional/api";

interface IDriverHistoryCard {
  sH: ControlSessionHistoryDto;
}

export const DriverHistoryCard = (props: IDriverHistoryCard) => {
  const dateHelpers = useDateHelpers();
  const { getUserShortFullName, getAvatarText } = useUserNameFormat();

  const [driverRecordLink, setDriverRecordLink] = useState<string | null>(props.sH.recordLink ?? null);

  const { findColorByKey } = useColorSchemeStyles();

  useEffect(() => {
    if (driverRecordLink == null) {
      updateDriverRecordLink();
    }
  }, [driverRecordLink, props.sH.recordId]);

  const updateDriverRecordLink = async () => {
    if (props.sH.recordId == null) return;
    const r = await api.telephony.updateRecordLink(String(props.sH.recordId));
    if (r == null) {
      return;
    }
    setDriverRecordLink(r);
  };

  const handleDriverRecordLinkError = async (errorEvent: any) => {
    if (errorEvent != null) {
      await updateDriverRecordLink();
    }
  };

  return (
    <div className="mb-2">
      <Card
        key={props.sH.id}
        variant="secondary"
        // className={`driver-page__history--list__item mt-${props.index > 0 ? 2 : 0}`}
        // style={{
        //   backgroundColor: props.sH.colorSchemeKey
        //     ? findColorByKey(props.sH.colorSchemeKey as colorSchemeType)?.backgroundColorSecond
        //     : undefined,
        // }}
      >
        {props.sH.createdByUser && (
          <>
            <div className="d-stack spacing-3 align-center">
              <Avatar
                text={getAvatarText(
                  props.sH.createdByUser?.lastName ?? "",
                  props.sH.createdByUser?.firstName ?? "",
                )}
                src={props.sH.createdByUser?.avatar?.image?.url}
                color={props.sH.createdByUser?.color}
                size={40}
              />
              <div className="d-stack-column flex-grow-1">
                <Text weight={500} children={
                  getUserShortFullName(
                    props.sH.createdByUser?.lastName ?? "",
                    props.sH.createdByUser?.firstName ?? "",
                    props.sH.createdByUser?.middleName ?? "",
                  )
                } />
                <Text weight={500} type="secondary" children={props.sH.createdByUser?.email} />
              </div>
              <div className="align-self-start">
                <Text
                  children={
                    props.sH.dateCreated
                      ? dateHelpers.formatDate(props.sH.dateCreated, { formatObject: { month: "short" } })
                      : ""
                  }
                />
              </div>
            </div>
            <Divider className="my-3" />
          </>
        )}
        <div className="d-stack-column spacing-1">
          <div className="d-stack spacing-2 align-center">
            {props.sH.icon != null && (
              <div style={{ lineHeight: 1 }}>
                {props.sH.icon == "icon.warning" && (
                  <Icon component={() => <FiAlertTriangle size={16} stroke="var(--color-warning-main)" />} />
                )}
                {props.sH.icon == "icon.message" && (
                  <Icon component={() => <FiMail size={16} stroke="var(--color-primary-base)" />} />
                )}
                {props.sH.icon == "icon.arrow_right" && (
                  <Icon component={() => <FiArrowRight size={16} stroke="var(--color-primary-base)" />} />
                )}
                {props.sH.icon == "icon.check_mark" && (
                  <Icon component={() => <FiCheck size={16} stroke="var(--color-success-base)" />} />
                )}
                {props.sH.icon == "icon.cross_mark" && (
                  <Icon component={() => <FiX size={16} stroke="var(--color-error-base)" />} />
                )}
                {props.sH.icon == "icon.telephone" && (
                  <Icon component={() => <FiPhone size={16} stroke="var(--color-primary-base)" />} />
                )}
                {props.sH.icon == "icon.messenger" && (
                  <Icon component={() => <FiSend size={16} stroke="var(--color-primary-base)" />} />
                )}
              </div>
            )}
            {props.sH.name != null && <Text className="flex-grow-1" children={props.sH.name} />}
          </div>
          {props.sH.description != null && (
            <Text type="secondary" weight={500}>{`${props.sH.description} ${
              (props.sH.datePostpone ?? "").trim().length > 0
                ? dateHelpers.formatDate(props.sH.datePostpone ?? "", { formatObject: { month: "short" } })
                : ""
            }`}</Text>
          )}
          {props.sH.text != null && <Text children={props.sH.text} />}
          {driverRecordLink != null && driverRecordLink.trim().length > 0 && (
            <audio
              className="full-width br-md mt-1"
              style={{ height: "40px" }}
              onError={handleDriverRecordLinkError}
              src={driverRecordLink}
              controlsList="nodownload noplaybackrate"
              preload="metadata"
              controls
            >
              <Link children={driverRecordLink} />
            </audio>
          )}
        </div>
      </Card>
    </div>
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import { Button, DatePickerRange, Dialog, DialogActions, Text } from "../../../uiKit";
import dayjs, { Dayjs } from "dayjs";

interface IAdminDriverPeriodSelectDialogView {
  open: boolean;
  value:
    | {
        from: string;
        to: string;
      }
    | undefined;
  allowNull?: boolean;
  onChange: (value: { from: string; to: string }) => void;
  onSave: () => void;
  onClose: () => void;
  disableFuture?: boolean;
}

function AdminDriverPeriodSelectDialogView(props: IAdminDriverPeriodSelectDialogView) {
  const { t } = useTranslation();

  const handleChangeRange = (value: (Dayjs | null)[] | null) => {
    //@ts-ignore
    if (!value && props.allowNull) return props.onChange(undefined);
    props.onChange({
      from: (value && value[0]?.toISOString()) ?? new Date().toISOString(),
      to: (value && value[1]?.toISOString()) ?? new Date().toISOString(),
    });
  };

  return (
    <Dialog
      width={400}
      open={props.open}
      onClose={props.onClose}
      title={t("ui:title.date_range_select")}
      closable={false}
    >
      <div className="d-stack-row justify-center full-width">
        <DatePickerRange
          value={props.value ? (Object.values(props.value).map((item) => dayjs(item)) as [Dayjs, Dayjs]) : undefined}
          disableFuture={!!props.disableFuture}
          onChange={handleChangeRange}
        />
      </div>
      {props.value?.from && props.value?.to && props.value?.from > props.value?.to && (
        <Text className="mt-3" style={{ color: "var(--color-txt-error)" }}>
          {t("parse:start_date_bigger_end")}!
        </Text>
      )}
      <DialogActions>
        <Button onClick={props.onClose} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button
          disabled={props.value?.from && props.value?.to ? props.value.from > props.value.to : undefined}
          onClick={props.onSave}
          variant="filled"
        >
          {t("ui:button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AdminDriverPeriodSelectDialogView;

import React from "react";
import { ButtonProps } from "antd/lib/button";
import "./IconButton.scss";
// import { Button } from "antd";
import clsx from "clsx";
import { Button } from "../button/Button";

interface IIconButtonView extends ButtonProps {
  isShowBorder?: boolean;
  isShowBackground?: boolean;
}

export function IconButtonView({ className, children, isShowBorder, isShowBackground, ...rest }: IIconButtonView) {
  return (
    <Button
      type={"default"}
      shape={"default"}
      // shape={"circle"}
      className={clsx(
        "uiKit-iconButton",
        "flex-shrink-0",
        className ?? "",
        {
          "uiKit-iconButton_border": isShowBorder,
          "uiKit-iconButton_bg": isShowBackground,
        }
      )}
      icon={children}
      {...rest}
    />
  );
}

import React, { forwardRef, useEffect, useState } from "react";
import { IIssueCreateContainer } from "./IssueCreateContainer.interface";
import { StaticFileDto } from "../../../../../../functional/api";
import { IssueFormKeys } from "../../../../../../functional/types/IssueForm.interface";
import { IssueFormWrapper } from "../../forms/common/IssueFormWrapper";
import { useIssueCreateContainer } from "../../../../../../functional/hooks";

export const IssueCreateContainer = forwardRef((props: IIssueCreateContainer, ref) => {
  const {
    attachmentsIssue,
    executorIds,
    handleChangeExecutorIds,
    setAttachmentsIssueSync,
    handleDeleteAttachmentsByFileId,
  } = useIssueCreateContainer(props);

  return (
    <>
      <IssueFormWrapper
        issueType={props.issueType}
        formType={IssueFormKeys.create}
        attachments={attachmentsIssue}
        executorIds={executorIds}
        onCreate={props.onCreate}
        updater={props.updater}
        onChangeExecutorIds={handleChangeExecutorIds}
        ref={ref}
        customFields={props.customFields}
        setAttachmentsIssueSync={setAttachmentsIssueSync}
        onDeleteAttachmentsByFileId={handleDeleteAttachmentsByFileId}
      />
    </>
  );
});

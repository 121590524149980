import React, { forwardRef } from "react";
import { IUpdateNoteEditor } from "./UpdateNoteEditor.interface";
import { TextEditor as TextEditorLexical } from "../../../../textEditor/TextEditor";
import { initialEditorContent } from "../../../../../../functional/utils/textEditor";

export const UpdateNoteEditor = forwardRef((props: IUpdateNoteEditor, ref) => {
  return (
    <>
      {/*<TextEditorOld*/}
      {/*  readOnly={!props.isEditable}*/}
      {/*  id="release-draft-editor"*/}
      {/*  ref={ref}*/}
      {/*  onChange={props.onChangeDraftUpdateNoteContent}*/}
      {/*  value={props.draftUpdateNoteContent ?? initialContent}*/}
      {/*  initialValue={props.draftUpdateNoteContent ?? initialContent}*/}
      {/*/>*/}
      <TextEditorLexical
        id={"release-draft-editor"}
        ref={ref}
        readOnly={!props.isEditable}
        onChange={props.onChangeDraftUpdateNoteContent}
        value={props.draftUpdateNoteContent ?? initialEditorContent}
        initialValue={props.draftUpdateNoteContent ?? initialEditorContent}
        withoutBackground
      />
    </>
  );
});

import React, { useMemo } from "react";
import { IUpdateNoteListContent } from "./UpdateNoteListContent.interface";
import { Divider, Empty } from "../../../../../uiKit";
import { UpdateNoteCard } from "../../components/UpdateNoteCard/UpdateNoteCard";
import { useRootStore } from "../../../../../../functional/hooks";

export const UpdateNoteListContent = (props: IUpdateNoteListContent) => {
  const { appStore } = useRootStore();

  const handleGetNotes = useMemo(() => {
    switch (props.isCurrentUserAdmin) {
      case true:
        return props.updateNotes;
      case false:
        const currentLocale = appStore.getAppLocale;
        return props.updateNotes.filter((note) => {
          const currentContent
            = note.contents!.find((content) => content.cultureKey === currentLocale);
          return note.isPublished && !!currentContent?.name
        });
    }
  }, [props.isCurrentUserAdmin, props.updateNotes]);

  return (
    <>
      <div className="d-flex flex-column full-height full-width">
        {/*<Card*/}
        {/*  title={t("ui:title.releases")}*/}
        {/*  size="default"*/}
        {/*  extra={<Button onClick={props.onRedirectToCreate}>{t("ui:button.add")}</Button>}*/}
        {/*  bodyStyle={{ padding: 0 }}*/}
        {/*  style={{ overflow: "hidden" }}*/}
        {/*  scrollStrategy="cardBody"*/}
        {/*>*/}
        {props.isDone && props.updateNotes.length == 0 ? (
          <Empty className="my-4" />
        ) : (
          <div style={{ borderRadius: "var(--border-radius-md)" }}>
            {handleGetNotes?.map((updateNote, index) => (
              <React.Fragment key={updateNote.id}>
                {index > 0 && <Divider />}
                <UpdateNoteCard updateNote={updateNote} />
              </React.Fragment>
            ))}
          </div>

          // <div>
          //   <ScrollTrigger
          //     disabled={props.isDone}
          //     onIntersection={props.loadNext}
          //     marginTop={props.updateNotes.length > 0}
          //     hidden={props.isDone}
          //   />
          // </div>
        )}
        {/*</Card>*/}
      </div>
    </>
  );
};

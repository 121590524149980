import React, { useEffect, useRef, useState } from "react";
import DriverExecutorAdminsDialogView from "./DriverExecutorAdminsDialogView";
import { User2CompanyDto, UserDto } from "../../../../functional/api";
import { useNotifier } from "../../../../functional/hooks";
import { api } from "../../../../functional/services";
import { useTranslation } from "react-i18next";

interface IDriverExecutorAdminsDialog {
  open: boolean;
  companies: User2CompanyDto[] | null;

  onClose(): void;
}

function DriverExecutorAdminsDialog(props: IDriverExecutorAdminsDialog) {
  const { t } = useTranslation();
  const notifier = useNotifier();

  const [adminsArray, setAdminsArray] = useState<UserDto[]>([]);
  const adminsArrayCopy = useRef<UserDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const setAdminsSync = (v: UserDto[]) => {
    adminsArrayCopy.current = v;
    setAdminsArray(adminsArrayCopy.current);
  };

  useEffect(() => {
    if (props.open) {
      generateAdminsArray();
    } else {
      setIsLoading(true);
      setAdminsSync([]);
    }
  }, [props.open]);

  const generateAdminsArray = async () => {
    const adminsIdsToFetch: number[] = (
      props.companies?.map((u2c: User2CompanyDto) => u2c.nearestAdminUserId as number) ?? []
    ).filter((n) => n != null);

    for await (const adminId of adminsIdsToFetch) {
      const r = await api.user.getById(adminId, { ignoreContext: true });
      if (r == null) {
        return;
      }
      setAdminsSync([...adminsArrayCopy.current, r]);
    }
    setIsLoading(false);
  };

  const onPhoneCopyClick = async (phone: string) => {
    await navigator.clipboard
      .writeText(phone)
      .then(() => notifier.show({ message: t("notifier:success.copy"), theme: "success" }))
      .catch(() => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" }));
  };

  const onPhoneCallClick = async (phoneNumber: string) => {
    if ((phoneNumber ?? "").trim().length == 0) {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      return;
    }
  };

  const onEmailCopyClick = async (email: string) => {
    await navigator.clipboard
      .writeText(email)
      .then(() => notifier.show({ message: t("notifier:success.copy"), theme: "success" }))
      .catch(() => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" }));
  };

  return (
    <DriverExecutorAdminsDialogView
      {...props}
      adminsArray={adminsArray}
      isLoading={isLoading}
      onPhoneCopyClick={onPhoneCopyClick}
      onEmailCopyClick={onEmailCopyClick}
      onPhoneCallClick={onPhoneCallClick}
    />
  );
}

export default DriverExecutorAdminsDialog;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { RolePositionDto } from "../../../../functional/api";
import { Checkbox, CheckboxGroup, Radio, RadioGroup } from "../../../uiKit";
import { IPositionSelectionBox, positionsKeys } from "../../../../functional/types/UserSelectionDialog.interface";

export const PositionSelectionBox: React.FC<IPositionSelectionBox> = (props) => {
  const { t } = useTranslation();
  const [disabledAllManagers, setDisabledAllManagers] = useState<boolean>(false);
  const [disabledAllPerformers, setDisabledAllPerformers] = useState<boolean>(false);
  const [disabledAll, setDisabledAll] = useState<boolean>(false);

  const handleIdentifyDisabledAllManagersOrPerformers = () => {
    if (!props.disabledRules?.length) return;
    const currentRoles = props.disabledRules
      ?.filter((item) => item.orgchartId === props.orgchartId)
      .filter((item) => item.roleId === props.role?.roleId);

    if (currentRoles) {
      currentRoles.forEach((currentRole) => {
        currentRole.rolePositionId == null && setDisabledAll(true);
        currentRole.positionType === positionsKeys.allManagers && setDisabledAllManagers(true);
        currentRole.positionType === positionsKeys.allPerformers && setDisabledAllPerformers(true);
      });
    } else {
      setDisabledAll(false);
      setDisabledAllManagers(false);
      setDisabledAllPerformers(false);
    }
  };

  const handleIdentifyDisabledByPositionId = (positionId: number): boolean =>
    !!props.disabledRules?.find((item) => item.rolePositionId === positionId);

  useEffect(() => {
    setDisabledAll(false);
    setDisabledAllManagers(false);
    setDisabledAllPerformers(false);
    handleIdentifyDisabledAllManagersOrPerformers();
  }, [props.disabledRules, props.role?.roleId]);

  useEffect(() => {
    props.setDisabledFromDown(disabledAll && props.rolePositionId === null);
    // props.setDisabledFromDown((props.rolePositionId === null && disabledAll) ? true : false)
  }, [props.role?.roleId, disabledAll]);


  const handleChangeRolePosition = useCallback((id: number) => {
    const { selectedPositions = [] } = props.role!;
    const selectedPositionsList = selectedPositions.includes(id)
      ? selectedPositions.filter((item: number) => item !== id)
      : [...selectedPositions, id];
    props.updateRolePosition(props.treeData, `${props.role?.roleId!}`, selectedPositionsList,  props.role?.positionType);
  }, [props.role]);

  // @ts-ignore
  const handleChangeRole = useCallback((e) => {
    const positionType = e.target.value as positionsKeys | null;
    const positionTypeValue = props.role?.positionType === positionType ? undefined : positionType;
    props.updateRolePosition(props.treeData, `${props.role?.roleId!}`, props.role?.selectedPositions ?? [], positionTypeValue);

    // positionType
  }, [props.role]);

  console.log("props.role2222222222", props.role);

  return (
    <div className="user-selection-dialog__right-bar d-stack-column spacing-2">
      <div
        className="user-selection-dialog__right-bar__radio-group d-stack-column spacing-2"
        // name="controlled-radio-buttons-group"
        // value={props.rolePositionId === null && disabledAll ? undefined : props.rolePositionId}
        // onChange={props.onChangeRolePositionId}
      >
        <div
          className="user-selection-dialog__right-bar__radio-group d-stack-column spacing-2"
        >
          {!props.disabledAllAll && (
            <Checkbox
              disabled={!props.role || disabledAll}
              value={null}
              checked={props.role?.positionType === null}
              onChange={handleChangeRole}
            >
              {t("ui:misc.radio_all")}
            </Checkbox>
          )}

          <Checkbox
            value={positionsKeys.allManagers}
            disabled={!props.role || disabledAllManagers}
            checked={props.role?.positionType === positionsKeys.allManagers}
            onChange={handleChangeRole}
          >
            {t("common:user_selection_dialog.position_select_box.all_managers")}
          </Checkbox>
          <Checkbox
            value={positionsKeys.allPerformers}
            disabled={!props.role || disabledAllPerformers}
            checked={props.role?.positionType === positionsKeys.allPerformers}
            onChange={handleChangeRole}
          >
            {t("common:user_selection_dialog.position_select_box.all_performers")}
          </Checkbox>
        </div>

        {(props.role?.positions)?.map((item: any) => (
            <Checkbox
              checked={props.role?.selectedPositions?.includes(item.id!) ?? false}
              disabled={props.role?.includeNestedRoles}
              value={item.id}
              key={item.id}
              onChange={() => handleChangeRolePosition(item.id!)}
            >
              {item.name}
            </Checkbox>
          ))}
      </div>
    </div>
  );
};

import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import { TextEditor as TextEditorLexical } from "../../modules/textEditor/TextEditor";
import { initialEditorContent } from "../../../functional/utils/textEditor";

interface IEditContentView {
  content: string;
  onContentChange: (value: string) => void;
  // onUploadJsonFile: (uploadedFile: File | undefined) => void;
  // onDownloadJsonFile: () => void;
  // setUploadFileName: (value: string | undefined) => void;
  // onUploadContentToServer: () => void;
}

export const EditContentView = forwardRef((props: IEditContentView, ref) => {
  // const contentRef = useRef<{ clear: () => void; reset: () => void; syncValue: () => void }>();
  // const fileUploadRef = useRef<any>(null);
  const { t } = useTranslation();

  // const clearContent = () => {
  //   contentRef.current?.clear();
  //   props.setUploadFileName(undefined);
  // };
  //
  // const clearRefValue = () => {
  //   fileUploadRef.current.value = "";
  // };
  //
  // useImperativeHandle(ref, () => ({
  //   reset: contentRef.current?.reset,
  //   clearValue: clearRefValue,
  //   syncValue: contentRef.current?.syncValue,
  // }));
  //
  // const handleUploadJsonMouseDown = (event: any) => {
  //   event.preventDefault();
  //   fileUploadRef.current?.click();
  // };

  return (
    <PageContent isFilled>
      {/*<TextEditor*/}
      {/*  id={"edit-content-page"}*/}
      {/*  value={props.content}*/}
      {/*  initialValue={initialContent}*/}
      {/*  onChange={props.onContentChange}*/}
      {/*  height="100vh"*/}
      {/*  ref={contentRef}*/}
      {/*  placeholder={t("ui:placeholder.upload_the_json_file")}*/}
      {/*/>*/}
      {/*<div className="mb-2" />*/}
      {/*<Button className="mr-2" onMouseDown={(event) => handleUploadJsonMouseDown(event)}>*/}
      {/*  {t("ui:button.upload")}*/}
      {/*</Button>*/}
      {/*<input*/}
      {/*  type="file"*/}
      {/*  accept="application/JSON"*/}
      {/*  ref={fileUploadRef}*/}
      {/*  style={{ display: "none" }}*/}
      {/*  onChange={(e) => props.onUploadJsonFile(e.target?.files?.[0])}*/}
      {/*/>*/}
      {/*<Button className="mr-2" onClick={props.onDownloadJsonFile}>*/}
      {/*  {t("ui:button.save")}*/}
      {/*</Button>*/}
      {/*<Button className="mr-2" onClick={props.onUploadContentToServer}>*/}
      {/*  {t("ui:button.format")}*/}
      {/*</Button>*/}
      {/*<Button onClick={clearContent} type="default">*/}
      {/*  {t("ui:button.clear")}*/}
      {/*</Button>*/}
      <TextEditorLexical
        id="edit-content-editor"
        value={props.content}
        initialValue={initialEditorContent}
        onChange={props.onContentChange}
        placeholder={t("ui:placeholder.upload_the_json_file")}
      />
    </PageContent>
  );
});

import React, { useEffect, useState } from "react";
import UpvotyWidgetView from "./UpvotyWidgetView";
import { upvoty } from "../../../scripts/upvoty";
import { useApi, useRootStore } from "../../../functional/hooks";
import { api } from "../../../functional/services";

interface IUpvotyWidget {
  //
}

function UpvotyWidget(props: IUpvotyWidget) {
  const { authStore, appStore } = useRootStore();
  const [isLoading, setIsLoading] = useState(true);

  const BOARD_HASH = "6d5fe4373306cc9da0e8bc68d312dca78a189968f0a468d8cc4b1d61f321ce66";
  // const SSO_TOKEN = "b7aab36bea976d0b5c1bb5afc89d2f00";
  const BASE_URL = "tonnus-board.upvoty.com";

  useEffect(() => {
    getSsoToken.fetch();
    return () => {
      upvoty.destroy();
    };
  }, []);

  // const user = authStore.getInitialInfo?.identity;

  const getSsoToken = useApi(
    () => api.authorized.getSSOToken(),
    () => {
      setIsLoading(false);
    },
    (res) => {
      initUpvoty(res ?? "");
      setIsLoading(false);
    }
  );

  const initUpvoty = (ssoToken: string) => {
    if (ssoToken.length == 0) return;
    upvoty.init("render", { boardHash: BOARD_HASH, ssoToken: ssoToken, baseUrl: BASE_URL });
  };

  return !isLoading ? <UpvotyWidgetView /> : <div />;
}

export default UpvotyWidget;

import React from "react";
import { IRegulationProgressBarView } from "./RegulationProgressBar.interface";
import "./RegulationProgressBar.scss";
import { theme } from "antd";
import { useRootStore } from "../../../../../../functional/hooks";

export const RegulationProgressBarView = ({ progress }: IRegulationProgressBarView) => {
  const { useToken } = theme;
  const { token } = useToken();
  const { appStore } = useRootStore();

  return (
    <div className="progress-bar">
      <div className="progress-bar__determinate" style={{ width: `${progress.progressPercent ?? 0}%` }} />
      <span className="progress-bar__percentage_placeholder" children="0%" />
      <span
        className="progress-bar__percentage"
        style={{
          color: progress.progressPercent > 60 ? "var(--color-txt-inverse)" : "var(--color-txt-primary)",
          clipPath: `polygon(0 0, ${progress.progressPercent ?? 0}% 0, ${progress.progressPercent ?? 0}% 100%, 0 100%)`,
        }}
        children={`${Number((progress.progressPercent ?? 0).toFixed(2))}%`}
      />
      <span
        className="progress-bar__percentage"
        style={{
          clipPath: `polygon(${progress.progressPercent ?? 0}% 0, 100% 0, 100% 100%, ${
            progress.progressPercent ?? 0
          }% 100%)`,
        }}
        children={`${Number((progress.progressPercent ?? 0).toFixed(2))}%`}
      />
    </div>
  );
};

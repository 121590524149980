import React from "react";
import "./IssueCommon.scss";
import { IIssueCommonDialog, IssueFormKeys } from "../../../../functional/types/IssueCommonDialog.interface";
import { IssueCommonViewDialog } from "./IssueCommonViewDialog";
import { useIssueCommonDialog } from "../../../../functional/hooks";
import VideoDialog from "../videoDialog/VideoDialog";
import { CustomConfirmDialog } from "../customConfirmDialog/СustomConfirmDialog";
import { IssueTypeKeys } from "../../../../functional/constants/issueTypeKeys";
import { observer } from "mobx-react-lite";

export const IssueCommonDialog = observer((props: IIssueCommonDialog) => {
  const {
    isOpenConfirmDialog,
    setIsOpenConfirmDialog,
    handleForceCloseDialog,
    dialogNotifications,
    createFormRef,
    createBtnIsLoading,
    handleCreate,
    handleClickCreate,
    currentUpdater,
    customFields,
    handleCloseDialog,
    isLoading,
  } = useIssueCommonDialog(props);

  return (
    <>
      {props.issueType === IssueTypeKeys.task && props.formType === IssueFormKeys.create && (
        <VideoDialog
          trainingKey="ed.t.ct"
          name="issueCreate"
          onWatchedClickObject={{ issueCreate: true }}
          hidden={!props.open}
        />
      )}
      <CustomConfirmDialog
        open={isOpenConfirmDialog}
        onClose={() => setIsOpenConfirmDialog(false)}
        onConfirm={handleForceCloseDialog}
        title={dialogNotifications.onClose}
      />
      <IssueCommonViewDialog
        ref={createFormRef}
        issueId={props.issueId}
        issueType={props.issueType}
        createBtnIsLoading={createBtnIsLoading}
        formType={props.formType}
        createFormRef={createFormRef}
        onCreate={handleCreate}
        onClickCreate={handleClickCreate}
        updater={currentUpdater}
        customFields={customFields}
        open={props.open}
        onClose={handleCloseDialog}
        onReopen={props.onRedirectToOtherIssue}
        isLoading={!(!props.isLoading && !isLoading)}
      />
    </>
  );
});

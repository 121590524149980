import React, { useEffect, useState } from "react";
import "./GlobalSearch.scss";
import { api } from "../../../../../../functional/services";
import {
  useRootStore,
  usePagingWithController,
  useDebounce,
  useGlobalSearch
} from "../../../../../../functional/hooks";
import { RelationsKeys } from "../../../../pages/communications/constants/keys";
import { GlobalSearchView } from "./GlobalSearchView";

export const GlobalSearch = () => {
  const {
    isOpen,
    isFocus,
    handleOpenDropdown,
    handleCloseDropdown,
    handleBlur,
    foundItems,
    handleToggleFocus,
    searchIssueText,
    handleChangeSearchText,
  } = useGlobalSearch()


  return (
    <GlobalSearchView
      isOpen={isOpen}
      isFocus={isFocus}
      onOpen={handleOpenDropdown}
      onClose={handleCloseDropdown}
      onBlur={handleBlur}
      searchedItems={foundItems.items}
      onToggleFocus={handleToggleFocus}
      searchIssueText={searchIssueText}
      onChangeSearchText={handleChangeSearchText}
      isDone={foundItems.info.isDone}
      isLoading={foundItems.info.isLoading}
      loadNext={foundItems.loadNext}
    />
  );
};

import { RoleDto } from "../../../../functional/api";
import { UserSelectionRule } from "../../../../functional/api/types/userSelectionRule";
import { RoleSelectorDto } from "../../../../functional/api/models/RoleSelectorDto";

export enum keysRulesFor {
  regulation = "REGULATION",
  section = "SECTION",
}

export type keysRulesForType = keysRulesFor.section | keysRulesFor.regulation;

export interface ISettingUpAccessDialog {
  isOpen: boolean;
  onClose: (isReload?: boolean) => void;
  keyRuleTo: keysRulesForType;
  currentRules?: UserSelectionRule[];
  // onSave: (rulesArr: RoleSelectorDto[]) => Promise<boolean>;
  isCreateMode?: boolean;
  regulationId?: number;
  sectionId?: number;
  title?: string;
}

export interface ISettingUpAccessDialogView {
  keyRuleTo: keysRulesForType;
  isOpen: boolean;
  isLoading: boolean;
  hasChanges: boolean;
  isExceptAccess: boolean;
  addRoleButtonIsDisabled: boolean;
  addExludedRoleButtonIsDisabled: boolean;
  roles: RoleDto[];
  selectionRoles: UserSelectionRule[];
  excludedSelectionRoles: UserSelectionRule[];
  onClose: () => void;
  onSave: () => void;
  onOpenUserSelectionDialog: () => void;
  onChangeIsExclude: (value: boolean) => void;
  onDeleteRole: (id: number) => void;
  onDeleteExcludedRole: (id: number) => void;
}

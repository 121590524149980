import React, { useCallback, useEffect, useState } from "react";
import VideoDialogView from "./VideoDialogView";
import { LocalStorageHelpers, useNotifier, useRootStore, useVideoDialog } from "../../../../functional/hooks";
import { observer } from "mobx-react-lite";
import { api } from "../../../../functional/services";
import { useTranslation } from "react-i18next";
import { IVideoDialog } from "../../../../functional/types";


function VideoDialog(props: IVideoDialog) {
  const {
    videoId,
    onAllowToSaveProgress,
    onSaveProgress,
    abilityToSave,
    handleClickClose,
    isPassed,
    isVideosState,
  } = useVideoDialog(props);

  return props.open && videoId ? (
    <VideoDialogView
      removeTitle={props.noShowTitle}
      onAllowToSaveProgress={onAllowToSaveProgress}
      videoId={videoId}
      onSaveProgress={onSaveProgress}
      autoPlay={props.autoPlay}
      abilityToSave={abilityToSave}
      handleLaterClick={handleClickClose}
      isPassed={isPassed}
    />
  ) : isPassed ? (
    <div />
  ) : // @ts-ignore
    isVideosState || props.hidden ? (
    <div />
  ) : !videoId && isPassed ? null : (
    <VideoDialogView
      removeTitle={props.noShowTitle}
      onAllowToSaveProgress={onAllowToSaveProgress}
      videoId={videoId!}
      autoPlay={props.autoPlay}
      onSaveProgress={onSaveProgress}
      abilityToSave={abilityToSave}
      handleLaterClick={handleClickClose}
      isPassed={isPassed}
    />
  );
}

export default observer(VideoDialog);

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spin, Text } from "../../../../uiKit";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import { AutocompleteModel, User2RoleDto, UserScheduleSubstituteDto } from "../../../../../functional/api";
import { useApi, useIsFirstRender } from "../../../../../functional/hooks";
import { api } from "../../../../../functional/services";
import { AutocompleteAsync } from "../../../../elements/autocompleteAsync/AutocompleteAsync";

interface IScheduleSetupDialogSubstituteContainerProps {
  applyToAllUserRoles: boolean;
  user2role: User2RoleDto;

  onChange: (value: UserScheduleSubstituteDto[]) => void;
}

type UserScheduleSubstituteDtoWithName = UserScheduleSubstituteDto & { name: string };

export const ScheduleSetupDialogSubstituteContainer = (props: IScheduleSetupDialogSubstituteContainerProps) => {
  const { t } = useTranslation();
  const isFirstRender = useIsFirstRender();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [list, setList] = useState<UserScheduleSubstituteDtoWithName[]>([]);

  const roles = useApi(
    () =>
      api.role.autocomplete({
        pageSize: -1,
        orderBy: "name",
        order: "asc",
        userIds: [props.user2role.userId!],
      }),
    null,
    (v) => {
      setIsLoading(false);
      setListFromAutocompleteWithCondition(v!.items!);
    }
  );

  const setListFromAutocompleteWithCondition = (v: AutocompleteModel[]): void =>
    setList(
      (props.applyToAllUserRoles ? v : v.filter((x) => x.id == props.user2role.roleId)).map(
        (x) =>
          ({
            roleId: x.id,
            name: x.text,
            userId: list.find((y) => y.roleId == x.id)?.userId ?? null,
          } as UserScheduleSubstituteDtoWithName)
      )
    );

  const handleListItemChange = (roleId: number, v: number | null): void =>
    setList(
      list.map((x) => {
        if (x.roleId == roleId) x.userId = v;
        return x;
      })
    );

  useEffect(() => props.onChange(list), [list]);

  useEffect(() => {
    if (isFirstRender) return;
    setListFromAutocompleteWithCondition(roles.value?.items ?? []);
  }, [props.applyToAllUserRoles]);

  return (
    <div className="d-stack-column">
      <SubheaderText text={t("ui:text.substitute")} />
      {isLoading ? (
        <div className="d-flex align-center justify-start">
          <Spin style={{ lineHeight: 1 }} size="small" spinning />
          <Text style={{ opacity: 0.8 }} className="ml-3" children={t("text:loading_data")} />
        </div>
      ) : (
        <div className="d-stack-column spacing-2">
          {list?.map((item) => (
            <div key={item.roleId} className="d-stack align-center spacing-2">
              <span
                style={{
                  flexShrink: 0,
                  width: "30%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                children={item.name}
              />
              <div className="flex-grow-1" style={{ overflow: "hidden" }}>
                <AutocompleteAsync
                  className="full-width"
                  type="user"
                  allowClear
                  size="middle"
                  idsToFilter={[props.user2role.userId!]}
                  value={item.userId ?? undefined}
                  onChange={(value) => handleListItemChange(item.roleId!, value != null ? +value : null)}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

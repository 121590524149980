import React, { memo, useEffect, useState } from "react";
import { UserDto, UserShortDto } from "../../../functional/api";
import { api } from "../../../functional/services";
import { useNotifier } from "../../../functional/hooks";
import { useTranslation } from "react-i18next";
import { UserInfoView } from "./UserInfoView";
import { Avatar } from "../../uiKit";

export type TUserInfoNameDisplayMode = "lastNameAndFirstName" | "fullName" | "none";

interface IUserInfo {
  className?: string;
  userIdToFetch?: number;
  requestOptions?: { [key: string]: any };
  user?: UserDto | UserShortDto;
  isTooltipDisabled?: boolean;
  avatarSize?: number;
  nameDisplayMode?: TUserInfoNameDisplayMode;
  nameStyle?: React.CSSProperties;
  width?: string;
  zIndex?: number;
  isStaticImg?: boolean;
  isLineThrough?: boolean;
  isShowIsFired?: boolean;
}

export const UserInfo = memo((props: IUserInfo) => {
  const notifier = useNotifier();
  const { t } = useTranslation();

  const [user, setUser] = useState<UserDto | UserShortDto>();
  const [isLoading, setIsLoading] = useState<boolean | undefined>(false);

  const handleShowError = () => {
    notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
  };

  const handleLoadUser = async (fetchUserId: number) => {
    setIsLoading(true);
    const res = await api.userShort.getById(fetchUserId, props.requestOptions);
    setIsLoading(res == null ? undefined : false);
    if (res == null) return handleShowError();
    res && setUser(res);
  };

  useEffect(() => {
    if (props.userIdToFetch && !props.user) handleLoadUser(props.userIdToFetch);
  }, [props.userIdToFetch]);

  const getFakeUserView = () => (
    <div className="user-info__container" style={{ maxWidth: "100%" }}>
      {/*<div className="user-info__container" style={{ maxWidth: `${props.width}%` }}>*/}
      <Avatar
        gap={6}
        //className={avatarStyles}
        size={props.avatarSize ?? 20}
        src={undefined}
        text="?"
        color="rgb(39, 31, 48)"
      />
      {/*{!props.isAvatarOnly && (*/}
      {/*  <p*/}
      {/*    className="user-info__name"*/}
      {/*    style={{ fontWeight: props.boldName ? 500 : 400, fontSize: props.fontSize ? `${props.fontSize}px` : "" }}*/}
      {/*  >*/}
      {/*    {t("parse:user_deleted")}*/}
      {/*  </p>*/}
      {/*)}*/}
    </div>
  );

  // if (isLoading == undefined) return getFakeUserView();

  return (
    <UserInfoView
      {...props}
      isLoading={isLoading}
      user={props.userIdToFetch && !props.user ? user : props.user}
      onError={handleShowError}
    />
  );
});

import React from "react";
import CompanyEditFormView from "./CompanyEditFormView";
import {
  useCompanyEditForm,
} from "../../../../functional/hooks";
import { observer } from "mobx-react-lite";
import { ICompanyEditForm } from "../../../../functional/types";



function CompanyEditForm(props: ICompanyEditForm) {
  const {
    companyData,
    updater,
    timeZones,
    canShowError,
    setCanShowError,
    scheduleData,
    handleScheduleSave,
    handleAvatarChange,
    handleAvatarDelete,
  } = useCompanyEditForm(props);

  return companyData.value && updater.currentState ? (
    <CompanyEditFormView
      companyData={companyData.value}
      updater={updater}
      firstLaunch={props.firstLaunch}
      onSaveClick={props.onSaveClick}
      timezonesList={timeZones}
      canShowError={canShowError}
      setCanShowError={setCanShowError}
      scheduleData={scheduleData}
      handleScheduleSave={handleScheduleSave}
      handleAvatarChange={handleAvatarChange}
      handleAvatarDelete={handleAvatarDelete}
    />
  ) : (
    <div />
  );
}

export default observer(CompanyEditForm);

import React, { memo, useCallback, useState } from "react";
import "./RegulationAccessControlDialog.scss";
import { RegulationAccessControlDialogProps } from "./RegulationAccessControlDialog";
import { Button, Dialog, Select, Tooltip } from "../../../uiKit";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { OrgchartSwitcher } from "../../../elements/orgchartSwitcher/OrgchartSwitcher";
import { FunctionCardCollapsePanel } from "./functionCardCollapsePanel/FunctionCardCollapsePanel";
import { SearchInputField } from "../../../elements/searchInputField/SearchInputField";
import { UserCard } from "../../../elements/userCard/UserCard";
import { FiHelpCircle } from "react-icons/fi";
import { useDebounce } from "../../../../functional/hooks";

export const RegulationAccessControlDialogView = memo((props: RegulationAccessControlDialogProps) => {
  const {
    isOpen,
    onClose,
    onOpenFunctionSelectionDialog,
    handleChangeOrgchartId,
    orgchartId,
    onSave,
    onChangeShowOnOrgStructure,
    isButtonLoading,
    isDisabledSaveButton,
    title,
  } = props;

  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [searchAccess, setSearchAccess] = useState<string>("");
  const debounceSearchAccess = useDebounce(searchAccess, 400);

  const onChangeSearchValue = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=> {
    setSearchAccess(event?.target.value)
  }, []);

  return (
    <Dialog
      title={
        props.title
          ? `${props.title} - ${t("common:menu.regulation_settings_dropdown.access_control")}`
          : t("common:menu.regulation_settings_dropdown.access_control")
      }
      open={isOpen}
      onClose={onClose}
      destroyOnClose
      closable={false}
    >
      {props.createdByUser && (
        <div className="created-user-info">
          <div className="created-user-info__text">
            { t("ui:subheader.regulation_access_control_dialog.creator") }:
          </div>
          <UserCard
            userInfo={props.createdByUser}
            avatarSize={24}
            isNewStyle
            nameSize={12}
            gap={8}
          />
          <Tooltip
            title={t("ui:tooltip.access_control_dialog.creator")}
            // placement="topLeft"
          >
            <FiHelpCircle className="" />
          </Tooltip>
        </div>
      )}

      <Form
        form={form}
        className="full-width"
        validateTrigger="onSubmit"
        requiredMark={false}
        autoComplete="on"
        layout="vertical"
        // onValuesChange={onValuesChange}
        // initialValues={}
      >
        {!!props.sectionsList?.length && (
          <Form.Item
            className="form-item form-item_bold form-item_mb5"
            name="navigtionItemId"
            label={t("ui:subheader.regulation_access_control_dialog.section")}
            initialValue={props.navigationMenuItemId}
          >
            <Select
              readOnly={props.sectionsList?.length === 1}
              disabled={props.sectionsList?.length === 1}
              items={props.sectionsList}
              value={undefined}
              onChange={(id: number) => {
                props.onChangeNavigationMenuItemId?.(id);
              }}
            />
          </Form.Item>
        )}
        <Form.Item
          className="form-item form-item_bold form-item_mb5"
          name="orgchartId"
          label={t("ui:subheader.regulation_access_control_dialog.orgchart")}
          initialValue={orgchartId}
          rules={
            [
              // { required: true, message: t("validation:field_required") },
            ]
          }
        >
          <OrgchartSwitcher
            drawType="select"
            onChange={handleChangeOrgchartId}
            value={orgchartId}
          />
        </Form.Item>

        <Form.Item
          className="form-item form-item_bold form-item_mb5"
          name="search-function"
          label={t("ui:subheader.regulation_access_control_dialog.accesses")}
          initialValue={searchAccess}
        >
          <SearchInputField
            value={searchAccess}
            setValue={setSearchAccess}
            // variant="standard"
            onChange={onChangeSearchValue}
            backgroundInherit
            placeholder={t("ui:placeholder.enter_role")}
          />
        </Form.Item>
        <Form.Item
          name="collapses"
          rules={
            [
              // { required: true, message: t("validation:field_required") },
            ]
          }
        >
          <FunctionCardCollapsePanel
            search={debounceSearchAccess}
            orgchartId={orgchartId}
          />
          <Button
            type="default"
            className="mt-4"
            onClick={onOpenFunctionSelectionDialog}
          >
            {t("ui:button.add")}
          </Button>
        </Form.Item>
        {/*<Form.Item*/}
        {/*  className="mt-6"*/}
        {/*  name="switch"*/}
        {/*  rules={*/}
        {/*    [*/}
        {/*      // { required: true, message: t("validation:field_required") },*/}
        {/*    ]*/}
        {/*  }*/}
        {/*>*/}
        {/*  <Switch*/}
        {/*    label={t("ui:label.show_on_orgchart")}*/}
        {/*    onChange={onChangeShowOnOrgStructure}*/}
        {/*  />*/}
        {/*</Form.Item>*/}
        <div className="d-stack-row justify-end">
          <Button type="default" onClick={onClose} variant="filled">
            {t("ui:button.cancel")}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            variant="filled"
            className="ml-3"
            onClick={onSave}
            disabled={isDisabledSaveButton}
            loading={isButtonLoading}
          >
            {t("ui:button.save")}
          </Button>
        </div>
      </Form>
    </Dialog>
  );
});

import React, { memo } from "react";
import { IDashboardContentView } from "../../../../../../functional/types/DashboardContent.interface";
import { Metrics } from "../../components/Metrics/Metrics";

export const DashboardContentView = memo((props: IDashboardContentView) => {
  const overflowY =  !props.metrics.items.length ? "hidden" : "scroll";
  return (
    <div
      style={{ overflowY: !props.metrics.items.length ? "hidden" : "scroll" }}
      className={`dashboard-content flex-grow-1 ${!props.metrics.items.length ? "d-flex align-center justify-center" : ""}`}
    >
      <Metrics
        metrics={props.metrics.items ?? []}
        isLoading={props.metrics.info.isLoading}
        isDone={props.metrics.info.isDone}
        loadNext={props.metrics.loadNext}
        weekReportStart={props.weekReportStart}
        dashboardColumnsAmount={props.dashboardColumnsAmount}
        deleteMetric={props.deleteMetric}
        restart={props.onRestartLoad}
        isPrint={props.isPrint}
        printMetricId={props.printMetricId}
        onSetIsPrintMetricId={props.onSetIsPrintMetricId}
      />
    </div>
  );
});

import { IDashboardSidebar } from "./DashboardSidebar.interface";
import "./DashboardSidebar.scss";
import { useTranslation } from "react-i18next";
import { Button, Icon, IconButton, Text } from "../../../../../uiKit";
import { FiSidebar } from "@react-icons/all-files/fi/FiSidebar";
import { staticElementsArr } from "./SidebarStaticElement/sidebarStaticArray";
import { SidebarStaticElement } from "./SidebarStaticElement/SidebarStaticElement";
import { SidebarCollapseElement } from "./SidebarCollapseElement/SidebarCollapseElement";
import { memo } from "react";

export const DashboardSidebar = memo((props: IDashboardSidebar) => {
  const { t } = useTranslation();

  return (
    <div className="dashboard-sidebar__wrapper full-height d-stack-column pa-4">
      <div className="d-stack-row align-center justify-space-between mb-4">
        <Button variant="filled" className="full-width" onClick={() => props.isOpenDialogChange(true, false)}>
          {t("ui:button.create_dashboard")}
        </Button>
        <IconButton
          disabled={!props.metrics.length && !props.availableDashboards.length && !props.myDashboards.length}
          className="flex-shrink-0 ml-4"
          onClick={props.onHidingSidebarChange}
          children={<Icon component={() => <FiSidebar />} />}
          isShowBackground
        />
      </div>
      <div className="dashboard-sidebar__content d-stack-column spacing-2">
        <div className="d-stack-column spacing-2">
          {staticElementsArr.map((el) => (
            <SidebarStaticElement key={el.uri} uri={el.uri} title={el.title} />
          ))}
        </div>
        <Text size="12px" className="ml-3 mt-2 mb-3" children={t("ui:subheader.dashboard")} />
        <div className="d-stack-column spacing-2">
          <SidebarCollapseElement
            elementKey={"My"}
            collapseName={t("common:tab.dashboard.my")}
            dashboards={props.myDashboards}
            isDone={props.myDashboardsIsDone}
            loadNext={props.myDashboardsLoadNext}
            collapsePanelKey={1}
          />
          <SidebarCollapseElement
            elementKey={"Available"}
            collapseName={t("common:tab.dashboard.available_to_me")}
            dashboards={props.availableDashboards}
            isDone={props.availableDashboardsIsDone}
            loadNext={props.availableDashboardsLoadNext}
            collapsePanelKey={2}
          />
        </div>
      </div>
    </div>
  );
});

import React, { useEffect, useMemo, useRef, useState } from "react";
import { OrgchartDrawerTabRecurringTasksView } from "./OrgchartDrawerTabRecurringTasksView";
import { RecurringIssueDto, RoleDto } from "../../../../../../functional/api";
import { useNotifier, useRootStore, usePagingWithController, useDebounce } from "../../../../../../functional/hooks";
import { useTranslation } from "react-i18next";
import { api } from "../../../../../../functional/services";
import { TIssuesFilters } from "../../orgchartCommonDrawerTabs/orgchartCommonDrawerTabRecurringTasks/OrgchartCommonDrawerTabRecurringTasks";
import { observer } from "mobx-react-lite";

export interface IOrgchartDrawerTabRecurringTasks {
  currentRole: RoleDto;
}

function OrgchartDrawerTabRecurringTasks(props: IOrgchartDrawerTabRecurringTasks) {
  const notifier = useNotifier();
  const { t } = useTranslation();
  const { orgchartStore } = useRootStore();
  // const orgchartContext = useContext(OrgchartContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isIssueCreateEditDialogOpen, setIsIssueCreateEditDialogOpen] = useState<boolean>(false);
  const [isIssueDeleteDialogOpen, setIsIssueDeleteDialogOpen] = useState<boolean>(false);
  const [isIssueHistoryDialogOpen, setIsIssueHistoryDialogOpen] = useState<boolean>(false);
  const [issueToManageId, setIssueToManageId] = useState<number | null>(null);
  // const [createOn, setCreateOn] = useState<"admins" | "executors" | "all" | undefined>("all");

  const [tabFilters, setTabFilters] = useState<TIssuesFilters>({
    name: "",
    roleId: null,
    isPaused: null,
    executorConfigType: "",
    executorPositionType: null,
  });

  const [tabSort, setTabSort] = useState<{ by: string; order: string }>({
    by: "date_created",
    order: "desc",
  });

  const axiosController = useRef<AbortController>(new AbortController());

  const recurringIssue = usePagingWithController(
    api.recurringIssue,
    {
      roleId: (props.currentRole?.id as number) ?? undefined,
      name: tabFilters.name,
      orgchartId: orgchartStore.getCurrentOrgchartId,
      // roleId: issuesFilters.roleId,
      executorConfigType: tabFilters.executorConfigType.length > 0 ? tabFilters.executorConfigType : null,
      isPaused: tabFilters.isPaused /* || null*/,
      executorConfigRolePositionType: tabFilters.executorPositionType,
      signal: axiosController.current?.signal,
    },
    {
      pageSize: 20,
      orderBy: tabSort.by,
      order: tabSort.order,
    },
  );

  const debouncedFilters = useDebounce(tabFilters, 500);
  const issueToManageData: RecurringIssueDto | null = useMemo(
    () => recurringIssue.items.find((rI) => rI.id == issueToManageId) ?? null,
    [issueToManageId, recurringIssue.items]
  );

  useEffect(() => {
    if (props.currentRole == null) return;
    setIsLoading(true);
    recurringIssue.reset();
    recurringIssue.restart().then(() => setIsLoading(false));
  }, [props.currentRole, debouncedFilters, tabSort]);

  useEffect(() => {
    return () => {
      axiosController.current.abort();
    }
  }, []);

  const handleIssuePauseClick = async (issueId: number) => {};

  const handleIssueHistoryClick = async (issueId: number) => {
    setIssueToManageId(issueId);
    setIsIssueHistoryDialogOpen(true);
  };

  const handleIssueEditClick = async (issueId: number) => {
    setIssueToManageId(issueId);
    setIsIssueCreateEditDialogOpen(true);
  };

  const handleIssueDeleteClick = async (issueId: number) => {
    setIssueToManageId(issueId);
    setIsIssueDeleteDialogOpen(true);
  };

  const handleIssueCreateClick = () => {
    setIssueToManageId(null);
    setIsIssueCreateEditDialogOpen(true);
  };

  const handleIssueAnyDialogClose = () => {
    setIsIssueDeleteDialogOpen(false);
    setIsIssueHistoryDialogOpen(false);
    setIsIssueCreateEditDialogOpen(false);
    setIssueToManageId(null);
  };

  const handleIssueDelete = async () => {
    setIsLoading(true);
    setIsIssueDeleteDialogOpen(false);
    const r = await api.recurringIssue.del(issueToManageId as number);
    if (r == null) {
      setIsLoading(false);
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error", timeout: 7 });
      return;
    }
    // recurringIssue.reset();
    await recurringIssue.restart(true);
    setIsLoading(false);
  };

  const handleIssueCreate = async () => {
    handleIssueAnyDialogClose();
    setIsLoading(true);
    recurringIssue.reset();
    await recurringIssue.restart();
    setIsLoading(false);
  };

  const handleIssueSave = async () => {
    handleIssueAnyDialogClose();
    setIsLoading(true);
    await recurringIssue.restart(true);
    setIsLoading(false);
  };

  return (
    <OrgchartDrawerTabRecurringTasksView
      currentRole={props.currentRole}
      isLoading={isLoading}
      issueToManageId={issueToManageId}
      issueToManageData={issueToManageData}
      isIssueCreateEditDialogOpen={isIssueCreateEditDialogOpen}
      isIssueDeleteDialogOpen={isIssueDeleteDialogOpen}
      isIssueHistoryDialogOpen={isIssueHistoryDialogOpen}
      recurringIssue={{
        items: recurringIssue.items,
        info: recurringIssue.info,
      }}
      tabFilters={tabFilters}
      tabSort={tabSort}
      setTabFilters={setTabFilters}
      setTabSort={setTabSort}
      onIssueCreateClick={handleIssueCreateClick}
      onIssuePauseClick={handleIssuePauseClick}
      onIssueHistoryClick={handleIssueHistoryClick}
      onIssueEditClick={handleIssueEditClick}
      onIssueDeleteClick={handleIssueDeleteClick}
      onIssueAnyDialogClose={handleIssueAnyDialogClose}
      onIssueDelete={handleIssueDelete}
      onIssueCreate={handleIssueCreate}
      onIssueSave={handleIssueSave}
      onLoadNext={recurringIssue.loadNext}
      // isAddDialogOpen={isAddDialogOpen}
      // currentRole={props.currentRole}
      // formMode={formMode}
      // setFormMode={setFormMode}
      // issues={issues}
      // handleSetAddDialogOpen={handleSetAddDialogOpen}
      // onCardPauseClick={handlePauseClick}
      // onCardWatchHistoryClick={handleHistoryWatchClick}
      // onCardEditClick={handleEditClick}
      // onCardDeleteClick={handleDeleteClick}
      // handleIssueDelete={handleIssueDelete}
      // isDeleteIssueDialogOpen={isDeleteIssueDialogOpen}
      // setIsDeleteIssueDialogOpen={setIsDeleteIssueDialogOpen}
      // issueToManageId={issueToManageId}
      // isIssueHistoryDialogOpen={isIssueHistoryDialogOpen}
      // setIsIssueHistoryDialogOpen={setIsIssueHistoryDialogOpen}
      // issueToManageData={issueToManageData}
      // setIssueToManageData={setIssueToManageData}
      // createOn={createOn}
      // setCreateOn={setCreateOn}
      // tabFilters={tabFilters}
      // setTabFilters={setTabFilters}
      // tabSort={tabSort}
      // setTabSort={setTabSort}
    />
  );
}

export default observer(OrgchartDrawerTabRecurringTasks);

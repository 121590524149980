import React, { useState } from "react";
import { Dropdown, Icon, IconButton } from "../../../../../uiKit";
import { DotsIcon } from "../../../../../../functional/constants/icon";
import { useTranslation } from "react-i18next";
import { IActionButtonProps } from "./IActionButton";

export const ActionButton = (props: IActionButtonProps) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleIsOpenChange = (value: boolean) => {
    setIsOpen(value);
  };

  const handleClickAddToSection = () => {
    handleIsOpenChange(false);
    props.onOpenFindSectionDialog?.();
  };

  const handleClickDelete = () => {
    handleIsOpenChange(false);
    props.onOpenDeleteRegulationDialog?.();
  };

  const handleClickSubmitForApproval = () => {
    handleIsOpenChange(false);
    props.onSubmitForApproval?.();
  };
   const handleClickSetAccess = () => {
     handleIsOpenChange(false);
     props.onSetAccess?.();
   };

  const handleClickAssignStudy = () => {
    handleIsOpenChange(false);
    props.onAssignStudyRegular?.();
  };



  const handleRemoveFromSection = () => {
    handleIsOpenChange(false);
    props.onRemoveFromSection?.();
  };

  const handleClickPublish = () => {
    handleIsOpenChange(false);
    props.onSubmitPublish?.();
  };

  const handleClickReject = () => {
    handleIsOpenChange(false);
    props.onSubmitReject?.();
  };

  const handleClickCopyLink = () => {
    handleIsOpenChange(false);
    props.onCopyLink?.();
  };



  return (
    <Dropdown
      open={isOpen}
      onOpenChange={handleIsOpenChange}
      placement={"bottom"}
      rootClassName={"regulation-editor-toolbar-action-drop-down"}
      trigger={["click"]}
      dropdownRender={() => {
        return (
          <div
            className="regulation-editor-toolbar-action"
          >
            {props.isSubmitForApproval && (
              <div
                className="regulation-editor-toolbar-action-item"
                onClick={handleClickSubmitForApproval}
              >
                <div className="regulation-editor-toolbar-action-item__title">
                  {t("ui:regulation_actions.submit_for_approval.title")}
                </div>
                <div className="regulation-editor-toolbar-action-item__description">
                  {t("ui:regulation_actions.submit_for_approval.text")}
                </div>
              </div>
            )}
            {props.isSubmitForPublish && (
              <div
                className="regulation-editor-toolbar-action-item"
                onClick={handleClickPublish}
              >
                <div className="regulation-editor-toolbar-action-item__title">
                  {t("ui:regulation_actions.publish.title")}
                </div>
                <div className="regulation-editor-toolbar-action-item__description">
                  {t("ui:regulation_actions.publish.text")}
                </div>
              </div>
            )}
            {props.isSubmitForReject && (
              <div
                className="regulation-editor-toolbar-action-item"
                onClick={handleClickReject}
              >
                <div className="regulation-editor-toolbar-action-item__title">
                  {t("ui:regulation_actions.reject.title")}
                </div>
                <div className="regulation-editor-toolbar-action-item__description">
                  {t("ui:regulation_actions.reject.text")}
                </div>
              </div>
            )}
            {props.isStudyAssignment && (
              <div
                className="regulation-editor-toolbar-action-item"
                onClick={handleClickAssignStudy}
              >
                <div className="regulation-editor-toolbar-action-item__title">
                  {t("ui:regulation_actions.study_assignment.title")}
                </div>
                <div className="regulation-editor-toolbar-action-item__description">
                  {t("ui:regulation_actions.study_assignment.text")}
                </div>
              </div>
            )}
            {props.isAccessToRegulations && (
              <div
                className="regulation-editor-toolbar-action-item"
                onClick={handleClickSetAccess}
              >
                <div className="regulation-editor-toolbar-action-item__title">
                  {t(`ui:regulation_actions.${props.isRegulation ? 'access_to_regulations' : 'access_to_sections'}.title`)}
                </div>
                <div className="regulation-editor-toolbar-action-item__description">
                  {t(`ui:regulation_actions.${props.isRegulation ? 'access_to_regulations' : 'access_to_sections'}.text`)}
                </div>
              </div>
            )}
            {props.isRemoveFromSection && (
              <div
                className="regulation-editor-toolbar-action-item"
                onClick={handleRemoveFromSection}
              >
                <div className="regulation-editor-toolbar-action-item__title">
                  {t("ui:regulation_actions.remove_from_section.title")}
                </div>
                <div className="regulation-editor-toolbar-action-item__description">
                  {t(`ui:regulation_actions.remove_from_section.${props.isRegulation ? 'text' : 'textSection'}`)}
                </div>
              </div>
            )}
            {props.isCopyLink && (
              <div
                className="regulation-editor-toolbar-action-item"
                onClick={handleClickCopyLink}
              >
                <div className="regulation-editor-toolbar-action-item__title">
                  {t("ui:regulation_actions.copy_link.title")}
                </div>
                <div className="regulation-editor-toolbar-action-item__description">
                  {t(`ui:regulation_actions.copy_link.${props.isRegulation ? 'text' : 'textSection'}`)}
                </div>
              </div>
            )}
            {props.isHashPermissionAddedToSection && (
              <div
                className="regulation-editor-toolbar-action-item"
                onClick={handleClickAddToSection}
              >
                <div className="regulation-editor-toolbar-action-item__title">
                  {t("ui:regulation_actions.added_to_section.title")}
                </div>
                <div className="regulation-editor-toolbar-action-item__description">
                  {t("ui:regulation_actions.added_to_section.text")}
                </div>
              </div>
            )}
            {props.isHashPermissionDelete && (
              <div
                className="regulation-editor-toolbar-action-item"
                onClick={handleClickDelete}
              >
                <div className="regulation-editor-toolbar-action-item__title">
                  {t("ui:regulation_actions.delete.title")}
                </div>
                <div className="regulation-editor-toolbar-action-item__description">
                  {t("ui:regulation_actions.delete.text")}
                </div>
              </div>
            )}
          </div>
        );
      }}
    >
      <IconButton
        onClick={() => handleIsOpenChange(true)}
        icon={<Icon component={() => (<DotsIcon />)} />}
        isShowBorder={false}
      />
    </Dropdown>
  )
}

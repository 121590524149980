import React from "react";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { UserDto } from "../../../../functional/api";
import { Alert, Button, Dialog, DialogActions } from "../../../uiKit";
import { AutocompleteAsync } from "../../../elements/autocompleteAsync/AutocompleteAsync";

interface ISignInAsAnotherUserDialogView {
  open: boolean;
  userId: number;
  allUsers: UserDto[];
  isDone: boolean;

  setUserId: (id: number) => void;

  onReset(): void;

  onCopyClick(): void;

  onClose(): void;

  onAccept(): void;
}

function SignInAsAnotherUserDialogView(props: ISignInAsAnotherUserDialogView) {
  const { t } = useTranslation();

  return (
    <Dialog
      width={600}
      title={t("parse:login_as_another_user")}
      open={props.open}
      onClose={props.onClose}
      closable={false}
    >
      <div>
        <SubheaderText text={t("parse:user")} />
        <AutocompleteAsync
          style={{ width: "100%" }}
          type="user"
          value={props.userId == 0 ? undefined : props.userId}
          onChange={(value) => {
            props.onReset();
            props.setUserId(Number(value as number));
          }}
          requestOptions={{ ignoreContext: true }}
        />
        {props.isDone && (
          <>
            <Alert className="my-4" description={t("parse:sign_in_as_another_user_description")}></Alert>
            <Button onClick={() => props.onCopyClick()} variant="filled">
              {t("parse:sign_in_as_another_user_copy_button")}
            </Button>
          </>
        )}
      </div>
      <DialogActions>
        {props.isDone && (
          <Button onClick={() => props.onReset()} variant="text">
            {t("ui:button.reset")}
          </Button>
        )}
        <Button onClick={() => props.onClose()} variant={props.isDone ? "filled" : "text"}>
          {props.isDone ? t("ui:button.close") : t("ui:button.cancel")}
        </Button>
        {!props.isDone && (
          <Button disabled={props.userId == 0} onClick={() => props.onAccept()} variant="filled">
            {t("ui:button.apply")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default SignInAsAnotherUserDialogView;

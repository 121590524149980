import React, { useCallback, useEffect, useState } from "react";
import "./FilterButton.scss";
import { IFilterMenu } from "./FilterButton.interface";
import { observer } from "mobx-react-lite";
import { BsFilter } from "@react-icons/all-files/bs/BsFilter";
import { Icon, IconButton, Popover } from "../../uiKit";

const FilterButtonObserver = (props: IFilterMenu) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleIsOpenChange = useCallback((value: boolean) => {
    if (props.onChangeIsOpenFilter) {
      props.onChangeIsOpenFilter(value);
    } else {
      setIsOpen(value);
    }
  }, []);

  useEffect(() => {
    if (props.isOpenFilter !== undefined) {
      setIsOpen(props.isOpenFilter);
    }
  }, [props.isOpenFilter]);

  return (
    <>
      <Popover
        open={isOpen}
        content={props.dropdownContent}
        placement={props.placement ?? "bottom"}
        arrow={{ pointAtCenter: false }}
        onOpenChange={handleIsOpenChange}
        trigger="click"
      >
        <IconButton
          children={<Icon component={() => <BsFilter  color={"var(--color-icn-accent)"}/>} />}
          isActiveFilter={props.isActiveFilter}
          isShowBorder={props.isShowBorder}
        />
        {/*<Button*/}
        {/*  className="filter-communications-button"*/}
        {/*  variant="default"*/}
        {/*  icon={<BsFilter style={{ margin: "0 auto" }} size="20px" className="filter-communications-icon" />}*/}
        {/*/>*/}
      </Popover>
    </>
  );
};

export const FilterButton = React.memo(observer(FilterButtonObserver));

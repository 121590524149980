import React, { useState } from "react";
import RestorePasswordFormView from "./RestorePasswordFormView";
import { useNotifier, useRestorePasswordForm, useRootStore } from "../../../../functional/hooks";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { api } from "../../../../functional/services";

function RestorePasswordForm() {
  const {
    step,
    isLoading,
    setStep,
    handleReset,
    handleSendCode,
  } = useRestorePasswordForm();

  return (
    <RestorePasswordFormView
      step={step}
      isLoading={isLoading}
      setStep={setStep}
      handleReset={handleReset}
      handleSendCode={handleSendCode}
    />
  );
}

export default RestorePasswordForm;

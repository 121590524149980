import React from "react";
import { Layout, theme } from "antd";
import "./LayoutHeader.scss";
import clsx from "clsx";
import { ILayoutHeaderView } from "../../../../../functional/types/LayoutHeader.interface";

const { Header } = Layout;
const { useToken } = theme;

export function LayoutHeaderView({ className, ...rest }: ILayoutHeaderView) {
  const { token } = useToken();

  return (
    <Header
      className={clsx("uiKit-layoutHeader", className ?? "")}
      {...rest}
    />
  );
}

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotifier, useRootStore, useDebounce } from "../../../../../../functional/hooks";
import { useRouterHandler } from "../../../../../pages/regulation/hooks/useRouterHandler";
import clsx from "clsx";
import { cardKeys } from "../../../../../../functional/constants/regulation/consts";
import { SearchInputField } from "../../../../../elements/searchInputField/SearchInputField";
import { IMainHeader } from "./MainHeader.interface";
import { HeaderButtons } from "../../components/HeaderButtons/HeaderButtons";
import { Breadcrumbs } from "../../../../../elements/breadcrumbs/Breadcrumbs";
import { breadcrumbsKeys } from "../../../../../elements/breadcrumbs/Breadcrumbs.interface";
import { Icon, IconButton } from "../../../../../uiKit";
import { FiSidebar } from "@react-icons/all-files/fi/FiSidebar";

export const MainHeader: React.FC<IMainHeader> = (props) => {
  const { t } = useTranslation();
  const { regulationStore } = useRootStore();
  const notifier = useNotifier();

  const { getTabKeys, pathname } = useRouterHandler();

  const tabKeys = getTabKeys();

  const [regulationSearch, setRegulationSearch] = useState<string | undefined>(undefined);

  const debounceRegulationSearch = useDebounce(regulationSearch, 500);

  const headerStyles = clsx("regulation-header", "mb-1", {
    "regulation-header__align": props.toolsHide && props.showBreadcrumbs,
  });

  const handleError = () => {
    notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
  };

  const handleGetPlaceholder = () => {
    if (tabKeys?.cardKey === cardKeys.regulation) return t("ui:placeholder.regulation_name");
    if (tabKeys?.cardKey === cardKeys.section) return t("ui:placeholder.section_name");
    if (tabKeys?.cardKey === cardKeys.multiple) return t("ui:placeholder.issue_name");
  };

  const handleResearchItems = () => {
    if (regulationSearch != undefined) {
      if (tabKeys?.cardKey === cardKeys.regulation || tabKeys?.cardKey === cardKeys.multiple) {
        regulationStore.resetRegulationAndSection();
        regulationStore.setRegulationsFilters({
          name: regulationSearch,
          tab: tabKeys.tabKey,
        });
        regulationStore.initialFetchRegulations(true);
      }
      // if (tabKeys?.cardKey === cardKeys.section) {
      //   regulationStore.setSectionsFilters({ name: regulationSearch });
      //   regulationStore.initialFetchSections(true);
      // }
      // if (tabKeys?.cardKey === cardKeys.multiple) {
      //   regulationStore.setRegulationsFilters({
      //     name: regulationSearch,
      //     tab: tabKeys.tabKey,
      //   });
      //   // regulationStore.setSectionsFilters({ name: regulationSearch });
      //   regulationStore.initialFetchRegulations(true);
      //   // regulationStore.initialFetchSections(true);
      // }
    }
  };

  useEffect(() => {
    handleResearchItems();
  }, [debounceRegulationSearch]);

  useEffect(() => {
    setRegulationSearch(undefined);
  }, [pathname]);

  useEffect(() => {
    regulationStore.setOnError(handleError);
  }, []);

  return (
    <div className={headerStyles}>
      {!props.toolsHide && props.availableToolsKeys && (
        <>
          <div className="d-stack-row spacing-2" style={{ marginLeft: 0, width: "40%" }}>
            {!props.isSidebarOpen && (
              <IconButton
                // disabled={!props.metrics.length && !props.availableDashboards.length && !props.myDashboards.length}
                className="flex-shrink-0"
                onClick={props.onSidebarOpenChange}
                children={<Icon component={() => <FiSidebar />} />}
              />
            )}
            <SearchInputField
              value={regulationSearch ?? ""}
              setValue={setRegulationSearch}
              className="regulation-header__input-search"
              placeholder={handleGetPlaceholder()}
              backgroundInherit
              size="middle"
              style={{ width: "100%" }}
            />
          </div>

          <div className="regulation-header-group">
            {/*<DownloadButton />*/}
            <HeaderButtons isSidebarOpen={props.isSidebarOpen} availableToolsKeys={props.availableToolsKeys} />
          </div>
        </>
      )}
      {}

      {/*{showBreadcrumbs && <ButtonAlternative onClick={handleRedirectToBack}>{t("ui:button.go_back")}</ButtonAlternative>}*/}
      {props.showBreadcrumbs && props.routeToken && <Breadcrumbs type={breadcrumbsKeys.regulation} />}
    </div>
  );
};

import React from "react";
import AvatarUploadFormView from "./AvatarUploadFormView";
import { useAvatarUploadForm } from "../../../../../functional/hooks";
import { IAvatarUploadForm } from "../../../../../functional/types";

function AvatarUploadForm(props: IAvatarUploadForm) {
  const {
    uploadInputRef,
    selectedFile,
    setSelectedFile,
    handleUpload,
    handleChangeClick,
    handleInputChanged,
    handleClose,
  } = useAvatarUploadForm(props);

  return (
    <AvatarUploadFormView
      {...props}
      uploadInputRef={uploadInputRef}
      open={props.open}
      selectedFile={selectedFile}
      setSelectedFile={setSelectedFile}
      onUpload={handleUpload}
      onChangeClick={handleChangeClick}
      onInputChanged={handleInputChanged}
      onClose={handleClose}
    />
  );
}

export default AvatarUploadForm;

import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { useApi, useDateHelpers } from "../../../../functional/hooks";
import { api } from "../../../../functional/services";
import { CompanyEmployeeStatsDialogView } from "./CompanyEmployeeStatsDialogView";

interface ICompanyEmployeeStatsDialog {
  open: boolean;
  companyId: number | null;

  onClose(): void;
}

export function CompanyEmployeeStatsDialog(props: ICompanyEmployeeStatsDialog) {
  const dateHelpers = useDateHelpers();

  const [displayMode, setDisplayMode] = useState<"chart" | "table">("table");
  const [tableFilters, setTableFilters] = useState({
    dateFrom: dayjs().subtract(1, "day"),
    dateTo: dayjs(),
  });

  const employeeStatsData = useApi(() =>
    api.adminCompany.getEmployeeStats(
      props.companyId as number,
      tableFilters.dateFrom.toDate(),
      tableFilters.dateTo.toDate()
    )
  );

  useEffect(() => {
    if (props.open) {
      employeeStatsData.fetch();
    } else {
      // employeeStatsData.set([]);
    }
  }, [props.open, tableFilters]);

  const handleClose = () => {
    props.onClose();
    employeeStatsData.set([]);
    setTableFilters({
      dateFrom: dayjs().subtract(1, "day"),
      dateTo: dayjs(),
    });
    setDisplayMode("table");
  };

  const tableItems = useMemo(() => {
    return (employeeStatsData.value ?? []).map((v) => ({
      date: dateHelpers.formatDate(v.date as string, {
        showYearWhenIsCurrent: true,
        formatObject: {
          month: "short",
          hour: undefined,
          minute: undefined,
        },
      }),
      value: v.value,
      full: v.usersCount?.Full ?? 0,
      restricted: v.usersCount?.Restricted ?? 0,
    }))
  }, [employeeStatsData.value]);

  return (
    <CompanyEmployeeStatsDialogView
      {...props}
      displayMode={displayMode}
      setDisplayMode={setDisplayMode}
      onClose={handleClose}
      tableFilters={tableFilters}
      setTableFilters={setTableFilters}
      tableItems={tableItems}
    />
  );
}

import React, { useEffect, useState } from "react";
import { ITimeCountItem } from "./TimeCountItem.intrface";
import { Text } from "../../uiKit";
import "./TimeCountItem.scss";
import clsx from "clsx";

export const TimeCountItem = (props: ITimeCountItem) => {
  const [prevValue, setPrevValue] = useState(props.value);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (props.value !== prevValue) {
      setAnimate(true);

      const timeout = setTimeout(() => {
        setPrevValue(props.value);
        setAnimate(false);
      }, 700);

      return () => clearTimeout(timeout);
    }
  }, [props.value, prevValue]);

  return (
    <div className="time-count-item">
      <div className="time-count-item-wrapper">
        <Text
          className={clsx("time-count-item__value", {
            "fade-up-old-value": animate,
          })}
        >
          {prevValue}
        </Text>
        <Text
          className={clsx("time-count-item__value time-count-item__value_new", {
            "fade-up-new-value": animate,
          })}
        >
          {props.value}
        </Text>
      </div>
      <Text
        className={"time-count-item__label"}
        type="secondary"
      >
        {props.label}
      </Text>
    </div>
  );
};

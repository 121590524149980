import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { PickerDateProps } from "antd/lib/date-picker/generatePicker";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import { DatePickerView } from "./DatePickerView";
import { Button } from "../../../general/button/Button";
import { useHash } from "../../../../../functional/hooks";

export type TDatePickerProps = {
  // firstDayOfWeek?: number;
  disableFuture?: boolean;
  disableToday?: boolean;
  disablePast?: boolean;
  minDate?: Dayjs | null;
  maxDate?: Dayjs | null;
  timeZone?: string;
  readOnly?: boolean;
  isStatic?: boolean;
  hiddenExtraFooter?: boolean;
} & PickerDateProps<Dayjs>;

export const DatePicker = memo(({
  className,
  dropdownClassName,
  value,
  open,
  onChange,
  onSelect,
  onOpenChange,
  disabledDate,
  disableFuture,
  disableToday,
  disablePast,
  minDate,
  maxDate,
  showTime,
  isStatic,
   hiddenExtraFooter,
  ...rest
}: TDatePickerProps) => {

  const { t } = useTranslation();
  const { hashFn } = useHash();
  const datePickerId = useRef<string>(hashFn(require("lodash.uniqueid")() + "-" + new Date().getTime()));

  const currentValue = useRef<Dayjs | undefined | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const checkIsDateDisabled = useCallback(
    (date: Dayjs) => {
      const _nowDate = dayjs();
      const _date = date;
      const _minDate = minDate;
      const _maxDate = maxDate;
      // const _nowDate = dayjs().tz();
      // const _date = date.tz();
      // const _minDate = minDate?.tz();
      // const _maxDate = maxDate?.tz();

      if (disabledDate != null) return disabledDate(date);
      if (_minDate == null && _maxDate == null) {
        if (disablePast && disableFuture) return disableToday ? true : !_date.isSame(_nowDate, "date");
        if (disablePast && !disableToday) return _date.isBefore(_nowDate, "date");
        if (disablePast && disableToday) return _date.isSameOrBefore(_nowDate, "date");
        if (disableFuture && !disableToday) return _date.isAfter(_nowDate, "date");
        if (disableFuture && disableToday) return _date.isSameOrAfter(_nowDate, "date");
        if (!disablePast && !disableFuture && disableToday) return _date.isSame(_nowDate, "date");
      }
      if (_minDate != null && _maxDate != null) return !_date.isBetween(_minDate, _maxDate, "day", "[]");
      if (_minDate != null) return _date.isBefore(_minDate, "date");
      if (_maxDate != null) return _date.isAfter(_minDate, "date");
      return false;
    },
    [minDate, maxDate]
  );

  const getFormat = (val: Dayjs | null, showTime: any) => {
    if (val == null) return "";
    const format = showTime != null ? `L ${typeof showTime === "boolean" ? "HH:mm:ss" : showTime.format}` : "L";
    return val?.format(format);
    // return val.utc(false).tz(undefined, false).format(format);
  };

  const handleOpenChange = (val: boolean) => {
    if (!isOpen && val) {
      currentValue.current = value;
      setIsOpen(val);
      onOpenChange && onOpenChange(true);
    }
  };

  const handleSelect = (val: Dayjs | null) => {
    currentValue.current = val?.utc(false);
    onSelect && val != null && onSelect(val?.utc());
  };

  const handleChange = (val: Dayjs | null) => {
    currentValue.current = val;
    if (val == null) {
      onChange && onChange(null, "");
      setIsOpen(false);
      onOpenChange && onOpenChange(false);
      currentValue.current = null;
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
    onOpenChange && onOpenChange(false);
    currentValue.current = null;
  };

  const handleOutsideClick = (evt: any) => {
    if (!isOpen || showTime == null) return;
    const targetEl =
      evt.target.closest(`.dp-${datePickerId.current}`) ?? evt.target.closest(`.dpd-${datePickerId.current}`);
    if (targetEl == null) {
      onChange &&
        onChange(
          currentValue.current?.utc(false) ?? null,
          getFormat(currentValue.current?.utc(false) ?? null, showTime)
        );
      setIsOpen(false);
      onOpenChange && onOpenChange(false);
      currentValue.current = null;
      // handleCancel();
    } else {
      //
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleOutsideClick);
    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <DatePickerView
      value={value?.tz()}
      className={`${className ?? ""} dp-${datePickerId.current}`}
      dropdownClassName={`${dropdownClassName ?? ""} dpd-${datePickerId.current}`}
      open={showTime != null && !isStatic ? isOpen : open}
      disabledDate={checkIsDateDisabled}
      showTime={typeof showTime === "boolean" ? showTime : showTime == null ? false : { ...showTime }}
      format={(value) => getFormat(value, showTime)}
      onOpenChange={showTime != null && !isStatic ? handleOpenChange : onOpenChange}
      onSelect={showTime != null ? handleSelect : onSelect}
      onChange={showTime != null ? handleChange : onChange}
      isStatic={isStatic}
      renderExtraFooter={(panel) =>
        showTime != null && !hiddenExtraFooter ? (
          <div className="px-2">
            <Button onClick={handleCancel} size="small" children={t("ui:button.cancel")} />
          </div>
        ) : null
      }
      {...rest}
    />
  );
});

import React, { useMemo } from "react";
import { Button, Dialog, DialogActions, Select, Spin, Text } from "../../../uiKit";
import { useTranslation } from "react-i18next";
import { TimeZoneDto } from "../../../../functional/api";
import { useClaims, useRootStore } from "../../../../functional/hooks";
import { permissionKeys } from "../../../../functional/utils/permissions";
import { TTimezoneNotificationDialogTimeZoneValue } from "./TimezoneNotificationDialog";
import { observer } from "mobx-react-lite";

interface ITimezoneNotificationDialogViewProps {
  isOpen: boolean;
  timeZones: TimeZoneDto[];
  timeZoneValue: TTimezoneNotificationDialogTimeZoneValue;
  isLoading: boolean;
  isLoadingCanonical: boolean;
  onTimeZoneValueChange: (timeZoneValue: TTimezoneNotificationDialogTimeZoneValue) => void;
  onSave: () => void;
  isSetTimeZone: boolean;
  handleCloseModal: () => void;
}

const TimezoneNotificationDialogViewObserved = (props: ITimezoneNotificationDialogViewProps) => {
  const { authStore } = useRootStore();
  const { t } = useTranslation();
  const claims = useClaims();

  const getTimeZoneFromUtcString: string = useMemo(() => {
    if (props.timeZones.find((tz) => tz.id == authStore.getCurrentCompany?.timeZoneId) != null)
      return `(${props.timeZones.find((tz) => tz.id == authStore.getCurrentCompany?.timeZoneId)?.title})`;
    if (authStore.getCurrentCompany?.timeZoneFromUtc != null) {
      return authStore.getCurrentCompany?.timeZoneFromUtc < 0
        ? `(UTC${authStore.getCurrentCompany?.timeZoneFromUtc})`
        : `(UTC+${authStore.getCurrentCompany?.timeZoneFromUtc})`;
    }
    return "";
  }, [props.timeZones, authStore.getCurrentCompany?.timeZoneFromUtc, authStore.getCurrentCompany?.timeZoneId]);

  // if (
  //   // claims.has(permissionKeys.company.edit.timeZone) &&
  //   // (authStore.getCurrentCompany?.timeZoneId ?? "").trim().length != 0 &&
  //   (authStore.getInitialInfo?.identity?.timeZoneId ?? "").trim().length != 0
  // )
  //   return null;

  return (
    <Dialog
      open={props.isOpen}
      closable={false}
      title={t("text:timezone_notification_dialog.text1")}
    >
      <Spin spinning={props.timeZones.length == 0 || props.isLoadingCanonical}>
        <div className="d-stack-column spacing-3">
          <Text className="no-select" children={t("text:timezone_notification_dialog.text2")} />
          <div className="d-stack-column spacing-1">
            <Select
              className="full-width"
              placeholder={t("ui:placeholder.time_zone")}
              disabled={props.isLoading}
              value={props.timeZoneValue.user ?? null}
              onChange={(value) => props.onTimeZoneValueChange({ user: value })}
              items={props.timeZones.map((tz, index) => ({
                id: index,
                value: tz.id!,
                text: tz.idWithUtc!,
                additionalProps: { label: tz.idWithUtc },
              }))}
            />
          </div>
          <Text
            className="no-select"
            children={props.isSetTimeZone
              ? t("text:timezone_notification_dialog.text3")
              : <div dangerouslySetInnerHTML={{
                __html: t("text:timezone_notification_dialog.text3_change_time_zone", {
                  oldTimeZone: authStore.getInitialInfo?.identity?.timeZoneId,
                  newTimeZone: props.timeZoneValue.user
                })
              }}></div>
            }
          />
        </div>
      </Spin>
      <DialogActions>
        {!props.isSetTimeZone ? (
          <Button
            variant="text"
            children={t("ui:button.leave")}
            onClick={props.handleCloseModal}
          />
        ) : null}

        <Button
          disabled={
            props.isLoadingCanonical || props.timeZones.length == 0 || props.timeZoneValue.user == null
            // (claims.has(permissionKeys.company.edit.timeZone) && props.timeZoneValue.company == null)
          }
          loading={props.isLoading}
          onClick={props.onSave}
          variant="filled"
          children={props.isSetTimeZone ? t("ui:button.save") : t("ui:button.changed")}
        />
      </DialogActions>
    </Dialog>
  );
};

export const TimezoneNotificationDialogView = observer(TimezoneNotificationDialogViewObserved);

import React, { memo } from "react";
import { basicColors, WIDTH } from "./misc/consts";
import { transformColor } from "./utils/colorFunctions";
import { MoveWrapper } from "./MoveWrapper/MoveWrapper";
import { IColorPickerView } from "./ColorPicker.interface";
import { Badge, Button, IconButton, Popover } from "../../../../uiKit";

export const ColorPickerView = memo((props: IColorPickerView) => {
  return (
    <Popover
      destroyTooltipOnHide
      placement="bottom"
      trigger="click"
      content={
        <div className="color-picker-wrapper" style={{ width: WIDTH }}>
          <div className="color-picker-basic-color">
            {basicColors.map((basicColor) => (
              <Button
                className={basicColor === props.selfColor.hex ? " active" : ""}
                key={basicColor}
                style={{ backgroundColor: `var(--color-tag-bg-{basicColor}` }}
                onClick={() => {
                  props.setSelfColor(transformColor("hex", basicColor));
                }}
              />
            ))}
          </div>
          <MoveWrapper
            className="color-picker-saturation"
            style={{ backgroundColor: `hsl(${props.selfColor.hsv.h}, 100%, 50%)` }}
            onChange={props.onMoveSaturation}
          >
            <div
              className="color-picker-saturation_cursor"
              style={{
                backgroundColor: props.selfColor.hex,
                left: props.saturationPosition.x,
                top: props.saturationPosition.y,
              }}
            />
          </MoveWrapper>
          <MoveWrapper className="color-picker-hue" onChange={props.onMoveHue}>
            <div
              className="color-picker-hue_cursor"
              style={{
                backgroundColor: `hsl(${props.selfColor.hsv.h}, 100%, 50%)`,
                left: props.huePosition.x,
              }}
            />
          </MoveWrapper>
          <div className="color-picker-color" style={{ backgroundColor: props.selfColor.hex }} />
        </div>
      }
    >
      <Badge color={props.color} dot offset={[-2, 5]} style={{ borderColor: "var(--color-gray-weak)" }}>
        <IconButton title={props.title} disabled={props.disabled} icon={props.icon} />
      </Badge>
    </Popover>
  );
});
